import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { general, expert } from '../../axiosConfig'
import { Context } from '../../contexts/Store'
import { THEME_COLORS } from '../../ThemeConfig'
import CancelSignupModal from './modals/CancelSignupModal'

function ExpertDetails({ completed, setCompleted }) {
  const navigate = useNavigate()
  const [experienceModal, setExperienceModal] = useState(false)
  const [selectedExperience, setSelectedExperience] = useState('')
  const [selectedExperienceID, setSelectedExperienceID] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const [loading, setLoading] = useState(false)
  const [languages, setLanguages] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])

  const [err, setErr] = useState('')
  const [qualifications, setQualifications] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState([])

  const [qualificationId, setQualificationId] = useState([])
  const [expertiseId, setExpertiseId] = useState([])
  const [languageId, setLanguageId] = useState([])
  const [experienceYear, setExperienceYear] = useState('')

  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [expertises, setExpertises] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState([])

  const [isQualificationSelected, setIsQualificationSelected] = useState(false)
  const [showQualifications, setShowQualifications] = useState(false)

  const [experience, setExperience] = useState([
    {
      id: 10,
      year: '0-1 years',
    },
    {
      id: 20,
      year: '1-2 years',
    },
    {
      id: 30,
      year: '2-3 years',
    },
    {
      id: 40,
      year: '3-4 years',
    },
    {
      id: 50,
      year: '5 + years',
    },
  ])

  useEffect(() => {
    general
      .get('/languages/')
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode == 6000) {
          setLanguages(data)
        } else {
          alert(
            'Some error occured while loading languages, please refresh the page',
          )
        }
      })
      .catch((error) => {
        alert(
          'Some error occured while loading languages, please refresh the page',
        )
      })
    expert
      .get('/area-of-expertises/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode == 6000) {
          setExpertises(data)
        } else {
          alert(
            'Some error occured while loading expertises, please refresh the page',
          )
        }
      })
      .catch((error) => {
        alert(
          'Some error occured while loading expertises, please refresh the page',
        )
      })
  }, [])
  const getQualifications = () => {
    let accessToken = state.user_details.access_token
    expert
      .get(`/qualifications/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setQualifications(data)
        } else {
          alert(
            'Some error occured while loading qualifications, please refresh the page',
          )
        }
      })
      .catch((error) => {
        alert(
          'Some error occured while loading qualifications, please refresh the page',
        )
      })
  }

  const getProfessionalDetails = () => {
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-professional-info/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        console.log(response.data, 'response.data')
        if (StatusCode === 6000) {
          setSelectedQualifications(data.qualification_names)
          setSelectedExpertises(data.area_of_expertise_names)
          setExperienceYear(parseInt(data.experience))
          if (data.experience) {
            setSelectedExperience(
              experience.find((item) => item.id == data.experience).year,
            )
          }
          setSelectedLanguages(data.language_preference_names)
          setQualificationId(data.qualifications)
          setExpertiseId(data.area_of_expertises)
          setLanguageId(data.language_preferences)
        } else {
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  const renderLanguages = () => {
    const handleLanguageClick = (language, id) => {
      if (selectedLanguages.includes(language)) {
        setSelectedLanguages((prev) => prev.filter((item) => item !== language))
        setLanguageId((prev) => prev.filter((item) => item !== id))
      } else {
        setSelectedLanguages((prev) => [...prev, language])
        setLanguageId((prev) => [...prev, id])
      }
    }

    return languages.map((language) => (
      <Languagetext
        key={language.id}
        onClick={() => handleLanguageClick(language.name, language.id)}
        className={selectedLanguages.includes(language.name) ? 'selected' : ''}
      >
        {language.name}
      </Languagetext>
    ))
  }

  const renderExpertise = () => {
    const handleExpertiseClick = (expertise, id) => {
      if (selectedExpertises.includes(expertise)) {
        setSelectedExpertises((prev) =>
          prev.filter((item) => item !== expertise),
        )
        setExpertiseId((prev) => prev.filter((item) => item !== id))
      } else {
        setSelectedExpertises((prev) => [...prev, expertise])
        setExpertiseId((prev) => [...prev, id])
      }
    }

    return expertises.map((expertise) => (
      <ExpertiseText
        key={expertise.id}
        onClick={() => handleExpertiseClick(expertise.name, expertise.id)}
        className={
          selectedExpertises.includes(expertise.name) ? 'selected' : ''
        }
      >
        {expertise.name}
      </ExpertiseText>
    ))
  }

  const handleQualificationSelection = (name, id) => {
    if (selectedQualifications.includes(name)) {
      setSelectedQualifications((prev) => prev.filter((item) => item !== name))
      setQualificationId((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedQualifications((prev) => [...prev, name])
      setQualificationId((prev) => [...prev, id])
    }
    setIsQualificationSelected(true)
  }

  // to handle outside click of qualificationDropdown
  const qualificationDropdownRef = useRef()
  const handleClickOutsideQualification = (event) => {
    if (
      qualificationDropdownRef.current &&
      !qualificationDropdownRef.current.contains(event.target)
    ) {
      // setTimeout(() => {
      setShowQualifications(false)
      // }, 50);
    } else {
      setShowQualifications(true)
    }
  }
  useEffect(() => {
    if (showQualifications) {
      document.addEventListener('mousedown', handleClickOutsideQualification)

      return () => {
        document.removeEventListener(
          'mousedown',
          handleClickOutsideQualification,
        )
      }
    }
  }, [showQualifications])

  // to handle outside click of experienceDropdown
  const experienceDropdownRef = useRef()
  const handleClickOutsideExperience = (event) => {
    if (
      experienceDropdownRef.current &&
      !experienceDropdownRef.current.contains(event.target)
    ) {
      // setTimeout(() => {
      setExperienceModal(false)
      // }, 50);
    } else {
      setExperienceModal(true)
    }
  }
  useEffect(() => {
    if (experienceModal) {
      document.addEventListener('mousedown', handleClickOutsideExperience)

      return () => {
        document.removeEventListener('mousedown', handleClickOutsideExperience)
      }
    }
  }, [experienceModal])

  const renderQualification = () => {
    return qualifications.map((qualification) => {
      const isQualificationSelected = selectedQualifications.includes(
        qualification.name,
      )

      return (
        <QualificationTextContainer key={qualification.id}>
          <QualificationText
            onClick={() => {
              handleQualificationSelection(qualification.name, qualification.id)
              // setShowQualifications(false);
            }}
            className={
              isQualificationSelected ? 'selected text active' : 'text'
            }
          >
            {qualification.name}
          </QualificationText>
        </QualificationTextContainer>
      )
      // );
    })
  }

  const CancelButton = ({ qualificationName }) => {
    return (
      <Button onClick={() => handleCancelQualification(qualificationName)}>
        X
      </Button>
    )
  }

  const handleCancelQualification = (qualificationName) => {
    setSelectedQualifications((prev) =>
      prev.filter((item) => item !== qualificationName),
    )
    setIsQualificationSelected(true)
  }

  const handleExperienceSelection = (experience) => {
    setSelectedExperience(experience.year)
    setExperienceYear(experience.id)
    setExperienceModal(false)
  }
  const [formData, setFormData] = useState({
    qualifications: '', // Add default values if needed
    area_of_expertises: '',
    total_relevant_experience: '',
    language_preferences: '',
  })

  const handleSubmit = (e) => {
    const qualification = JSON.stringify(qualificationId)
    const expertise = JSON.stringify(expertiseId)
    const experience = JSON.stringify(experienceYear)
    const language = JSON.stringify(languageId)

    // console.log(qualification);

    if (qualificationId && expertiseId && experienceYear && languageId) {
      // console.log(formData);
      setLoading(true) // Set loading to true when the request starts

      const data = {
        qualifications: qualification,
        area_of_expertises: expertise,
        total_relevant_experience: experienceYear,
        language_preferences: language,
      }

      expert
        .post('/add-professional-info/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data

          setErr('')
          setLoading(false)
          completed.expert_details = 'tick'
          navigate('/auth/working-status') // Navigate to Expert-Details page
          // }
        })
        .catch((error) => {
          console.error('Error creating post personal-infooo:', error.response)
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
          setLoading(false) // Make sure to set loading to false in case of an error
        })
    } else {
      setErr('*This field is mandatory')
    }
  }

  useEffect(() => {
    const isNewRegitrationStarted = state.nrs
    if (isNewRegitrationStarted !== true) {
      navigate('/auth/login-page/')
    }
  }, [state])

  useEffect(() => {
    getQualifications()
  }, [])

  useEffect(() => {
    getProfessionalDetails()
  }, [])
  return (
    <>
      {isCancelModalOpen && (
        <CancelSignupModal
          isModalOpen={isCancelModalOpen}
          setIsModalOpen={setIsCancelModalOpen}
        />
      )}
      <Div></Div>
      <Main>
        <Wrapper>
          <TitileBox>
            <Titile>Expert Details</Titile>
            <ExitBox
              onClick={() => {
                setIsCancelModalOpen(true)
              }}
            >
              <Exit
                src={require('../../assets/image/loginpage/close.svg').default}
                alt="Exit_icon"
              />
              Cancel
            </ExitBox>
          </TitileBox>
          <ItemFlexContainer style={{ marginTop: '60px' }}>
            <Left>
              <Heading>
                <TopBox>
                  <SubHeading>Professional Info*</SubHeading>
                  <SubTitile>Provide your Professional Info</SubTitile>
                </TopBox>
              </Heading>
            </Left>
            <Right>
              {/* <Details> */}
              <QualificationTitleText>Qualification *</QualificationTitleText>
              {selectedQualifications.length > 0 && (
                <>
                  <Flex>
                    {selectedQualifications.map((qualification) => (
                      <>
                        <WrapperSection key={qualification}>
                          <QualificationSelectedText className="selected">
                            {qualification}
                          </QualificationSelectedText>
                          <CancelButton qualificationName={qualification} />
                        </WrapperSection>
                      </>
                    ))}
                  </Flex>
                </>
              )}
              <ExpertBox
                ref={qualificationDropdownRef}
                onClick={() => setShowQualifications(!showQualifications)}
              >
                <SelectedDataBox>
                  <p>Select your Qualification * </p>
                </SelectedDataBox>
                <ListDown>
                  <ListIcon
                    src={
                      require('../../assets/image/loginpage/list_down.svg')
                        .default
                    }
                    alt="list_down"
                    isOpen={showQualifications}
                  />
                </ListDown>
                <DropList className={showQualifications && 'view-modal'}>
                  {renderQualification()}
                </DropList>
              </ExpertBox>
              {qualificationId.length == 0 && err ? (
                <ErrorRed className="error-message">{err}</ErrorRed>
              ) : (
                ''
              )}
              <Area>
                <QualificationTitleText>
                  Area of Expertise *
                </QualificationTitleText>
                <Textfelid>{renderExpertise()}</Textfelid>
              </Area>
              {expertiseId.length == 0 && err ? (
                <ErrorRed className="error-message">{err}</ErrorRed>
              ) : (
                ''
              )}
            </Right>
          </ItemFlexContainer>

          <ItemFlexContainer style={{ marginTop: '40px' }}>
            <Left>
              <Heading>
                <TopBox>
                  <SubHeading>Experience*</SubHeading>
                  <SubTitile>Provide your Experience</SubTitile>
                </TopBox>
              </Heading>
            </Left>
            <Right>
              <Experience>
                <ExperienceBox
                  ref={experienceDropdownRef}
                  onClick={() => setExperienceModal(!experienceModal)}
                >
                  <SelectedDataBox>
                    <p>Total Relevant Experience *</p>
                    <span style={{ fontSize: '16px' }}>
                      {selectedExperience}
                    </span>
                  </SelectedDataBox>
                  <ListDown>
                    <ListIcon
                      src={
                        require('../../assets/image/loginpage/list_down.svg')
                          .default
                      }
                      alt="list_down"
                      isOpen={experienceModal}
                    />
                  </ListDown>
                  {experienceModal && (
                    <DropList className={experienceModal && 'view-modal'}>
                      {experience.map((ex) => (
                        <DropDowns
                          key={ex.id}
                          onClick={() => handleExperienceSelection(ex)}
                        >
                          {ex.year}
                        </DropDowns>
                      ))}
                    </DropList>
                  )}
                </ExperienceBox>
              </Experience>
              {selectedExperience == '' && err ? (
                <ErrorRed className="error-message">{err}</ErrorRed>
              ) : (
                ''
              )}

              <Language>
                <LanguageHead>Language Preference *</LanguageHead>
                <LanguageFelid>
                  {renderLanguages()}
                  <LanguageBox></LanguageBox>
                </LanguageFelid>
                {expertiseId.length == 0 && err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )}
              </Language>
            </Right>
          </ItemFlexContainer>

          <ItemFlexContainer style={{ marginTop: '100px' }}>
            <Left></Left>
            <Right>
              <Bottom>
                <LeftBox>*These fields are mandatory</LeftBox>
                <RightBox>
                  <Previous
                    onClick={() => {
                      completed.expert_details = ''
                      navigate('/auth/profile-about-you')
                    }}
                  >
                    Previous
                  </Previous>
                  <Save
                    onClick={() => {
                      handleSubmit()
                      completed.expert_details = 'tick'
                    }}
                  >
                    Save & Continue
                  </Save>
                </RightBox>
              </Bottom>
            </Right>
          </ItemFlexContainer>
        </Wrapper>
      </Main>
    </>
  )
}

export default ExpertDetails
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
`
const ItemFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const WrapperSection = styled.div`
  display: flex;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #333;
  text-align: center;

  padding: 7px;
  gap: 8px;
`
const Button = styled.button`
  color: #333;
  cursor: pointer;
`
const ExperienceBox = styled.div`
  user-select: none;
  height: auto;
  cursor: pointer;
  p {
    color: #818181;
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  background-color: #f4f4f4;
  padding: 11px 20px;
  position: relative;
`
const Experience = styled.div``
const LanguageFelid = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`
const LanguageBox = styled.div``
const Languagetext = styled.p`
  border: 1px solid #e2e2e2;
  width: fit-content;
  border-radius: 25px;
  padding: 5px 20px;
  cursor: pointer;
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
`
const LanguageHead = styled.p`
  margin-bottom: 12px;
  font-family: 'dm_sans_medium';
`
const QualificationTitleText = styled.div`
  font-family: 'dm_sans_medium';
  font-size: 16px;
  margin-bottom: 12px;
  color: #3e3e53;
`
const Language = styled.div`
  margin-top: 28px;
`
const Textfelid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
const Expertise = styled.div``
const ExpertiseText = styled.p`
  font-family: 'DM_sans';

  border: 1px solid #e2e2e2;
  width: fit-content;
  padding: 5px 20px;
  border-radius: 25px;
  cursor: pointer;
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
`
const AreaHead = styled.p`
  margin-bottom: 2%;
  font-family: 'dm_sans_medium';
`
const Area = styled.div`
  width: 100%;
  margin-top: 28px;
`
const Main = styled.div`
  padding: 68px 0;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  scroll-behavior: smooth;
`
const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  @media all and (max-width: 900px) {
    width: 90%;
  }
`
const Details = styled.div`
  margin-bottom: 3%;
`
const Left = styled.div`
  width: 40%;
`
const DropList = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 0;
  overflow-y: hidden;
  transition: all 0.5 ease-in-out;

  &.view-modal {
    height: auto;
    max-height: calc(100vh - 200px);
    transition: all 0.5 ease-in-out;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    padding: 8px 10px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 50px;
  left: 0;
  border-radius: 5px;
  background-color: #fff;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
`
const DropDowns = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 8px;
  /* margin: 10px; */
  transition: all 0.2s;
  margin-bottom: 4px;
  font-size: 14px;

  :hover {
    background: #f4f4f4;
  }
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
  &.example::-webkit-scrollbar {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
const SelectedDataBox = styled.div``
const ListIcon = styled.img`
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : null)};
  transition: 0.1s;
`
const ExpertBox = styled.div`
  user-select: none;
  cursor: pointer;
  p {
    color: #818181;
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  /* margin-top: 4%; */
  width: 100%;
  background-color: #f4f4f4;
  padding: 11px 20px;
  position: relative;
`
const ListDown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const TitileBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Titile = styled.h1`
  font-size: 30px;
  /* font-weight: 500; */
  font-family: 'dm_sans_bold';
`
const Right = styled.div`
  width: 55%;
`
const Div = styled.div`
  border-top: 2px solid #366eef;
  width: 50%;
`
const Heading = styled.div``
const ExitBox = styled.div`
  /* justify-content: end; */
  align-items: center;
  display: flex;
  /* margin-right: 5px; */
  color: #1da687;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  gap: 4px;
  padding: 6px;
  margin: 0 0 0 auto;
  width: fit-content;
  font-family: 'DM_sans_medium';
`
const Exit = styled.img`
  display: block;
  width: 12px;

  margin-right: 5px;
`
const SubTitile = styled.h5`
  color: #818181;
  font-size: 14px;
  font-family: 'DM_sans';
`
const SubHeading = styled.h3`
  font-size: 20px;
  /* font-weight: 400; */
  color: #3e3e53;
  font-family: 'DM_sans_medium';
`
const TopBox = styled.div``

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const LeftBox = styled.p`
  color: #818181;
  font-size: 14px;
  text-align: start;
`
const RightBox = styled.div`
  align-items: flex-end;
  display: flex;
`
const Save = styled.button`
  background-color: #366ee3;
  border: 2px solid #366ee3 !important;
  padding: 14px 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-family: 'DM_sans_medium';
`
const Previous = styled.button`
  background-color: #fff;
  border: 2px solid #d3d3d3;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  font-family: 'DM_sans_medium';
`
const QualificationTextContainer = styled.div`
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: #f4f4f4;
  }
`
const QualificationSelectedText = styled.div`
  padding: 4px 8px;
  font-size: 14px;
`
const QualificationText = styled.div`
  padding: 4px 8px;

  font-size: 14px;
  &.active {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 20px;
`
