import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";

function RenderingChip({
    array,
    selectedChips,
    setSelectedChipsKey,
    setFunction,
}) {
    const renderFunction = () => {
        const handleChipClick = (itemId) => {
            if (selectedChips?.includes(itemId)) {
                setFunction((prev) => ({
                    ...prev,
                    [setSelectedChipsKey]: selectedChips.filter(
                        (id) => id !== itemId
                    ),
                }));
            } else {
                setFunction((prev) => ({
                    ...prev,
                    [setSelectedChipsKey]: [...selectedChips, itemId],
                }));
            }
        };
        return array.map((chip) => (
            <LabelText
                key={chip.id}
                onClick={() => handleChipClick(chip.id)}
                className={selectedChips?.includes(chip.id) ? "selected" : ""}
            >
                {chip.name}
            </LabelText>
        ));
    };

    return (
        <>
            <RoundLabel>
                <LabelFelid>
                    {renderFunction()}
                    <LabelBox></LabelBox>
                </LabelFelid>
            </RoundLabel>
        </>
    );
}
export default RenderingChip;

const LabelFelid = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
`;
const LabelBox = styled.div``;
const LabelText = styled.p`
    border: 1px solid #e2e2e2;
    width: fit-content;
    border-radius: 25px;
    padding: 5px 20px;

    &.selected {
        border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
        color: ${THEME_COLORS.chips_blue_on_container_2};
        background-color: ${THEME_COLORS.chips_blue_container};
    }
`;
const RoundLabel = styled.div`
    margin-bottom: 20px;
`;
