import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";

import { THEME_COLORS } from "../../../ThemeConfig.js";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne.jsx";
import SuccessToast from "../manage_profile/toast/SuccessToast.jsx";
import { expert } from "../../../axiosConfig.js";
import { Context } from "../../../contexts/Store.js";

const FeedbackPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const email_Id = state.user_details.email;
    const phone = state.user_details.phone;
    const first_name = state.user_details.first_name;
    const last_name = state.user_details.last_name;

    const navigate = useNavigate();

    const clearStates = () => {
        setName("");
        setEmail("");
        setMessage("");
        setShowErrors(false);
    };

    const handleEmail = () => {
        if (validator.isEmail(email)) {
            setEmailError(false);
            return true;
        } else {
            setEmailError(true);
            return false;
        }
    };

    const data = {
        firstname: first_name,
        lastname: last_name,
        email: email_Id,
        phoneNumber: phone,
        message: message,
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message) {
            expert
                .post("/add-feedback/", data, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setShowSuccessToast(true);
                        setTimeout(() => {
                            setShowSuccessToast(false);
                        }, 3000);
                        clearStates();
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        } else {
            setShowErrors(true);
        }
    };

    const handleCancel = () => {
        clearStates();
        navigate(-1);
    };

    const handleInputValidation = (inputValue, setInputState) => {
        const regex = /^[A-Za-z]+$/; // Regular expression to match only letters

        if (regex.test(inputValue) || inputValue === "") {
            setInputState(inputValue);
        }
    };

    return (
        <Main>
            {showSuccessToast && <SuccessToast sentMessage />}
            <Title>Send Feedback</Title>
            <Content>
                <Left>
                    <SubTitle>Let us know your feedback</SubTitle>
                    <Description>
                        We value your feedback and strive to provide the best
                        possible experience for you. If you have any questions,
                        concerns, or comments, please don't hesitate to reach
                        out to us.
                    </Description>
                </Left>
                <Right>
                    <Form>
                        <Top>
                            <InputWrapper
                                className="input-wrapper"
                                style={{ height: "auto" }}
                            >
                                <FormLeft>
                                    <FormLabel
                                        htmlFor="message"
                                        className={
                                            message
                                                ? "input-label show"
                                                : "input-label"
                                        }
                                    >
                                        Message
                                    </FormLabel>
                                    <FormText
                                        id="message"
                                        name="message"
                                        type="text"
                                        value={message}
                                        rows="3"
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        placeholder="Message"
                                        className={
                                            message
                                                ? "textarea-value active"
                                                : "textarea-value"
                                        }
                                    ></FormText>
                                </FormLeft>
                            </InputWrapper>
                            {showErrors && !message && (
                                <Error className="error">
                                    This field is required.
                                </Error>
                            )}
                        </Top>
                        <Bottom>
                            <ButtonTypeOne
                                text="Cancel"
                                color={THEME_COLORS.dark_500}
                                bkgcolor={THEME_COLORS.white}
                                border={THEME_COLORS.light_200}
                                onclick={handleCancel}
                            />
                            <ButtonTypeOne
                                text="Submit"
                                ml="10px"
                                color={THEME_COLORS.white}
                                bkgcolor={
                                    THEME_COLORS.chips_blue_on_container_2
                                }
                                onclick={handleSubmit}
                            />
                        </Bottom>
                    </Form>
                </Right>
            </Content>
        </Main>
    );
};

export default FeedbackPage;

const Main = styled.div`
    width: 80%;
    height: calc(100vh - 69.6px);
    margin: 0 auto;
    padding: 88px 0;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Title = styled.h3`
    margin-bottom: 66px;
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.black};
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    height: 78%;
`;
const Left = styled.div`
    width: 30%;
`;
const SubTitle = styled.h4`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 6px;
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const Right = styled.div`
    width: 60%;
    height: 100%;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const Top = styled.div``;
const Wrapper = styled.div`
    margin-bottom: 20px;
`;
const InputWrapper = styled.div``;
const FormLeft = styled.div`
    flex-grow: 1;
`;
const Error = styled.small`
    margin-top: 10px;
`;
const FormLabel = styled.label`
    &.show {
        display: block;
    }
`;
const FormInput = styled.input`
    &.active {
        font-weight: 500;
    }
`;
const FormText = styled.textarea`
    &.active {
        font-weight: 500;
    }
`;
const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;
