import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { Context } from '../../../../contexts/Store'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'

function PastIllness({
  formType,
  setdraftModal,
  session_id,
  pastIllnessFormFields,
  setpastIllnessFormFields,
  initialPastIllnessFieldValues,
  handlePastIllnessFormSubmit,
  detectChanges,
}) {
  const { id } = useParams()
  const pastRef = useRef(null)
  const psychRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token

  const { pastMedical, psychiatricHistory } = pastIllnessFormFields

  const [showErrors, setShowErrors] = useState(false)

  const handleCancel = () => {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        formType: 'Chief complaints & Summary',
      },
    })
  }

  useEffect(() => {
    if (pastMedical) pastRef.current.rows = 3
    if (psychiatricHistory) psychRef.current.rows = 3
  }, [])

  const handleKeyUp = (e, refType) => {
    if (e.target.value !== '') {
      const textareaLineHeight = 24
      const textareaMaxHeight = textareaLineHeight * 3
      const newRows = Math.ceil(e.target.scrollHeight / textareaLineHeight)
      if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
        refType.current.rows = newRows
      }
    } else {
      refType.current.rows = 1
    }
  }

  // useEffect(() => {
  //     console.log(showErrors, "showErrors in past illness");
  // }, [showErrors]);

  return (
    <>
      <FormContainer>
        <Top>
          <div>
            <FormTitle>Past Illness</FormTitle>
            <Label>
              List prior significant medical conditions or illnesses
            </Label>
          </div>
        </Top>

        <FormInputs>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: pastMedical && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="history"
                  className={pastMedical ? 'input-label show' : 'input-label'}
                >
                  Past medical
                </FormLabel>
                <FormText
                  id="history"
                  name="history"
                  value={pastMedical}
                  type="text"
                  rows="1"
                  ref={pastRef}
                  onChange={(e) => {
                    setpastIllnessFormFields((prev) => ({
                      ...prev,
                      pastMedical: e.target.value,
                    }))
                    handleKeyUp(e, pastRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Past medical"
                  className={
                    pastMedical ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !pastMedical && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: psychiatricHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="diagnosis"
                  className={
                    psychiatricHistory ? 'input-label show' : 'input-label'
                  }
                >
                  Psychiatric history
                </FormLabel>
                <FormText
                  id="diagnosis"
                  name="diagnosis"
                  value={psychiatricHistory}
                  type="text"
                  rows="1"
                  ref={psychRef}
                  onChange={(e) => {
                    setpastIllnessFormFields((prev) => ({
                      ...prev,
                      psychiatricHistory: e.target.value,
                    }))
                    handleKeyUp(e, psychRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Psychiatric history"
                  className={
                    psychiatricHistory
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !psychiatricHistory && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
        </FormInputs>
        <Buttons>
          <ButtonTypeOne
            className={'case'}
            text="Back"
            color={THEME_COLORS.dark_500}
            bkgcolor={THEME_COLORS.white}
            border={THEME_COLORS.light_400}
            onclick={() => {
              if (
                detectChanges(
                  initialPastIllnessFieldValues,
                  pastIllnessFormFields,
                )
              ) {
                setpastIllnessFormFields(initialPastIllnessFieldValues)
                handleCancel()
              } else {
                handleCancel()
              }
            }}
          />
          <ButtonTypeOne
            text="Save"
            color={THEME_COLORS.white}
            bkgcolor={THEME_COLORS.chips_blue_on_container_2}
            onclick={() => {
              // console.log('success')
              handlePastIllnessFormSubmit()
            }}
          />
        </Buttons>
      </FormContainer>
    </>
  )
}

export default PastIllness

const FormContainer = styled.div`
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
`
const FormInputs = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Wrapper = styled.div`
  margin-bottom: 20px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small`
  margin-top: 5px;
`
const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
`

const Label = styled.p`
  /* width: 40%; */
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`
const ButtonContainer = styled.div`
  color: #fff;
  background-color: #366ee3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 30%; */
  margin: 0 auto;
  div.one {
    margin-right: 20px;
  }
`
