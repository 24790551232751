import React from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'

const ReviewCard = ({ review, index, active, setActive, loadReview }) => {
  return (
    <Card
      onClick={() => setActive(index)}
      className={index === active && 'active'}
      loadReview={loadReview}
    >
      <CardTop>
        <TopLeft>
          {review.profile_photo ? (
            <ProfileImage>
              <img src={review.profile_photo} alt="profile" />
            </ProfileImage>
          ) : (
            <ProfileImage>
              <img
                src={
                  require('../../../assets/image/my_account/avatar.svg').default
                }
                alt="profile"
              />
            </ProfileImage>
          )}
          <Title>
            <Status>{review.concern}</Status>
            <Name>{review.patient?.full_name}</Name>
          </Title>
        </TopLeft>
        <TopRight>{review.date}</TopRight>
      </CardTop>
      <CardMiddle>
        <Content>{review.review}</Content>
        <RatingBox>
          <Rating>
            <Text>Session Rating</Text>
            {Array.from({ length: review.rating }, (value, index) => (
              <img
                key={index}
                src={
                  require('../../../assets/image/my_account/smiley.svg').default
                }
                alt="star"
              />
            ))}
          </Rating>
          <Rating>
            <Text>Therapist Rating</Text>
            {Array.from({ length: review.rating }, (value, index) => (
              <img
                key={index}
                src={
                  require('../../../assets/image/my_account/full_star_green.svg')
                    .default
                }
                alt="star"
              />
            ))}
            <Number>
              {review.rating % 1 === 0 ? `${review.rating}.0` : review.rating}
            </Number>
          </Rating>
        </RatingBox>
      </CardMiddle>
    </Card>
  )
}

export default ReviewCard

const Card = styled.li`
  padding: 16px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_400};
  box-shadow: ${THEME_COLORS.elevation_expert_e4};
  border-radius: 10px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    border: 1px solid ${THEME_COLORS.others_green};
  }
  @media all and (max-width: 1280px) {
    &:nth-child(2) {
      margin-bottom: ${(props) => props.loadReview && '0'};
      box-shadow: ${(props) =>
        props.loadReview ? THEME_COLORS.elevation_expert_e4 : 'none'};
    }
  }
`
const CardTop = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`
const TopLeft = styled.div`
  display: flex;
  align-items: center;
`
const ProfileImage = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  img {
    width: 100%;
    height: 100%;
  }
`
const Title = styled.div``
const Status = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const Name = styled.h4`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.chips_blue_on_container};
`
const TopRight = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_para};
`
const CardMiddle = styled.div`
  padding: 10px;
  background: ${THEME_COLORS.light_200};
  border-radius: 6px;
  margin-bottom: 10px;
`
const Content = styled.div`
  margin-bottom: 8px;
  text-align: justify;
  color: #3e3e53;
`
const RatingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Rating = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 17px;
    height: 16px;
  }
`
const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_para};
  margin-right: 6px;
  &.feedback {
    margin-bottom: 2px;
  }
`
const Number = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  margin-left: 4px;
`
const CardBottom = styled.div``
const ListItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const ListItem = styled.li`
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 4px 10px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 53px;
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_title};
`
