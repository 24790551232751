import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_details: {
        is_verified: false,
        header: "",
        phone: "",
        country: "IN",
        username: "",
        password: "",
        access_token: "",
        user_id: "",
        refresh_token: "",
        email: "",
        expert_Id: "",
        first_name: "",
        last_name: "",
        gender: "",
        age: "",
        specialization: "",
        location: "",
        activeOption: 1,
        formType: "Chief complaints & Summary",
        is_default_time_slot: false,
        session_exist: false,
        active_session: false,
    },
    isFollowUpActive: false,
    activeOption: "about",
    isConfirmReschedule: false,
    isModal: false,
    time_slot: {
        date: {},
        time: {},
    },
    nrs: false, //nrs - new registeration started
    // status: "Completed",
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};
export const Context = createContext(initialState);

export default Store;
