import React, { useContext, useEffect, useState } from "react";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";
import { authentication } from "../../axiosConfig";

function LoginPage() {
    const navigate = useNavigate();
    //states
    const [email, setEmail] = useState("");

    const [isChecked, setChecked] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, SetPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { dispatch } = useContext(Context);

    const handleSubmit = () => {
        // if (isChecked) {
        if (username != null && password != null) {
            setIsLoading(true);
            setError("");
            authentication
                .post("/login/", {
                    email,
                    password,
                })
                .then(function (response) {
                    const { StatusCode } = response.data;
                    const { access_token, refresh_token, message } =
                        response.data.data;

                    if (StatusCode == 6000) {
                        dispatch({
                            type: "UPDATE_USER",
                            user_details: {
                                is_verified: true,
                                access_token: access_token,
                                user_id: response.data.data.user_id,
                                role: response.data.data.roles,
                            },
                        });
                        setIsLoading(false);
                        navigate("/");
                    } else if (StatusCode == 6001) {
                        setError(response.data.data.message);
                        setIsLoading(false);
                    } else {
                        alert("Some error occured. Please try later");
                        setIsLoading(false);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.data.message);
                    setIsLoading(false);
                });
            // }
            // setError("please fill all the fields");
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setError("");
        }, 2000);
        let user_details = localStorage.getItem("user_details");
        user_details = JSON.parse(user_details);
        dispatch({ type: "UPDATE_USER", user_details: user_details });
    }, [setError]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 9000);
        }
    }, [error]);

    return (
        <Main>
            <Wrapper>
                <Left>
                    <ImageBox>
                        <img
                            src={require("../../assets/image/loginpage/loginpageimage.png")}
                            alt="loginimage"
                        />
                    </ImageBox>
                </Left>
                <Right>
                    <LogoBox>
                        <img
                            src={
                                require("../../assets/image/loginpage/logo.svg")
                                    .default
                            }
                            alt="logo"
                        />
                    </LogoBox>
                    <TopText>
                        <Titile>Welcome Again, Login</Titile>
                        <SubTitile>
                            Welcome to the MindMitra community of <br /> expert
                            psychologists!
                        </SubTitile>
                    </TopText>
                    <Inputs>
                        <InputUser>
                            <UserBox>
                                <img
                                    src={
                                        require("../../assets/image/loginpage/account_circle.svg")
                                            .default
                                    }
                                    alt="account_circle"
                                />
                            </UserBox>
                            {/* <input
                type="text"
                placeholder="Enter Username"
                name="username"
                required
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value.slice(0, 30))
                  setError('')
                }}
              ></input> */}
                            <input
                                type="email"
                                placeholder="Enter your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            ></input>
                        </InputUser>
                        <InputBox>
                            {/* <PassIcon /> */}
                            <PassBox>
                                <img
                                    src={
                                        require("../../assets/image/loginpage/lock.svg")
                                            .default
                                    }
                                    alt="lock"
                                />
                            </PassBox>
                            <input
                                type={viewPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                name="password"
                                required
                                value={password}
                                onChange={(e) => {
                                    SetPassword(e.target.value.slice(0, 30));
                                    setError("");
                                }}
                            ></input>
                            <Eye onClick={() => setViewPassword(!viewPassword)}>
                                <img
                                    src={
                                        viewPassword
                                            ? require("../../assets/image/loginpage/eye-closed.svg")
                                                  .default
                                            : require("../../assets/image/loginpage/visibility.svg")
                                                  .default
                                    }
                                    alt="visibility"
                                />
                            </Eye>
                        </InputBox>
                    </Inputs>
                    {error && <ErrorRed>{error}</ErrorRed>}
                    <ForContainer>
                        <For to="/auth/forgot-password">Forget Password?</For>
                    </ForContainer>
                    {/* <Box>
                        <CheckBox onClick={() => setChecked(!isChecked)}>
                            {isChecked && (
                                <img
                                    src={
                                        require("../../assets/image/loginpage/Check-box.svg")
                                            .default
                                    }
                                    alt="checkbox"
                                />
                            )}
                        </CheckBox>{" "}
                        {""}
                        <Check>
                            I Agree to mindmitra {""}{" "}
                            <TCBox to={"/auth/terms-and-conditions"}>
                                {" "}
                                Terms and Policy
                            </TCBox>
                        </Check>
                    </Box> */}

                    <ButtonBox
                        onClick={() => {
                            if (email == "" && password == "") {
                                setError("Please fill all the fields");
                            } else if (password == "") {
                                setError("Please enter password");
                            } else if (email == "") {
                                setError("Please enter a valid email");
                            } else {
                                handleSubmit();
                            }
                        }}
                    >
                        Login
                    </ButtonBox>
                    <Expert>
                        Don't you have an account?{" "}
                        <Log to="/auth/register-page"> Create Account</Log>{" "}
                    </Expert>
                </Right>
            </Wrapper>
        </Main>
    );
}

export default LoginPage;
const Eye = styled.div`
    width: 25px;
    margin-right: 15px;
    margin-top: 7px;
    img {
        display: block;
        width: 100%;
    }
`;
const UserBox = styled.div`
    margin-top: 5px;
`;
const PassBox = styled.div`
    margin-top: 5px;
`;
const Check = styled.div`
    display: flex;
    color: #818181;
    margin-left: 10px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const TCBox = styled(Link)`
    text-decoration: underline;
    color: #366ee3;
    margin-left: 5px;
`;

const Inputs = styled.div`
    margin-bottom: 10px;
`;
const Box = styled.div`
    margin-bottom: 20px;
    display: flex;
`;
const CheckBox = styled.div`
    width: 19px;
    height: 19px;
    border-radius: 5px;
    margin-top: 1px;
    cursor: pointer;
    border: 1px solid #c1bfbf;
    @media all and (max-width: 1280px) {
        margin-top: 0;
        width: 15px;
        height: 15px;
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
const ForContainer = styled.div`
    width: 80%;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
`;
const For = styled(Link)`
    text-decoration: underline;
    color: #2b3990;
    text-align: end;
    display: block;

    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
`;
const Main = styled.div`
    padding: 70px 0;
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 90%;
    margin: 0 auto;
    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
        gap: 50px;
    }
`;
const Left = styled.div`
    width: 55%;
    @media all and (max-width: 1440px) {
    }
    @media all and (max-width: 1050px) {
        width: 64%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
`;
const ImageBox = styled.div`
    align-items: center;
    width: 100%;
    /* @media all and (max-width: 1440px) {
    width: 90%;
  } */
    @media all and (max-width: 980px) {
        width: 100%;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const Right = styled.div`
    /* margin: 140px 0 0 0; */
    width: 40%;
    @media all and (max-width: 1440px) {
        /* margin: 140px 0 0 0; */
        /* width: 36%; */
    }
    @media all and (max-width: 980px) {
        width: 75%;
    }
`;
const LogoBox = styled.div`
    width: 16%;
    /* margin-bottom: 20px; */
`;
const TopText = styled.div``;
const Titile = styled.h5`
    font-size: 24px;
    font-family: "DM_sans_bold";
    margin: 30px 0 7px 0;

    /* font-family: "dm_sans_bold"; */
    @media all and (max-width: 1440px) {
        margin-top: 15px;
    }
    @media all and (max-width: 1050px) {
        font-size: 22px;
    }
`;
const SubTitile = styled.p`
    color: #7a838a;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    @media all and (max-width: 1050px) {
        font-size: 14px;
    }
`;

const InputUser = styled.div`
    background-color: #f6f7f9;
    /* height: 40px; */
    display: flex;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
    align-items: center;
    margin-top: 50px;
    width: 80%;
    /* @media all and (max-width: 1440px) {
    margin-top: 25px;
    margin-bottom: 10px;
    padding: 8px 10px;
    height: 35px;
  } */
    @media all and (max-width: 1280px) {
        /* height: 25px; */
    }

    input {
        border: none;
        background-color: #f6f7f9;
        padding-left: 10px;
        /* height: 20px; */
        font-size: 18px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        :focus {
            /* outline: none;
            border: 1px solid #f1f2f378; */
        }

        ::placeholder {
            font-size: 14px;
            font-weight: 400;
        }
    }
`;
const InputBox = styled.div`
    background-color: #f6f7f9;
    /* height: 40px; */
    display: flex;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
    align-items: center;
    margin-top: 10px;
    width: 80%;
    @media all and (max-width: 1280px) {
        /* height: 25px; */
    }
    input {
        border: none;
        background-color: #f6f7f9;
        padding-left: 10px;
        /* height: 20px; */
        height: 100%;
        box-sizing: border-box;
        font-size: 18px;
        width: 100%;
        /* cursor: pointer; */
        :focus {
            /* outline: none;
            border: 1px solid #f1f2f378; */
        }

        ::placeholder {
            font-size: 14px;
            font-weight: 400;
        }
    }
`;
const ButtonBox = styled.button`
    background-color: #366ee3;
    color: #fff;
    text-align: center;
    padding: 15px 160px;
    text-decoration: none;
    border-radius: 8px;
    margin-top: 5px;
    width: 100%;
    border: none;
    cursor: pointer;
    width: 80%;
`;
const Log = styled(Link)`
    color: #2b3990;
    text-decoration: underline;
`;
const Expert = styled.h5`
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    text-align: center;
    color: #818181;
    margin-top: 20px;
    width: 80%;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
`;
const ErrorRed = styled.div`
    color: red;
    font-size: 14px;
    margin-top: 20px;
`;
