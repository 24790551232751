import React, { useContext, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import CallTypeChip from "../Sessionchips/CallTypeChip";
import SessionCountChip from "../Sessionchips/SessionCountChip";
import SessionDateChip from "../Sessionchips/SessionDateChip";
import SessionSchedulechip from "../Sessionchips/SessionSchedulechip";
import SessionTimeChip from "../Sessionchips/SessionTimeChip";
import avatar from "../../../assets/image/sessionsinglepage/avatar.svg";
import SessionCallChip from "../Sessionchips/SessionCallChip";
import notification from "../../../assets/image/sessionsinglepage/notifications_active.svg";
import settings from "../../../assets/image/sessionsinglepage/settings.svg";
import Countdown from "react-countdown";
import link from "../../../assets/image/dashboard/session-card/Link.svg";
import SessionChangesModal from "./modals/SessionChangesModal";
import casenote from "../../../assets/image/sessionsinglepage/add_notes.svg";
import CaseReportChip from "./CaseReportChip";
import DateChip from "../Sessionchips/DateChip";
import upcoming from "../../../assets/image/sessionsinglepage/event_upcoming.svg";
import reschedule from "../../../assets/image/sessionsinglepage/event_repeat.svg";
import ConfirmRescheduleModal from "./modals/ConfirmRescheduleModal";
import { Context } from "../../../contexts/Store";
import moment from "moment";
// import { Context } from "../../contexts/Store";

function CaseReport({ sessionDetailsData, caseId }) {
    // console.log(sessionDetailsData, "sessionDetailsData");
    const today = new Date();
    const [counter, setcouter] = useState("false");
    const [sessionChange, setSessionChange] = useState(false);
    const [isNextSession, setNextSession] = useState(true);
    const [isSessionCompleted, SetSessionCompleted] = useState(true);
    const [isRescheduleModal, setRescheduleModal] = useState(false);
    const [isConfirm, setConfirm] = useState(false);
    // const [isConfirmReschedule, setConfirmReschedule] = useState(false);
    const { state, dispatch } = useContext(Context);
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
        } else if (hours <= 9 && minutes <= 9) {
            // Render a countdown
            return (
                <span className="counter">
                    0{hours}&nbsp;&nbsp;:&nbsp;&nbsp;0{minutes}{" "}
                    &nbsp;&nbsp;:&nbsp;
                    {seconds}
                </span>
            );
        } else if (hours <= 9 && minutes >= 10) {
            // Render a countdown
            return (
                <span className="counter">
                    0{hours}&nbsp;&nbsp;:&nbsp;&nbsp;{minutes}{" "}
                    &nbsp;&nbsp;:&nbsp;
                    {seconds}
                </span>
            );
        } else if (hours >= 10 && minutes <= 9) {
            // Render a countdown
            return (
                <span className="counter">
                    {hours}&nbsp;&nbsp;:&nbsp;&nbsp;0{minutes}{" "}
                    &nbsp;&nbsp;:&nbsp;
                    {seconds}
                </span>
            );
        } else {
            // Render a countdown
            return (
                <span className="counter">
                    {hours}&nbsp;&nbsp;:&nbsp;&nbsp;{minutes}{" "}
                    &nbsp;&nbsp;:&nbsp;
                    {seconds}
                </span>
            );
        }
        if (seconds == "00") {
            setcouter("true");
        }
    };
    return (
        <>
            <Wrapper>
                {/* <SessionChangesModal
                    sessionChange={sessionChange}
                    setSessionChange={setSessionChange}
                /> */}
                <MainContainer>
                    <CardContainer>
                        <Card>
                            <ContainerDiv>
                                <AvatarContainer>
                                    <Avatar>
                                        <img
                                            src={
                                                sessionDetailsData
                                                    ?.current_record?.session
                                                    ?.patient?.profile_photo
                                                    ? sessionDetailsData
                                                          ?.current_record
                                                          ?.session?.patient
                                                          ?.profile_photo
                                                    : avatar
                                            }
                                            alt="icon"
                                        />
                                    </Avatar>
                                </AvatarContainer>
                                <LeftSection>
                                    <CaseId>
                                        Case ID:{" "}
                                        {
                                            sessionDetailsData?.current_record
                                                ?.session_id
                                        }
                                    </CaseId>
                                    <PatientDetails>
                                        <Name>
                                            {
                                                sessionDetailsData
                                                    ?.current_record?.session
                                                    ?.patient?.full_name
                                            }
                                        </Name>
                                        <Age>
                                            {
                                                sessionDetailsData
                                                    ?.current_record?.session
                                                    ?.patient?.age
                                            }
                                        </Age>
                                        <Gender>
                                            {
                                                sessionDetailsData
                                                    ?.current_record?.session
                                                    ?.patient?.gender
                                            }
                                        </Gender>

                                        <SessionCountChip
                                            current_session={
                                                sessionDetailsData
                                                    ?.current_record
                                                    ?.current_session_number
                                            }
                                            total_session={
                                                sessionDetailsData
                                                    ?.current_record
                                                    ?.total_sessions
                                            }
                                        />
                                    </PatientDetails>
                                </LeftSection>
                            </ContainerDiv>
                            <Details>
                                {/* <Top> */}

                                <RightSection>
                                    {sessionDetailsData?.current_record?.session_record_status.toLowerCase() ==
                                    "completed" ? (
                                        <>
                                            <SessionSchedulechip session_record_status="completed" />
                                            <SessionDateChip
                                                day={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.case_date ==
                                                    moment(today).format(
                                                        "DD-MM-YYYY"
                                                    )
                                                        ? "Today"
                                                        : sessionDetailsData
                                                              ?.current_record
                                                              ?.case_date ==
                                                          moment(today)
                                                              .add(1, "days")
                                                              .format(
                                                                  "DD-MM-YYYY"
                                                              )
                                                        ? "Tomorrow"
                                                        : sessionDetailsData
                                                              ?.current_record
                                                              ?.case_date
                                                }
                                            />
                                            <CallTypeChip call_type="video" />
                                        </>
                                    ) : (
                                        <>
                                            <SessionSchedulechip
                                                session_record_status={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.session_record_status
                                                }
                                            />
                                            <SessionDateChip
                                                day={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.case_date ==
                                                    moment(today).format(
                                                        "DD-MM-YYYY"
                                                    )
                                                        ? "Today"
                                                        : sessionDetailsData
                                                              ?.current_record
                                                              ?.case_date ==
                                                          moment(today)
                                                              .add(1, "days")
                                                              .format(
                                                                  "DD-MM-YYYY"
                                                              )
                                                        ? "Tomorrow"
                                                        : sessionDetailsData
                                                              ?.current_record
                                                              ?.case_date
                                                }
                                            />
                                            <SessionTimeChip
                                                start_time={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.start_time
                                                }
                                                end_time={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.end_time
                                                }
                                            />
                                            <CallTypeChip
                                                call_type={
                                                    sessionDetailsData
                                                        ?.current_record
                                                        ?.session?.meet_mode
                                                }
                                            />
                                        </>
                                    )}
                                </RightSection>
                                {/* </Top> */}
                            </Details>
                        </Card>
                        <Middle>
                            <Heading>
                                {
                                    sessionDetailsData?.current_record?.session
                                        ?.concern
                                }
                            </Heading>
                            <Description>
                                {
                                    sessionDetailsData?.current_record?.session
                                        ?.describe
                                }
                            </Description>
                            {/* <EmotionsTitle>
                                Related Emotions ----------------- Pending
                            </EmotionsTitle>
                            <EmotionChipsContainer>
                                {" "}
                                {Emotions.map((item) => (
                                    <EmotionsChip>
                                        <EomotionsText>{item}</EomotionsText>
                                    </EmotionsChip>
                                ))}
                            </EmotionChipsContainer> */}
                        </Middle>
                        <Bottom>
                            {sessionDetailsData?.session_records?.map((item) =>
                                item.session_record_status === "completed" ? (
                                    <CurrentSession>
                                        <CurrentSessionLeftContainer>
                                            Session{" "}
                                            {item.current_session_number}
                                        </CurrentSessionLeftContainer>
                                        <CurrentSessionRightContainer>
                                            <SessionSchedulechip
                                                session_record_status={
                                                    item.session_record_status
                                                }
                                            />
                                            <DateChip date={item.case_date} />
                                            <SessionTimeChip
                                                start_time={item.start_time}
                                                end_time={item.end_time}
                                            />
                                            <CallTypeChip
                                                call_type={item.meet_mode}
                                            />
                                        </CurrentSessionRightContainer>
                                    </CurrentSession>
                                ) : item.session_record_status ===
                                  "cancelled" ? (
                                    <CurrentSession>
                                        <CurrentSessionLeftContainer>
                                            <SessionCount>
                                                {item.count}
                                            </SessionCount>
                                        </CurrentSessionLeftContainer>
                                        <CurrentSessionRightContainer>
                                            <SessionSchedulechip
                                                status={item.status}
                                            />
                                            <DateChip date={item.date} />
                                            <SessionTimeChip time={item.time} />
                                            <CallTypeChip
                                                call_type={item.call_type}
                                            />
                                        </CurrentSessionRightContainer>
                                    </CurrentSession>
                                ) : item.session_record_status ===
                                  "Rescheduled" ? (
                                    <NextSessionCountDownDiv>
                                        <NextSessionLeft>
                                            <CountDownTitle>
                                                Session {item.id} will start in
                                                :
                                            </CountDownTitle>
                                            <Countdown
                                                date={Date.now() + 109500000}
                                                renderer={renderer}
                                            />
                                        </NextSessionLeft>
                                        <NextSessionRight>
                                            <SessionSchedulechip status="Rescheduled" />
                                            <RemaindDiv>
                                                <BellIcon>
                                                    <img
                                                        src={notification}
                                                        alt="Icon"
                                                    />
                                                </BellIcon>
                                                <Remind>Remind Client</Remind>
                                            </RemaindDiv>
                                            <SessionCallChip
                                                call_type="video"
                                                status="next"
                                            />
                                            <LinkIcon src={link} alt="Icon" />
                                            <SettingsIcon
                                                onClick={() => {
                                                    setSessionChange(
                                                        !sessionChange
                                                    );
                                                }}
                                                src={settings}
                                                alt="Icon"
                                            />
                                        </NextSessionRight>
                                        <SessionChangesModal
                                            sessionChange={sessionChange}
                                            setSessionChange={setSessionChange}
                                        />
                                    </NextSessionCountDownDiv>
                                ) : item.session_record_status ===
                                  "upcoming" ? (
                                    <NextSessionCountDownDiv>
                                        {sessionDetailsData?.current_record
                                            ?.case_date ==
                                            moment(today).format(
                                                "DD-MM-YYYY"
                                            ) ||
                                        sessionDetailsData?.current_record
                                            ?.case_date ==
                                            moment(today)
                                                .add(1, "days")
                                                .format("DD-MM-YYYY") ? (
                                            <NextSessionLeft>
                                                <CountDownTitle>
                                                    Session{" "}
                                                    {item.session_record_id}{" "}
                                                    will start in :
                                                </CountDownTitle>
                                                <Countdown
                                                    date={Date.now() + 10950}
                                                    renderer={renderer}
                                                />
                                            </NextSessionLeft>
                                        ) : (
                                            <NextSessionLeft>
                                                <CountDownTitle>
                                                    Session{" "}
                                                    {item.session_record_id}{" "}
                                                    will start at{" "}
                                                    {item.start_time} on{" "}
                                                    {item.case_date}
                                                </CountDownTitle>
                                                {/* <Countdown
                                                date={Date.now() + 10950}
                                                renderer={renderer}
                                            /> */}
                                            </NextSessionLeft>
                                        )}

                                        <NextSessionRightDiv>
                                            <RemaindDiv>
                                                <BellIcon>
                                                    <img
                                                        src={notification}
                                                        alt="Icon"
                                                    />
                                                </BellIcon>
                                                <Remind>Remind Client</Remind>
                                            </RemaindDiv>
                                            <SessionCallChip
                                                call_type="video"
                                                status="next"
                                            />
                                            <LinkIcon src={link} alt="Icon" />
                                            {/* <SettingsIcon
                                                onClick={() => {
                                                    setSessionChange(
                                                        !sessionChange
                                                    );
                                                }}
                                                src={settings}
                                                alt="Icon"
                                            /> */}
                                        </NextSessionRightDiv>
                                        {/* <SessionChangesModal
                                            sessionChange={sessionChange}
                                            setSessionChange={setSessionChange}
                                        /> */}
                                    </NextSessionCountDownDiv>
                                ) : null
                            )}
                        </Bottom>
                    </CardContainer>
                </MainContainer>
            </Wrapper>
        </>
    );
}

export default CaseReport;
const MainContainer = styled.div`
    top: 150px;
    border: 1px solid ${THEME_COLORS.light_300};
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    padding: 40px;
    background-color: ${THEME_COLORS.white};
    /* @media all and (max-width: 1280px) {
        padding: 30px;
    } */
`;
const CardContainer = styled.div`
    /* background-color: #0e0c09; */
`;
const ContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Wrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Card = styled.div`
    display: flex;
    background: ${THEME_COLORS.white};
    /* border-radius: 10px; */
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
`;
const AvatarContainer = styled.div`
    margin-right: 20px;
`;
const Avatar = styled.div`
    img {
        display: block;
        width: 100%;
    }
`;
const Details = styled.div``;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
`;
const LeftSection = styled.div``;
const RightSection = styled.div`
    display: flex;
`;
const Middle = styled.div`
    /* margin-bottom: 20px; */
`;
const CaseId = styled.p`
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;
const PatientDetails = styled.ol`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    list-style: disc;
    font-size: 14px;
`;
const Name = styled.li`
    list-style: none;
    font-weight: 500;
    color: ${THEME_COLORS.chips_blue_on_container};
    margin-right: 20px;
`;
const Age = styled.li`
    color: ${THEME_COLORS.text_para};
    margin-right: 20px;
`;
const Gender = styled.li`
    color: ${THEME_COLORS.text_para};
    margin-right: 20px;
`;
const Description = styled.p`
    color: ${THEME_COLORS.text_title};
    font-size: 16px;
    cursor: pointer;
    /* margin-bottom: 20px; */
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Heading = styled.h2`
    font-size: 20px;
    margin-bottom: 10px;
    color: ${THEME_COLORS.text_title};
`;
const EmotionsTitle = styled.h6`
    font-size: 14px;
    font-weight: 500;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`;
const EmotionChipsContainer = styled.div`
    display: flex;
`;
const EmotionsChip = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    text-align: center;
    padding: 8px 16px;
    margin-right: 10px;
`;
const EomotionsText = styled.h6`
    font-size: 12px;
    color: ${THEME_COLORS.text_title};
`;
const Bottom = styled.ul``;
const CurrentSession = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 5px;
`;
const CurrentSessionLeftContainer = styled.p`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    display: flex;
    align-items: center;
`;
const CurrentSessionRightContainer = styled.div`
    /* width: 45%; */
    display: flex;
    justify-content: space-between;
`;
const NextSessionCountDownDiv = styled.div`
    position: relative;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const NextSessionLeft = styled.div`
    display: flex;
    align-items: center;
`;
const CountDownTitle = styled.p`
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    margin-right: 5px;
`;
const NextSessionRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    @media all and (max-width: 1280px) {
        width: 50%;
    }
    @media all and (max-width: 1080px) {
        width: 55%;
    }
`;
const NextSessionRightDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    /* width: 30%; */
    @media all and (max-width: 1280px) {
        /* width: 40%; */
    }
`;
const RemaindDiv = styled.div`
    /* margin-right: 10px; */
    display: flex;
    align-items: center;
`;
const BellIcon = styled.div`
    img {
        display: block;
        width: 100%;
    }
    margin-right: 10px;
`;
const Remind = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;
const LinkIcon = styled.img``;
const SettingsIcon = styled.img``;
const FollowUpDiv = styled.div`
    display: flex;
    width: 35%;
    justify-content: space-between;
    margin-top: 30px;
    @media all and (max-width: 1080px) {
        width: 40%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
`;
const SessionCount = styled.div`
    margin-right: 30px;
`;
