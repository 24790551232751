import React from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig';


const ChipReport = ({icon, bkgcolor, count, title}) => {
  return (
    <Chip bkgcolor={bkgcolor} >
        <Left>
            <ImageBox>
                <img src={require(`../../../assets/image/reports/${icon}`)} alt="icon" />
            </ImageBox>
        </Left>
        <Right> 
            <Top>
                <Count>{count}</Count>
                <Arrow>
                    <img src={require("../../../assets/image/reports/right_arrow_grey.svg").default} alt="right arrow" />
                </Arrow>
            </Top>
            <Bottom>
                {title}
            </Bottom>
        </Right>
    </Chip>
  )
}

export default ChipReport

const Chip = styled.div`
    width: 100%;
    min-height: 61px;
    padding: 10px;
    border-radius: 10px;
    background-color: ${props=>props.bkgcolor};
    display: flex;
    &:last-child{
        margin-right: 0;
    }

    @media all and (max-width: 1268px){
        flex-direction: column;
    }
`
const Left = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`
const ImageBox = styled.div`
    width: 40px;
    height: 40px;
    img{
        width: 100%;
        height: 100%;
    }
    @media all and (max-width:1268px){
        margin-bottom: 10px;
    }
`
const Right = styled.div`
    flex-grow: 1;
`
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
`
const Count = styled.p`
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    line-height: normal;
`
const Arrow = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Bottom = styled.h5`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    text-align: left;
`




