import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import profile from "../../../assets/image/dashboard/profile/profile.svg";
import payments from "../../../assets/image/dashboard/profile/wallet.svg";
import faq from "../../../assets/image/dashboard/profile/faq.svg";
import Legal from "../../../assets/image/dashboard/profile/legal.svg";
import feedback from "../../../assets/image/dashboard/profile/feedback.svg";
import contact from "../../../assets/image/dashboard/profile/contact.svg";
import about from "../../../assets/image/dashboard/profile/about.svg";
import signout from "../../../assets/image/dashboard/profile/signout.svg";
import Logout from "./Logout";
import { Context } from "../../../contexts/Store";

function ProfileModal({ modal, setModal, wrapperRef }) {
    const { state, dispatch } = useContext(Context);
    const navigate = useNavigate();
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setModal(false); // Close the modal if clicked outside
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, setModal]);
    return (
        <>
            {showLogoutModal && (
                <Logout setShowLogoutModal={setShowLogoutModal} />
            )}

            {modal && (
                <CardContainer
                    // ref={wrapperRef}
                    className="outer-click"
                >
                    <Card>
                        <Middle to="/manage-profile">
                            <Left>
                                <Icon src={profile} alt="icon" />
                            </Left>
                            <Right>
                                <Text>Manage Profile</Text>
                            </Right>
                        </Middle>
                        <Middle to="/transactions-list">
                            <Left>
                                <Icon src={payments} alt="icon" />
                            </Left>
                            <Right>
                                <Text>Payments</Text>
                            </Right>
                        </Middle>
                        <Middle to="/faqs">
                            <Left>
                                <Icon src={faq} alt="icon" />
                            </Left>
                            <Right>
                                <Text>FAQ</Text>
                            </Right>
                        </Middle>
                        <Middle to="/terms-and-conditions">
                            <Left>
                                <Icon src={Legal} alt="icon" />
                            </Left>
                            <Right>
                                <Text>Terms & Conditions</Text>
                            </Right>
                        </Middle>
                        <Middle to="/feedback">
                            <Left>
                                <Icon src={feedback} alt="icon" />
                            </Left>
                            <Right>
                                <Text>Feedback</Text>
                            </Right>
                        </Middle>
                        <Middle to="/contact">
                            <Left>
                                <Icon src={contact} alt="icon" />
                            </Left>
                            <Right>
                                <Text>Contact MM Admin</Text>
                            </Right>
                        </Middle>

                        <Middle to="/about">
                            <Left>
                                <Icon src={about} alt="icon" />
                            </Left>
                            <Right>
                                <Text>About</Text>
                            </Right>
                        </Middle>
                        <Middle
                            onClick={() => setShowLogoutModal(true)}
                            className={"signout"}
                        >
                            <Left>
                                <Icon src={signout} alt="icon" />
                            </Left>
                            <Right>
                                <Text className={""}>signout</Text>
                            </Right>
                        </Middle>
                    </Card>
                </CardContainer>
            )}
        </>
    );
}

export default ProfileModal;
const CardContainer = styled.div`
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    width: 245px;
    position: absolute;
    top: 46px;
    right: 0;
    cursor: default;
    z-index: 10;
`;
const Card = styled.div`
    padding: 8px 10px;
    background: ${THEME_COLORS.white};
    border-radius: 10px;
`;

const Middle = styled(Link)`
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px 10px;
    cursor: pointer;
    &.signout {
        justify-content: center;
    }
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;

const Left = styled.div`
    margin-right: 10px;
`;

const Icon = styled.img``;

const Right = styled.div``;

const Text = styled.h3`
    font-size: 14px;
    &.signout {
        color: ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
