import React from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import request from "../../../assets/image/my_account/request.png";

const TransactionCard = ({ data }) => {
    return (
        <Card>
            <ImageBox type={data.type}>
                {data.type === "credit" && (
                    <img
                        src={
                            require("../../../assets/image/my_account/arrow_curve_up_green.svg")
                                .default
                        }
                        alt=" arrow up"
                    />
                )}
                {data.type === "debit" && (
                    <img
                        src={
                            require("../../../assets/image/my_account/arrow_curve_down_red.svg")
                                .default
                        }
                        alt=" arrow down"
                    />
                )}
                {data.type === "pending" && (
                    <img
                        src={
                            require("../../../assets/image/my_account/pending.svg")
                                .default
                        }
                        alt=" arrow down"
                    />
                )}
                {data.type === "withdraw request" && (
                    <img src={request} alt="request" />
                )}
                {data.type === "rejected" && (
                    <img
                        src={
                            require("../../../assets/image/my_account/rejected.svg")
                                .default
                        }
                        alt=" arrow down"
                    />
                )}
            </ImageBox>
            <Content>
                <Title>{data.short_message}</Title>
                <Date>{data.transaction_date}</Date>
            </Content>
            <Amount>
                {data.type === "debit" && "-"}₹ {data.amount}
            </Amount>
        </Card>
    );
};

export default TransactionCard;

const Card = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    padding: 8px;
    background: ${THEME_COLORS.white};
    border-radius: 8px;
    margin-bottom: 10px;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
const ImageBox = styled.div`
    margin-right: 10px;
    width: 32px;
    height: 32px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) =>
        props.type === "payment"
            ? THEME_COLORS.chips_green_container
            : THEME_COLORS.chips_red_container};
    border: 1px solid ${THEME_COLORS.white};
    border-radius: 44px;
    img {
        width: 20px;
        height: 20px;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    margin-right: 10px;
    width: 160px;
`;
const Title = styled.h5`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.black};
`;
const Date = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
const Amount = styled.h5`
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.black};
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
`;
