import React, { useContext, useState } from 'react'

// package
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'

// components
import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import { authentication } from '../../../axiosConfig'

function SetPassword() {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [ConfirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [sucess, setSucess] = useState('')
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(Context)
  const email = state.user_details.email
  const access_token = state.user_details.access_token
  const [viewPassword, setViewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

  const data = {
    new_password: password,
    confirm_password: password,
    email: state.user_details.email,
  }
  const handleSubmit = () => {
    setLoading(true)
    authentication
      .post('/forget-password/reset/', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(function (response) {
        const { StatusCode, data } = response.data
        const { access_token, refresh_token, message } = response.data.data
        if (StatusCode == 6000) {
          setSucess('Password Updated Suceccesfully')
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              access_token: '',
              role: '',
            },
          })
          setTimeout(() => {
            navigate('/auth/login-page')
          }, 1000)
        } else {
          setError(data?.message)
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <MainContent>
        <ForgotContainer>
          <LogoBox>
            <img
              src={require('../../../assets/image/loginpage/logo.svg').default}
              alt="logo"
            />
          </LogoBox>
          <Name>Set new Password</Name>
          <Explain>
            Your new password must be different to previously used password
          </Explain>
          <Form>
            <Box>
              <input
                type={viewPassword ? 'text' : 'password'}
                placeholder="New Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value.slice(0, 30))
                  setError('')
                }}
              ></input>
              <Eye onClick={() => setViewPassword(!viewPassword)}>
                <img
                  src={
                    viewPassword
                      ? require('../../../assets/image/loginpage/eye-closed.svg')
                          .default
                      : require('../../../assets/image/loginpage/visibility.svg')
                          .default
                  }
                  alt="visibility"
                />
              </Eye>
            </Box>

            <Box>
              <input
                type={viewConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value.slice(0, 30))
                  setError('')
                }}
              ></input>
              <Eye onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                <img
                  src={
                    viewConfirmPassword
                      ? require('../../../assets/image/loginpage/eye-closed.svg')
                          .default
                      : require('../../../assets/image/loginpage/visibility.svg')
                          .default
                  }
                  alt="visibility"
                />
              </Eye>
            </Box>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {sucess && <ErrorMessage className="sucess">{sucess}</ErrorMessage>}
            <ResetBox>
              <Reset
                onClick={() => {
                  if (password && ConfirmPassword) {
                    if (password == ConfirmPassword) {
                      handleSubmit()
                    } else {
                      setError("Passwords doesn't match")
                    }
                  } else {
                    setError('All fields are required')
                  }
                }}
              >
                Submit
              </Reset>
            </ResetBox>
          </Form>
          <Charactors>
            *Password must be atleast 8 characters long and should contain an
            uppercase letter, a digit and a special charecter.
          </Charactors>
        </ForgotContainer>
      </MainContent>
    </>
  )
}

export default SetPassword

const MainContent = styled.section`
  padding: 10% 5% 9%;
  background: ${THEME_COLORS.input};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ForgotContainer = styled.div`
  background: ${THEME_COLORS.white};
  padding: 30px 45px;
  border-radius: 15px;
  width: 35%;
  margin: 0 auto;
  box-shadow: ${THEME_COLORS.elevation_user_u4};
  @media all and (max-width: 1280px) {
    width: 45%;
  }
  @media all and (max-width: 1050px) {
    width: 50%;
  }
  @media all and (max-width: 980px) {
    width: 55%;
  }
  @media all and (max-width: 768px) {
    width: 90%;
  }
`
const LogoBox = styled.div`
  width: 50px;
  margin-bottom: 40px;
  img {
    display: block;
    width: 100%;
  }
`
const Name = styled.h3`
  color: ${THEME_COLORS.text_title};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`
const Explain = styled.p`
  color: ${THEME_COLORS.text_para};
  margin-bottom: 30px;
  font-size: 16px;
`
const Form = styled.form`
  margin-bottom: 5px;
`
const Box = styled.div`
  background: ${THEME_COLORS.input};
  margin-bottom: 20px;
  padding: 17px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  input {
    width: 100%;
  }
  input[type='text'] {
    appearance: none;
    border-radius: 5px;
    background: 0 0;
    font-size: 16px;
  }
`
const Charactors = styled.p`
  font-size: 14px;
  color: #3e3e53;
  margin-bottom: 20px;
`
const ResetBox = styled(Link)`
  width: 100%;
  display: inline-block;
  background: ${THEME_COLORS.chips_blue_on_container_2};
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
`
const Reset = styled.span`
  display: inline-block;
  text-align: center;
  color: ${THEME_COLORS.white};
  width: 100%;
`
const ErrorMessage = styled.p`
  color: ${THEME_COLORS.chips_red_on_container};
  font-style: 16px;
  margin-bottom: 20px;
  &.sucess {
    color: #52b244;
  }
`
const Eye = styled.div`
  width: 25px;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`
