import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import upcomimng from '../../../assets/image/dashboard/session-card/upcoming.svg'
import reschedule from '../../../assets/image/dashboard/session-card/rescheduled.svg'
import followup from '../../../assets/image/dashboard/session-card/followup.svg'
import completed from '../../../assets/image/dashboard/session-card/completed.svg'
import cancelled from '../../../assets/image/dashboard/session-card/cancelled.svg'
import pending from '../../../assets/image/dashboard/session-card/pending.svg'
import close from '../../../assets/image/dashboard/notification-card/close-red.svg'

function CalendarClickModal({
  events,
  selectedDate,
  date,
  top,
  left,
  setModal,
}) {
  const [state, setState] = useState(0)
  const newtop = top - 100

  const renderContent = () => {
    if (events.some((event) => event.date === date)) {
      return events
        .filter((event) => event.date === date)
        .map((item, index) => (
          <div key={index}>
            <Middle key={index} status={item.status}>
              <Left>
                <Icon
                  src={
                    item.status === 'upcoming'
                      ? upcomimng
                      : item.status === 'completed'
                      ? completed
                      : item.status === 'cancelled'
                      ? cancelled
                      : item.status === 'rescheduled'
                      ? reschedule
                      : item.status === 'followups'
                      ? followup
                      : pending
                  }
                  alt="icon"
                />
              </Left>
              <Right>
                <Text status={item.status}>{item.slot}</Text>
              </Right>
            </Middle>
          </div>
        ))
    } else {
      return <NoData>No scheduled sessions</NoData>
    }
  }

  return (
    <>
      <CardContainer style={{ top, left }}>
        <Card>
          <Top>
            <Date>{selectedDate}</Date>
            <CloseContainer
              onClick={() => {
                setModal(false)
              }}
            >
              <Close src={close} />
            </CloseContainer>
          </Top>
          {renderContent()}
          <div className="box"></div>
        </Card>
      </CardContainer>
    </>
  )
}

export default CalendarClickModal

const CardContainer = styled.div`
  cursor: default;
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  background: ${THEME_COLORS.chips_green_on_container};
  border-radius: 12px;
  padding-top: 5px;
  width: 220px;
  position: absolute;

  z-index: 1000;
`
const Card = styled.div`
  padding: 8px 10px;
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  border: 2px solid ${THEME_COLORS.white};
  div.box {
    position: absolute;
    background: white;
    width: 30px;
    height: 30px;
    transform: rotateZ(45deg);
    top: 50%;
    left: -8px;
    z-index: -1;
  }
`
const Top = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.divider};
  padding: 8px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Date = styled.h3`
  font-size: 14px;
  font-family: 'DM_sans_medium';
`

const Middle = styled.div`
  display: flex;
  padding: 3px 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  background: ${(props) =>
    props.status === 'cancelled'
      ? THEME_COLORS.chips_red_container
      : props.status === 'rescheduled'
      ? THEME_COLORS.chips_violet_container
      : props.status === 'followUp'
      ? THEME_COLORS.chips_orange_container
      : props.status === 'completed'
      ? THEME_COLORS.chips_blue_container
      : props.status === 'upcoming'
      ? THEME_COLORS.chips_green_container
      : THEME_COLORS.chips_red_container};
`

const Left = styled.div`
  margin-right: 10px;
`

const Icon = styled.img``

const Right = styled.div``

const Text = styled.h3`
  font-size: 14px;
  color: ${(props) =>
    props.status === 'cancelled'
      ? THEME_COLORS.chips_red_on_container
      : props.status === 'rescheduled'
      ? THEME_COLORS.chips_violet_on_container
      : props.status === 'followUp'
      ? THEME_COLORS.chips_orange_on_container
      : props.status === 'completed'
      ? THEME_COLORS.chips_blue_on_container_2
      : props.status === 'upcoming'
      ? THEME_COLORS.chips_green_on_container
      : THEME_COLORS.chips_red_on_container};
`
const NoData = styled.div`
  font-size: 14px;
  font-family: 'DM_sans_medium';
  padding: 8px 10px;
  color: ${THEME_COLORS.text_primary};
`
const CloseContainer = styled.div`
  &:hover {
    background: #ffe9e9;
  }
  width: 32px;
  height: 32px;

  border-radius: 20px;
  padding: 4px;
  cursor: pointer;
`
const Close = styled.img``
