import React, { useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import icon from "../../../assets/image/dashboard/sorting-chip/search.svg";

function SearchChip({ setSearch, search }) {
  const [hover, setHover] = useState(true);
  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <ChipContainer
      onClick={() => {
        setHover(false);
      }}
      onMouseLeave={() => {
        setHover(true);
      }}
    >
      <IconContainer className={hover && "active"}>
        <Icon src={icon} alt="icon" />
      </IconContainer>

      <InputContainer className={hover && "active"}>
        <Input
          onChange={handleChange}
          value={search}
          className={hover && "active"}
          type="text"
          placeholder="Search"
        />
      </InputContainer>
    </ChipContainer>
  );
}

export default SearchChip;

const Icon = styled.img``;
const Input = styled.input`
  margin-left: 10px;
  &.active {
    display: none;
  }
`;
const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 7px;
  border-radius: 8px;
  background: ${THEME_COLORS.white};
  justify-content: space-between;
  border: 2px solid ${THEME_COLORS.light_300};
  cursor: pointer;
  // margin-left: 10px;
`;
const IconContainer = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  &.active {
    margin: 0;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100px; */
  transition: all 0.5s ease;
  width: 150px;
  overflow: hidden;

  &.active {
    width: 0px;
    /* display: none; */
    transition: all 0.5s ease;
  }
`;
