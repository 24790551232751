import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne";
import AttentionModal from "./modals/AttentionModal";

const Documents = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const [education, setEducation] = useState("");
  const [educationFileName, setEducationFileName] = useState("");
  const [rciRegistration, setRciRegistration] = useState("");
  const [rciRegistrationFileName, setRciRegistrationFileName] = useState("");
  const [license, setLicense] = useState("");
  const [licenseFileName, setLicenseFileName] = useState("");
  const [membership, setMembership] = useState("");
  const [membershipFileName, setMembershipFileName] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const navigate = useNavigate();

  const addFile = (e, type) => {
    const file = new FileReader();

    // extract filename from fullpath
    let fullPath = e.target.value;
    let startIndex =
      fullPath.indexOf("\\") >= 0
        ? fullPath.lastIndexOf("\\")
        : fullPath.lastIndexOf("/");
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
      filename = filename.substring(1);
    }

    file.addEventListener("load", () => {
      switch (type) {
        case "education":
          setEducation(file.result);
          setEducationFileName(filename);
          break;
        case "rci":
          setRciRegistration(file.result);
          setRciRegistrationFileName(filename);
          break;
        case "license":
          setLicense(file.result);
          setLicenseFileName(filename);
          break;
        case "membership":
          setMembership(file.result);
          setMembershipFileName(filename);
          break;
        default:
          break;
      }
    });
    file.readAsDataURL(e.target.files[0]);
  };

  const cancelFile = (type) => {
    switch (type) {
      case "education":
        setEducation("");
        setEducationFileName("");
        break;
      case "rci":
        setRciRegistration("");
        setRciRegistrationFileName("");
        break;
      case "license":
        setLicense("");
        setLicenseFileName("");
        break;
      case "membership":
        setMembership("");
        setMembershipFileName("");
        break;
      default:
        break;
    }
  };

  const clearStates = () => {
    setEducation("");
    setRciRegistration("");
    setLicense("");
    setMembership("");
    setEducationFileName("");
    setRciRegistrationFileName("");
    setLicenseFileName("");
    setMembershipFileName("");
    setShowErrors(false);
    setShowAttentionModal(false);
  };

  const handleSubmit = (e, close) => {
    e.preventDefault();
    if (education && rciRegistration) {
      setShowSuccessToast(true);
      clearStates();
      if (close === "close") {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } else {
      setShowErrors(true);
    }
  };

  const handleCancel = () => {
    if (education || rciRegistration || license || membership)
      setShowAttentionModal(true);
    else {
      clearStates();
      navigate(-1);
    }
  };

  return (
    <Form>
      <FormData>
        <Head>Documents</Head>
        <TitleWrapper>
          <Title>Upload your doctuments*</Title>
          <SubTitle>Describe yourself in a short brief.</SubTitle>
        </TitleWrapper>
        <Wrapper>
          <Label>
            Education and Training*
            <br />
            {showErrors && !education && (
              <Error className="error">This field is required.</Error>
            )}
          </Label>
          <InputWrapper className={education && "active"}>
            <Input
              id="education"
              type="file"
              accept="application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => addFile(e, "education")}
            />
            <InputLabelWrapper>
              {educationFileName ? (
                <FilenameWrapper>
                  <InputLabel htmlFor="education" className="active">
                    {educationFileName}
                  </InputLabel>
                  <CancelButton
                    onClick={() => cancelFile("education")}
                    src={
                      require("../../../assets/image/manage_profile/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                </FilenameWrapper>
              ) : (
                <InputLabel htmlFor="education">+ Upload</InputLabel>
              )}
            </InputLabelWrapper>
          </InputWrapper>
        </Wrapper>

        <Wrapper>
          <Label>
            RCI Registration*
            <br />
            {showErrors && !rciRegistration && (
              <Error className="error">This field is required.</Error>
            )}
          </Label>
          <InputWrapper className={rciRegistration && "active"}>
            <Input
              id="rci"
              type="file"
              accept="application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => addFile(e, "rci")}
            />
            <InputLabelWrapper>
              {rciRegistrationFileName ? (
                <FilenameWrapper>
                  <InputLabel htmlFor="rci" className="active">
                    {rciRegistrationFileName}
                  </InputLabel>
                  <CancelButton
                    onClick={() => cancelFile("rci")}
                    src={
                      require("../../../assets/image/manage_profile/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                </FilenameWrapper>
              ) : (
                <InputLabel htmlFor="rci">+ Upload</InputLabel>
              )}
            </InputLabelWrapper>
          </InputWrapper>
        </Wrapper>

        <Wrapper>
          <Label>Licensing (if any)</Label>
          <InputWrapper className={license && "active"}>
            <Input
              id="license"
              type="file"
              accept="application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => addFile(e, "license")}
            />
            <InputLabelWrapper>
              {licenseFileName ? (
                <FilenameWrapper>
                  <InputLabel htmlFor="license" className="active">
                    {licenseFileName}
                  </InputLabel>
                  <CancelButton
                    onClick={() => cancelFile("license")}
                    src={
                      require("../../../assets/image/manage_profile/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                </FilenameWrapper>
              ) : (
                <InputLabel htmlFor="license">+ Upload</InputLabel>
              )}
            </InputLabelWrapper>
          </InputWrapper>
        </Wrapper>

        <Wrapper>
          <Label>Professional Membership (if any)</Label>
          <InputWrapper className={membership && "active"}>
            <Input
              id="membership"
              type="file"
              accept="application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => addFile(e, "membership")}
            />
            <InputLabelWrapper>
              {membershipFileName ? (
                <FilenameWrapper>
                  <InputLabel htmlFor="membership" className="active">
                    {membershipFileName}
                  </InputLabel>
                  <CancelButton
                    onClick={() => cancelFile("membership")}
                    src={
                      require("../../../assets/image/manage_profile/cancel.svg")
                        .default
                    }
                    alt="cancel"
                  />
                </FilenameWrapper>
              ) : (
                <InputLabel htmlFor="membership">+ Upload</InputLabel>
              )}
            </InputLabelWrapper>
          </InputWrapper>
        </Wrapper>
      </FormData>
      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_400}
          onclick={handleCancel}
        />
        <ButtonTypeOne
          text="Save"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
          onclick={handleSubmit}
        />
      </FormButtons>
      {showAttentionModal && (
        <AttentionModal
          setShowAttentionModal={setShowAttentionModal}
          showAttentionModal={showAttentionModal}
          handleSubmit={handleSubmit}
        />
      )}
    </Form>
  );
};

export default Documents;

const Form = styled.form`
  min-height: calc(100vh - 229.6px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const FormData = styled.div``;

const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`;
const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;
const Title = styled.h5`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`;
const InputWrapper = styled.div`
  border-radius: 8px;
  padding: 6px 16px;
  border: 2px solid ${THEME_COLORS.light_400};
  &.active {
    background: ${THEME_COLORS.chips_green_container};
    padding: 8px 16px;
    border: none;
    min-width: 40%;
  }
`;
const Input = styled.input`
  display: none;
`;
const InputLabelWrapper = styled.div`
  cursor: pointer;
`;
const InputLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
  &.active {
    color: ${THEME_COLORS.chips_green_on_container};
  }
`;
const FilenameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CancelButton = styled.img`
  margin-left: 10px;
`;
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Error = styled.small``;
