import React, { useState } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig.js'

const SearchButton = ({ searchItem, handleSearch, placeholder }) => {
  const [searchExpand, setSearchExpand] = useState(true)
  return (
    <Search
      onClick={() => setSearchExpand(true)}
      className={(searchExpand || searchItem) && 'active'}
    >
      <img
        src={require('../../../assets/image/search.svg').default}
        alt="search"
      />
      <input
        type="text"
        placeholder={placeholder ? placeholder : 'Search Patient'}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchItem}
      />
    </Search>
  )
}

export default SearchButton

const Search = styled.div`
    padding: 9px;
    background: ${THEME_COLORS.white};
    border: 1px solid #e2e2e2;
    /* box-shadow: ${THEME_COLORS.elevation_expert_e6}; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 34px; */
    width: 240px;
    transition: all 0.4s ease;
    img {
        width: 20px;
        display: block;
    }
    input {
        margin-left: 5px;
        flex-grow: 1;
        &::placeholder {
            color: ${THEME_COLORS.text_para};
        }
    }
    &.active {
        width: 240px;
        /* border: 2px solid ${THEME_COLORS.chips_blue_on_container_2}; */
    }
`
