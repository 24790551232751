import React, { useEffect } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import ScheduleFollowUpSession from './ScheduleFollowUpSession'
import ScheduleNextSession from './ScheduleNextSession'
import SocioDemographicDetails from './SocioDemographicDetails'
import DraftsModal from './DraftsModal'

const ModalStructure = ({
  addPatientDetails,
  scheduleNextSession,
  scheduleFollowUpSession,
  title,
  description,
  handleModal,
  setTimeSlot,
  large,
  drafts,
}) => {
  useEffect(() => {
    // Disable scrolling on the body element when the modal is open
    document.body.style.overflow = 'hidden'

    // Cleanup function to re-enable scrolling when the modal is closed
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  const closeModal = (e) => {
    const element = document.querySelector('.click-form')
    if (!element.contains(e.target)) {
      handleModal()
    }
  }

  return (
    <Background
      className={
        addPatientDetails ? 'SocioDemographic' : drafts ? 'drafts' : ''
      }
      onClick={(e) => closeModal(e)}
    >
      <Form
        scheduleNextSession={scheduleNextSession}
        large={large}
        className={drafts ? 'drafts click-form' : 'click-form'}
      >
        <Title>{title}</Title>
        <Description>{description}</Description>
        {addPatientDetails && (
          <SocioDemographicDetails handleModal={handleModal} />
        )}
        {drafts && <DraftsModal handleModal={handleModal} />}
        {scheduleNextSession && (
          <ScheduleNextSession
            handleModal={handleModal}
            setTimeSlot={setTimeSlot}
          />
        )}
        {scheduleFollowUpSession && (
          <ScheduleFollowUpSession
            handleModal={handleModal}
            setTimeSlot={setTimeSlot}
          />
        )}
      </Form>
    </Background>
  )
}

export default ModalStructure

const Background = styled.div`
  width: 100vw;
  padding-bottom: 60px;
  padding-top: 60px;
  background: ${THEME_COLORS.elevation_expert_e9};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  border-radius: 24px 24px 0px 0px;

  &.SocioDemographic {
    width: 100vw;
    padding-bottom: 60px;
    padding-top: 100px;
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    top: -75px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    border-radius: 0 0 0 0;
    /* height: max-content; */
    align-items: flex-start;
  }
  &.drafts {
    width: 100vw;
    padding-bottom: 60px;
    padding-top: 100px;
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    top: -75px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    border-radius: 0 0 0 0;
    align-items: center;
  }
`
const Form = styled.form`
  width: ${(props) => (props.large ? '54%' : '46%')};
  height: max-content;
  background-color: ${THEME_COLORS.white};
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
  padding: 40px;
  &.drafts {
    width: 30%;
  }
  @media all and (max-width: 1440px) {
    width: ${(props) => (props.large ? '58%' : '56%')};
  }
  @media all and (max-width: 1280px) {
    width: ${(props) => (props.large ? '68%' : '58%')};
  }
  @media all and (max-width: 980px) {
    width: ${(props) => (props.large ? '85%' : '68%')};
  }
`
const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 4px;
`
const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  margin-bottom: 32px;
`
