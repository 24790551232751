import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { cases } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'
import RenderingChip from '../../session_summary/modals/RenderingChip'

function FamilyHistory({
  familyHistoryFormFields,
  setFamilyHistoryFormFields,
  initialfamilyHistoryFormValues,
  handleFamilyHistoryFormSubmit,
  detectChanges,
}) {
  const { id } = useParams()
  const familyTreeRef = useRef(null)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [dementiasList, setDementiasList] = useState([])
  const { familyTree, mentalIllnesses, dementias } = familyHistoryFormFields

  const [loading, setLoading] = useState(false)
  const [diagnosis, setDiagnosis] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [mentalIllnessHistoryList, setMentalIllnessHistoryList] = useState([])

  const handleCancel = () => {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        formType: 'Chief complaints & Summary',
      },
    })
  }

  useEffect(() => {
    if (familyTree) familyTreeRef.current.rows = 3
  }, [])

  const handleKeyUp = (e, refType) => {
    if (e.target.value !== '') {
      const textareaLineHeight = 24
      const textareaMaxHeight = textareaLineHeight * 3
      const newRows = Math.ceil(e.target.scrollHeight / textareaLineHeight)
      if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
        refType.current.rows = newRows
      }
    } else {
      refType.current.rows = 1
    }
  }
  const getMentalIllnessHistoryList = () => {
    if (access_token) {
      cases
        .get('/mental-illness/', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const { StatusCode } = response.data
          if (StatusCode == 6000) {
            setMentalIllnessHistoryList(response.data.data)
          } else if (StatusCode == 6001) {
            setShowErrors(response)
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setShowErrors(error.message)
        })
    }
  }

  const getDementiasList = () => {
    if (access_token) {
      cases
        .get('/dementia/', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const { StatusCode } = response.data
          if (StatusCode == 6000) {
            setDementiasList(response.data.data)
          } else if (StatusCode == 6001) {
            setShowErrors(response)
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setShowErrors(error.message)
        })
    }
  }

  useEffect(() => {
    getMentalIllnessHistoryList()
    getDementiasList()
  }, [])

  return (
    <>
      <FormContainer>
        <Top>
          <div>
            <FormTitle>Family History</FormTitle>
            <Label>Detail relatives' medical conditions and diseases.</Label>
          </div>
        </Top>

        <FormInputs>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: diagnosis && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="diagnosis"
                  className={diagnosis ? 'input-label show' : 'input-label'}
                >
                  Family tree
                </FormLabel>
                <FormText
                  id="diagnosis"
                  name="diagnosis"
                  value={familyTree}
                  type="text"
                  rows="1"
                  ref={familyTreeRef}
                  onChange={(e) => {
                    setFamilyHistoryFormFields((prev) => ({
                      ...prev,
                      familyTree: e.target.value,
                    }))
                    handleKeyUp(e, familyTreeRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Family tree"
                  className={
                    familyTree ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !familyTree && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Title>Family history of mental Illness</Title>
          <RenderingChip
            array={mentalIllnessHistoryList}
            selectedChips={mentalIllnesses}
            setSelectedChipsKey="mentalIllnesses"
            setFunction={setFamilyHistoryFormFields}
          />
          <Title>Any history of dementia</Title>
          <RenderingChip
            array={dementiasList}
            selectedChips={dementias}
            setSelectedChipsKey="dementias"
            setFunction={setFamilyHistoryFormFields}
          />
        </FormInputs>
        <Buttons>
          <ButtonTypeOne
            className={'case'}
            text="Back"
            color={THEME_COLORS.dark_500}
            bkgcolor={THEME_COLORS.white}
            border={THEME_COLORS.light_400}
            onclick={() => {
              if (
                detectChanges(
                  initialfamilyHistoryFormValues,
                  familyHistoryFormFields,
                )
              ) {
                setFamilyHistoryFormFields(initialfamilyHistoryFormValues)
                handleCancel()
              } else {
                handleCancel()
              }
            }}
          />
          <ButtonTypeOne
            text="Save"
            color={THEME_COLORS.white}
            bkgcolor={THEME_COLORS.chips_blue_on_container_2}
            onclick={handleFamilyHistoryFormSubmit}
          />
        </Buttons>
      </FormContainer>
    </>
  )
}

export default FamilyHistory

const FormContainer = styled.div`
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
`
const FormInputs = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Wrapper = styled.div`
  margin-bottom: 20px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small`
  margin-top: 5px;
`
const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
`

const Label = styled.p`
  /* width: 40%; */
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`
const ButtonContainer = styled.div`
  color: #fff;
  background-color: #366ee3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`
const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  color: #818181;
  margin-bottom: 20px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 30%; */
  margin: 0 auto;
  div.one {
    margin-right: 20px;
  }
`
