import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import AttentionModal from './modals/AttentionModal'
import { authentication } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import CancelWarningModal from './modals/CancelWarningModal'

const ChangePassword = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token

  const navigate = useNavigate()

  const clearStates = () => {
    setOldPassword('')
    setNewPassword('')
    setShowErrors(false)
    setNewPasswordError(false)
    setShowAttentionModal(false)
  }

  const handleNewPassword = () => {
    const specialChars = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/
    if (specialChars.test(newPassword)) {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = (close) => {
    if (oldPassword && newPassword) {
      if (handleNewPassword()) {
        setShowSuccessToast(true)
        if (close === 'close') {
          setTimeout(() => {
            navigate(-1)
          }, 2000)
        }
        setShowErrors(false)
        setNewPasswordError(false)
        setShowAttentionModal(false)
      } else {
        setNewPasswordError(true)
      }
    } else {
      setShowErrors(true)
    }
  }

  const handleCancel = () => {
    if (oldPassword || newPassword) setShowAttentionModal(true)
    else {
      clearStates()
      navigate(-1)
    }
  }

  const handleFinalSubmit = () => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    }
    // console.log(oldPassword.length);
    if (oldPassword.length > 5 && oldPassword.length > 5) {
      authentication
        .post('/update-password/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          if (StatusCode == 6000) {
            setNewPasswordError('')
            setShowErrors('')
            handleSubmit()
          } else if (StatusCode == 6001) {
            setNewPasswordError(response)
            console.log(response, 'error')
          }
        })
        .catch(function (error) {
          console.log(error, 'error')
        })
    } else {
      setShowErrors(true)
      setNewPasswordError(true)
    }
  }

  return (
    <Form>
      <FormData>
        <Head>Change Password</Head>
        <Wrapper>
          <InputWrapper className="input-wrapper">
            <Left>
              <FormLabel
                htmlFor="old-password"
                className={oldPassword ? 'input-label show' : 'input-label'}
              >
                Enter Old Password
              </FormLabel>
              <FormInput
                id="old-password"
                name="old-password"
                value={oldPassword}
                type="password"
                maxLength={15}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter Old Password"
                className={oldPassword ? 'input-value active' : 'input-value'}
              ></FormInput>
            </Left>
          </InputWrapper>
          {!oldPassword && showErrors && (
            <Error className="error">This field is required.</Error>
          )}
          {newPasswordError && (
            <Error className="error">Enter valid password</Error>
          )}
        </Wrapper>

        <Wrapper>
          <InputWrapper className="input-wrapper">
            <Left>
              <FormLabel
                htmlFor="new-password"
                className={newPassword ? 'input-label show' : 'input-label'}
              >
                Enter New Password
              </FormLabel>
              <FormInput
                id="new-password"
                name="new-password"
                value={newPassword}
                type="password"
                maxLength={15}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter New Password"
                className={newPassword ? 'input-value active' : 'input-value'}
              ></FormInput>
            </Left>
          </InputWrapper>
          {!newPassword && showErrors && (
            <Error className="error">This field is required.</Error>
          )}
          {newPasswordError && (
            <Error className="error">Enter valid password</Error>
          )}
        </Wrapper>
        <Small>*Minimum 6 character including special characters.</Small>
      </FormData>

      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_400}
          onclick={handleCancel}
        />
        <ButtonTypeOne
          text="Save"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
          onclick={handleFinalSubmit}
        />
      </FormButtons>
      {showAttentionModal && (
        <CancelWarningModal
          isModal={showAttentionModal}
          setIsModal={setShowAttentionModal}
        />
      )}
    </Form>
  )
}

export default ChangePassword

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 229.6px);
`
const FormData = styled.div``
const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const Wrapper = styled.div`
  margin-bottom: 16px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const FormInput = styled.input`
  &.active {
    font-weight: 500;
    font-size: 16px !important;
  }
`
const Error = styled.small`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`
const Small = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
