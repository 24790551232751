import React, { useState, useContext } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import upcoming from "../../../assets/image/dashboard/sorting-chip/upcoming-balck.svg";
import reschedule from "../../../assets/image/dashboard/sorting-chip/rescheduled-balck.svg";
import followup from "../../../assets/image/dashboard/sorting-chip/followup-balck.svg";
import completed from "../../../assets/image/dashboard/sorting-chip/completed-balck.svg";
import cancelled from "../../../assets/image/dashboard/sorting-chip/cancelled-balck.svg";
import { Context } from "../../../contexts/Store";

function SortingChip({
    sort,
    setSort,
    setsessionType,
    getSessions,
    followUPTrue,
}) {
    const { state } = useContext(Context);
    const [isitem, Setitem] = useState(state.isFollowUpActive ? 4 : 2);

    const data = [
        // "upcoming":2, "completed":3, "cancelled":4, "followups":5, "rescheduled":6, "failed":8
        {
            id: 1,
            title: "All",
            icon: "",
            type: "",
        },
        {
            id: 2,
            title: "Upcoming",
            icon: upcoming,
            type: "upcoming",
        },
        {
            id: 3,
            title: "Follow Ups",
            icon: followup,
            type: "followups",
        },
        {
            id: 4,
            title: "Completed",
            icon: completed,
            type: "completed",
        },
        {
            id: 5,
            title: "Cancelled",
            icon: cancelled,
            type: "cancelled",
        },
        {
            id: 8,
            title: "Failed",
            icon: cancelled,
            type: "failed",
        },
    ];

    return (
        <>
            {data.map((item) => (
                <ChipContainer
                    key={item.id}
                    className={item.id === isitem ? "active" : ""}
                    onClick={() => {
                        Setitem(item.id);
                        setsessionType(item.type);
                        setSort(item.title);
                    }}
                >
                    <IconContainer
                        className={item.title == "All" ? "active" : ""}
                    >
                        <Icon src={item.icon} alt="icon" />
                    </IconContainer>
                    <Date>{item.title}</Date>
                    <Dot
                        className={
                            item.title == "Follow Ups" && followUPTrue == true
                                ? "active"
                                : ""
                        }
                    ></Dot>
                </ChipContainer>
            ))}
        </>
    );
}

export default SortingChip;

const ChipContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 6px 16px;
    border-radius: 25px;
    background: ${THEME_COLORS.white};
    justify-content: space-between;
    border: 2px solid ${THEME_COLORS.light_300};
    cursor: pointer;
    position: relative;
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
    &:last-child {
        margin-right: 0px;
    }
`;

const Date = styled.p`
    color: ${THEME_COLORS.black};
    font-family: "DM_sans_medium";
    font-size: 12px;
`;
const IconContainer = styled.div`
    margin-right: 5px;

    &.active {
        display: none;
    }
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;
const Dot = styled.div`
    background: #fe6666;
    width: 6px;
    height: 6px;
    border-radius: 20px;
    margin-right: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    display: none;
    &.active {
        display: block;
    }
`;
