import React, { useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import Reschedulemodal from "./Reschedulemodal";
import CancelSessionModal from "./CancelSessionModal";
import ConfirmRescheduleModal from "./ConfirmRescheduleModal";

function SessionChangesModal({ sessionChange, setSessionChange }) {
    const [isRescheduleModal, setRescheduleModal] = useState(false);
    const [isCancelSessionModal, setCancelSessionModal] = useState(false);

    return (
        <>
            <Reschedulemodal
                isRescheduleModal={isRescheduleModal}
                setRescheduleModal={setRescheduleModal}
            />
            <CancelSessionModal
                isCancelSessionModal={isCancelSessionModal}
                setCancelSessionModal={setCancelSessionModal}
            />
            {/* <ConfirmRescheduleModal /> */}
            {sessionChange && (
                <CardContainer>
                    <Card>
                        <Middle>
                            <Text
                                onClick={() => {
                                    setRescheduleModal(true);
                                    setSessionChange(false);
                                }}
                            >
                                Reschedule
                            </Text>
                        </Middle>
                        <Middle>
                            <Text
                                onClick={() => {
                                    setCancelSessionModal(true);
                                    setSessionChange(false);
                                }}
                            >
                                Cancel
                            </Text>
                        </Middle>
                        <Middle>
                            <Text>Contact Admin</Text>
                        </Middle>
                    </Card>
                </CardContainer>
            )}
        </>
    );
}

export default SessionChangesModal;
const CardContainer = styled.div`
    cursor: default;
    /* margin-bottom: 20px; */
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 12px;
    padding-top: 5px;
    width: 155px;
    position: absolute;
    bottom: -155px;
    right: 0px;
    @media all and (max-width: 1080px) {
        right: 0px;
    }
`;
const Card = styled.div`
    padding: 8px 10px;
    background: ${THEME_COLORS.white};
    border-radius: 10px;
    border: 2px solid ${THEME_COLORS.white};
`;

const Middle = styled.div`
    display: flex;
    border-radius: 8px;
    padding: 8px 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const Text = styled.h3`
    font-size: 14px;
    color: ${THEME_COLORS.black};
`;

const Bottom = styled.div`
    display: flex;
    padding: 8px 10px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
