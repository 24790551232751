import React from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import TransactionCard from "./TransactionCard";

const Wallet = () => {
  const wallet_data = [
    {
      id: 1,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 2,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 3,
      type: "withdraw",
      title: "Cash withdrawal",
      date: "8-Jan-2023",
      amount: 5000,
    },
    {
      id: 4,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 5,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 6,
      type: "withdraw",
      title: "Cash withdrawal",
      date: "8-Jan-2023",
      amount: 5000,
    },
    {
      id: 7,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 8,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 9,
      type: "withdraw",
      title: "Cash withdrawal",
      date: "8-Jan-2023",
      amount: 5000,
    },
    {
      id: 10,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
    {
      id: 11,
      type: "payment",
      title: "Session #SID00241 Paid",
      date: "9-Jan-2023",
      amount: 1000,
    },
  ];
  return (
    <Main>
      <Head>Wallet</Head>
      <Top>
        <Cover>
          <EarningsBox>
            <EarningsTop>
              <ImageBox>
                <img
                  src={
                    require("../../../assets/image/my_account/earnings.svg")
                      .default
                  }
                  alt="earnings"
                />
              </ImageBox>
              <Earnings>
                <Title>Total Earnings</Title>
                <Value className="earnings">30,000 INR</Value>
              </Earnings>
              <ArrowBox>
                <img
                  src={
                    require("../../../assets/image/my_account/right_arrow_long_blue.svg")
                      .default
                  }
                  alt="arrow"
                />
              </ArrowBox>
            </EarningsTop>
            <EarningsBottom>
              <Section>
                <Title>Current Balance</Title>
                <Value>16,000 INR</Value>
              </Section>
              <Section>
                <Title>Withdrawn</Title>
                <Value>14,000 INR</Value>
              </Section>
            </EarningsBottom>
          </EarningsBox>
        </Cover>
      </Top>
      <Bottom>
        <HeadContainer>
          <SubHead>Transactions</SubHead>
          <Line></Line>
          <ViewLink>View all</ViewLink>
        </HeadContainer>
        <TransactionCards>
          {wallet_data.slice(0, 10).map((data) => {
            return <TransactionCard key={data.id} data={data} />;
          })}
        </TransactionCards>
      </Bottom>
    </Main>
  );
};

export default Wallet;

const Main = styled.div`
  // width: 31%;
  width: 440px;
  padding: 30px;
  @media all and (max-width: 1440px) {
    width: 31%;
  }
  @media all and (max-width: 1300px) {
    margin-right: 30px;
    margin-left: 30px;
    width: 100%;
    padding: 16px;
  }
`;
const Head = styled.h5`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.black};
`;
const Top = styled.div``;
const Cover = styled.div`
  width: 100%;
  height: 148px;
  padding: 20px;
  background: ${THEME_COLORS.gradient_3};
  border-radius: 10px;
  margin-bottom: 50px;
  @media all and (max-width: 1280px) {
    margin-bottom: 46px;
  }
`;
const EarningsBox = styled.div`
  width: 100%;
  height: 154px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e4};
  border-radius: 8px;
  padding: 20px;
`;
const EarningsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 16px;
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  margin-bottom: 20px;
`;
const ImageBox = styled.div`
  width: 40px;
  height: 40px;
  background: ${THEME_COLORS.chips_blue_container};
  border-radius: 8px;
  margin-right: 20px;
  padding: 10px;
  img {
    width: 20px;
    height: 20px;
  }
`;
const Earnings = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.h5`
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_label};
`;
const Value = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.black};
  &.earnings {
    font-size: 20px;
  }
`;
const ArrowBox = styled.div`
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
  }
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`;
const EarningsBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Section = styled.div`
  width: 48%;
`;
const Bottom = styled.div`
  padding: 16px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_300};
  box-shadow: ${THEME_COLORS.elevation_expert_e1};
  border-radius: 8px;
`;
const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
const SubHead = styled.h6`
  font-weight: 500;
  font-size: 16px;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.black};
`;
const Line = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  flex-grow: 1;
  margin: 0 10px;
`;
const ViewLink = styled.button`
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.chips_blue_on_container_2};
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`;
const TransactionCards = styled.div`
  // max-height: 912px;
  // overflow-y: scroll;
  // &::-webkit-scrollbar{
  //     display: none;
  // }
`;
