import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import SearchButton from "../../includes/buttons/SearchButton";
import ReportCard from "./ReportCard";
import { Context } from "../../../contexts/Store";
import { expert } from "../../../axiosConfig";

const ReportList = ({ type }) => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [searchItem, setSearchItem] = useState("");
    const [error, SetError] = useState([]);
    const [activeButton, setActiveButton] = useState("pending");
    const [list, setList] = useState();

    useEffect(() => {
        expert
            .get("/pending-reports", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setList(response.data.data.reports);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
    }, []);

    const handleSearch = (value) => {
        setSearchItem(value);
        setList(
            state.clientData?.filter((client) =>
                client.name.toLowerCase().startsWith(value.toLowerCase())
            )
        );
    };

    return (
        <Wrapper>
            <Head>
                <BackLink to="/reports-list">
                    <img
                        src={
                            require("../../../assets/image/reports/left_arrow_long_blue.svg")
                                .default
                        }
                        alt="left arrow"
                    />
                    <span>Back to Reports</span>
                </BackLink>
            </Head>
            <Content>
                <Top>
                    <Title>
                        {type === "case_report"
                            ? "Pending case reports"
                            : type === "summary_report"
                            ? "Pending Summary Reports"
                            : ""}
                    </Title>
                    <Right>
                        <SearchButton
                            searchItem={searchItem}
                            handleSearch={handleSearch}
                        />
                    </Right>
                </Top>
                <Bottom>
                    {type === "case_report" &&
                        activeButton === "pending" &&
                        list?.map((client) => {
                            return (
                                <ReportCard
                                    key={client.id}
                                    client={client}
                                    type="case_report"
                                    long
                                />
                            );
                        })}
                    {type === "case_report" &&
                        activeButton === "completed" &&
                        list?.map((client) => {
                            return (
                                <ReportCard
                                    key={client.id}
                                    client={client}
                                    type="case_report"
                                    completed
                                    long
                                />
                            );
                        })}
                    {type === "summary_report" &&
                        activeButton === "pending" &&
                        list?.map((client) => {
                            return (
                                <ReportCard
                                    key={client.id}
                                    client={client}
                                    type="summary_report"
                                    long
                                />
                            );
                        })}
                    {type === "summary_report" &&
                        activeButton === "completed" &&
                        list?.map((client) => {
                            return (
                                <ReportCard
                                    key={client.id}
                                    client={client}
                                    type="summary_report"
                                    completed
                                    long
                                />
                            );
                        })}
                </Bottom>
            </Content>
        </Wrapper>
    );
};

export default ReportList;

const Wrapper = styled.div`
    width: 67%;
    margin: 32px auto;
    @media all and (max-width: 980px) {
        width: 85%;
    }
`;
const Head = styled.div`
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.h3`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.black};
`;
const BackLink = styled(Link)`
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    img {
        width: 20px;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        color: ${THEME_COLORS.chips_blue_on_container_2};
        margin-left: 10px;
    }
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const Content = styled.div``;
const Top = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div``;
const Button = styled.button`
    padding: 6px 16px;
    background-color: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.light_300};
    color: ${THEME_COLORS.text_title};
    border-radius: 53px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const Right = styled.div``;
const Bottom = styled.div``;
