import React from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig.js'
import ButtonTypeOne from '../buttons/ButtonTypeOne.jsx'

const Logout = ({ setShowLogoutModal }) => {
  const handleCancel = () => {
    setShowLogoutModal(false)
  }

  const closeModal = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      handleCancel()
    }
  }

  const handleLogout = () => {
    // logout functionality
    localStorage.clear()
    window.location = '/'
    handleCancel()
  }

  return (
    <Background onClick={(e) => closeModal(e)}>
      <Container className="outer-click">
        <Title>Logout</Title>
        <SubTitle>Are you sure you wanted to Logout?</SubTitle>
        <ButtonContainer>
          <ButtonTypeOne
            text="Cancel"
            color={THEME_COLORS.dark_500}
            bkgcolor={THEME_COLORS.white}
            border={THEME_COLORS.light_400}
            pd="6px 16px"
            onclick={handleCancel}
          />
          <ButtonTypeOne
            text="Logout"
            color={THEME_COLORS.white}
            bkgcolor={THEME_COLORS.chips_blue_on_container_2}
            ml="12px"
            pd="6px 16px"
            onclick={handleLogout}
          />
        </ButtonContainer>
      </Container>
    </Background>
  )
}

export default Logout

const Background = styled.div`
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2222;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  position: absolute;
  width: 302px;
  padding: 32px;
  background: ${THEME_COLORS.white};
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
`
const Title = styled.h4`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 16px;
`
const SubTitle = styled.h4`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`
