import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import person from '../../../assets/image/dashboard/notification-card/person.svg'
import nodata from '../../../assets/image/dashboard/no-data.svg'

import CallTypeChip from '../../includes/Sessionchips/CallTypeChip'
import SessionCallChip from '../../includes/Sessionchips/SessionCallChip'
import SessionCountChip from '../../includes/Sessionchips/SessionCountChip'
import SessionDateChip from '../../includes/Sessionchips/SessionDateChip'
import SessionSchedulechip from '../../includes/Sessionchips/SessionSchedulechip'
import SessionTimeChip from '../../includes/Sessionchips/SessionTimeChip'

import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import SortingChip from '../../includes/Sessionchips/SortingChip'
import CountdownTimer from '../../includes/modals/CountdownTimer'
import { Link, useNavigate } from 'react-router-dom'

function SessionCard({
  sessionData,
  sessionType,
  setsessionType,
  error,
  getSessions,
  setIsUpdateStatusModal,
  setSelectedUpdateSessionId,
}) {
  const { state, dispatch } = useContext(Context)
  const [timerZero, setTimerZero] = useState(false)
  const navigate = useNavigate()
  const [sort, setSort] = useState('upcoming')

  return (
    <>
      {sessionData?.next_session ? (
        <Cover key={sessionData?.next_session?.id}>
          <CardContainer
            id="card"
            key={sessionData?.next_session?.id}
            className={'active'}
            onClick={(e) => {
              e.stopPropagation()
              navigate(`/session-single-page/${sessionData?.next_session?.id}`)
            }}
          >
            <Card key={sessionData?.next_session?.id} className={'active'}>
              <AvatarContainer>
                {sessionData?.next_session?.session?.patient.profile_photo ? (
                  <Avatar
                    src={
                      sessionData?.next_session?.session?.patient?.profile_photo
                    }
                    alt="icon"
                  />
                ) : (
                  <Avatar src={person} alt="icon" />
                )}
              </AvatarContainer>
              <Details>
                <Top>
                  <LeftSection>
                    <CaseId>
                      Case ID :{' '}
                      <span>
                        {sessionData?.next_session?.session_record_id}
                      </span>
                    </CaseId>
                    <PatientDetails>
                      <Name>
                        {' '}
                        {sessionData?.next_session?.session?.patient?.full_name}
                      </Name>
                      <Age>
                        {' '}
                        {sessionData?.next_session?.session?.patient?.age}
                      </Age>
                      <Gender>
                        {sessionData?.next_session?.session?.patient?.gender}
                      </Gender>
                      {sessionData?.next_session?.total_sessions > 1 && (
                        <SessionCountChip
                          current_session={
                            sessionData?.next_session?.current_session_number
                          }
                          total_session={
                            sessionData?.next_session?.total_sessions
                          }
                        />
                      )}
                    </PatientDetails>
                  </LeftSection>
                  <RightSection>
                    <SessionSchedulechip
                      session_record_status={
                        sessionData?.next_session?.session_record_status
                      }
                    />
                    <SessionDateChip
                      day={sessionData?.next_session?.case_date}
                    />
                    <SessionTimeChip
                      start_time={sessionData?.next_session?.start_time}
                      end_time={sessionData?.next_session?.end_time}
                    />
                    <CallTypeChip
                      call_type={sessionData?.next_session?.session?.meet_mode}
                    />
                  </RightSection>
                </Top>
                <Bottom>
                  <Left>
                    <Heading>
                      {' '}
                      {sessionData?.next_session?.session.concern}
                    </Heading>
                  </Left>
                  <Right>
                    {/* {timerZero == true ? ( */}
                    <SessionCallChip
                      link={sessionData?.next_session?.meet_link}
                      call_type={sessionData?.next_session?.session?.meet_mode}
                      status={sessionData?.next_session?.session_record_status}
                      session_status={
                        sessionData?.next_session?.session_record_status
                      }
                      type={'current'}
                      isNextSession={true}
                      timerZero={timerZero}
                    />
                  </Right>
                </Bottom>
              </Details>
            </Card>

            <BottomContainer className={'active'}>
              {sessionData?.pending_time_for_next_session ? (
                <Title className={timerZero == true ? 'active' : ''}>
                  Your session starts in
                </Title>
              ) : (
                <Title className={timerZero == true ? 'active' : ''}>
                  Your session starts soon
                </Title>
              )}
              <TitleTwo className={timerZero == true ? 'active bold' : ''}>
                You are good to go!
              </TitleTwo>
              {timerZero != true && (
                <div>
                  {sessionData?.pending_time_for_next_session ? (
                    <CountdownTimer
                      timerZero={timerZero}
                      setTimerZero={setTimerZero}
                      timeData={sessionData?.pending_time_for_next_session}
                    />
                  ) : (
                    <Title></Title>
                  )}
                </div>
              )}
            </BottomContainer>
          </CardContainer>
        </Cover>
      ) : (
        <Cover></Cover>
      )}
      <SortingContaner>
        <SortingChipContainer>
          <SortingChip
            setSort={setSort}
            sort={sort}
            setsessionType={setsessionType}
            getSessions={getSessions}
            followUPTrue={sessionData?.followup}
          />
        </SortingChipContainer>
      </SortingContaner>
      {sessionData?.data?.length != 0 ? (
        <div>
          {sessionData?.data?.map((item) => (
            <>
              <Cover key={item.id}>
                <CardContainer id="card" key={item.id}>
                  <Card key={item.id}>
                    <AvatarContainer
                      onClick={() => {
                        navigate(`/session-single-page/${item.id}`)
                      }}
                    >
                      {item.session?.patient?.profile_photo ? (
                        <Avatar
                          src={item?.session?.patient?.profile_photo}
                          alt="icon"
                        />
                      ) : (
                        <Avatar src={person} alt="icon" />
                      )}
                    </AvatarContainer>
                    <Details>
                      <Top
                        onClick={() => {
                          navigate(`/session-single-page/${item.id}`)
                        }}
                      >
                        <LeftSection
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <CaseId>
                            Case ID : <span>{item.session_record_id}</span>
                          </CaseId>
                          <PatientDetails>
                            <Name> {item.session?.patient?.full_name}</Name>
                            <Age> {item.session?.patient?.age}</Age>
                            <Gender>{item.session?.patient?.gender}</Gender>
                            {item.total_sessions > 1 ? (
                              <SessionCountChip
                                current_session={item.current_session_number}
                                total_session={item.total_sessions}
                              />
                            ) : (
                              ''
                            )}
                          </PatientDetails>
                        </LeftSection>
                        <RightSection
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <SessionSchedulechip
                            session_record_status={item.session_record_status}
                          />
                          <SessionDateChip day={item.case_date} />
                          <SessionTimeChip
                            start_time={item.start_time}
                            end_time={item.end_time}
                          />
                          <CallTypeChip call_type={item?.session?.meet_mode} />
                        </RightSection>
                      </Top>
                      <Bottom>
                        <Left
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <Heading> {item.session.concern}</Heading>
                        </Left>
                        <Right>
                          {
                            <SessionCallChip
                              id={item.id}
                              call_type={item?.session?.meet_mode}
                              status="queued"
                              session_status={item.session_record_status}
                              setIsUpdateStatusModal={setIsUpdateStatusModal}
                              setSelectedUpdateSessionId={
                                setSelectedUpdateSessionId
                              }
                              // link={item.meet_link}
                              type={'next'}
                            />
                          }
                        </Right>
                      </Bottom>
                    </Details>
                  </Card>
                </CardContainer>
              </Cover>
            </>
          ))}
        </div>
      ) : (
        <NoData className="nodata">
          <img src={nodata} alt="no data" />
          <CaseId>No {sort} sessions to show.</CaseId>
        </NoData>
      )}
    </>
  )
}

export default SessionCard

const Cover = styled.div``
const CardContainer = styled.div`
  cursor: pointer;
  margin-bottom: 20px;
  &.active {
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 12px;
  }
  &.count {
    color: ${THEME_COLORS.light_200};
    font-size: 14px;
    margin-right: 5px;
  }
`
const Card = styled.div`
  display: flex;
  padding: 20px 18px;
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  border: 2px solid ${THEME_COLORS.white};
  &:hover {
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
  }
  &.active {
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
  }
`
const AvatarContainer = styled.div`
  border-radius: 57px;
  background: #f4f4f4;
  padding: 7px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-right: 20px;
`
const Avatar = styled.img``
const Details = styled.div`
  width: 100%;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
`
const LeftSection = styled.div``
const RightSection = styled.div`
  display: flex;
`
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const Left = styled.div`
  width: 80%;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const CaseId = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 12px;
`
const PatientDetails = styled.ol`
  display: flex;
  align-items: center;
  list-style: disc;
  font-size: 14px;
`
const Name = styled.li`
  list-style: none;
  color: ${THEME_COLORS.chips_blue_on_container};
  margin-right: 20px;
`
const Age = styled.li`
  color: ${THEME_COLORS.text_para};
  margin-right: 20px;
`
const Gender = styled.li`
  color: ${THEME_COLORS.text_para};
  margin-right: 20px;
`
const Heading = styled.h2`
  font-size: 14px;
  margin-bottom: 8px;
`
const BottomContainer = styled.div`
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
  }
`
const Title = styled.p`
  margin-right: 30px;
  color: ${THEME_COLORS.light_200};
  font-size: 14px;
  &.active {
    display: none;
  }
`
const TitleTwo = styled.p`
  margin-right: 30px;
  color: ${THEME_COLORS.light_200};
  font-size: 14px;
  display: none;
  &.active {
    display: block;
  }
`

const SortingContaner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 20px;
  @media all and (max-width: 1300px) {
    align-items: flex-start;
  }
`
const NoData = styled.div`
  padding: 100px 40px;
  font-size: 24px;
  color: #000;
  text-align: center;
`

const SortingChipContainer = styled.div`
  display: flex;
  align-items: center;

  @media all and (max-width: 1300px) {
    flex-wrap: wrap;
    width: 70%;
  }
`
