import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import SuccessToast from "../../manage_profile/toast/SuccessToast";
import { expert } from "../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";

const WithdrawRequestModal = ({
    handleModal,
    setTimeSlot,
    setModal,
    isModal,
    approved_amount,
}) => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [requestedAmount, setRequestedAmount] = useState("");
    const [balanceAmount, setBalanceAmount] = useState(approved_amount - 200);
    const [error, setError] = useState("");

    useEffect(() => {
        if (approved_amount == 0) {
            setBalanceAmount(0);
        }
        document.body.classList.add("modal-open");

        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [approved_amount]);

    const closeModal = (e) => {
        const element = document.querySelector(".click-form");
        if (!element.contains(e.target)) {
            handleModal();
        }
    };
    const data = {
        amount: requestedAmount,
    };
    const handleSubmit = (event) => {
        expert
            .post("/wallet/", data, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setShowSuccessToast(true);
                    setTimeout(() => {
                        setShowSuccessToast(false);
                    }, 3000);
                } else if (StatusCode == 6001) {
                    setError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error.message);
            });
    };

    return (
        <Background
            className={showSuccessToast && "modal-active"}
            onClick={(e) => closeModal(e)}
        >
            <Cover></Cover>
            <RightContainer className="click-form">
                <MainTitle>Withdraw money from your wallet </MainTitle>
                <Description>
                    To withdraw money from your wallet, submit a request to
                    admin by filling out the form.
                </Description>

                <Section>
                    <Title>Balance Amount</Title>
                    <Value>{balanceAmount}.00 INR</Value>
                </Section>

                <Label>Requested Amount</Label>
                <InputBox
                    onSubmit={() => {
                        if (requestedAmount) {
                            if (
                                parseInt(requestedAmount) <=
                                parseInt(balanceAmount)
                            ) {
                                handleSubmit();
                            } else {
                                setError(
                                    "Amount must be less than or equal to balance amount"
                                );
                            }
                        } else {
                            setError("Enter an amount");
                        }
                    }}
                    type="submit"
                >
                    <input
                        type="number"
                        id="requestedAmount"
                        placeholder="Enter the amount"
                        value={requestedAmount}
                        onChange={(e) => {
                            setRequestedAmount(e.target.value);
                            setError("");
                        }}
                        required
                    />
                    {error && <Error>{error}</Error>}
                </InputBox>

                <WithdrawButton
                    onClick={() => {
                        if (requestedAmount) {
                            if (requestedAmount != 0) {
                                if (
                                    parseInt(requestedAmount) <=
                                    parseInt(balanceAmount)
                                ) {
                                    handleSubmit();
                                } else {
                                    setError(
                                        "Amount must be less than or equal to balance amount"
                                    );
                                }
                            } else {
                                setError("Can't withdraw requested amount");
                            }
                        } else {
                            setError("Enter an amount");
                        }
                    }}
                >
                    Withdraw
                </WithdrawButton>
            </RightContainer>
            {showSuccessToast && (
                <Modal>
                    <SuccessToast message={"Request submitted successfully"} />
                </Modal>
            )}
        </Background>
    );
};

export default WithdrawRequestModal;

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
const Cover = styled.div`
    background: #161616;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0.5;
`;
const MainTitle = styled.h3`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 30px;
`;
const RightContainer = styled.div`
    width: 50%;
    height: max-content;
    padding: 40px;
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    background-color: ${THEME_COLORS.white};
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Label = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #818181;
    margin-bottom: 10px;
`;
const Modal = styled.div``;
const Section = styled.div`
    /* width: 45%; */
`;
const Title = styled.h5`
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_label};
`;
const Value = styled.p`
    font-weight: 600;
    font-size: 18px;
    color: ${THEME_COLORS.black};
    margin-bottom: 30px;

    &.earnings {
        font-size: 20px;
    }
`;
const WithdrawButton = styled.button`
    padding: 8px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background: #366ee3;
    color: ${THEME_COLORS.white};
    font-size: 14px;
    font-family: "DM_sans_medium";
`;
const InputBox = styled.form`
    background-color: #f6f7f9;
    display: flex;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 300;
    padding: 12px 17px;
    align-items: center;
    width: 80%;
    margin-bottom: 40px;
    position: relative;
    input {
        border: none;
        background-color: #f6f7f9;
        height: 20px;
        font-size: 20px;
        width: 100%;
        cursor: pointer;
        @media all and (max-width: 1280px) {
            font-size: 16px;
        }
        :focus {
            outline: none;
            border: 1px solid #f1f2f378;
        }

        ::placeholder {
            font-size: 16px;
            font-weight: 400;
        }
    }
`;
const Error = styled.div`
    color: red;
    font-size: 14px;
    position: absolute;
    bottom: -28px;
    left: 0;
`;
