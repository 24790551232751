import React, { useContext, useEffect, useState } from "react";

// package
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// components
import { THEME_COLORS } from "../../../ThemeConfig";
import { authentication } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);

    const handleSubmit = () => {
        // e.preventDefault();
        if (error) {
            setError("");
        }

        authentication
            .post("/forget-password/enter-email/", {
                email: email,
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode == 6000) {
                    dispatch({
                        type: "UPDATE_USER",
                        user_details: {
                            email: email,
                        },
                    });
                    navigate("/auth/verify-otp");
                } else {
                    setError(data?.message);
                }
            })
            .catch(function (error) {
                setError(error?.response?.data?.data?.message);
            });
    };
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 8000);
        }
    }, [error]);
    return (
        <>
            <MainContent>
                <ForgotContainer>
                    <LogoBox>
                        <img
                            src={
                                require("../../../assets/image/loginpage/logo.svg")
                                    .default
                            }
                            alt="logo"
                        />
                    </LogoBox>
                    <Name>Forgot Password ?</Name>
                    <Explain>
                        Enter your email id associated with your account and
                        we'll send you a otp to reset your password
                    </Explain>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (email == "") {
                                setError("Enter your registered email id");
                            } else {
                                handleSubmit();
                            }
                        }}
                    >
                        <Box>
                            <input
                                type="email"
                                placeholder="Enter your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            ></input>
                        </Box>
                        <ResetBox
                            onClick={() => {
                                if (email == "") {
                                    setError("Enter your registered email id");
                                } else {
                                    handleSubmit();
                                }
                            }}
                        >
                            <Reset>Reset Password</Reset>
                        </ResetBox>
                        {error && <ErrorText>{error}</ErrorText>}

                        <LoginBox>
                            <MainButton to="/auth/login-page">
                                <Arrow>
                                    <img
                                        src={
                                            require("../../../assets/image/dashboard/left-arrow-blue.svg")
                                                .default
                                        }
                                        alt="Arrow"
                                    />
                                </Arrow>
                                <Back>Back to login in</Back>
                            </MainButton>
                        </LoginBox>
                    </Form>
                </ForgotContainer>
            </MainContent>
        </>
    );
}

export default ForgotPassword;

const MainContent = styled.section`
    padding: 10% 5% 9%;
    text-align: center;
    background: ${THEME_COLORS.input};
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ForgotContainer = styled.div`
    background: ${THEME_COLORS.white};
    padding: 45px 30px;
    border-radius: 15px;
    width: 35%;
    margin: 0 auto;
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    @media all and (max-width: 1440px) {
        width: 40%;
    }
    @media all and (max-width: 1280px) {
        width: 49%;
    }
    @media all and (max-width: 1050px) {
        width: 55%;
    }
    @media all and (max-width: 980px) {
        width: 67%;
    }
    @media all and (max-width: 768px) {
        width: 80%;
    }
`;
const LogoBox = styled.div`
    width: 50px;
    margin-bottom: 40px;
    img {
        display: block;
        width: 100%;
    }
`;
const Name = styled.h3`
    color: ${THEME_COLORS.text_title};
    /* font-weight: 600; */
    font-size: 24px;
    font-family: "DM_sans_bold";
    text-align: left;

    margin-bottom: 10px;
`;
const Explain = styled.p`
    color: ${THEME_COLORS.text_para};
    margin-bottom: 30px;
    font-size: 16px;
    text-align: left;
`;
const Form = styled.form``;
const Box = styled.div`
    background: ${THEME_COLORS.input};
    margin-bottom: 20px;
    padding: 16px 20px;
    border-radius: 12px;
    /* height: 52px; */

    input {
        width: 100%;
    }
`;
const ResetBox = styled.div`
    width: 100%;
    display: inline-block;
    background: ${THEME_COLORS.chips_blue_on_container_2};
    margin-bottom: 20px;
    /* padding: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 44px;
    box-sizing: border-box;
    cursor: pointer;
`;
const LoginBox = styled.div`
    /* background: ${THEME_COLORS.input}; */
    display: flex;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
`;
const Reset = styled.span`
    display: inline-block;
    text-align: center;
    color: ${THEME_COLORS.white};
    width: 100%;
    font-size: 14px;
    font-family: "DM_sans_medium";
`;
const MainButton = styled(Link)`
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;
const Arrow = styled.div`
    width: 20px;
    margin-right: 5px;
    img {
        display: block;
        width: 100%;
    }
`;
const Back = styled.span`
    display: inline-block;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-size: 14px;
    font-family: "DM_sans_medium";
`;
const ErrorText = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 10px;
`;
