import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { THEME_COLORS } from '../../../ThemeConfig'
import arrow from '../../../assets/image/dashboard/left-arrow-blue.svg'
import DashboardHeader from '../../genaral/DashboardHeader'
import DateCard from '../../includes/cards/DateCard'
import DateChip from '../../includes/chips/DateChip'
import DateDropdown from '../../includes/dropdown/DateDropdown'
import DateModal from '../../includes/modals/DateModal'
import SessionSlotModal from '../session_summary/modals/SessionSlotModal'
import UpdateSlotModal from '../session_summary/modals/UpdateSlotModal'
import SlotCard from './SlotCard'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'

function SlotDetailedView() {
  const { state, dispatch } = useContext(Context)

  const access_token = state.user_details.access_token

  const [datemodal, setDateModal] = useState(false)
  const [filtermodal, setFilterModal] = useState(false)
  const [month, setMonth] = useState('January')
  const [filter, setFilter] = useState('Upcoming')
  const [datecards, setDateCards] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [date, setDate] = useState(new Date().getDate())
  const [day, setDay] = useState(new Date().getDay())
  const [dot, setDot] = useState(true)
  const [updatemodal, setUpdateModal] = useState(false)
  const [sessionData, setSessionData] = useState([])
  const [error, SetError] = useState('')

  const [selectedDays, setSelectedDays] = useState('')
  const [selectedMonths, setSelectedMonths] = useState('')
  const [selectedYears, setSelectedYears] = useState('')

  const currentDate = new Date()

  const showDates = () => {
    let today = new Date()
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    let days_full = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let days_show = 30
    let dates = []

    for (let i = 0; i < days_show; i++) {
      let next_date = new Date(new Date().setDate(today.getDate() + i))
      let date = next_date.getDate()
      let day = days[next_date.getDay()]
      let day_full = days_full[next_date.getDay()]
      let month = months[next_date.getMonth()]
      let year = next_date.getFullYear()

      dates.push({
        id: Math.random() * 100,
        date: date,
        day: day,
        day_full: day_full,
        month: month,
        year: year,
      })
    }
    setDateCards(dates)
  }
  function getFormattedDate() {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const currentDate = new window.Date()
    const month = months[currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }

  useEffect(() => {
    showDates()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  function formatDate(obj) {
    const monthAbbrev = obj?.month?.slice(0, 3)
    const formattedDate = `${monthAbbrev} ${obj.date}, ${obj.year}`
    return formattedDate
  }

  const handleDateClick = (dateObj) => {
    const { date, month, year } = dateObj
    const monthNumberMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    }

    const monthNumber = monthNumberMap[month]
    setSelectedMonths(monthNumber)
    setSelectedDays(date)
    setSelectedYears(year)
  }
  useEffect(() => {
    if (selectedDate) {
      handleDateClick(selectedDate)
    } else {
      const day = currentDate.getDate()

      const currentMonthNumber = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()

      setSelectedMonths(currentMonthNumber)
      setSelectedDays(day)
      setSelectedYears(year)
    }
  }, [selectedDate])

  const getSessions = () => {
    expert
      .get('/sessions/committed/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          date: selectedDays,
          month: selectedMonths,
          year: selectedYears,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setSessionData(response.data.data)
        } else if (StatusCode == 6001) {
          SetError(response)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
  }
  useEffect(() => {
    getSessions()
  }, [selectedDays, selectedMonths, selectedYears])

  return (
    <>
      <Container className={updatemodal && 'modal-active'}>
        <DashboardHeader />
        <Cover>
          <TopContainer>
            <ButtonContainer to="/schedule-calendar">
              <Icon src={arrow} />
              <Content>Back to Schedule</Content>
            </ButtonContainer>
            <DateChip />
          </TopContainer>
          <Dates>
            <DateCard
              dot={dot}
              cards={datecards}
              value={selectedDate}
              setValue={setSelectedDate}
            />
          </Dates>
          <Middle></Middle>
          <Bottom>
            <RightContainer>
              <SessionSlotModal
                updatemodal={updatemodal}
                setUpdateModal={setUpdateModal}
                day={selectedDays}
                month={selectedMonths}
                year={selectedYears}
              />
            </RightContainer>
            <LeftContainer>
              {selectedDate ? (
                <Heading>All Schedule - {formatDate(selectedDate)}</Heading>
              ) : (
                <Heading>
                  All Schedule - {getFormattedDate(currentDate)}
                </Heading>
              )}
              <BottomContainer>
                <SlotCard
                  sessionData={sessionData}
                  day={selectedDays}
                  month={selectedMonths}
                  year={selectedYears}
                />
              </BottomContainer>
            </LeftContainer>
          </Bottom>
        </Cover>
        {updatemodal && (
          <UpdateSlotModal
            updatemodal={updatemodal}
            setUpdateModal={setUpdateModal}
          />
        )}
      </Container>
    </>
  )
}

export default SlotDetailedView

const Container = styled.section`
  padding: 16px;
  position: relative;
  &.modal-active {
    height: calc(100vh - 69.6px);
    overflow-y: hidden;
  }
`
const Cover = styled.section`
  padding: 16px;
  border: 1px solid ${THEME_COLORS.light_200};
  border-radius: 10px;
`
const LeftContainer = styled.div`
  width: 70%;
  padding: 40px 20px 20px 20px;
  background: ${THEME_COLORS.chips_green_container};
  border-radius: 10px;
  overflow: scroll;
  height: 90vh;
  ::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1280px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
const RightContainer = styled.div`
  width: 30%;
  margin-right: 30px;
  @media all and (max-width: 1280px) {
    width: 100%;
    margin-right: 0;
  }
`
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 8px;
`
const DateDiv = styled.div`
  position: relative;
`
const Filter = styled.div`
  position: relative;
`
const Middle = styled.div``
const Bottom = styled.div`
  display: flex;
  @media all and (max-width: 1280px) {
    flex-wrap: wrap-reverse;
  }
`

const BottomContainer = styled.div``
const RightDiv = styled.div`
  display: flex;
  width: 15%;
  justify-content: flex-end;
`
const ButtonContainer = styled(Link)`
  display: flex;
  justify-content: space-evenly;
  width: 175px;
  cursor: pointer;
`
const Content = styled.p`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Icon = styled.img``
const Dates = styled.div`
  margin-bottom: 20px;
`
const Heading = styled.h2`
  margin-bottom: 20px;
  color: ${THEME_COLORS.text_title};
  font-size: 18px;
`
