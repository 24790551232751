import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import check from '../../../../assets/image/dashboard/Check box.svg'

function TimeSlotChip({
  time,
  timeSlots,
  setTimeSlots,
  selectedSlots,
  setSelectedSlots,
  timeSlot,
  setTimeSlot,
  setTimeSlotID,
  title,
  timeSlotID,
}) {
  const handleSlot = (language, id) => {
    if (timeSlot.includes(language)) {
      setTimeSlot((prev) => prev.filter((item) => item !== language))
      // console.log(timeSlot, 'selected Slot')
      setTimeSlotID((prev) => prev.filter((item) => item !== id))
    }
  }
  const renderAfterNoonSlots = () => {
    const handleSlotClick = (language, id) => {
      if (timeSlot.includes(language)) {
        setTimeSlot((prev) => prev.filter((item) => item !== language))
        // console.log(timeSlot, 'selected Slot')
        setTimeSlotID((prev) => prev.filter((item) => item !== id))
      } else {
        setTimeSlot((prev) => [...prev, language])
        // console.log(timeSlot, 'selected ‚Slot')
        setTimeSlotID((prev) => [...prev, id])
      }
    }
    return time.map((slot) => (
      <div>
        {timeSlot.includes(slot.start_time) ? (
          <SlotText
            key={slot.id}
            onClick={() => {
              handleSlotClick(slot.start_time, slot.id)
            }}
            className={
              timeSlot.includes(slot.start_time) || slot.active == true
                ? 'selected'
                : ''
            }
          >
            <ImageContainer
              className={
                timeSlot.includes(slot.start_time) || slot.active == true
                  ? 'selected'
                  : ''
              }
            >
              {' '}
              <img
                className={timeSlot.includes(slot.start_time) ? 'selected' : ''}
                src={check}
                alt="icon"
              />
            </ImageContainer>
            {slot.start_time} - {slot.end_time}
          </SlotText>
        ) : (
          <>
            {
              <SlotText
                key={slot.id}
                onClick={() => {
                  handleSlotClick(slot.start_time, slot.id)
                }}
                className={
                  timeSlot.includes(slot.start_time) || slot.active == true
                    ? 'selected'
                    : ''
                }
              >
                <ImageContainer
                  className={
                    timeSlot.includes(slot.start_time) || slot.active == true
                      ? 'selected'
                      : ''
                  }
                >
                  {' '}
                  <img
                    className={
                      timeSlot.includes(slot.start_time) ? 'selected' : ''
                    }
                    src={slot.active == true ? check : ''}
                    alt=""
                  />
                </ImageContainer>
                {slot.start_time} - {slot.end_time}
              </SlotText>
            }
          </>
        )}
      </div>
    ))
  }
  return (
    <div>
      <HeadContainer>
        <Title>{title}</Title>
      </HeadContainer>

      <SlotsContainer>
        {timeSlots?.map((slotItem) => (
          <SlotItem
            onClick={() => {
              if (selectedSlots.includes(slotItem.id)) {
                setSelectedSlots(
                  selectedSlots.filter((item) => item !== slotItem.id),
                )
              } else {
                setSelectedSlots([...selectedSlots, slotItem.id])
              }
            }}
          >
            <SlotItemIconContainer>
              {selectedSlots.includes(slotItem.id) && (
                <img src={check} alt="icon" />
              )}
            </SlotItemIconContainer>
            <SlotItemText>
              {slotItem.start_time} - {slotItem.end_time}
            </SlotItemText>
          </SlotItem>
        ))}
      </SlotsContainer>
    </div>
  )
}

export default TimeSlotChip
const SlotItem = styled.div`
  border: 1px solid ${THEME_COLORS.light_400};
  width: fit-content;
  border-radius: 15px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${THEME_COLORS.dark_500};
  &.selected {
    background: #e0f8f2;
    color: #1da687;
    border: 1px solid #1da687;
  }
`
const SlotItemIconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 4px;
  &.selected {
    border: 1px solid #1da687;
    margin-right: 10px;
    background: #1da687;
  }
  img {
    width: 100%;
    display: block;
    &.selected {
      display: block;
    }
  }
`
const SlotItemText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${THEME_COLORS.dark_500};
  display: inline-block;
`
const SlotBox = styled.div``
const SlotText = styled.p`
  border: 1px solid ${THEME_COLORS.light_400};
  width: fit-content;
  border-radius: 15px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${THEME_COLORS.dark_500};
  &.selected {
    background: #e0f8f2;
    color: #1da687;
    border: 1px solid #1da687;
  }
`
const SlotsContainer = styled.div`
  /* margin-bottom: 50px; */
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  gap: 15px;
`
const SlotField = styled.div``
const Title = styled.div`
  color: ${THEME_COLORS.dark_300};
  font-size: 14px;
  margin-bottom: 5px;
`
const HeadContainer = styled.div`
  margin-bottom: 10px;
`
const ImageContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 4px;
  &.selected {
    border: 1px solid #1da687;
    margin-right: 10px;
    background: #1da687;
  }
  img {
    width: 100%;
    display: block;
    &.selected {
      display: block;
    }
  }
`
