import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ChipRectangle from '../../includes/chips/ChipRectangle'
import person from '../../../assets/image/dashboard/profile.svg'
import { Context } from '../../../contexts/Store'

const ReportCard = ({ client, type, completed, long }) => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(Context)

  return (
    <Card long={long}>
      <Content long={long}>
        <Top long={long}>
          <LeftContainer long={long}>
            <Left>
              <img
                src={client.profile_photo ? client.profile_photo : person}
                alt="client"
              />
            </Left>
            <Right>
              <RightTop>
                <Status>{client.status}</Status>
              </RightTop>
              <RightBottom>
                <Name>{client.name}</Name>
                <ID>Case ID :{client.session_record_id}</ID>
              </RightBottom>
            </Right>
          </LeftContainer>
          <RightContainer>
            <AddIcon>
              {completed && (
                <ChipRectangle
                  content={
                    type === 'case_report' ? (
                      <span>View Report</span>
                    ) : type === 'summary_report' ? (
                      <span>View Summary</span>
                    ) : null
                  }
                  color={THEME_COLORS.chips_blue_on_container_2}
                  font_size="14px"
                  font_weight="500"
                  pd="4px 6px"
                  br="4px"
                  hover_color={THEME_COLORS.light_200}
                  // onclick
                />
              )}
              {!completed && (
                <IconContainer>
                  <ChipRectangle
                    image={
                      type === 'case_report'
                        ? 'case_add_btn.svg'
                        : type === 'summary_report'
                        ? 'summary_add_btn.svg'
                        : null
                    }
                    pd="8px"
                    bkgcolor={THEME_COLORS.chips_blue_container}
                    br="8px"
                    image_width="35px"
                    hover_color={THEME_COLORS.gradient_2}
                    onclick={() => {
                      if (type == 'case_report') {
                        dispatch({
                          type: 'UPDATE_USER',
                          user_details: {
                            activeOption: 1,
                          },
                        })
                        navigate('/add-case-report')
                      } else if (type == 'summary_report') {
                        dispatch({
                          type: 'UPDATE_USER',
                          user_details: {
                            activeOption: 1,
                          },
                        })
                        navigate('/add-summary-report')
                      } else {
                      }
                    }}
                  />
                </IconContainer>
              )}
            </AddIcon>
          </RightContainer>
        </Top>
        <Middle>
          <Concern>{client.concern}</Concern>
        </Middle>
        <Bottom long={long}>
          <ChipRectangle
            content={`${client.current_session_number} of ${client.no_of_sessions} Session`}
            bkgcolor={THEME_COLORS.chips_blue_container}
            color={THEME_COLORS.chips_blue_on_container_2}
            pd="4px 8px"
            br="4px"
            font_size="12px"
            font_weight="400"
          />

          <ChipRectangle
            image="video_green.svg"
            pd="6px"
            br="4px"
            bkgcolor={THEME_COLORS.chips_green_container}
            image_width="12px"
            // mr_left="10px"
          />
          <ChipRectangle
            image="calendar_grey.svg"
            content={`Completed ${client.case_date}`}
            image_width="12px"
            font_weight="400"
            font_size="12px"
            color={THEME_COLORS.dark_300}
            pd="4px 8px 4px 3px"
            br="4px"
            bkgcolor={THEME_COLORS.light_200}
          />
        </Bottom>
      </Content>
    </Card>
  )
}

export default ReportCard

const Card = styled.div`
  width: 100%;
  min-height: ${(props) => (props.long ? '90px' : '122px')};
  padding: ${(props) => (props.long ? '12px' : '10px')};
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_300};
  box-shadow: ${THEME_COLORS.elevation_expert_e1};
  border-radius: 8px;
  margin-bottom: ${(props) => (props.long ? '12px' : '8px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin-bottom: 10px;
  }
`
const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: ${(props) => (props.long ? 'column' : 'column')};
  justify-content: ${(props) => props.long && 'space-between'};
  align-items: ${(props) => props.long && 'flex-start'};
`
const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: ${(props) => props.long && '100%'};
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  padding-bottom: 10px;
  justify-content: ${(props) => props.long && 'space-between'};
`
const Left = styled.div`
  margin-right: 18px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #f4f4f4;
  overflow: hidden;
  padding: 5px;
  img {
    width: 100%;
    display: block;
  }
`
const Right = styled.div``
const RightTop = styled.div``
const Status = styled.h5`
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const RightBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media all and (max-width: 1268px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Name = styled.h5`
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  color: ${THEME_COLORS.secondary};
  @media all and (max-width: 1268px) {
    margin-bottom: 4px;
  }
`
const ID = styled.h5`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.dark_300};
  @media all and (max-width: 1268px) {
    margin-bottom: 4px;
  }
`
const Middle = styled.div`
  margin-bottom: 10px;
`
const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  gap: ${(props) => props.long && '20px'};
  @media all and (max-width: 1380px) {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    grid-gap: 10px;
    height: max-content;
  }
  @media all and (max-width: 1268px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: max-content;
  }
`
const AddIcon = styled.div`
  margin-left: auto;
`
const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-left: auto;
`
const Concern = styled.h4`
  font-family: 'DM_sans_medium';
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Line = styled.div``
const LeftContainer = styled.div``
const RightContainer = styled.div``
