import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import NotificationCard from "../../screens/notification/NotificationCard";
import nodata from "../../../assets/image/dashboard/no-data.svg";
import { general } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

const NotificationModal = ({ setNotificationModal, notifications }) => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;

    const [error, SetError] = useState([]);

    // console.log(notifications, 'notifications')
    return (
        <CardContainer className="outer-click">
            <Head>Notification</Head>
            {notifications.length != 0 ? (
                <>
                    {notifications?.map((item) => (
                        <NotificationCard item={item} key={item.id} />
                    ))}
                    <ViewLink
                        to="/notifications"
                        onClick={() => setNotificationModal(false)}
                    >
                        View all notifications
                    </ViewLink>
                </>
            ) : (
                <Nodata className="nodata">
                    <img src={nodata} />
                    <p>You don't have any new notifications</p>
                </Nodata>
            )}
        </CardContainer>
    );
};

export default NotificationModal;

const CardContainer = styled.div`
    padding: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    background: ${THEME_COLORS.white};
    border-radius: 12px;
    width: 420px;
    position: absolute;
    top: 48px;
    right: 0;
    cursor: default;
    z-index: 1111;
`;
const Head = styled.h4`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
`;
const ViewLink = styled(Link)`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    margin-top: 28px;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 8px;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const Nodata = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    img {
        margin-bottom: 10px;
    }
`;
