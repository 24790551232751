import React, { useEffect, useState } from 'react'
import { Calendar } from 'react-calendar'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import CalenderAppointmentModal from '../../../includes/modals/CalenderAppointmentModal'
import SelectSlotCard from './SelectSlotCard'
import icon from '../../../../assets/image/dashboard/calendar-black.svg'
import CalenderModal from './CalenderModal'
import SuccessToast from '../../manage_profile/toast/SuccessToast'

function UpdateSlotModal({ updatemodal, setUpdateModal }) {
  const [dayModal, setDayModal] = useState(false)
  const [calendarModal, setCalendarModal] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)

  const [calendarDay, setCalendarDay] = useState('')
  const [calendarMonth, setCalendarMonth] = useState('')
  const [calendarYear, setCalendarYear] = useState('')
  const [selectedDateInFormat, setSelectedDateInFormat] = useState('')

  // console.log(calendarModal)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // console.log(updatemodal, 'updatemodal')

  const handleCaledarClick = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const selectedMonthNumber = date.getMonth() + 1
      const selectedMonth = date.toLocaleString('default', {
        month: 'short',
      })
      const selectedDay = date.getDate()
      const selectedYear = date.getFullYear()

      const formattedDate = `${selectedYear}-${
        selectedMonthNumber < 10 ? '0' : ''
      }${selectedMonthNumber}-${selectedDay < 10 ? '0' : ''}${selectedDay}`

      setCalendarDay(selectedDay)
      setCalendarMonth(selectedMonthNumber)
      setCalendarYear(selectedYear)
      setSelectedDateInFormat(formattedDate)
    }
  }

  const setDefaultDate = () => {
    const currentDate = new Date()
    const defaultMonthNumber = currentDate.getMonth() + 1
    const defaultMonth = currentDate.toLocaleString('default', {
      month: 'short',
    })
    const defaultDay = currentDate.getDate()
    const defaultYear = currentDate.getFullYear()

    const formattedDate = `${defaultYear}-${
      defaultMonthNumber < 10 ? '0' : ''
    }${defaultMonthNumber}-${defaultDay < 10 ? '0' : ''}${defaultDay}`

    setCalendarDay(defaultDay)
    setCalendarMonth(defaultMonthNumber)
    setCalendarYear(defaultYear)
    setSelectedDateInFormat(formattedDate)
  }

  useState(() => {
    setDefaultDate()
  }, [])

  return (
    <>
      {/* {updatemodal && ( */}
      <Background>
        <Cover>
          <Main className="wrapper">
            <TopContainer>
              <Left>
                <Title>Update Slots</Title>
                <Text>Update your slots for upcoming sessions</Text>
              </Left>
              <Right>
                <Icon
                  onClick={() => {
                    setCalendarModal(!calendarModal)
                  }}
                  src={icon}
                />
                <CalenderModal
                  calendarModal={calendarModal}
                  setCalendarModal={setCalendarModal}
                />
              </Right>
            </TopContainer>

            <Container>
              <LeftContainer>
                <CalendarContainer>
                  <Calendar
                    onClickDay={(date) => {
                      handleCaledarClick(date)
                    }}
                  />
                  {dayModal && <CalenderAppointmentModal />}
                </CalendarContainer>
              </LeftContainer>
              <RightContainer>
                <SelectSlotCard
                  selectedDateInFormat={selectedDateInFormat}
                  calendarMonth={calendarMonth}
                  setShowSuccessToast={setShowSuccessToast}
                  updatemodal={updatemodal}
                  setUpdateModal={setUpdateModal}
                />
              </RightContainer>
            </Container>
          </Main>
        </Cover>
        {showSuccessToast && (
          <Modal>
            <SuccessToast />
          </Modal>
        )}
      </Background>
      {/* )} */}
    </>
  )
}

export default UpdateSlotModal

const Background = styled.div`
  width: 100%;
  padding-top: 80px;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 1111111;
`
const Cover = styled.div`
  background-color: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 24px 24px 0px 0px;
  position: relative;
  padding: 60px 0;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
const Main = styled.div`
  /* height: 100vh; */
`
const Container = styled.div`
  display: flex;
  gap: 20px;
`

const LeftContainer = styled.div`
  width: 35%;

  @media all and (max-width: 1280px) {
    display: none;
  }
`
const RightContainer = styled.div`
  width: 65%;
  @media all and (max-width: 1280px) {
    width: 100%;
  }
`
const CalendarContainer = styled.section`
  width: auto;
  position: relative;

  .react-calendar {
    padding: 8px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: auto;
    border-radius: 10px;
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    @media all and (max-width: 1400px) {
      width: 100%;
    }
  }

  .react-calendar button {
    display: flex !important;
    font-size: 14px;
    padding: 12px;
    border-radius: 48px;
    width: 35px !important;
    height: 55px;
    justify-content: center;
    align-items: center;
    /* flex: 0 0 13.2857% !important; */
    @media all and (max-width: 1400px) {
      font-size: 14px;
      height: 60px !important;
    }

    &:hover {
      background: ${THEME_COLORS.chips_green_container};
    }

    @media all and (max-width: 1400px) {
    }
  }

  .react-calendar__tile--now {
    background: ${THEME_COLORS.chips_green_container};
  }

  .react-calendar__tile--active {
    background: ${THEME_COLORS.chips_green_on_container} !important;
    color: white;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    &:hover {
      background: none;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    font-family: 'DM_sans_medium';
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    span {
      font-family: 'DM_sans_medium' !important;
    }
  }
  .react-calendar__navigation__prev2-button {
    background: ${THEME_COLORS.chips_blue_container};
    /* display: none; */
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__prev2-button {
    display: none !important;
  }
  .react-calendar__tile .react-calendar__year-view__months__month {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__label {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar button {
    display: flex;
  }
`
const Title = styled.h1`
  font-size: 24px;
`
const Text = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
`
const Bottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  color: ${THEME_COLORS.text_title};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const Right = styled.div``
const Left = styled.div``
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Icon = styled.img`
  display: none;
  @media all and (max-width: 1280px) {
    display: block;
  }
`
const Modal = styled.div``
