import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'

import AccountDetails from './AccountDetails'
import Reviews from './Reviews'
import Wallet from './Wallet'
import DashboardHeader from '../../genaral/DashboardHeader'
import { Context } from '../../../contexts/Store'
import Transactions from './Transactions'

const MyAccountPage = () => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    return dispatch({
      type: 'PROFILE_OPTION',
      activeOption: 'about',
    })
  }, [])

  return (
    <>
      <MainContainer>
        <AccountDetails />
        <Reviews />
        <Transactions />
      </MainContainer>
    </>
  )
}

export default MyAccountPage

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media all and (max-width: 980px) {
    margin-bottom: 60px;
  }
`
