import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../ThemeConfig";
import icon from "../../assets/image/dashboard/conatct-icon.svg";
import verify from "../../assets/image/dashboard/verified.svg";
import { Link } from "react-router-dom";
import { Context } from "../../contexts/Store";
import { expert } from "../../axiosConfig";
import rejected from "../../assets/image/dashboard/rejected.svg";

function ProfileVerification() {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [verification_status, setverification_status] = useState();
    const [expertData, setExpertData] = useState([]);

    useEffect(() => {
        expert
            .get("/expert-details/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                setExpertData(data);
                setverification_status(
                    data?.basic_details?.verification_status
                );
            })
            .catch((error) => {});
        // }
    }, []);
    return (
        <>
            {verification_status == "Verified" ? (
                <DashboardContainer className="verify">
                    <VerificationContainer>
                        <ImgContainer>
                            <img src={verify} alt="verify" />
                        </ImgContainer>
                        <Heading>Application Verified</Heading>
                        <Description>
                            Congratulations! Your application has been
                            successfully verified. You're now an expert on our
                            platform.
                        </Description>
                        <ContactButton to="/auth/login-page">
                            Login
                        </ContactButton>
                    </VerificationContainer>
                </DashboardContainer>
            ) : verification_status == "Pending" ? (
                <DashboardContainer className="verify">
                    <VerificationContainer>
                        <ImgContainer>
                            <img src={icon} alt="verify" />
                        </ImgContainer>
                        <Heading>Application Under Review </Heading>
                        <Description>
                            Your application is under review. We'll notify you
                            once it's complete. Contact our{" "}
                            <SupportTag href="mailto:expertcare@mymindmitra.com">
                                support
                            </SupportTag>{" "}
                            team for questions.
                        </Description>
                        <ContactButton to="/auth/login">
                            Back to Login
                        </ContactButton>
                    </VerificationContainer>
                </DashboardContainer>
            ) : verification_status == "Rejeceted" ? (
                <DashboardContainer className="verify">
                    <VerificationContainer>
                        <ImgContainer>
                            <img src={rejected} alt="verify" />
                        </ImgContainer>
                        <Heading>Application Rejected </Heading>
                        <Description>
                            We appreciate your interest and encourage you to
                            reapply when you meet our criteria in the future.
                        </Description>
                        <ButtonContainer>
                            <ReturnButton to="/auth/contact">
                                Contact Support
                            </ReturnButton>
                            <ContactButton to="/register-page">
                                Return to Sign Up
                            </ContactButton>
                        </ButtonContainer>
                    </VerificationContainer>
                </DashboardContainer>
            ) : (
                ""
            )}
        </>
    );
}

export default ProfileVerification;

const DashboardContainer = styled.section`
    display: flex;
    position: relative;
    &.modal-active {
        height: calc(100vh - 69.6px);
    }
    &.verify {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 69.6px);
    }
`;

const VerificationContainer = styled.div`
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    padding: 30px;
    width: 30%;
    @media all and (max-width: 1080px) {
        width: 40%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 50%;
    }
`;
const ImgContainer = styled.div`
    width: 70%;
    margin: 0 auto;
    img {
        display: block;
        width: 100%;
    }
`;
const SupportTag = styled.a`
    color: #366ee3;
    text-decoration: underline;
    cursor: pointer;
`;
const ContactButton = styled(Link)`
    color: #fff;
    background-color: #366ee3;
    border: 2px solid #366ee3;

    border-radius: 7px;
    padding: 14px 24px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;
const ReturnButton = styled(Link)`
    border: 2px solid #d3d3d3;
    border-radius: 7px;
    padding: 14px 24px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;
const Heading = styled.h5`
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    font-family: "DM_sans_medium";
    text-align: center;
    margin-top: 20px;
`;
const Description = styled.p`
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
