import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import SearchButton from '../../includes/buttons/SearchButton'
import ReviewCard from './ReviewCard'
import Pagination from '../../includes/pagination/Pagination'
import { Context } from '../../../contexts/Store'
import { expert } from '../../../axiosConfig'
import nodata from '../../../assets/image/dashboard/no-data.svg'

const Reviews = () => {
  const { state, dispatch } = useContext(Context)
  const [reviews, setReviews] = useState([])

  const access_token = state.user_details.access_token

  useEffect(() => {
    // if (access_token) {
    expert
      .get('/patient-feedbacks/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        setReviews(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
    // }
  }, [access_token])

  const [searchItem, setSearchItem] = useState('')
  const [active, setActive] = useState(0)
  const [loadReview, setLoadReview] = useState(false)

  // pagination
  const [activePage, setActivePage] = useState(1)
  const [currentList, setCurrentList] = useState([])
  const [list, setList] = useState(reviews)
  const itemsPerPage = 4
  const indexOfLastItem = activePage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  useEffect(() => {
    setCurrentList(list.slice(indexOfFirstItem, indexOfLastItem))
  }, [list, activePage])

  const handleSearch = (value) => {
    setSearchItem(value)
  }

  return (
    <Main>
      <Head>
        <Left>Latest Reviews</Left>
        <Right>
          <SearchBox>
            <SearchButton searchItem={searchItem} handleSearch={handleSearch} />
          </SearchBox>
        </Right>
      </Head>

      {reviews?.feedbacks?.length != 0 ? (
        <ReviewCards className="web-view">
          {reviews?.feedbacks?.map((review, index) => {
            return (
              <ReviewCard
                key={review.id}
                review={review}
                index={index}
                active={active}
                setActive={setActive}
              />
            )
          })}
        </ReviewCards>
      ) : (
        <Nodata className="nodata">
          <img src={nodata} />
          <p>You don't have any Reviews yet</p>
        </Nodata>
      )}

      <PaginationContainer className="web-view">
        <Pagination
          list={list}
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </PaginationContainer>
      <ReviewCards className="tab-view">
        {loadReview
          ? currentList.map((review, index) => {
              return (
                <ReviewCard
                  key={review.id}
                  review={review}
                  index={index}
                  active={active}
                  setActive={setActive}
                />
              )
            })
          : reviews?.feedbacks?.slice(0, 2).map((review, index) => {
              return (
                <ReviewCard
                  key={review.id}
                  review={review}
                  index={index}
                  active={active}
                  setActive={setActive}
                  loadReview={loadReview}
                />
              )
            })}
        {!loadReview && <Rectangle></Rectangle>}
      </ReviewCards>
      {loadReview ? (
        <PaginationContainer className="tab-view">
          <Pagination
            list={list}
            itemsPerPage={itemsPerPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </PaginationContainer>
      ) : (
        <LoadButton onClick={() => setLoadReview(true)}>Load More</LoadButton>
      )}
    </Main>
  )
}

export default Reviews

const Main = styled.div`
  width: 39%;
  padding: 30px 0px;
  flex-grow: 1;
  @media all and (max-width: 1300px) {
    width: 48%;
    margin-right: 30px;
  }
  @media all and (max-width: 1280px) {
    width: 100%;
    padding: 16px;
    margin-left: 30px;
    margin-right: 30px;
  }
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`
const Left = styled.h5`
  font-weight: 500;
  font-family: 'DM_sans_medium';
  font-size: 16px;
  color: ${THEME_COLORS.black};
`
const Right = styled.div`
  display: flex;
  align-items: center;
`
const SearchBox = styled.div`
  margin-right: 2px;
`
const ReviewCards = styled.ul`
  position: relative;
  max-height: 1000px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.tab-view {
    display: none;
  }
  @media all and (max-width: 1280px) {
    &.web-view {
      display: none;
    }
    &.tab-view {
      display: block;
      margin-bottom: 17px;
      max-height: max-content;
    }
  }
`
const Rectangle = styled.div`
  display: none;
  width: 100%;
  height: 111px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  @media all and (max-width: 1280px) {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`
const LoadButton = styled.button`
  display: none;
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.chips_blue_on_container_2};
  padding: 4px 8px;
  border-radius: 6px;
  margin: 0 auto;
  &:hover {
    background: ${THEME_COLORS.light_300};
  }
  @media all and (max-width: 1280px) {
    display: block;
  }
`
const PaginationContainer = styled.div`
  margin-top: 13px;
  @media all and (max-width: 1280px) {
    &.web-view {
      display: none;
    }
  }
`
const Nodata = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  img {
    margin-bottom: 10px;
  }
`
