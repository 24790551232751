import React, {useState} from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../../ThemeConfig';
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne';
import DateTimeSlot from './DateTimeSlot';


const ScheduleFollowUpSession = ({handleModal,setTimeSlot}) => {

    const [selectedTime,setSelectedTime] = useState({});
    const [selectedDate,setSelectedDate] = useState({});
    const [showErrors,setShowErrors] = useState(false)

    const clearStates = ()=>{
        setSelectedTime({})
        setSelectedDate({})
        setShowErrors(false)
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        if(Object.keys(selectedDate).length !== 0 && Object.keys(selectedTime).length !== 0){
            setTimeSlot({
                "type":"followup",
                "time":selectedTime,
                "date":selectedDate
            })
            clearStates()
            handleModal()
        }else{
            setShowErrors(true)
        }
    }

    const handleCancel =()=>{
        clearStates()
        handleModal()
    }

  return (
    <>
        {(showErrors && Object.keys(selectedTime).length === 0) && <Error className='error'>Choose a time</Error>}
        {(showErrors && Object.keys(selectedDate).length === 0) && <Error className='error'>Choose a date</Error>}
        <DateTimeSlot 
            selectedTime={selectedTime} 
            selectedDate={selectedDate} 
            setSelectedTime={setSelectedTime} 
            setSelectedDate={setSelectedDate} 
            setTimeSlot={setTimeSlot}
        />
        <FormButtons>
            <ButtonTypeOne 
                text="Cancel" 
                color={THEME_COLORS.dark_500}
                bkgcolor={THEME_COLORS.white} 
                border={THEME_COLORS.light_200}
                onclick={handleCancel}
            />
            <ButtonTypeOne 
                text="Submit" 
                ml='20px'
                color={THEME_COLORS.white}
                bkgcolor={THEME_COLORS.chips_blue_on_container_2} 
                onclick={handleSubmit}
            />
        </FormButtons>     
    </>
  )
}

export default ScheduleFollowUpSession

const FormButtons = styled.div`
    display: flex;
    float: right;
    margin-top: 24px;
`
const Error = styled.small``


