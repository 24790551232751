import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { expert } from "../../axiosConfig";

import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";
import CancelSignupModal from "./modals/CancelSignupModal";

function WorkingStatus({ completed, setCompleted }) {
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");
    const [selectedID, setSelectedID] = useState("");
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    const [statusesModal, setStatusesModal] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [attr, setAttr] = useState("");
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [inputFocused, setInputFocused] = useState("");
    const setAttribute = (attr) => {
        setAttr(attr);
    };
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };
    useEffect(() => {
        let user_details = localStorage.getItem("user_details");
        user_details = JSON.parse(user_details);
        dispatch({ type: "UPDATE_USER", user_details: user_details });
    }, []);

    useEffect(() => {
        // if (access_token) {
        expert
            .get("/working-statuses/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                // console.log(response.data, 'working status data')
                const { StatusCode, data } = response.data;
                setStatuses(data);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
        // }
    }, []);

    const handleStatusSelection = (name, id) => {
        setSelected(name);
        setSelectedID(id);
        setStatusesModal(false);
    };

    const renderStatuses = () => {
        return statuses.map((status) => (
            <DropDownsText
                key={status.id}
                onClick={() => handleStatusSelection(status.name, status.id)}
            >
                {status.name}
            </DropDownsText>
        ));
    };
    const [formData, setFormData] = useState({
        working_status: "",
        employer_name: "",
        location: "",
    });

    const handleSubmit = () => {
        if (selectedID && name) {
            setLoading(true);
            const data = {
                working_status: selectedID,
                employer_name: name,
                location: location,
            };
            // console.log(data, "formData------");
            expert
                .put("/add-working-status/", data, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then(function (response) {
                    const { StatusCode } = response.data;
                    const { access_token, refresh_token, message } =
                        response.data.data;
                    // console.log(response.data.data);

                    setErr("");
                    setLoading(false);
                    completed.working_status = "tick";
                    navigate("/auth/your-bio"); // Navigate to Expert-Details page
                    // }
                })
                .catch((error) => {
                    // console.error(
                    //     "Error creating post personal-infooo:",
                    //     error
                    // );
                    if (error.response.status === 404) {
                        setErr("NOT_FOUND");
                    }
                    setLoading(false); // Make sure to set loading to false in case of an error
                });
        } else {
            setErr("*This field is mandatory");
        }
    };
    // console.log(selectedID, "selected");
    // console.log(document.getElementById('FirstName').value, 'name')
    // console.log(document.getElementById('Location').value, 'location')
    const handleInputFocus = (fieldName) => {
        setInputFocused(fieldName);
    };

    const handleInputBlur = () => {
        setInputFocused("");
    };
    const statusDropdownRef = useRef();
    const handleClickOutsideStatus = (event) => {
        if (
            statusDropdownRef.current &&
            !statusDropdownRef.current.contains(event.target)
        ) {
            setStatusesModal(false);
        }
    };
    useEffect(() => {
        if (statusesModal) {
            document.addEventListener("mousedown", handleClickOutsideStatus);

            return () => {
                document.removeEventListener(
                    "mousedown",
                    handleClickOutsideStatus
                );
            };
        }
    }, [statusesModal]);
    const getWorkingStatus = () => {
        let accessToken = state.user_details.access_token;
        expert
            .get(`/get-working-status/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                console.log(response.data, "response.data");
                if (StatusCode === 6000) {
                    setSelected(data.working_status_name);
                    setSelectedID(data.working_status);
                    setName(data.employer_name);
                    setLocation(data.location);
                } else {
                    // alert(
                    //     "Some error occured while loading qualifications, please refresh the page"
                    // );
                }
            })
            .catch((error) => {
                // alert(
                //     "Some error occured while loading qualifications, please refresh the page"
                // );
            });
    };
    useEffect(() => {
        getWorkingStatus();
    }, []);

    useEffect(() => {
        const isNewRegitrationStarted = state.nrs;
        if (isNewRegitrationStarted !== true) {
            navigate("/auth/login-page/");
        }
    }, [state]);

    return (
        <>
            {isCancelModalOpen && (
                <CancelSignupModal
                    isModalOpen={isCancelModalOpen}
                    setIsModalOpen={setIsCancelModalOpen}
                />
            )}
            <Div></Div>
            <Main>
                <Wrapper>
                    <TitileBox>
                        <Titile>Working Status</Titile>
                        <ExitBox
                            onClick={() => {
                                setIsCancelModalOpen(true);
                            }}
                        >
                            <Exit
                                src={
                                    require("../../assets/image/loginpage/close.svg")
                                        .default
                                }
                                alt="Exit_icon"
                            />
                            Cancel
                        </ExitBox>
                    </TitileBox>

                    <ItemFlexContainer style={{ marginTop: "60px" }}>
                        <Left>
                            <Heading>
                                <TopBox>
                                    <SubHeading>Employer Details *</SubHeading>
                                    <SubTitile>
                                        Provide your Personal Info
                                    </SubTitile>
                                </TopBox>
                            </Heading>
                        </Left>
                        <Right>
                            <Details>
                                <PersonalBox
                                    ref={statusDropdownRef}
                                    onClick={() =>
                                        setStatusesModal(!statusesModal)
                                    }
                                >
                                    <SelectedDataBox>
                                        <p>Working Status *</p>
                                        <span style={{ fontSize: "15px" }}>
                                            {selected && selected}
                                        </span>{" "}
                                    </SelectedDataBox>
                                    <ListDown>
                                        <ListIcon
                                            src={
                                                require("../../assets/image/loginpage/list_down.svg")
                                                    .default
                                            }
                                            alt="list_down"
                                            isOpen={statusesModal}
                                        />
                                    </ListDown>
                                    <DropList
                                        className={
                                            statusesModal && "view-modal"
                                        }
                                    >
                                        {renderStatuses()}
                                    </DropList>
                                </PersonalBox>
                                {selected == "" && err ? (
                                    <ErrorRed className="error-message">
                                        {err}
                                    </ErrorRed>
                                ) : (
                                    ""
                                )}
                            </Details>
                            <InputFieldWrapper>
                                <InputField
                                    className={
                                        inputFocused === "firstName"
                                            ? "active"
                                            : ""
                                    }
                                    for="name"

                                    // onClick={() => setInputFocused("FirstName")}
                                >
                                    <SubHead>Name of Employer *</SubHead>
                                    <NameBox
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        onFocus={() => {
                                            handleInputFocus("firstName");
                                        }}
                                        onBlur={handleInputBlur}
                                    />
                                </InputField>
                                {name == "" && err && (
                                    <ErrorRed className="error-message">
                                        {err}
                                    </ErrorRed>
                                )}
                            </InputFieldWrapper>
                            <InputFieldWrapper>
                                <InputField
                                    className={
                                        inputFocused === "Location"
                                            ? "active"
                                            : ""
                                    }
                                    for="Location"
                                >
                                    <SubHead>Location of Employer</SubHead>
                                    <NameBox
                                        type="text"
                                        id="Location"
                                        value={location}
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                        }}
                                        onFocus={() => {
                                            handleInputFocus("Location");
                                        }}
                                        onBlur={handleInputBlur}
                                    />
                                </InputField>
                            </InputFieldWrapper>
                        </Right>
                    </ItemFlexContainer>

                    <ItemFlexContainer style={{ marginTop: "220px" }}>
                        <Left></Left>
                        <Right>
                            <Bottom>
                                <LeftBox>*These fields are mandatory</LeftBox>
                                <RightBox>
                                    <Previous
                                        onClick={() => {
                                            navigate("/auth/expert-details");
                                        }}
                                    >
                                        Previous
                                    </Previous>
                                    <Save
                                        onClick={() => {
                                            handleSubmit();
                                            completed.working_status = "tick";
                                            // navigate('/your-bio')
                                        }}
                                    >
                                        {" "}
                                        Save & Continue
                                    </Save>
                                </RightBox>
                            </Bottom>
                        </Right>
                    </ItemFlexContainer>
                </Wrapper>
            </Main>
        </>
    );
}

export default WorkingStatus;
const Main = styled.div`
    padding: 68px 0;
    height: calc(100vh - 80px);
    /* box-sizing: border-box; */
    overflow-y: scroll;
    scroll-behavior: smooth;
`;
const Wrapper = styled.div`
    /* display: flex;
    justify-content: space-between; */
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    @media all and (max-width: 900px) {
        width: 90%;
    }
`;
const ItemFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Div = styled.div`
    border-top: 2px solid #366eef;
    width: 75%;
`;
const Left = styled.div`
    width: 45%;
`;
const TitileBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Titile = styled.h1`
    font-size: 30px;
    /* font-weight: 500; */
    font-family: "dm_sans_bold";
`;
const Right = styled.div`
    width: 64%;
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftBox = styled.p`
    color: #818181;
    font-size: 14px;
    text-align: start;
`;
const RightBox = styled.div`
    align-items: flex-end;
    display: flex;
`;
const Save = styled.button`
    background-color: #366ee3;
    border: 2px solid #366ee3 !important;
    padding: 14px 24px;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-family: "DM_sans_medium";
`;
const Previous = styled.button`
    background-color: #fff;
    border: 2px solid #d3d3d3;
    padding: 14px 24px;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 14px;
    font-family: "DM_sans_medium";
    cursor: pointer;
`;
const TopBox = styled.div``;
const SubHeading = styled.h3`
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #3e3e53;
    font-family: "dm_sans_medium";
`;
const SubTitile = styled.h5`
    color: #818181;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;
`;
const Heading = styled.div`
    .working {
        margin-bottom: 250px;
    }
`;
const ExitBox = styled(Link)`
    /* justify-content: end; */
    align-items: center;
    display: flex;
    /* margin-right: 5px; */
    color: #1da687;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    gap: 4px;
    padding: 6px;
    margin: 0 0 0 auto;
    width: fit-content;
    font-family: "DM_sans_medium";
`;
const Exit = styled.img`
    display: block;
    width: 12px;
    margin-right: 5px;
`;
const Details = styled.div``;
const PersonalBox = styled.div`
    user-select: none;
    cursor: pointer;
    p {
        color: #818181;
        margin: 0;
        font-size: 14px;
    }
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
    height: 64px;
    background-color: #f4f4f4;
    padding: 11px 20px;

    //new
    position: relative;
`;
const ListDown = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const ListIcon = styled.img`
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : null)};
    transition: 0.1s;
`;
const SelectedDataBox = styled.div``;
const DropList = styled.div`
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 0;
    overflow: hidden;
    transition: all 0.5 ease-in-out;

    &.view-modal {
        height: auto;
        transition: all 0.5 ease-in-out;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    }
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 64px;
    left: 0;
    border-radius: 5px;
    background-color: #fff;
    max-height: 400px;
    overflow-y: scroll;
`;
const DropDownsText = styled.div`
    padding: 10px 25px;
    font-size: 14px;
`;
const Name = styled.div`
    /* margin-bottom: 18%; */
    margin-top: 16px;
`;
const Empname = styled.div`
    height: auto;
    background-color: #f4f4f4;
    padding: 11px 20px;
    border-radius: 8px;
    cursor: pointer;

    &.active {
        border: 2px solid #366eef;
    }
`;
const Location = styled.div`
    height: auto;
    margin-top: 16px;

    background-color: #f4f4f4;
    padding: 11px 20px;
    border-radius: 8px;
    cursor: pointer;

    &.active {
        border: 2px solid #366eef;
    }
`;
const SubHead = styled.div`
    color: #818181;
    margin: 0;
    font-size: 14px;
    width: 100%;
    display: block;
`;
const NameBox = styled.input`
    border: none;
    width: 90%;
    height: 50%;
    font-size: 18px;
    background-color: #f4f4f4;
    outline: none;
    text-transform: capitalize;
`;
const ErrorRed = styled.div`
    color: red;
    font-size: 14px;
    margin-top: 4px;
    /* position: absolute;
    bottom: -5px; */
`;
const InputFieldWrapper = styled.div`
    /* position: relative; */
    margin-top: 18px;
`;
const InputField = styled.label`
    display: block;
    height: 64px;
    background-color: #f4f4f4;
    padding: 11px 20px;
    /* margin-right: 3%; */
    border-radius: 8px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
    border: 2px solid #f4f4f4;
    &.active {
        border: 2px solid #366eef;
    }
    opacity: ${(props) => (props.disabled ? "0.6" : "1")};
`;
