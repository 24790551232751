import React, { useEffect } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";

function ConfirmCancelModal({ isConfirm, setConfirm }) {
    // useEffect(() => {
    //     console.log(isConfirm, "isofirm confirm modaal");
    // }, [isConfirm]);
    return (
        isConfirm && (
            <>
                <Cover></Cover>
                <MainContainer>
                    <ContainerWhite>
                        <Heading>Cancel Session</Heading>
                        <RedDiv>
                            <RedImage>
                                <img
                                    src={require("../../../../assets/image/sessionsinglepage/redCross.png")}
                                    alt="Image"
                                />
                            </RedImage>
                            <Message>
                                Session Cancelled, MM admin will contact you
                                ASAP.
                            </Message>
                        </RedDiv>
                        <BottomContainer>
                            <Cancel>Cancel</Cancel>
                            <RescheduleButton
                                onClick={() => {
                                    setConfirm(false);
                                }}
                            >
                                Done
                            </RescheduleButton>
                        </BottomContainer>
                    </ContainerWhite>
                </MainContainer>
            </>
        )
    );
}

export default ConfirmCancelModal;
const Cover = styled.div`
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    opacity: 0.4;
`;
const MainContainer = styled.div`
    position: fixed;
    bottom: 170px;
    right: 570px;
    z-index: 2;
    width: 409px;
    cursor: default;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 12px;
    padding-top: 5px;
    @media all and (max-width: 1280px) {
        right: 450px;
        bottom: 100px;
    }
    @media all and (max-width: 1080px) {
        right: 350px;
    }
    @media all and (max-width: 980px) {
        right: 300px;
    }
`;
const ContainerWhite = styled.div`
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-radius: 10px;
    border: 2px solid ${THEME_COLORS.white};
`;
const Heading = styled.h5`
    color: ${THEME_COLORS.text_title};
    font-size: 24px;
    margin-bottom: 40px;
`;
const RedDiv = styled.div`
    background-color: ${THEME_COLORS.chips_red_container};
    padding: 32px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 30px;
`;
const RedImage = styled.div`
    margin-bottom: 20px;
`;
const Message = styled.p`
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Cancel = styled.button`
    padding: 14px 24px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.dark_500};
`;
const RescheduleButton = styled.button`
    padding: 14px 24px;
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.chips_blue_on_container_2};
`;
