import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/Store";

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    let user_details = localStorage.getItem("user_details");
    user_details = JSON.parse(user_details);
    const { state, dispatch } = useContext(Context);
    const is_verified = state.user_details.is_verified;
    const accessToken = state.user_details.access_token;
    // console.log(Boolean(accessToken), "access");
    const location = useLocation();

    useEffect(() => {
        if (!accessToken) {
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    is_verified: false,
                },
            });
            navigate("/");
            // localStorage.clear();
            // navigate("/auth/login-page/");
        }
    }, [accessToken]);

    return is_verified ? (
        children
    ) : (
        <Navigate
            to={{
                pathname: "/auth/login-page/",
                search: `?next=${location.pathname}`,
            }}
        />
    );
};

export default PrivateRoute;
