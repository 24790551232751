import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import validator from "validator";
import SuccessToast from "../manage_profile/toast/SuccessToast.jsx";
import { THEME_COLORS } from "../../../ThemeConfig.js";
import contact_image from "../../../assets/image/contact/contact_image.png";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne.jsx";
import { Context } from "../../../contexts/Store.js";
import { expert } from "../../../axiosConfig.js";

const ContactPage = () => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const user_details = state.user_details;
    const email_Id = state.user_details.email;
    const phone = state.user_details.phone;
    const first_name = state.user_details.first_name;
    const last_name = state.user_details.last_name;
    console.log(user_details, "user_details");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [message, setMessage] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [requestedAmount, setRequestedAmount] = useState("");
    const [error, setError] = useState("");

    const clearStates = () => {
        setFirstname("");
        setEmail("");
        setLastname("");
        setMessage("");
        setShowErrors(false);
    };

    const handleEmail = () => {
        if (validator.isEmail(email)) {
            setEmailError(false);
            return true;
        } else {
            setEmailError(true);
            return false;
        }
    };

    const handleKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
            // only allow numeric key codes
            event.preventDefault();
        }
    };
    const data = {
        firstname: first_name,
        lastname: last_name,
        email: email_Id,
        phoneNumber: phone ? phone : phoneNumber,
        message: message,
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (message) {
            expert
                .post("/contact-admin/", data, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setShowSuccessToast(true);
                        setTimeout(() => {
                            setShowSuccessToast(false);
                        }, 3000);
                        clearStates();
                    } else if (StatusCode == 6001) {
                        setError(response);
                        console.log(error, "error");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setError(error.message);
                });
        } else {
            setShowErrors(true);
        }
    };

    const handleInputValidation = (inputValue, setInputState) => {
        const regex = /^[A-Za-z]+$/; // Regular expression to match only letters

        if (regex.test(inputValue) || inputValue === "") {
            setInputState(inputValue);
        }
    };

    return (
        <Main>
            <Left>
                <Content>
                    <Head>
                        <img
                            src={
                                require("../../../assets/image/contact/logo_full.svg")
                                    .default
                            }
                            alt="logo"
                        />
                    </Head>
                    <Description>
                        Our dedicated support team is always here to help.
                        Contact us via email, phone, or through the form on this
                        page and we'll get back to you as soon as possible.
                    </Description>
                    <Icons>
                        <Link to="https://www.whatsapp.com/">
                            <img
                                src={
                                    require("../../../assets/image/contact/whatsapp.svg")
                                        .default
                                }
                                alt="whatsapp"
                            />
                        </Link>
                        <Link to="https://youtu.be/pBBMz9NEego?si=YBvjBjAyRHyBcyPU">
                            <img
                                src={
                                    require("../../../assets/image/contact/youtube.svg")
                                        .default
                                }
                                alt="youtube"
                            />
                        </Link>
                        <Link to="https://twitter.com/">
                            <img
                                src={
                                    require("../../../assets/image/contact/twitter.svg")
                                        .default
                                }
                                alt="twitter"
                            />
                        </Link>
                        <Link to="https://www.instagram.com/">
                            <img
                                src={
                                    require("../../../assets/image/contact/instagram.svg")
                                        .default
                                }
                                alt="instagram"
                            />
                        </Link>
                        <Link to="https://www.linkedin.com/">
                            <img
                                src={
                                    require("../../../assets/image/contact/linkedin.svg")
                                        .default
                                }
                                alt="linkedin"
                            />
                        </Link>
                    </Icons>
                </Content>
            </Left>
            <Right>
                <FormWrapper>
                    <Title>Contact Admin</Title>
                    <SubTitle>
                        24/7 We will answer your questions and problems
                    </SubTitle>
                    <Form>
                        <Top>
                            <Wrapper>
                                <InputWrapper className="input-wrapper disable">
                                    <FormLeft>
                                        <FormLabel
                                            htmlFor="firstname"
                                            className={
                                                firstname
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            First name *
                                        </FormLabel>
                                        <FormInput
                                            id="firstname"
                                            name="firstname"
                                            readOnly="true"
                                            type="text"
                                            value={first_name}
                                            onInput={(e) =>
                                                handleInputValidation(
                                                    e.target.value,
                                                    setFirstname
                                                )
                                            }
                                            onChange={(e) =>
                                                setFirstname(e.target.value)
                                            }
                                            placeholder="First name *"
                                            // className={
                                            //     firstname
                                            //         ? "input-value active"
                                            //         : "input-value"
                                            // }
                                        ></FormInput>
                                    </FormLeft>
                                </InputWrapper>
                                {showErrors && !firstname && !first_name && (
                                    <Error className="error">
                                        This field is required.
                                    </Error>
                                )}
                            </Wrapper>

                            <Wrapper>
                                <InputWrapper className="input-wrapper disable">
                                    <FormLeft>
                                        <FormLabel
                                            htmlFor="lastname"
                                            className={
                                                lastname
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Last name
                                        </FormLabel>
                                        <FormInput
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            value={last_name}
                                            readOnly="true"
                                            onInput={(e) =>
                                                handleInputValidation(
                                                    e.target.value,
                                                    setLastname
                                                )
                                            }
                                            onChange={(e) =>
                                                setLastname(e.target.value)
                                            }
                                            placeholder="Last name"
                                            // className={
                                            //     lastname
                                            //         ? "input-value active"
                                            //         : "input-value"
                                            // }
                                        ></FormInput>
                                    </FormLeft>
                                </InputWrapper>
                            </Wrapper>

                            <Wrapper>
                                <InputWrapper className="input-wrapper disable">
                                    <FormLeft>
                                        <FormLabel
                                            htmlFor="email"
                                            className={
                                                email
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Email *
                                        </FormLabel>
                                        <FormInput
                                            // id="email"
                                            // name="email"
                                            value={email_Id ? email_Id : email}
                                            type="email"
                                            readOnly="true"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Email *"
                                            // className={
                                            //     email
                                            //         ? "input-value active"
                                            //         : "input-value"
                                            // }
                                        ></FormInput>
                                    </FormLeft>
                                </InputWrapper>
                                {showErrors && !email && !email_Id && (
                                    <Error className="error">
                                        This field is required.
                                    </Error>
                                )}
                                {email && emailError && !email_Id && (
                                    <Error className="error">
                                        Enter valid email
                                    </Error>
                                )}
                            </Wrapper>

                            <Wrapper>
                                <InputWrapper className="input-wrapper disable">
                                    <FormLeft>
                                        <FormLabel
                                            htmlFor="phonenumber"
                                            className={
                                                phoneNumber
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Phone Number
                                        </FormLabel>
                                        {phone ? (
                                            <FormInput
                                                readOnly="true"
                                                type="tel"
                                                value={
                                                    phone ? phone : phoneNumber
                                                }
                                                placeholder="Phone Number"
                                            ></FormInput>
                                        ) : (
                                            <FormInput
                                                // id="phonenumber"
                                                // name="phonenumber"
                                                // readOnly="true"
                                                type="tel"
                                                value={
                                                    phone ? phone : phoneNumber
                                                }
                                                onChange={(e) => {
                                                    const inputValue =
                                                        e.target.value;
                                                    // Allow only numeric characters and limit the length to 10
                                                    const sanitizedInput =
                                                        inputValue
                                                            .replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            )
                                                            .slice(0, 10);
                                                    setPhoneNumber(
                                                        sanitizedInput
                                                    );
                                                }}
                                                placeholder="Phone Number"
                                                // className={
                                                //     phoneNumber
                                                //         ? "input-value active"
                                                //         : "input-value"
                                                // }
                                            ></FormInput>
                                        )}
                                    </FormLeft>
                                </InputWrapper>
                                {showErrors &&
                                    phoneNumber.length < 10 &&
                                    !phone && (
                                        <Error className="error">
                                            Phone number must be at least 10
                                            digits
                                        </Error>
                                    )}
                            </Wrapper>
                        </Top>
                        <Bottom>
                            <Wrapper className="message">
                                <TextWrapper
                                    className="input-wrapper disable"
                                    style={{ height: "auto" }}
                                >
                                    <FormLeft>
                                        <FormLabel
                                            htmlFor="message"
                                            className={
                                                message
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Message
                                        </FormLabel>
                                        <FormText
                                            id="message"
                                            name="message"
                                            type="text"
                                            value={message}
                                            rows="8"
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            placeholder="Message"
                                            className={
                                                message
                                                    ? "textarea-value active"
                                                    : "textarea-value"
                                            }
                                        ></FormText>
                                    </FormLeft>
                                </TextWrapper>
                                {showErrors && !message && (
                                    <Error className="error">
                                        This field is required.
                                    </Error>
                                )}
                            </Wrapper>
                        </Bottom>
                        <Button>
                            <ButtonTypeOne
                                text="Submit"
                                color={THEME_COLORS.white}
                                bkgcolor={
                                    THEME_COLORS.chips_blue_on_container_2
                                }
                                onclick={handleSubmit}
                            />
                        </Button>
                    </Form>
                </FormWrapper>
            </Right>
            {showSuccessToast && (
                <Modal>
                    <SuccessToast message={"submitted successfully"} />
                </Modal>
            )}
        </Main>
    );
};

export default ContactPage;

const Main = styled.div`
    width: 100vw;
    /* height: calc(100vh - 69.6px); */
    display: flex;
    @media all and (max-width: 980px) {
        margin-bottom: 60px;
        /* height: calc(100vh - 129.6px); */
    }
`;
const Left = styled.div`
    width: 40%;
    background: url(${contact_image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 60px;
    @media all and (max-width: 1440px) {
        padding-bottom: 60px;
    }
    @media all and (max-width: 1280px) {
        padding-bottom: 40px;
    }
`;
const Content = styled.div`
    width: 55%;
    @media all and (max-width: 1280px) {
        width: 65%;
    }
`;
const Head = styled.div`
    width: 261px;
    margin-bottom: 36px;
    img {
        width: 100%;
    }
    @media all and (max-width: 1280px) {
        width: 200px;
    }
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 40px;
`;
const Icons = styled.div`
    display: flex;
    a {
        width: 40px;
        margin-right: 10px;
        img {
            width: 100%;
        }
    }
`;
const Right = styled.div`
    width: 60%;
    display: flex;
    align-items: end;
    background-color: ${THEME_COLORS.white};
    padding-left: 83px;
    padding-bottom: 60px;
    @media all and (max-width: 1440px) {
        padding-bottom: 60px;
    }
    @media all and (max-width: 1280px) {
        padding: 40px;
    }
`;
const FormWrapper = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 65px;
    /* height: 80vh; */
    @media all and (max-width: 1280px) {
        width: 100%;
    }
`;
const Title = styled.h3`
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.black};
    margin-bottom: 8px;
`;
const SubTitle = styled.p`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 66px;
`;
const Form = styled.form``;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const Wrapper = styled.div`
    width: 48%;
    margin-bottom: 20px;
    &.message {
        margin-bottom: 20px;
        width: 100%;
    }
`;
const InputWrapper = styled.div`
    &.disable:focus-within {
        background-color: #f4f4f4;
        border: 2px solid #f4f4f4;
        box-shadow: none;
    }
`;
const TextWrapper = styled.div`
    // margin-bottom: 20px;
    &.disable:focus-within {
        background-color: #f4f4f4;
        border: 2px solid #f4f4f4;
        box-shadow: none;
    }
`;
const FormLeft = styled.div`
    flex-grow: 1;
`;
const Error = styled.small``;
const FormLabel = styled.label`
    &.show {
        display: block;
    }
`;
const FormInput = styled.input`
    cursor: default;
    width: 100%;
    &.active {
        font-weight: 500;
    }
`;
const FormText = styled.textarea`
    &.active {
        font-weight: 500;
    }
    &.textarea-value:focus {
        background-color: #f4f4f4;
    }
    &.textarea-value {
        color: #000;
    }
`;
const Bottom = styled.div``;
const Button = styled.div`
    text-align: center;
`;
const Modal = styled.div``;
