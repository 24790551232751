import React, { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
//packages
import { Route, Routes } from "react-router-dom";
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";
import PrivateRoute from "../routes/PrivateRoute";
import { Context } from "../../../contexts/Store";
import AuthRoute from "../routes/AuthRoute";

//components

function MainRouter() {
    const [isLoading, setLoading] = useState(true);
    const { state, dispatch } = useContext(Context);

    async function fetchUserData() {
        let user_details = localStorage.getItem("user_details");
        let user_details_stored = JSON.parse(user_details);
        dispatch({
            type: "UPDATE_USER",
            user_details: user_details_stored,
        });
        setTimeout(() => {
            setLoading(false);
        }, 50);
    }

    useEffect(() => {
        fetchUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <Lottie
            animationData={LoaderLottie}
            style={{
                height: "100vh",
                width: 150,
                margin: "auto",
            }}
        />
    ) : (
        <Routes>
            <Route
                path="auth/*"
                element={
                    <AuthRoute>
                        <AuthRouter />
                    </AuthRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <AppRouter />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default MainRouter;
