import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Countdown from 'react-countdown'
import CallTypeChip from '../../includes/Sessionchips/CallTypeChip'
import SessionCallChip from '../../includes/Sessionchips/SessionCallChip'
import SessionCountChip from '../../includes/Sessionchips/SessionCountChip'
import SessionDateChip from '../../includes/Sessionchips/SessionDateChip'
import SessionSchedulechip from '../../includes/Sessionchips/SessionSchedulechip'
import SessionTimeChip from '../../includes/Sessionchips/SessionTimeChip'
import person from '../../../assets/image/dashboard/notification-card/person.svg'

import avatar1 from '../../../assets/image/dashboard/session-card/avatar-1.svg'
import avatar2 from '../../../assets/image/dashboard/session-card/avatar-2.svg'
import avatar3 from '../../../assets/image/dashboard/session-card/avatar-3.svg'
import avatar4 from '../../../assets/image/dashboard/session-card/avatar-4.svg'
import link from '../../../assets/image/dashboard/session-card/Link.svg'
import pattern from '../../../assets/image/dashboard/session-card/Pattern-card.svg'
import empty from '../../../assets/image/dashboard/session-card/empty-icon.svg'

import { THEME_COLORS } from '../../../ThemeConfig'
import SortingChip from '../../includes/Sessionchips/SortingChip'
import SearchChip from '../../includes/Sessionchips/SearchChip'
import { expert } from '../../../axiosConfig'
import { Link, useNavigate } from 'react-router-dom'

function SlotCard({ sessionData }) {
  let user_details = localStorage.getItem('user_details')
  user_details = JSON.parse(user_details)
  const access_token = user_details.access_token
  const navigate = useNavigate()
  const [counter, setcouter] = useState('false')
  const [isitem, Setitem] = useState(false)
  const [sort, setSort] = useState('All')
  const [data, isData] = useState(true)
  const [error, SetError] = useState('')

  // console.log(sessionData, "sessionData");
  return (
    <>
      {sessionData?.data?.length != 0 ? (
        <>
          {sessionData?.next_session && (
            <Cover key={sessionData?.next_session?.id}>
              <CardContainer
                id="card"
                key={sessionData?.next_session?.id}
                className={'active'}
                onClick={() => {
                  navigate(
                    `/session-single-page/${sessionData?.next_session?.id}`,
                  )
                }}
              >
                <Card key={sessionData?.next_session?.id} className={'active'}>
                  <AvatarContainer>
                    {sessionData?.next_session?.session?.patient
                      .profile_photo ? (
                      <Avatar
                        src={
                          sessionData?.next_session?.session?.patient
                            ?.profile_photo
                        }
                        alt="icon"
                      />
                    ) : (
                      <Avatar src={person} alt="icon" />
                    )}
                  </AvatarContainer>
                  <Details>
                    <Top>
                      <LeftSection>
                        <CaseId>
                          Case ID :{' '}
                          <span>
                            {sessionData?.next_session?.session_record_id}
                          </span>
                        </CaseId>
                        <PatientDetails>
                          <Name>
                            {' '}
                            {
                              sessionData?.next_session?.session?.patient
                                ?.full_name
                            }
                          </Name>
                          <Age>
                            {' '}
                            {sessionData?.next_session?.session?.patient?.age}
                          </Age>
                          <Gender>
                            {
                              sessionData?.next_session?.session?.patient
                                ?.gender
                            }
                          </Gender>
                          {sessionData?.next_session?.total_sessions > 1 ? (
                            <SessionCountChip
                              current_session={
                                sessionData?.next_session
                                  ?.current_session_number
                              }
                              total_session={
                                sessionData?.next_session?.total_sessions
                              }
                            />
                          ) : (
                            ''
                          )}
                        </PatientDetails>
                      </LeftSection>
                      <RightSection>
                        <SessionSchedulechip
                          session_record_status={
                            sessionData?.next_session?.session_record_status
                          }
                        />
                        <SessionDateChip
                          day={sessionData?.next_session?.case_date}
                        />
                        <SessionTimeChip
                          start_time={sessionData?.next_session?.start_time}
                          end_time={sessionData?.next_session?.end_time}
                        />
                        <CallTypeChip
                          call_type={
                            sessionData?.next_session?.session?.meet_mode
                          }
                        />
                      </RightSection>
                    </Top>
                    <Bottom>
                      <Left>
                        <Heading>
                          {' '}
                          {sessionData?.next_session?.session.concern}
                        </Heading>
                      </Left>
                    </Bottom>
                  </Details>
                </Card>
              </CardContainer>
              <TimeContainer>
                <Time>
                  {' '}
                  {sessionData?.next_session?.start_time} to{' '}
                  {sessionData?.next_session?.end_time}{' '}
                </Time>
              </TimeContainer>
            </Cover>
          )}
          <Container>
            {sessionData?.data?.map((item) => (
              <Cover key={item.id}>
                <CardContainer
                  // to="/session-single-page"
                  id="card"
                  key={item.id}
                >
                  <Card key={item.id}>
                    <AvatarContainer
                      onClick={() => {
                        navigate(`/session-single-page/${item.id}`)
                      }}
                    >
                      {item.session?.patient?.profile_photo ? (
                        <Avatar
                          src={item?.session?.patient?.profile_photo}
                          alt="icon"
                        />
                      ) : (
                        <Avatar src={person} alt="icon" />
                      )}
                    </AvatarContainer>
                    <Details>
                      <Top
                        onClick={() => {
                          navigate(`/session-single-page/${item.id}`)
                        }}
                      >
                        <LeftSection
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <CaseId>
                            Case ID : <span>{item.session_record_id}</span>
                          </CaseId>
                          <PatientDetails>
                            <Name> {item.session?.patient?.full_name}</Name>
                            <Age> {item.session?.patient?.age}</Age>
                            <Gender>{item.session?.patient?.gender}</Gender>
                            {item.total_sessions > 1 ? (
                              <SessionCountChip
                                current_session={item.current_session_number}
                                total_session={item.total_sessions}
                              />
                            ) : (
                              ''
                            )}
                          </PatientDetails>
                        </LeftSection>
                        <RightSection
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <SessionSchedulechip
                            session_record_status={item.session_record_status}
                          />
                          <SessionDateChip day={item.case_date} />
                          <SessionTimeChip
                            start_time={item.start_time}
                            end_time={item.end_time}
                          />
                          <CallTypeChip call_type={item?.session?.meet_mode} />
                        </RightSection>
                      </Top>
                      <Bottom>
                        <Left
                          onClick={() => {
                            navigate(`/session-single-page/${item.id}`)
                          }}
                        >
                          <Heading> {item.session.concern}</Heading>
                        </Left>
                        <Right>
                          {
                            <SessionCallChip
                              id={item.id}
                              call_type={item?.session?.meet_mode}
                              status="queued"
                              session_status={item.session_record_status}
                              // link={item.meet_link}
                              type={'next'}
                            />
                          }
                        </Right>
                      </Bottom>
                    </Details>
                  </Card>
                </CardContainer>
                <TimeContainer>
                  <Time>
                    {' '}
                    {item.start_time} to {item.end_time}{' '}
                  </Time>
                </TimeContainer>
              </Cover>
            ))}
          </Container>
        </>
      ) : (
        <SessionEmptyContainer>
          <EmptyContainer>
            <IconContainer>
              <Icon src={empty} />
            </IconContainer>
            <Description>
              You don’t have any bookings today. Browse cases in updates
            </Description>
            <Text to={'/'}>Go to Dashboard</Text>
          </EmptyContainer>
        </SessionEmptyContainer>
      )}
    </>
  )
}

export default SlotCard
const Container = styled.section``
const CardContainer = styled.div`
  cursor: default;
  margin-bottom: 30px;
  padding: 0 0 0 10px;
  background: ${THEME_COLORS.chips_green_on_container};
  border-radius: 12px;
  transition: all 0.3s ease;
  &:hover {
    padding: 0 0 0 0px;
    transition: all 0.3s ease;
  }
  &.active {
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 12px;
  }
  &.count {
    color: ${THEME_COLORS.light_200};
    font-size: 14px;
    margin-right: 5px;
  }
`
const Card = styled.div`
  display: flex;
  padding: 20px 18px;
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  border: 2px solid ${THEME_COLORS.white};
  &:hover {
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
  }
  &.active {
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
    background-image: url(${pattern});
  }
`
const AvatarContainer = styled.div`
  border-radius: 57px;
  background: #f4f4f4;
  padding: 7px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-right: 20px;
`
const Avatar = styled.img``
const Details = styled.div`
  width: 100%;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
`
const LeftSection = styled.div`
  /* width: 35%; */
`
const RightSection = styled.div`
  display: flex;
`
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const Left = styled.div`
  width: 80%;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const CaseId = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 12px;
`
const PatientDetails = styled.ol`
  display: flex;
  align-items: center;
  list-style: disc;
  font-size: 14px;
`
const Name = styled.li`
  list-style: none;
  color: ${THEME_COLORS.chips_blue_on_container};
  margin-right: 20px;
`
const Age = styled.li`
  color: ${THEME_COLORS.text_para};
  margin-right: 20px;
`
const Gender = styled.li`
  color: ${THEME_COLORS.text_para};
  margin-right: 20px;
`
const Heading = styled.h2`
  font-size: 14px;
  margin-bottom: 8px;
`
const BottomContainer = styled.div`
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
  }
`
const Title = styled.p`
  margin-right: 30px;
  color: ${THEME_COLORS.light_200};
  font-size: 14px;
  &.active {
    display: none;
  }
`
const TitleTwo = styled.p`
  margin-right: 30px;
  color: ${THEME_COLORS.light_200};
  font-size: 14px;
  display: none;
  &.active {
    display: block;
  }
`

const SortingContaner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 20px;
  @media all and (max-width: 1300px) {
    align-items: flex-start;
  }
`
const NoData = styled.div`
  padding: 40px;
  font-size: 24px;
  color: #000;
  text-align: center;
`

const SortingChipContainer = styled.div`
  display: flex;
  align-items: center;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px; */
  @media all and (max-width: 1300px) {
    flex-wrap: wrap;
    width: 70%;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 60%; */
  text-align: center;
`
const SessionEmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  height: 50vh;
`
const Text = styled(Link)`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Cover = styled.section`
  padding-top: 30px;
  border-top: 1px solid ${THEME_COLORS.light_400};
  position: relative;
`
const IconContainer = styled.div`
  margin-bottom: 30px;
`

const Description = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 18px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.7s ease;
  &.active {
    transition: all 0.8s ease;
    height: auto;
    margin-bottom: 10px;
  }
`
// const Heading = styled.h2`
//   font-size: 14px;
//   margin-bottom: 8px;
// `
// const LinkIcon = styled.div`
//   margin-right: 10px;
//   cursor: pointer;
//   &.active {
//     filter: opacity(0.5);
//   }
// `
const Icon = styled.img``
const Time = styled.p`
  color: ${THEME_COLORS.chips_green_on_container};
  font-size: 14px;
  /* margin-right: 12px; */
`
const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  padding: 4px 20px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 32px;
  background: ${THEME_COLORS.white};
  position: absolute;
  top: -15px;
  left: 42%;
`
