import React from 'react'
import styled from 'styled-components'
import appointment from '../../../assets/image/dashboard/appointment-black.svg'
import slot from '../../../assets/image/dashboard/update-slot.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Link } from 'react-router-dom'
import close from '../../../assets/image/dashboard/notification-card/close-red.svg'

function CalenderAppointmentModal(props) {
  const { formattedDateString, setDayModal, handleClose } = props
  return (
    <>
      <CardContainer>
        <Card>
          <Top>
            <Date>{formattedDateString}</Date>
            <CloseContainer
              onClick={() => {
                setDayModal(false)
              }}
            >
              <Close src={close} />
            </CloseContainer>
          </Top>

          <Middle>
            <Left>
              <Icon src={appointment} alt="icon" />
            </Left>
            <Right to="/schedule-calendar">
              <Text>View Appointments</Text>
            </Right>
          </Middle>
          <Bottom>
            <Left>
              <Icon src={slot} alt="icon" />
            </Left>
            <Right to="/schedule-calendar/slot-detailed-view">
              <Text>Update Slots</Text>
            </Right>
          </Bottom>
        </Card>
      </CardContainer>
    </>
  )
}

export default CalenderAppointmentModal
const CardContainer = styled.div`
  cursor: default;
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  background: ${THEME_COLORS.chips_green_on_container};
  border-radius: 12px;
  padding-top: 5px;
  width: max-content;
`
const Card = styled.div`
  padding: 8px 10px;
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  border: 2px solid ${THEME_COLORS.white};
`
const Top = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.divider};
  padding: 8px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Date = styled.h3`
  font-size: 14px;
  font-family: 'DM_sans_medium';
`

const Middle = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 8px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`

const Left = styled.div`
  margin-right: 10px;
`

const Icon = styled.img``

const Right = styled(Link)``

const Text = styled.h3`
  font-size: 14px;
`

const Bottom = styled.div`
  display: flex;
  padding: 8px 10px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`
const CloseContainer = styled.div`
  &:hover {
    background: #ffe9e9;
  }
  width: 32px;
  height: 32px;
  border-radius: 20px;
  padding: 4px;
  cursor: pointer;
`
const Close = styled.img``
