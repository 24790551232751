import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import calender from ".././../../assets/image/dashboard/calender.svg";
import welcome from "../../../assets/image/login_dashboard/web.png";
import line from "../../../assets/image/login_dashboard/line-green.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import SetDefaultSlotMdal from "../session_summary/modals/SetDefaultSlotMdal";
import { general } from "../../../axiosConfig";

function WelcomePage({ setCalendarModal, calendarModal }) {
    const [plyVideoOne, setPlyVideoOne] = useState(false);
    const [videoContents, setVideoContents] = useState("");
    const setModalIsOpenToFalse = () => {
        setPlyVideoOne(false);
    };

    const getVideo = () => {
        general
            .get("/videos/")
            .then((response) => {
                setVideoContents(response.data.data);
            })
            .catch((error) => {
                console.error("Error", error);
            });
    };

    useEffect(() => {
        getVideo();
    }, []);

    const RenderVideoOne = () => (
        <Popup
            className="videoone"
            offsetY={200}
            offsetX={20}
            lockScroll={true}
            open={plyVideoOne}
            position="center center"
        >
            <VideoRenderDiv>
                <VideoIframe
                    src={videoContents[0]?.video_file}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></VideoIframe>
            </VideoRenderDiv>
            <Closebutton isOpen={plyVideoOne}>
                <Close
                    onClick={setModalIsOpenToFalse}
                    src={
                        require("../../../assets/image/login_dashboard/cross.svg")
                            .default
                    }
                    alt="Close"
                />
            </Closebutton>
        </Popup>
    );
    return (
        <>
            <Container>
                <Main>
                    <Contents>
                        <Line>
                            <Linebox>
                                <LineImg src={line} />
                            </Linebox>
                        </Line>
                        <Welcome>
                            <BoxOne>
                                <GreenBox>
                                    <MainHeading>
                                        Welcome to MindMitra Expert
                                    </MainHeading>
                                    <Paragraph>
                                        Discover Mind Mitra Expert: Our
                                        introduction video is your first journey
                                        of healing and empowerment.
                                    </Paragraph>
                                </GreenBox>
                                <WatchNow>
                                    <ButtonGreen
                                        className="videobtn"
                                        onClick={(e) =>
                                            e.preventDefault(
                                                plyVideoOne === false
                                                    ? setPlyVideoOne(true)
                                                    : setPlyVideoOne(false)
                                            )
                                        }
                                    >
                                        {RenderVideoOne()} Watch Now
                                    </ButtonGreen>
                                </WatchNow>
                            </BoxOne>
                        </Welcome>
                        <BoxTwo>
                            <ImageBox>
                                <WelcomeImg src={welcome} />
                            </ImageBox>
                        </BoxTwo>
                    </Contents>
                    <Explain>
                        Your profile verification is complete. Rest assured,
                        we'll notify you as soon as someone books their slot.
                        Stay tuned for updates!
                    </Explain>
                </Main>
            </Container>
        </>
    );
}

export default WelcomePage;
const Container = styled.section`
    cursor: default;
`;

const Main = styled.div`
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
`;
const Contents = styled.div`
    width: 100%;
    background-color: #e0f8f2;
    color: #1da687;
    border-radius: 16px;
    padding: 80px 30px 0 40px;
    height: 461px;
    margin-bottom: 30px;
    @media all and (max-width: 1440px) {
        padding: 110px 30px 0 30px;
    }
    @media all and (max-width: 980px) {
        height: 409px;
    }
    @media all and (max-width: 768px) {
        height: 365px;
    }
`;
const Line = styled.div`
    position: relative;
`;
const Linebox = styled.div`
    position: absolute;
    right: -30px;
    width: 60%;
    bottom: -137px;
    @media all and (max-width: 1440px) {
        bottom: -142px;
    }
    @media all and (max-width: 1200px) {
        bottom: -157px;
    }
    @media all and (max-width: 980px) {
        bottom: -112px;
    }
    @media all and (max-width: 768px) {
        bottom: -91px;
    }
`;
const LineImg = styled.img`
    width: 100%;
    display: block;
`;
const Welcome = styled.div``;
const BoxOne = styled.div`
    width: 50%;
`;
const BoxTwo = styled.div`
    position: relative;
`;
const ImageBox = styled.div`
    width: 55%;
    bottom: -104px;
    position: absolute;
    right: 0;
    @media all and (max-width: 1440px) {
        bottom: -56px;
        width: 59%;
    }
    @media all and (max-width: 1200px) {
        bottom: -84px;
        width: 52%;
    }
    @media all and (max-width: 1050px) {
        bottom: -86px;
        width: 53%;
    }
    @media all and (max-width: 980px) {
        bottom: -37px;
        width: 45%;
    }
    @media all and (max-width: 768px) {
        bottom: -25px;
        width: 40%;
    }
`;
const WelcomeImg = styled.img`
    width: 100%;
    display: block;
`;
const GreenBox = styled.div``;
const MainHeading = styled.h2`
    color: #1da687;
    font-size: 40px;
    width: 80%;
    margin-bottom: 30px;
    @media all and (max-width: 1440px) {
        font-size: 35px;
    }
    @media all and (max-width: 1200px) {
        font-size: 37px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media (max-width: 768px) {
        font-size: 27px;
        width: 90%;
        margin-bottom: 18px;
    }
`;
const Paragraph = styled.p`
    font-size: 18px;
    margin-bottom: 30px;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
const WatchNow = styled.div``;
const ButtonGreen = styled.a`
    background-color: #1da687;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    padding: 16px 30px;
    border-radius: 12px;
    @media all and (max-width: 980px) {
        padding: 12px 25px;
    }
    @media all and (max-width: 768px) {
        padding: 8px 20px;
    }
`;
const Explain = styled.p`
    font-size: 18px;
    text-align: center;
    width: 57%;
    margin: 0 auto;
    @media all and (max-width: 1440px) {
        width: 68%;
    }
    @media all and (max-width: 980px) {
        width: 82%;
    }
    @media all and (max-width: 768px) {
        width: 76%;
    }
`;
const Closebutton = styled.div`
    width: 16px;
    height: 30px;
    position: relative;
`;
const Close = styled.img`
    display: block;
    width: 100%;
    position: absolute;
    bottom: 404px;
    left: 732px;
    @media all and (max-width: 1440px) {
        bottom: 397px;
        left: 601px;
    }
    @media all and (max-width: 1280px) {
        left: 503px;
    }
    @media all and (max-width: 1080px) {
        left: 452px;
        bottom: 388px;
    }
    @media all and (max-width: 980px) {
        left: 348px;
    }
    @media all and (max-width: 768px) {
        left: 282px;
    }
`;
const VideoRenderDiv = styled.div``;
const VideoIframe = styled.iframe`
    width: 100%;
    height: 400px;
`;
