import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { expert, general } from '../../../../axiosConfig'
import DateCard from '../../../includes/cards/DateCard'
import ChipOval from '../../../includes/chips/ChipOval'
import slot from '../../../../assets/image/dashboard/update-slot-blue.svg'
import { Link } from 'react-router-dom'
import { Context } from '../../../../contexts/Store'
import TimeSlotChip from './TimeSlotChip'
import TimeSlot from '../../../includes/chips/TimeSlot'
import { THEME_COLORS } from '../../../../ThemeConfig'

const ScheduleSlotModal = ({}) => {
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  // console.log(access_token, 'access_token')
  const [datecards, setDateCards] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedDate, setSelectedDate] = useState('')

  const [date, setDate] = useState(new Date().getDate())
  const [day, setDay] = useState(new Date().getDay())
  const [active, setActive] = useState(true)
  const [dot, setDot] = useState(true)
  const [timeslots, setTimeslots] = useState([])
  const [Datelots, setDatelots] = useState([])

  const [morning, setMorning] = useState([])
  const [morningSlots, setMorningSlots] = useState([])
  const [morningSlotID, setMorningSlotID] = useState([])

  const [afterNoon, setAfterNoon] = useState([])
  const [afterNoonSlot, setAfternoonSlot] = useState([])
  const [afterNoonSlotID, setAfternoonSlotID] = useState([])

  const [evening, setEvening] = useState([])
  const [eveningSlot, setEveningSlot] = useState([])
  const [eveningSlotID, setEveningSlotID] = useState([])

  const [night, setNight] = useState([])
  const [nightSlot, setNightSlot] = useState([])
  const [nightSlotID, setNightSlotID] = useState([])

  useEffect(() => {
    general
      .get('/time-slots/')
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setTimeslots(data)
          // console.log(data, 'Timeslots')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  function getMonthNumber(monthName) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const monthIndex = months?.findIndex(
      (month) => month?.toLowerCase() === monthName?.toLowerCase(),
    )

    // Adding 1 to index to match with the month numbering (January starts at 1)
    return monthIndex !== -1 ? monthIndex + 1 : null
  }
  function getCurrentYear() {
    const currentYear = new Date().getFullYear()
    return currentYear
  }

  // Usage example:
  const year = getCurrentYear()

  const monthNumber = getMonthNumber(selectedDate.month)

  useEffect(() => {
    expert
      .get('/sessions/day-slots/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          year: year,
          month: monthNumber,
          day: selectedDate.date,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setDatelots(data.slots)
          setMorning(data?.slots?.morning)
          setAfterNoon(data?.slots?.after_noon)
          setEvening(data?.slots?.evening)
          setNight(data?.slots?.night)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [monthNumber, selectedDate.date, year])

  const showDates = () => {
    let today = new Date()
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    let days_full = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let days_show = 30
    let dates = []

    for (let i = 0; i < days_show; i++) {
      let next_date = new Date(new Date().setDate(today.getDate() + i))
      let date = next_date.getDate()
      let day = days[next_date.getDay()]
      let day_full = days_full[next_date.getDay()]
      let month = months[next_date.getMonth()]
      let year = next_date.getFullYear()

      dates.push({
        id: Math.random() * 100,
        date: date,
        day: day,
        day_full: day_full,
        month: month,
        year: year,
      })
    }
    setDateCards(dates)
  }
  function getFormattedDate() {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    const monthsOfYear = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const date = new Date()
    const dayOfWeek = daysOfWeek[date.getDay()]
    const dayOfMonth = date.getDate()
    const monthOfYear = monthsOfYear[date.getMonth()]
    return `${dayOfWeek}, ${dayOfMonth} ${monthOfYear}`
  }
  const formattedDate = getFormattedDate()
  const [pk, setPk] = useState(formattedDate)

  useEffect(() => {
    showDates()
  }, [])
  const d = new Date()
  const y = d.getFullYear()
  const currentDate = new Date()
  const dateNumber = currentDate.getDate()
  useEffect(() => {
    if (selectedDate.date == 'undefined') {
      setPk('formattedDate')
    }
    setPk(selectedDate.date + selectedDate.month + y)
  }, [selectedDate.month])

  return (
    <>
      <ContentBox>
        <SubTitleWrapper2>
          <Text>Today</Text>
          {selectedDate != '' ? (
            <SubTitle className="date">
              {Object.keys(selectedDate).length !== 0 &&
                selectedDate.day +
                  ', ' +
                  selectedDate.date +
                  ' ' +
                  selectedDate.month}
            </SubTitle>
          ) : (
            <SubTitle className="date">{formattedDate}</SubTitle>
          )}
        </SubTitleWrapper2>

        {/* show date slider */}
        <Dates>
          <DateCard
            dateNumber={dateNumber}
            dot={dot}
            active={active}
            cards={datecards}
            value={selectedDate}
            setValue={setSelectedDate}
          />
        </Dates>
        <SubTitleWrapper>
          <SubTitle>Booked Session Slots</SubTitle>
          <div className="border"></div>
        </SubTitleWrapper>
        <TimeSlot
          time={morning}
          timeSlot={morningSlots}
          setTimeSlot={setMorningSlots}
          setTimeSlotID={setMorningSlotID}
          timeSlotID={morningSlotID}
          title={'MORNING'}
        />
        <TimeSlot
          time={afterNoon}
          timeSlot={afterNoonSlot}
          setTimeSlot={setAfternoonSlot}
          setTimeSlotID={setAfternoonSlotID}
          timeSlotID={afterNoonSlotID}
          title={'AFTER NOON'}
        />

        <TimeSlot
          time={evening}
          timeSlot={eveningSlot}
          setTimeSlot={setEveningSlot}
          setTimeSlotID={setEveningSlotID}
          timeSlotID={eveningSlotID}
          title={'EVENING'}
        />
        <TimeSlot
          time={night}
          timeSlot={nightSlot}
          setTimeSlot={setNightSlot}
          setTimeSlotID={setNightSlotID}
          timeSlotID={nightSlotID}
          title={'NIGHT'}
        />

        <Bottom>
          <Deatil
            to={`/slot-detailed-view`}
            // to={`/slot-detailed-view/${pk}`}
          >
            <Content>Detailed View</Content>
          </Deatil>
        </Bottom>
      </ContentBox>
    </>
  )
}

export default ScheduleSlotModal

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 10px;
`
const SubTitleGreen = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  text-transform: uppercase;
  color: ${THEME_COLORS.chips_green_on_container};
  margin-bottom: 24px;
`
const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* padding: 10px; */
  border-radius: 8px;
  div.border {
    margin-left: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
  }
`
const SubTitleWrapper2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    background-color: ${THEME_COLORS.chips_blue_container};
    border-radius: 8px;
    /* div {
    margin-left: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
  } */
`
const SubTitle = styled.h4`
  color: ${THEME_COLORS.dark_500};
  font-size: 14px;
  font-weight: 600;
  &.date {
    color: ${THEME_COLORS.dark_300};
  }
`
const Dates = styled.div`
  margin-bottom: 20px;
`
const Bottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  color: ${THEME_COLORS.text_title};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const Text = styled.div``
const Middle = styled.div``

const UpdateButton = styled.div`
  display: flex;
  cursor: pointer;
  /* width: 170px; */
  @media all and (max-width: 1080px) {
    width: 350px;
  }
`
const Deatil = styled(Link)`
  padding: 8px 15px;
  background: ${THEME_COLORS.chips_blue_container};
  width: 90%;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @media all and (max-width: 1080px) {
    width: 350px;
  }
`
const Content = styled.p`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Icon = styled.img`
  margin-right: 10px;
`
const Title = styled.div`
  color: ${THEME_COLORS.dark_300};
  font-size: 14px;
  margin-bottom: 5px;
`
const Morning = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.light_200};
  margin-bottom: 20px;
`
const Afternoon = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.light_200};
  margin-bottom: 20px;
`
const Night = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.light_200};
`
const Box = styled.div``
