import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../ThemeConfig'
import '../../assets/css/navbar.css'

//images
import logo from '../../assets/image/dashboard/Logo.svg'
import schedule_Icon from '../../assets/image/dashboard/Schedule.svg'
import bell_Icon from '../../assets/image/dashboard/bell.svg'
import dashboard_Icon from '../../assets/image/dashboard/dashboard.svg'
import down_arrow from '../../assets/image/dashboard/down-arrow.svg'
import account_Icon from '../../assets/image/dashboard/my-account.svg'
import person from '../../assets/image/dashboard/profile.svg'
import reports_Icon from '../../assets/image/dashboard/reports.svg'
import schedule_blue from '../../assets/image/dashboard/Schedule-blue.svg'
import account_blue from '../../assets/image/dashboard/account-blue.svg'
import bell_blue from '../../assets/image/dashboard/bell-blue.svg'
import dashboard_blue from '../../assets/image/dashboard/dashboard-blue.svg'
import reports_blue from '../../assets/image/dashboard/reports-blue.svg'
import { general } from '../../axiosConfig'
import { Context } from '../../contexts/Store'
import NotificationModal from '../includes/modals/NotificationModal'
import ProfileModal from '../includes/modals/ProfileModal'

function DashboardHeader({ notification, expertData }) {
  const { state, dispatch } = useContext(Context)
  const [hover, setHover] = useState('')
  const [isitem, Setitem] = useState(1)
  const [searchmodal, setSearchModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [notificationModal, setNotificationModal] = useState(false)
  const navigate = useNavigate()
  const [newActiveLink, setNewActiveLink] = useState(null)
  const access_token = state.user_details.access_token
  const [notifications, setNotifications] = useState([])
  const [error, SetError] = useState([])
  const userId = state.user_details.user_id
  const wrapperRef = useRef(null)

  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setModal(false)
      setNotificationModal(false)
    }
  }

  const getNotifications = () => {
    general
      .get('/notifications', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setNotifications(response.data.data)
        } else if (StatusCode == 6001) {
          SetError(response)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  // useEffect(() => {
  //     const socket = new WebSocket(`${socketUrl}/notifications/${userId}/`);

  //     socket.onopen = () => {
  //         console.log("WebSocket connected");
  //     };

  //     socket.onmessage = (event) => {
  //         console.log("webSocket Received message:", event.data);
  //         getNotifications();
  //     };

  //     socket.onerror = (error) => {
  //         console.error("WebSocket error:", error);
  //     };

  //     socket.onclose = () => {
  //         console.log("WebSocket connection closed");
  //     };

  //     return () => {
  //         socket.close();
  //     };
  // }, []);
  // console.log(modal, " profile modal");

  return (
    <>
      <Container
        onClick={(e) => (modal || notificationModal) && handleOuterClick(e)}
      >
        <Cover className="wrapper">
          <Left>
            <LogoContainer to="/">
              <Logo src={logo} alt="logo" />
            </LogoContainer>
          </Left>

          <NavContainer>
            <NavLink
              onMouseEnter={() => {
                setHover('dashboard')
              }}
              onMouseLeave={() => {
                setHover('')
              }}
              to="/"
              className={({ isActive }) => (isActive ? 'blue' : 'grey')}
            >
              {({ isActive }) => (
                <NavBox className={isActive ? 'navbox-active' : ''}>
                  <IconContainer>
                    <Icon
                      src={
                        isActive || hover == 'dashboard'
                          ? dashboard_blue
                          : dashboard_Icon
                      }
                      alt="icon"
                    />
                  </IconContainer>
                  <Text
                    className={isActive || hover == 'dashboard' ? 'active' : ''}
                  >
                    Dashboard
                  </Text>
                </NavBox>
              )}
            </NavLink>
            <NavLink
              onMouseEnter={() => {
                setHover('schedule')
              }}
              onMouseLeave={() => {
                setHover('')
              }}
              to="/schedule-page"
              className={({ isActive }) => (isActive ? 'blue' : 'grey')}
            >
              {({ isActive }) => (
                <NavBox className={isActive ? 'navbox-active' : ''}>
                  <IconContainer>
                    <Icon
                      src={
                        isActive || hover == 'schedule'
                          ? schedule_blue
                          : schedule_Icon
                      }
                      alt="icon"
                    />
                  </IconContainer>
                  <Text
                    className={isActive || hover == 'schedule' ? 'active' : ''}
                  >
                    Schedule
                  </Text>
                </NavBox>
              )}
            </NavLink>

            <NavLink
              onMouseEnter={() => {
                setHover('reports')
              }}
              onMouseLeave={() => {
                setHover('')
              }}
              to="/reports-list"
              className={({ isActive }) => (isActive ? 'blue' : 'grey')}
            >
              {({ isActive }) => (
                <NavBox className={isActive ? 'navbox-active' : ''}>
                  <IconContainer>
                    <Icon
                      src={
                        isActive || hover == 'reports'
                          ? reports_blue
                          : reports_Icon
                      }
                      alt="icon"
                    />
                  </IconContainer>
                  <Text
                    className={isActive || hover == 'reports' ? 'active' : ''}
                  >
                    Reports
                  </Text>
                </NavBox>
              )}
            </NavLink>

            <NavLink
              onMouseEnter={() => {
                setHover('accounts')
              }}
              onMouseLeave={() => {
                setHover('')
              }}
              to="/my-account"
              className={({ isActive }) => (isActive ? 'blue' : 'grey')}
            >
              {({ isActive }) => (
                <NavBox className={isActive ? 'navbox-active' : ''}>
                  <IconContainer>
                    <Icon
                      src={
                        isActive || hover == 'accounts'
                          ? account_blue
                          : account_Icon
                      }
                      alt="icon"
                    />
                  </IconContainer>
                  <Text
                    className={isActive || hover == 'accounts' ? 'active' : ''}
                  >
                    My Account
                  </Text>
                </NavBox>
              )}
            </NavLink>
          </NavContainer>

          <Right>
            <IconDiv
              onClick={() => {
                setNotificationModal(!notificationModal)
                setModal(false)
                setSearchModal(false)
              }}
              className={notification && 'active'}
            >
              <Icon src={notification ? bell_blue : bell_Icon} alt="icon" />
              {/* <Dot></Dot> */}
            </IconDiv>

            {notificationModal && (
              <NotificationModal
                notifications={notifications}
                notificationModal={notificationModal}
                setNotificationModal={setNotificationModal}
              />
            )}

            <ProfileContainer
              onClick={(e) => {
                setModal((prevModal) => !prevModal)
                setSearchModal(false)
                setNotificationModal(false)
              }}
            >
              {expertData?.photo ? (
                <ProfileDiv className="photo-container">
                  {' '}
                  <Profile src={expertData.photo} alt="profile" />
                </ProfileDiv>
              ) : (
                <ProfileDiv className="static">
                  {' '}
                  <Profile src={person} alt="profile" />
                </ProfileDiv>
              )}

              <IconDiv>
                <Icon src={down_arrow} alt="icon" />
              </IconDiv>
              <ProfileModal
                wrapperRef={wrapperRef}
                modal={modal}
                setModal={setModal}
                reports
                my_account
              />
            </ProfileContainer>
          </Right>
        </Cover>
      </Container>
    </>
  )
}

export default DashboardHeader

const Container = styled.section`
  padding: 14px 30px;
  border-bottom: 2px solid ${THEME_COLORS.divider};
`

const Cover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.wrapper {
    width: 100%;
    margin: 0 auto;
  }
`
const Left = styled.div``
const LogoContainer = styled(Link)``
const Logo = styled.img`
  display: block;
  width: 100%;
`
const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* @media all and (max-width: 1000px) {
    display: none;
  } */
`
const NavBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  margin-right: 20px;
  &.navbox-active {
    background-color: ${THEME_COLORS.chips_blue_container};
  }
  :hover {
    background-color: ${THEME_COLORS.chips_blue_container};
  }
`

const NavLinks = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 25px;
  &.active {
    background-color: ${THEME_COLORS.chips_blue_container};
    p {
      color: ${THEME_COLORS.chips_blue_on_container_2};
    }
    &.grey {
      display: none;
    }
    &.blue {
      display: block;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  @media all and (max-width: 1080px) {
    margin: 0;
  }
`

const IconContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Icon = styled.img`
  width: 100%;
  display: block;
  &.active {
    display: none;
  }
`
const Icon_2 = styled.img`
  display: none;
  &.active {
    display: block;
  }
`
const Text = styled.p`
  margin-left: 10px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.dark_300};
  &.active {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const IconDiv = styled.a`
  width: 45px;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  :hover {
    background: ${THEME_COLORS.light_200};
  }
  &.active {
    background: ${THEME_COLORS.light_200};
  }
`
const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 15%;
  position: relative;
  @media all and (max-width: 1080px) {
    width: 20%;
  }
`
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
`
const Profile = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`
const ProfileDiv = styled.div`
  border-radius: 50%;
  background: #f4f4f4;
  width: 36px;
  height: 36px;
  &.static {
    padding: 5px;
  }
`
const Dot = styled.div`
  background: ${THEME_COLORS.chips_red_on_container};
  width: 4px;
  height: 4px;
  border-radius: 20px;
  position: absolute;
  right: 12px;
  top: 13px;
  background: #fe6666;
  width: 6px;
  height: 6px;
  border-radius: 20px;
  position: absolute;
  right: 14px;
  top: 13px;
`
