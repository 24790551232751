import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { authentication } from '../../axiosConfig'
import { Context } from '../../contexts/Store'
import { THEME_COLORS } from '../../ThemeConfig'

function OtpPage() {
  const [error, setError] = useState('')
  const [countdown, setCountdown] = useState(15)

  const [resend, setResend] = useState(
    localStorage.getItem('resendMessage') || '',
  )
  const [isResendAttemptOver, setIsResendAttemptOver] = useState(false)
  const [showResendMessage, setShowResendMessage] = useState(
    resend ? true : false,
  )
  const [showResendSuccessMessage, setShowResendSuccessMessage] = useState(
    false,
  )
  const navigate = useNavigate()
  const [OTP, setOTP] = useState('')
  const { state, dispatch } = useContext(Context)
  const [loading, setLoading] = useState(false) // Add loading state

  useEffect(() => {
    let user_details = localStorage.getItem('user_details')
    user_details = JSON.parse(user_details)
    dispatch({ type: 'UPDATE_USER', user_details: user_details })
  }, [])

  useEffect(() => {
    let timeout
    if (error) {
      timeout = setTimeout(() => {
        setError('')
      }, 5000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [error])

  let email = state.user_details.email

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    // Remove non-digit characters and limit to 10 digits
    inputValue = inputValue.replace(/\D/g, '').slice(0, 10)
    setOTP(inputValue)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    if (OTP.trim() === '') {
      setError('Please enter your OTP')
      setLoading(false)
      return
    }

    authentication
      .post('/signup/verify/', {
        otp: OTP,
        email: email,
      })
      .then(function (response) {
        const { StatusCode, data } = response.data
        const { access_token, refresh_token, message } = response.data.data
        if (StatusCode == 6000) {
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              access_token: access_token,
              role: response.data.data.roles,
            },
          })

          navigate('/auth/profile-about-you')
        } else {
          setError(data?.message)
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // Initialize the countdown when the component mounts
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }, 1000)

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timer)
  }, [])
  const handleResendOTP = () => {
    if (error) {
      setError('')
    }
    authentication
      .post('/signup/resend-otp/', {
        email: email,
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          setShowResendMessage(true) // Show the message
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              access_token: response.data.data.access_token,
              role: response.data.data.roles,
            },
          })
          if (data.remaining_attempts == 0) {
            setIsResendAttemptOver(true)
          }
          setShowResendSuccessMessage(true)
          setCountdown(15)
          setTimeout(() => {
            setShowResendMessage(false)
            setShowResendSuccessMessage(false)
          }, 5000)
        } else {
          setError(data?.message)
          // console.log("else error");
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.data?.message, 'error')
        if (
          error?.response?.data?.data?.message ==
          "You crossed the maximum limit of OTP's"
        ) {
        }
      })
  }

  return (
    <>
      <Main>
        <Wrapper>
          <Left>
            <ImageBox>
              <img
                src={require('../../assets/image/loginpage/loginpageimage.png')}
                alt="loginimage"
              />
            </ImageBox>
          </Left>
          <Right>
            <LogoBox>
              <img
                src={require('../../assets/image/loginpage/logo.svg').default}
                alt="logo"
              />
            </LogoBox>
            <TopText>
              <Titile>Verify OTP</Titile>
              <SubTitile>
                Enter OTP that has sent to your email <br />
                <span>{email}</span>
              </SubTitile>
            </TopText>
            <InputBox onSubmit={handleSubmit} method="post">
              <input
                type="tel"
                id="otp"
                value={OTP}
                maxLength={4}
                className="active"
                placeholder="Enter OTP"
                onChange={handleInputChange}
                required
              />
            </InputBox>
            <ButtonBox onClick={handleSubmit}>Verify</ButtonBox>
            <ErrorMsg>{error}</ErrorMsg>
            <ResendButton>
              {!isResendAttemptOver ? (
                <ExpertText>
                  {countdown > 0 ? (
                    <>
                      <GrayText>Haven't received an OTP ? </GrayText>
                      <CountdownText
                        style={{
                          cursor: 'not-allowed',
                        }}
                      >{`Resend in ${countdown}s`}</CountdownText>
                    </>
                  ) : (
                    <>
                      <GrayText>Haven't received an OTP ? </GrayText>
                      <CountdownText
                        onClick={handleResendOTP}
                        style={{ cursor: 'pointer' }}
                      >
                        Resend
                      </CountdownText>
                    </>
                  )}
                </ExpertText>
              ) : (
                <ExpertText>
                  <GrayText>OTP Resend attempts exceeded, </GrayText>
                  <CountdownText
                    onClick={() => {
                      navigate(-1)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Go Back
                  </CountdownText>
                </ExpertText>
              )}

              {showResendMessage && <ResendMessage>{resend}</ResendMessage>}
            </ResendButton>
          </Right>
        </Wrapper>
      </Main>
    </>
  )
}

export default OtpPage
const Main = styled.div`
  padding: 70px 0;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 90%;
  margin: 0 auto;
  @media all and (max-width: 980px) {
    flex-direction: column-reverse;
    gap: 50px;
  }
`
const Left = styled.div`
  width: 55%;
  @media all and (max-width: 1440px) {
  }
  @media all and (max-width: 1050px) {
    width: 64%;
  }
  @media all and (max-width: 980px) {
    width: 85%;
  }
`
const ImageBox = styled.div`
  align-items: center;
  width: 100%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
  img {
    width: 100%;
    display: block;
  }
`
const Right = styled.div`
  width: 40%;
  @media all and (max-width: 980px) {
    width: 75%;
  }
`
const LogoBox = styled.div`
  width: 16%;
`
const TopText = styled.div`
  margin: 40px 0;
`
const Titile = styled.h5`
  font-size: 24px;
  font-family: 'DM_sans_bold';
  color: #3e3e53;
  margin-bottom: 11px;
  @media all and (max-width: 1050px) {
    font-size: 22px;
  }
`
const SubTitile = styled.p`
  color: #7a838a;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-left: 5px;

  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
  @media all and (max-width: 1050px) {
    font-size: 14px;
  }
`

const InputBox = styled.form`
  background-color: #f6f7f9;
  display: flex;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 300;
  padding: 12px 17px;
  align-items: center;
  width: 80%;
  input {
    border: none;
    background-color: #f6f7f9;
    height: 20px;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
    @media all and (max-width: 1280px) {
      font-size: 16px;
    }
    :focus {
      outline: none;
      border: 1px solid #f1f2f378;
    }

    ::placeholder {
      font-size: 16px;
      font-weight: 400;
    }
  }
`
const ButtonBox = styled.button`
  background-color: #366ee3;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 16px;
  width: 80%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  @media all and (max-width: 1050px) {
    padding: 15px 130px;
  }
`
const Log = styled.a`
  color: #2b3990;
`
const ResendButton = styled.button`
  display: block;
  width: 80%;
  margin-top: 20px;
  cursor: default;
`
const Expert = styled.h5`
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  text-align: center;
  color: #818181;
`

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`
const ResendMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-top: 10px;
`
const ResendSuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-top: 10px;
`
const CountdownText = styled.span`
  color: blue;
`

const GrayText = styled.span`
  color: grey;
`
const ExpertText = styled.h5`
  font-size: 14px;
  text-align: center;
  color: #818181;
  font-family: 'DM_sans_medium';
`
