import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import DefaultSlotCard from './DefaultSlotCard'
import SuccessToast from '../../manage_profile/toast/SuccessToast'

const SetDefaultSlotMdal = ({
  handleModal,
  setTimeSlot,
  setModal,
  isModal,
}) => {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  useEffect(() => {
    // Disable scrolling on the body element when the modal is open
    document.body.classList.add('modal-open')

    // Cleanup function to re-enable scrolling when the modal is closed
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const closeModal = (e) => {
    const element = document.querySelector('.click-form')
    if (!element.contains(e.target)) {
      handleModal()
    }
  }

  return (
    <Background
      className={showSuccessToast && 'modal-active'}
      onClick={(e) => closeModal(e)}
    >
      <Cover></Cover>

      <RightContainer className="click-form">
        <TopContainer>
          <Title>Set Default Time Slots for the Week</Title>
        </TopContainer>

        <Description>
          Set default time slots for each day of the week to manage your
          schedule <br></br>
          and availability efficiently.
        </Description>

        {/* Default slot component main part  */}
        <DefaultSlotCard
          showSuccessToast={showSuccessToast}
          setShowSuccessToast={setShowSuccessToast}
          setModal={setModal}
          closeModal={closeModal}
        />
      </RightContainer>
      {showSuccessToast && (
        <Modal>
          <SuccessToast />
        </Modal>
      )}
    </Background>
  )
}

export default SetDefaultSlotMdal

const Background = styled.div`
    width: 100%;
    padding-bottom: 60px;
    padding-top: 60px;
    /* background: ${THEME_COLORS.elevation_expert_e9}; */
    /* background: red; */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    height: 100vh;
    /* &.modal-active{
    height: calc(100vh - 69.6px);
    overflow-y: hidden;
  } */
`
const Cover = styled.div`
  background: #161616;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;

  z-index: -1;
  opacity: 0.5;
`
const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 4px;
`
const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  margin-bottom: 32px;
`
const RightContainer = styled.div`
  width: 70%;
  /* height: 650px; */
  position: relative;
  max-height: 90vh;
  padding: 40px;
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
  background-color: ${THEME_COLORS.white};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1280px) {
    width: 95%;
  }
`
const Modal = styled.div``
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
