import React, { useState } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import ChipRectangle from '../../includes/chips/ChipRectangle'
import ModalStructure from './modals/ModalStructure'
import check_white from '../../../assets/image/session_summary/check_white.svg'
import ButtonTypeTwo from '../../includes/buttons/ButtonTypeTwo'

function SessionSummaryPage() {
  const [addPatientDetails, setAddPatientDetails] = useState(false)
  const [scheduleNextSession, setScheduleNextSession] = useState(false)
  const [scheduleFollowUpSession, setScheduleFollowUpSession] = useState(false)
  const [isFollowUp, setIsFollowUp] = useState(false)

  const [symptoms, setSymptoms] = useState('')
  const [diagnosis, setDiagnosis] = useState('')
  const [recommendation, setRecommendation] = useState('')
  const [treatmentPlan, setTreatmentPlan] = useState('')
  const [notes, setNotes] = useState('')
  const [videoLinks, setVideoLinks] = useState('')
  const [videoLinksArray, setVideoLinksArray] = useState([])
  const [timeSlot, setTimeSlot] = useState({})
  const [showErrors, setShowErrors] = useState(false)

  const handleNextSession = () => {
    setScheduleNextSession(true)
  }

  const handleFollowUpSession = () => {
    setScheduleFollowUpSession(true)
  }

  const handleModal = () => {
    setAddPatientDetails(false)
    setScheduleNextSession(false)
    setScheduleFollowUpSession(false)
  }

  const addVideoLink = () => {
    if (videoLinks !== '') {
      let obj = {
        link: videoLinks,
        id: Math.floor(Math.random() * 100 + 1),
      }
      setVideoLinksArray([...videoLinksArray, obj])
      setVideoLinks('')
    }
  }

  const removeVideoLink = (videoId) => {
    setVideoLinksArray(videoLinksArray.filter((video) => video.id !== videoId))
  }

  const clearStates = () => {
    setSymptoms('')
    setDiagnosis('')
    setRecommendation('')
    setTreatmentPlan('')
    setNotes('')
    setVideoLinksArray([])
    setShowErrors(false)
  }

  const handleSubmit = () => {
    if (symptoms && diagnosis && recommendation && treatmentPlan) {
      clearStates()
    } else {
      setShowErrors(true)
    }
  }

  const handleCancel = () => {
    clearStates()
    // cancel functionality
  }

  return (
    <Background>
      <Main>
        {addPatientDetails && (
          <ModalStructure
            addPatientDetails
            title="Socio Demographic Details"
            description="Add your client's Socio Demographic Details"
            handleModal={handleModal}
            large
          />
        )}
        {scheduleNextSession && (
          <ModalStructure
            scheduleNextSession
            title="Schedule Next Session"
            description="Schedule the Next session."
            handleModal={handleModal}
            setTimeSlot={setTimeSlot}
          />
        )}
        {scheduleFollowUpSession && (
          <ModalStructure
            scheduleFollowUpSession
            title="Follow Your Schedule"
            description="Schedule follow-up"
            handleModal={handleModal}
            setTimeSlot={setTimeSlot}
          />
        )}
        <Wrapper>
          <Head>
            <Title>Session Summary Report</Title>
            <CaseId>
              Case ID : <span>#CID022300001</span>
            </CaseId>
          </Head>
          <Content>
            <ContentLeft>
              <Box>
                <Top>
                  <ImageBox>
                    <img
                      src={
                        require('../../../assets/image/session_summary/profile_picture.svg')
                          .default
                      }
                      alt="profile"
                    />
                  </ImageBox>
                  <Data>
                    <h5>Not a good mood at school</h5>
                    <p>Brandon Workman</p>
                  </Data>
                </Top>
                <Bottom>
                  {/* date */}
                  <ChipRectangle
                    image="calendar.svg"
                    content="Completed on 5 Jan, 2023"
                    bkgcolor={THEME_COLORS.chips_blue_container}
                    color={THEME_COLORS.chips_blue_on_container_2}
                    pd="4px 8px 4px 3px"
                    br="4px"
                    font_size="12px"
                    font_weight="400"
                    image_width="11.33px"
                  />
                  {/* video */}
                  <ChipRectangle
                    image="video_camera.svg"
                    bkgcolor={THEME_COLORS.chips_green_container}
                    mr_left="10px"
                    pd="4px"
                    br="4px"
                    image_width="13.33px"
                  />
                </Bottom>
              </Box>
              <Box>
                <DetailsTop>
                  <SubTitle>Patient Details</SubTitle>
                  <ButtonTypeTwo
                    text="+ Add more details"
                    onclick={() => setAddPatientDetails(true)}
                  />
                </DetailsTop>
                <PatientDetailsBottom>
                  <Item>
                    <Label>Patient Name</Label>
                    <Value>
                      :<span>Brandon Workman</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Age</Label>
                    <Value>
                      :<span>25</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Gender</Label>
                    <Value>
                      :<span>Male</span>
                    </Value>
                  </Item>
                </PatientDetailsBottom>
              </Box>
              <Box>
                <DetailsTop>
                  <SubTitle>Session Details</SubTitle>
                </DetailsTop>
                <SessionDetailsBottom>
                  <Item>
                    <Label>Session ID</Label>
                    <Value>
                      :<span>#SID00001</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Expert Name</Label>
                    <Value>
                      :<span>Dr. Sophia Julie</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Date of session</Label>
                    <Value>
                      :<span>5 January, 2023</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Session Type</Label>
                    <Value>
                      :<span>Online video session</span>
                    </Value>
                  </Item>
                </SessionDetailsBottom>
              </Box>
            </ContentLeft>
            <ContentRight>
              <Form>
                <FormTitle>Summary</FormTitle>
                <InputWrapper
                  className="input-wrapper"
                  style={{ height: 'auto' }}
                >
                  <Left>
                    <FormLabel
                      htmlFor="symptoms"
                      className={symptoms ? 'input-label show' : 'input-label'}
                    >
                      Symptoms *
                    </FormLabel>
                    <FormText
                      id="symptoms"
                      name="symptoms"
                      type="text"
                      value={symptoms}
                      rows="3"
                      onChange={(e) => setSymptoms(e.target.value)}
                      placeholder="Symptoms *"
                      className={
                        symptoms ? 'textarea-value active' : 'textarea-value'
                      }
                    ></FormText>
                  </Left>
                </InputWrapper>
                {showErrors && !symptoms && (
                  <Error className="error">This field is required.</Error>
                )}

                <InputWrapper className="input-wrapper input">
                  <Left>
                    <FormLabel
                      htmlFor="diagnosis"
                      className={diagnosis ? 'input-label show' : 'input-label'}
                    >
                      Diagnosis *
                    </FormLabel>
                    <FormInput
                      id="diagnosis"
                      name="diagnosis"
                      type="text"
                      value={diagnosis}
                      onChange={(e) => setDiagnosis(e.target.value)}
                      placeholder="Diagnosis *"
                      className={
                        diagnosis ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !diagnosis && (
                  <Error className="error">This field is required.</Error>
                )}

                <InputWrapper className="input-wrapper input">
                  <Left>
                    <FormLabel
                      htmlFor="recommendation"
                      className={
                        recommendation ? 'input-label show' : 'input-label'
                      }
                    >
                      Recommendation and intervention *
                    </FormLabel>
                    <FormInput
                      id="recommendation"
                      name="recommendation"
                      type="text"
                      value={recommendation}
                      onChange={(e) => setRecommendation(e.target.value)}
                      placeholder="Recommendation and intervention *"
                      className={
                        recommendation ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !recommendation && (
                  <Error className="error">This field is required.</Error>
                )}

                <InputWrapper className="input-wrapper input">
                  <Left>
                    <FormLabel
                      htmlFor="treatmentplan"
                      className={
                        treatmentPlan ? 'input-label show' : 'input-label'
                      }
                    >
                      Treatment plan *
                    </FormLabel>
                    <FormInput
                      id="treatmentplan"
                      name="treatmentplan"
                      type="text"
                      value={treatmentPlan}
                      onChange={(e) => setTreatmentPlan(e.target.value)}
                      placeholder="Treatment plan *"
                      className={
                        treatmentPlan ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !treatmentPlan && (
                  <Error className="error">This field is required.</Error>
                )}

                <InputWrapper className="input-wrapper input">
                  <Left>
                    <FormLabel
                      htmlFor="Notes"
                      className={notes ? 'input-label show' : 'input-label'}
                    >
                      Notes
                    </FormLabel>
                    <FormInput
                      id="notes"
                      name="notes"
                      type="text"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Notes"
                      className={notes ? 'input-value active' : 'input-value'}
                    ></FormInput>
                  </Left>
                </InputWrapper>
                <RecommendedVideos>
                  <FormSubTitle>Recommended Videos</FormSubTitle>
                  <VideoLinks>
                    {videoLinksArray.map((video) => {
                      return (
                        <VideoLinkWrapper key={video.id}>
                          <Link to={video.link}>{video.link}</Link>
                          <CloseButton
                            onClick={() => removeVideoLink(video.id)}
                          >
                            <img
                              src={
                                require('../../../assets/image/session_summary/cancel.svg')
                                  .default
                              }
                              alt="cancel"
                            />
                          </CloseButton>
                        </VideoLinkWrapper>
                      )
                    })}
                  </VideoLinks>
                  <VideoInput>
                    <input
                      type="text"
                      value={videoLinks}
                      onChange={(e) => setVideoLinks(e.target.value)}
                      placeholder="Paste link here"
                      className={videoLinks && 'active'}
                    />
                    {/* Add button  */}
                    <ChipRectangle
                      image="check_blue.svg"
                      content="Add"
                      bkgcolor={THEME_COLORS.chips_blue_container}
                      color={THEME_COLORS.chips_blue_on_container_2}
                      pd="8px 15px"
                      br="8px"
                      font_size="14px"
                      font_weight="500"
                      image_width="12.45px"
                      onclick={addVideoLink}
                      clickable
                      hover
                    />
                  </VideoInput>
                </RecommendedVideos>
                <FollowUpButtons>
                  <LeftButton checked={isFollowUp} check={check_white}>
                    <input
                      type="checkbox"
                      id="followup"
                      checked={isFollowUp}
                      onChange={(e) => setIsFollowUp(!isFollowUp)}
                    />
                    <label htmlFor="followup">Mark for follow-Up</label>
                  </LeftButton>
                  {isFollowUp ? (
                    // schedule follow up button
                    <ChipRectangle
                      image="calendar_add_on.svg"
                      content="Schedule Follow-up"
                      bkgcolor={THEME_COLORS.chips_blue_container}
                      color={THEME_COLORS.chips_blue_on_container_2}
                      pd="8px 15px"
                      br="8px"
                      font_size="14px"
                      font_weight="500"
                      image_width="15.21px"
                      clickable
                      onclick={handleFollowUpSession}
                      hover
                    />
                  ) : (
                    // schedule next session button
                    <ChipRectangle
                      image="calendar_add_on.svg"
                      content="Schedule Next Session"
                      bkgcolor={THEME_COLORS.chips_blue_container}
                      color={THEME_COLORS.chips_blue_on_container_2}
                      pd="8px 15px"
                      br="8px"
                      font_size="14px"
                      font_weight="500"
                      image_width="15.21px"
                      clickable
                      onclick={handleNextSession}
                      hover
                    />
                  )}
                </FollowUpButtons>
                <FormButtons>
                  <ButtonTypeOne
                    text="Cancel"
                    color={THEME_COLORS.dark_500}
                    bkgcolor={THEME_COLORS.white}
                    border={THEME_COLORS.light_200}
                    onclick={handleCancel}
                  />
                  <ButtonTypeOne
                    text="Submit"
                    ml="20px"
                    color={THEME_COLORS.white}
                    bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                    onclick={handleSubmit}
                  />
                </FormButtons>
              </Form>
            </ContentRight>
          </Content>
        </Wrapper>
      </Main>
    </Background>
  )
}

export default SessionSummaryPage

const Background = styled.div`
  width: 100vw;
  padding-top: 69.6px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`
const Main = styled.div`
  background-color: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 24px 24px 0px 0px;
  position: relative;
  padding: 60px 0;
`
const Wrapper = styled.div`
  width: 85%;
  margin: 0 auto;
  overflow: hidden;
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`
const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: ${THEME_COLORS.black};
`
const CaseId = styled.p`
  color: ${THEME_COLORS.text_para};
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  span {
    color: ${THEME_COLORS.text_title};
    font-weight: 400;
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
`
const ContentLeft = styled.div`
  width: 35%;
`
const Box = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  box-shadow: ${THEME_COLORS.elevation_expert_e1};
  background-color: ${THEME_COLORS.white};
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 12px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
`
const ImageBox = styled.div`
  width: 46px;
  margin-right: 10px;
  img {
    width: 100%;
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 50%;
  }
`
const Data = styled.div`
  h5 {
    font-size: 16px;
    font-weight: 500;
    color: ${THEME_COLORS.text_title};
    line-height: 20px;
    margin-bottom: 5px;
  }
  p {
    color: ${THEME_COLORS.chips_blue_on_container};
    font-size: 14px;
    font-weight: 400;
  }
`
const Bottom = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 10px;
`
const DetailsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${THEME_COLORS.text_title};
`
const PatientDetailsBottom = styled.div`
  margin-top: 10px;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`
const Label = styled.p`
  width: 40%;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
`
const Value = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  span {
    color: ${THEME_COLORS.text_title};
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
  }
`
const SessionDetailsBottom = styled.div`
  background: ${THEME_COLORS.light_200};
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px;
`
const ContentRight = styled.div`
  width: 50%;
`
const Form = styled.div``
const FormTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const InputWrapper = styled.div`
  margin-top: 20px;
`
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small``
const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const FormInput = styled.input`
  &.active {
    font-weight: 500;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
  }
`
const RecommendedVideos = styled.div`
  margin-top: 20px;
`
const FormSubTitle = styled.h4`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const VideoLinks = styled.ul`
  width: 100%;
`
const VideoLinkWrapper = styled.li`
  background-color: ${THEME_COLORS.chips_green_container};
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Link = styled.a`
  color: ${THEME_COLORS.chips_green_on_container};
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
`
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`
const VideoInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    margin-right: 16px;
    flex-grow: 1;
    background-color: ${THEME_COLORS.light_200};
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 16px;
    &.active {
      background-color: ${THEME_COLORS.white};
      color: ${THEME_COLORS.text_title};
      border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
      font-weight: 500;
      font-size: 14px;
      text-decoration: underline;
    }
    &::placeholder {
      color: ${THEME_COLORS.text_para};
    }
  }
`
const FollowUpButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`
const LeftButton = styled.div`
  input {
    margin-right: 10px;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_para};
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 0.5em;
      border: 2px solid
        ${(props) => (props.checked ? 'none' : THEME_COLORS.light_400)};
      background-color: ${(props) =>
        props.checked
          ? THEME_COLORS.chips_blue_on_container_2
          : THEME_COLORS.white};
      background-image: url(${(props) => props.check});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`
const FormButtons = styled.div`
  text-align: right;
  margin-top: 100px;
  display: flex;
  float: right;
`
