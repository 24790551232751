import React from 'react'
import styled from 'styled-components'

function ButtonTypeOne({
  text,
  ml,
  color,
  bkgcolor,
  border,
  onclick,
  pd,
  className,
}) {
  return (
    <ButtonContainer
      className={className == 'case' ? 'case' : ''}
      color={color}
      bkgcolor={bkgcolor}
      border={border}
      ml={ml}
      onClick={onclick}
      pd={pd}
    >
      {text}
    </ButtonContainer>
  )
}

export default ButtonTypeOne

const ButtonContainer = styled.div`
  color: ${(props) => props.color};
  border: ${(props) => (props.border ? `2px solid ${props.border}` : 'none')};
  background-color: ${(props) => props.bkgcolor};
  border-radius: 8px;
  padding: ${(props) => (props.pd ? props.pd : '14px 24px')};
  margin-left: ${(props) => props.ml && props.ml};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  &.case {
    margin-right: 20px;
  }
`
