import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { general } from '../../../axiosConfig'

import { THEME_COLORS } from '../../../ThemeConfig.js'
import SearchButton from '../../includes/buttons/SearchButton.jsx'

const FaqsPage = () => {
  const [activeItems, setActiveItems] = useState([])
  const [searchItem, setSearchItem] = useState('')
  const [faqsResults, setFaqsResults] = useState([])
  const [searchExpand, setSearchExpand] = useState(false)
  const [statuses, setStatuses] = useState([])

  const faqs = []

  const handleOnClick = (id) => {
    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((item) => item !== id))
    } else {
      setActiveItems([...activeItems, id])
    }
  }

  const handleSearch = (value) => {
    setSearchItem(value)
  }

  useEffect(() => {
    general
      .get('/faq/')
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setStatuses(data)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  const renderStatuses = () => {
    return statuses.map((status) => (
      <Item
        key={status.id}
        className={activeItems.includes(status.id) && 'active'}
      >
        <QuestionWrapper>
          <Question>{status.title}</Question>
          <Arrow onClick={() => handleOnClick(status.id)}>
            {activeItems.includes(status.id) ? (
              <img
                src={require('../../../assets/image/faqs/up_arrow.svg').default}
                alt="up arrow"
              />
            ) : (
              <img
                src={
                  require('../../../assets/image/faqs/down_arrow.svg').default
                }
                alt="down arrow"
              />
            )}
          </Arrow>
        </QuestionWrapper>
        {activeItems.includes(status.id) && (
          <Answer>{status.description}</Answer>
        )}
      </Item>
    ))
  }

  useEffect(() => {
    if (searchItem) {
      setFaqsResults(
        faqs.filter((faq) =>
          faq.question.toLowerCase().includes(searchItem.toLowerCase()),
        ),
      )
    } else {
      setFaqsResults(faqs)
    }
  }, [searchItem])

  return (
    <Main>
      <Top>
        <Left>FAQ'S</Left>
        <SearchButton searchItem={searchItem} handleSearch={handleSearch} />
      </Top>
      <Bottom>{renderStatuses()}</Bottom>
    </Main>
  )
}

export default FaqsPage

const Main = styled.div`
  width: 65%;
  margin: 32px auto;
  @media all and (max-width: 980px) {
    margin-bottom: 60px;
  }
`
const Top = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 20px;
`
const Left = styled.h3`
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`
const Item = styled.div`
  background: ${THEME_COLORS.light_100};
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 10px;
  &.active {
    background: ${THEME_COLORS.light_200};
    padding: 12px 16px;
  }
`
const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Question = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.black};
`
const Arrow = styled.div`
  margin-left: 10px;
  img {
    width: 100%;
  }
`
const Answer = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  margin-top: 17px;
`
