import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import video from '../../../assets/image/dashboard/session-card/gmeet_grey.svg'
import audio from '../../../assets/image/dashboard/session-card/audio.svg'

function CallTypeChip({ call_type }) {
  // const { call_type } = props
  return (
    <>
      <ChipContainer call_type={call_type}>
        <IconContainer>
          {call_type == 'Video' ? (
            <Icon src={video} alt="icon" />
          ) : (
            <Icon src={audio} alt="icon" />
          )}
        </IconContainer>
      </ChipContainer>
    </>
  )
}

export default CallTypeChip

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background: ${(props) =>
    props.call_type == 'Voice only'
      ? THEME_COLORS.chips_green_container
      : props.call_type == 'Video'
      ? THEME_COLORS.light_200
      : THEME_COLORS.white};
  justify-content: center;
  /* cursor: pointer; */
`

const IconContainer = styled.div`
  width: 16px;
`
const Icon = styled.img`
  display: block;
  width: 100%;
`
