import React, { useContext, useState } from 'react'

// package
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'

// components
import { THEME_COLORS } from '../../../ThemeConfig'
import { authentication } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'

const Verify = () => {
  const navigate = useNavigate()
  const [OTP, setOTP] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(Context)
  const email = state.user_details.email

  const inputRefs = Array.from({ length: 4 }, () => React.createRef())
  const handleInputChange = (e) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/\D/g, '').slice(0, 10)
    setOTP(inputValue)
  }
  const handleSubmit = () => {
    // e.preventDefault();
    setLoading(true)

    if (OTP.trim() === '') {
      setError('Please enter your OTP')
      setLoading(false)
      return
    }

    authentication
      .post('/forget-password/verify/', {
        otp: OTP,
        email: state.user_details.email,
      })
      .then(function (response) {
        const { StatusCode, data } = response.data
        const { access_token, refresh_token, message } = response.data.data
        if (StatusCode == 6000) {
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              access_token: response.data.data.access_token,
              role: response.data.data.roles,
            },
          })

          navigate('/auth/set-password')
        } else {
          setError(data?.message)
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <MainContent>
        <ForgotContainer>
          <LogoBox>
            <img
              src={require('../../../assets/image/loginpage/logo.svg').default}
              alt="logo"
            />
          </LogoBox>
          <Name>Verify OTP</Name>
          <Explain>
            Enter OTP that has sent to your Email <br /> <span>{email}</span>
          </Explain>

          <InputBox method="post">
            <input
              type="tel"
              id="otp"
              value={OTP}
              maxLength={4}
              className="active"
              placeholder="Enter OTP"
              onChange={handleInputChange}
              required
            />
          </InputBox>
          <ErrorMessage>{error}</ErrorMessage>
          <ResetBox
            onClick={() => {
              handleSubmit()
            }}
          >
            <Reset>Verify</Reset>
          </ResetBox>
          <MainButton>
            <Back>Resend OTP</Back>
          </MainButton>
        </ForgotContainer>
      </MainContent>
    </>
  )
}

export default Verify

const MainContent = styled.section`
  padding: 9% 5% 9%;
  text-align: center;
  background: ${THEME_COLORS.input};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ForgotContainer = styled.div`
  background: ${THEME_COLORS.white};
  padding: 30px 45px;
  border-radius: 15px;
  width: 35%;
  margin: 0 auto;
  text-align: left;
  box-shadow: ${THEME_COLORS.elevation_user_u4};
  /* @media all and (max-width: 1440px) {
        width: 45%;
    } */
  @media all and (max-width: 1280px) {
    width: 45%;
  }
  @media all and (max-width: 1050px) {
    width: 50%;
  }
  @media all and (max-width: 980px) {
    width: 55%;
  }
  @media all and (max-width: 768px) {
    width: 90%;
  }
`
const LogoBox = styled.div`
  width: 50px;
  margin-bottom: 40px;
  img {
    display: block;
    width: 100%;
  }
`
const Name = styled.h3`
  color: ${THEME_COLORS.text_title};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`
const Explain = styled.p`
  color: ${THEME_COLORS.text_para};
  margin-bottom: 40px;
  font-size: 16px;
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const Form = styled.form`
  display: flex;
  justify-content: space-between;
`
const Box = styled.div`
  background: ${THEME_COLORS.light_200};
  margin-bottom: 20px;
  width: 100px;
  height: 70px;
  border-radius: 15px;
  input {
    width: 100%;
    text-align: center;
    border-radius: 15px;
    display: block;
    height: 70px;
  }
  input:focus {
    border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const ErrorMessage = styled.p`
  color: ${THEME_COLORS.chips_red_on_container};
  font-style: 16px;
  margin-bottom: 20px;
`
const ResetBox = styled.div`
  width: 100%;
  display: inline-block;
  background: ${THEME_COLORS.chips_blue_on_container_2};
  margin-bottom: 20px;
  padding: 14px 24px;
  border-radius: 8px;
`
const MainButton = styled.div`
  text-align: center;
  cursor: pointer;
`
const Reset = styled.span`
  display: inline-block;
  text-align: center;
  color: ${THEME_COLORS.white};
  width: 100%;
`
const Back = styled.span`
  display: inline-block;
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const InputBox = styled.form`
  background-color: #f6f7f9;
  display: flex;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 300;
  padding: 12px 17px;
  align-items: center;
  width: 100%;
  input {
    border: none;
    background-color: #f6f7f9;
    height: 20px;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
    @media all and (max-width: 1280px) {
      font-size: 16px;
    }
    :focus {
      outline: none;
      border: 1px solid #f1f2f378;
    }

    ::placeholder {
      font-size: 16px;
      font-weight: 400;
    }
  }
`
