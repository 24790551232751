import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import DateCard from "../../../includes/cards/DateCard";
import ChipOval from "../../../includes/chips/ChipOval";

const ChooseDateTimeSlotModal = ({
    selectedTime,
    selectedDate,
    setSelectedTime,
    setSelectedDate,
    setTimeSlot,
    caseReport,
}) => {
    const [datecards, setDateCards] = useState([]);

    let timing = [
        {
            id: 1,
            start_time: "7:00AM",
            end_time: "7:45AM",
            disabled: true,
        },
        {
            id: 2,
            start_time: "8:00AM",
            end_time: "8:45AM",
            disabled: true,
        },
        {
            id: 3,
            start_time: "9:00AM",
            end_time: "9:45AM",
            disabled: false,
        },
        {
            id: 4,
            start_time: "10:00AM",
            end_time: "10:45AM",
            disabled: false,
        },
        {
            id: 5,
            start_time: "11:00AM",
            end_time: "11:45AM",
            disabled: true,
        },
        {
            id: 6,
            start_time: "12:00PM",
            end_time: "12:45PM",
            disabled: false,
        },
        {
            id: 7,
            start_time: "1:00PM",
            end_time: "1:45PM",
            disabled: true,
        },
        {
            id: 8,
            start_time: "2:00PM",
            end_time: "2:45PM",
            disabled: true,
        },
        {
            id: 9,
            start_time: "3:00PM",
            end_time: "3:45PM",
            disabled: false,
        },
        {
            id: 10,
            start_time: "4:00PM",
            end_time: "4:45PM",
            disabled: false,
        },
        {
            id: 11,
            start_time: "5:00PM",
            end_time: "5:45PM",
            disabled: true,
        },
        {
            id: 12,
            start_time: "6:00PM",
            end_time: "6:45PM",
            disabled: true,
        },
        {
            id: 13,
            start_time: "7:00PM",
            end_time: "7:45PM",
            disabled: true,
        },
        {
            id: 14,
            start_time: "8:00PM",
            end_time: "8:45PM",
            disabled: true,
        },
        {
            id: 15,
            start_time: "9:00PM",
            end_time: "9:45PM",
            disabled: false,
        },
        {
            id: 16,
            start_time: "10:00PM",
            end_time: "10:45PM",
            disabled: true,
        },
        {
            id: 17,
            start_time: "11:00PM",
            end_time: "11:45PM",
            disabled: true,
        },
    ];

    const showDates = () => {
        let today = new Date();
        let days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
        let days_full = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        let months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        let days_show = 30;
        let dates = [];

        for (let i = 0; i < days_show; i++) {
            let next_date = new Date(new Date().setDate(today.getDate() + i));
            let date = next_date.getDate();
            let day = days[next_date.getDay()];
            let day_full = days_full[next_date.getDay()];
            let month = months[next_date.getMonth()];
            let year = next_date.getFullYear();

            dates.push({
                id: Math.random() * 100,
                date: date,
                day: day,
                day_full: day_full,
                month: month,
                year: year,
            });
        }
        setDateCards(dates);
    };

    useEffect(() => {
        showDates();
    }, []);

    return (
        <>
            <ContentBox>
                {!caseReport && <SubTitleGreen>choose slot</SubTitleGreen>}
                <SubTitleWrapper>
                    <SubTitle>
                        {Object.keys(selectedDate).length !== 0 &&
                            selectedDate.day +
                                ", " +
                                selectedDate.date +
                                " " +
                                selectedDate.month}
                    </SubTitle>
                    <div></div>
                </SubTitleWrapper>

                {/* show date slider */}
                <Dates>
                    <DateCard
                        cards={datecards}
                        value={selectedDate}
                        setValue={setSelectedDate}
                    />
                </Dates>
                <SubTitleWrapper>
                    <SubTitle>Choose Rescheduling time</SubTitle>
                    <div></div>
                </SubTitleWrapper>
                {timing.map((time) => {
                    return (
                        <ChipOval
                            key={time.id}
                            obj={time}
                            content={time.start_time}
                            value={selectedTime}
                            setValue={setSelectedTime}
                            disabled={time.disabled}
                            active_green
                        />
                    );
                })}
            </ContentBox>
            {Object.keys(selectedDate).length !== 0 &&
                Object.keys(selectedTime).length !== 0 && (
                    <Bottom>
                        <Text>
                            You’ve scheduled the session 3 into,{" "}
                            <span>
                                {selectedTime.start_time} to{" "}
                                {selectedTime.end_time}
                            </span>{" "}
                            on{" "}
                            <span>
                                {selectedDate.date} {selectedDate.month},{" "}
                                {selectedDate.day_full}
                            </span>
                        </Text>
                    </Bottom>
                )}
        </>
    );
};

export default ChooseDateTimeSlotModal;

const ContentBox = styled.div`
    width: 100%;
    padding: 24px;
    /* margin-top: 32px; */
    background-color: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_200};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    border-radius: 10px;
`;
const SubTitleGreen = styled.h4`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    text-transform: uppercase;
    color: ${THEME_COLORS.chips_green_on_container};
    margin-bottom: 20px;
`;
const SubTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    div {
        margin-left: 10px;
        flex-grow: 1;
        border-top: 1px solid ${THEME_COLORS.light_300};
    }
`;
const SubTitle = styled.h4`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;
const Dates = styled.div`
    margin-bottom: 20px;
`;
const Bottom = styled.p`
    height: 10px;
`;
const Text = styled.p`
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    color: ${THEME_COLORS.text_title};
    span {
        color: ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
