import React, { useContext } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/Store";

export default function CancelSignupModal({ isModalOpen, setIsModalOpen }) {
    const { dispatch } = useContext(Context);

    const navigate = useNavigate();
    return (
        <MainContainer>
            <Overlay></Overlay>
            <Modal>
                <div>
                    <Title>Cancel Registeration</Title>
                    <ConfirmationText>
                        Are you sure you want to cancel ?
                    </ConfirmationText>
                </div>
                <ButtonsWrapper>
                    <CancelButton
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                    >
                        No
                    </CancelButton>
                    <OkeyButton
                        onClick={() => {
                            setIsModalOpen(false);
                            dispatch({
                                type: "UPDATE_NEW_REGISTERATION_STATUS",
                                nrs: false,
                            });
                            navigate("/auth/register-page");
                        }}
                    >
                        Yes
                    </OkeyButton>
                </ButtonsWrapper>
            </Modal>
        </MainContainer>
    );
}
const MainContainer = styled.div``;
const Overlay = styled.div`
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    opacity: 0.4;
`;
const Modal = styled.div`
    min-width: 480px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 20px;
    border-radius: 12px;
    border-top: 4px solid #1da687;
    background: #fff;
    padding: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
`;
const Title = styled.div`
    font-size: 24px;
    font-family: "DM_sans_medium";
    margin-bottom: 12px;
`;
const ConfirmationText = styled.span`
    font-size: 16px;
    color: #818181;
    display: inline-block;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
`;
const CancelButton = styled.button`
    border-radius: 8px;
    padding: 6px 16px;
    font-size: 14px;
    font-family: "DM_sans_medium";
    border: 2px solid #d3d3d3;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    /* opacity: 0.8;
    &:focus {
        opacity: 1;
    } */
`;
const OkeyButton = styled.button`
    border-radius: 8px;
    padding: 6px 16px;
    font-size: 14px;
    font-family: "DM_sans_medium";
    background: #366ee3;
    border: 2px solid #366ee3;
    color: #fff;
    cursor: pointer;
`;
