import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import SearchButton from "../../includes/buttons/SearchButton";
import ChipRectangle from "../../includes/chips/ChipRectangle";
import ChipReport from "../../includes/chips/ChipReport";
import Pagination from "../../includes/pagination/Pagination";
import ClientCard from "./ClientCard";
import ReportCard from "./ReportCard";
import { Context } from "../../../contexts/Store";
import DashboardHeader from "../../genaral/DashboardHeader";
import { expert } from "../../../axiosConfig";

const ReportsListPage = () => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [patientList, setPatientList] = useState([]);
    const [Pendingreports, setPendingreports] = useState([]);
    const [error, SetError] = useState([]);

    useEffect(() => {
        expert
            .get("/completed-session-reports", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setPatientList(response.data.data.patients);
                    setList(response.data.data.patients);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });

        expert
            .get("/pending-reports", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setPendingreports(response.data.data.reports);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
    }, []);

    const [searchItem, setSearchItem] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [currentList, setCurrentList] = useState([]);
    const [list, setList] = useState(patientList);

    const itemsPerPage = 10;
    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const navigate = useNavigate();

    const handleSearch = (value) => {
        setSearchItem(value);
        setList(
            patientList?.filter((client) =>
                client.name.toLowerCase().startsWith(value.toLowerCase())
            )
        );
        setActivePage(1);
    };

    const handleFollowUp = () => {
        dispatch({
            type: "FOLLOWUP_ACTIVE",
            isFollowUpActive: true,
        });
        navigate("/");
    };

    useEffect(() => {
        setCurrentList(list?.slice(indexOfFirstItem, indexOfLastItem));
    }, [list, activePage, patientList]);

    useEffect(() => {
        return () => {
            dispatch({
                type: "FOLLOWUP_ACTIVE",
                isFollowUpActive: false,
            });
        };
    }, [state.isFollowUpActive]);

    return (
        <>
            <Main>
                <MainLeft>
                    <TableContainer>
                        <TableTitleContainer>
                            <TableTitle>Clients</TableTitle>
                            <SearchBox>
                                <SearchButton
                                    searchItem={searchItem}
                                    handleSearch={handleSearch}
                                />
                            </SearchBox>
                        </TableTitleContainer>
                        {patientList?.length > 0 ? (
                            <TableCover>
                                <TableHeading>
                                    <Items>
                                        <Label className="slno" type="no">
                                            No
                                        </Label>
                                        <Label type="name">Client Name</Label>
                                        <Label type="date">Concern</Label>
                                        <Label type="date">Date</Label>
                                        <Label type="report">Case ID</Label>
                                        <Label type="report">Case Report</Label>
                                    </Items>
                                </TableHeading>
                                <ClientCardCover>
                                    {currentList?.length > 0 ? (
                                        currentList.map((client, index) => {
                                            return (
                                                <ClientCard
                                                    key={client.id}
                                                    number={
                                                        indexOfFirstItem +
                                                        index +
                                                        1
                                                    }
                                                    client={client}
                                                />
                                            );
                                        })
                                    ) : (
                                        <p style={{ textAlign: "center" }}>
                                            No clients found
                                        </p>
                                    )}
                                </ClientCardCover>
                            </TableCover>
                        ) : (
                            <EmptyCover>
                                <img
                                    src={
                                        require("../../../assets/image/reports/empty_client.svg")
                                            .default
                                    }
                                    alt="empty"
                                />
                                <p>
                                    The clients after each cases will shown here
                                </p>
                            </EmptyCover>
                        )}
                    </TableContainer>
                    <Pagination
                        list={list}
                        itemsPerPage={itemsPerPage}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                </MainLeft>

                <MainRight>
                    <CardContainer>
                        <CardTitleContainer>
                            <CardTitle>Pending</CardTitle>
                            <ViewLink to="/reports-list/case-reports">
                                View All
                            </ViewLink>
                        </CardTitleContainer>
                        {Pendingreports?.length > 0 ? (
                            Pendingreports.slice(0, 4).map((client) => (
                                <ReportCard
                                    key={client.id}
                                    client={client}
                                    type="case_report"
                                />
                            ))
                        ) : (
                            <EmptyCard>
                                <img
                                    src={
                                        require("../../../assets/image/reports/empty_case_report.svg")
                                            .default
                                    }
                                    alt="empty"
                                />
                                <p>No case reports to be added</p>
                            </EmptyCard>
                        )}
                    </CardContainer>
                </MainRight>
            </Main>
        </>
    );
};

export default ReportsListPage;

const Main = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1268px) {
        flex-direction: column;
        margin-bottom: 60px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 60px;
    }
`;
const MainLeft = styled.div`
    width: 70%;
    padding: 30px;

    @media all and (max-width: 1268px) {
        width: 100%;
        padding-bottom: 0;
    }
`;
const ChipContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
`;
const Button = styled.button`
    width: 18.5%;
`;
const TableContainer = styled.div`
    margin-bottom: 12px;
`;
const TableTitleContainer = styled.div`
    margin-bottom: 8px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TableTitle = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.dark_500};
`;
const SearchBox = styled.div`
    display: flex;
    align-items: center;
`;
const TableCover = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 10px;
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const EmptyCover = styled.div`
    height: 664px;
    width: 100%;
    background: ${THEME_COLORS.light_200};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 82px;
        height: 82px;
        margin-bottom: 42px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        color: ${THEME_COLORS.dark_400};
    }
`;
const TableHeading = styled.div`
    min-width: 100%;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
    height: 56px;
`;
const Items = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    height: 100%;
`;
const Label = styled.li`
    font-size: 14px;
    font-weight: 500;
    color: ${THEME_COLORS.dark_200};
    /* padding-left: 14px; */
    padding: 10px;
    width: ${(props) =>
        props.type === "no"
            ? "5%"
            : props.type === "count"
            ? "8%"
            : props.type === "date"
            ? "15%"
            : props.type === "name"
            ? "15%"
            : props.type === "report"
            ? "20%"
            : "10%"};
    text-align: ${(props) => (props.type === "count" ? "center" : "left")};
    /* &.slno {
        padding-left: 10px;
    } */

    @media all and (max-width: 1268px) {
        width: ${(props) =>
            props.type === "no"
                ? "5%"
                : props.type === "count"
                ? "20%"
                : props.type === "date"
                ? "20%"
                : props.type === "name"
                ? "20%"
                : props.type === "report"
                ? "25%"
                : "10%"};
        &.tab-view {
            display: none;
        }
    }
`;
const ClientCardCover = styled.div``;
const MainRight = styled.div`
    width: 30%;
    padding: 30px 30px 26px 16px;

    @media all and (max-width: 1268px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 20px 20px 26px;
    }
`;
const CardContainer = styled.div`
    width: 100%;
    padding: 13px 14px;
    margin-bottom: 24px;
    background-color: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_200};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    border-radius: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 1268px) {
        width: 48%;
        margin-bottom: 0;
    }
`;
const CardTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;
const CardTitle = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 18px;
    margin-right: 8px;
    color: ${THEME_COLORS.text_title};
`;
const EmptyCard = styled.div`
    height: 342px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 42px;
        height: 42px;
        margin-bottom: 20px;
    }
`;
const Line = styled.div`
    flex-grow: 1;
    border: 1px solid ${THEME_COLORS.light_300};
`;
const ViewButton = styled.div`
    height: 36px;
    &.empty {
        opacity: 0.35;
        pointer-events: none;
    }
`;
const ViewLink = styled(Link)`
    color: #366ee3;
    font-size: 14px;
`;
