import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import down from '../../../../assets/image/reports/down-arrow.svg'
import up from '../../../../assets/image/reports/up-arrow.svg'
import { Context } from '../../../../contexts/Store'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'

function PresentingIllness(props) {
  const {
    setformType,
    presentingIllnessFormFields,
    setPresentingIllnessFormFields,
    initialPresentingIllnessValues,
    handlePresentingIllnessFormSubmit,
    detectChanges,
  } = props

  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const { id } = useParams()
  const {
    precipitatingEvents,
    relevantHistory,
    onset,
  } = presentingIllnessFormFields

  const precipitatingRef = useRef(null)
  const relevantRef = useRef(null)
  const OnsetRef = useRef(null)

  // const [precipitating, setPrecipitating] = useState('')
  // const [relevant, setRelevant] = useState('')
  // const [Onset, setOnset] = useState('')

  const [showErrors, setShowErrors] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [item, setitem] = useState('')
  const [loading, setLoading] = useState(false)

  const handleKeyUp = (e, refType) => {
    if (e.target.value !== '') {
      const textareaLineHeight = 24
      const textareaMaxHeight = textareaLineHeight * 3
      const newRows = Math.ceil(e.target.scrollHeight / textareaLineHeight)
      if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
        refType.current.rows = newRows
      }
    } else {
      refType.current.rows = 1
    }
  }

  const drop = [
    {
      id: 1,
      name: 'Acute',
    },
    {
      id: 2,
      name: 'Insidious',
    },
    {
      id: 3,
      name: 'Unknown',
    },
  ]

  const handleCancel = () => {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        formType: 'Chief complaints & Summary',
      },
    })
  }

  // useEffect(() => {
  //     console.log(showErrors, "showErrors in presenting illness ");
  // }, [showErrors]);

  return (
    <>
      <FormContainer>
        <Top>
          <div>
            <FormTitle>History of Presenting Illness</FormTitle>
            <Label>Describe current health concern or medical issue</Label>
          </div>
          {/* <ButtonContainer >Save</ButtonContainer> */}
        </Top>

        <FormInputs>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: precipitatingEvents && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="Precipitating events"
                  className={
                    precipitatingEvents ? 'input-label show' : 'input-label'
                  }
                >
                  Precipitating events
                </FormLabel>
                <FormText
                  id="Precipitating events"
                  name="events"
                  value={precipitatingEvents}
                  type="text"
                  rows="1"
                  ref={precipitatingRef}
                  onChange={(e) => {
                    setPresentingIllnessFormFields((prev) => ({
                      ...prev,
                      precipitatingEvents: e.target.value,
                    }))
                    handleKeyUp(e, precipitatingRef)
                  }}
                  placeholder="Precipitating events"
                  className={
                    precipitatingEvents
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !precipitatingEvents && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: relevantHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="history"
                  className={
                    relevantHistory ? 'input-label show' : 'input-label'
                  }
                >
                  Relevant negative history
                </FormLabel>
                <FormText
                  id="history"
                  name="history"
                  value={relevantHistory}
                  type="text"
                  rows="1"
                  ref={relevantRef}
                  onChange={(e) => {
                    setPresentingIllnessFormFields((prev) => ({
                      ...prev,
                      relevantHistory: e.target.value,
                    }))
                    handleKeyUp(e, relevantRef)
                  }}
                  placeholder="Relevant negative history"
                  className={
                    relevantHistory ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !relevantHistory && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper onset"
              style={{ height: onset && 'auto' }}
              onClick={() => {
                setDropDown(!dropDown)
              }}
            >
              <Left
                onClick={() => {
                  setDropDown(!dropDown)
                }}
                className="arrow"
              >
                <FormText
                  disabled
                  id="Onset"
                  name="Onset"
                  value={
                    onset ? drop.find((item) => item.id == onset)?.name : null
                  }
                  type="text"
                  rows="1"
                  ref={OnsetRef}
                  onChange={(e) => {
                    // setOnset(e.target.value)
                    handleKeyUp(e, OnsetRef)
                  }}
                  onClick={() => {
                    setDropDown(!dropDown)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Onset"
                  className={
                    (onset ? 'textarea-value active' : 'textarea-value',
                    'onset')
                  }
                ></FormText>
                <IconContainer
                  onClick={() => {
                    setDropDown(!dropDown)
                  }}
                >
                  {dropDown ? <Icon src={up} /> : <Icon src={down} />}
                </IconContainer>
              </Left>
            </InputWrapper>
            {showErrors && !item && (
              <Error className="error">This field is required.</Error>
            )}
            <Contaner className={dropDown && 'active'}>
              {drop.map((item) => (
                <DateContaner
                  key={item.id}
                  onClick={() => {
                    setitem(item.name)
                    setPresentingIllnessFormFields((prev) => ({
                      ...prev,
                      onset: item.id,
                    }))
                    setDropDown(false)
                  }}
                >
                  <Date key={item.id}>{item.name}</Date>
                </DateContaner>
              ))}
            </Contaner>
          </Wrapper>
          <Buttons>
            <ButtonTypeOne
              className={'case'}
              text="Back"
              color={THEME_COLORS.dark_500}
              bkgcolor={THEME_COLORS.white}
              border={THEME_COLORS.light_400}
              onclick={() => {
                if (
                  detectChanges(
                    initialPresentingIllnessValues,
                    presentingIllnessFormFields,
                  )
                ) {
                  setPresentingIllnessFormFields(initialPresentingIllnessValues)
                  handleCancel()
                } else {
                  handleCancel()
                }
              }}
            />
            <ButtonTypeOne
              text="Save"
              color={THEME_COLORS.white}
              bkgcolor={THEME_COLORS.chips_blue_on_container_2}
              onclick={() => {
                // console.log('success')
                handlePresentingIllnessFormSubmit()
              }}
            />
          </Buttons>
        </FormInputs>
      </FormContainer>
    </>
  )
}

export default PresentingIllness

const FormContainer = styled.div`
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
`
const FormInputs = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Wrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`
const InputWrapper = styled.div`
  &.onset {
    cursor: pointer;
  }
`

const IconContainer = styled.div``
const Icon = styled.img``

const Left = styled.div`
  flex-grow: 1;
  &.arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Error = styled.small`
  margin-top: 5px;
`
const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
  &.onset {
    font-weight: 400;
    font-size: 16px;
    color: #3e3e53;
    background-color: #f4f4f4;
    border: none;
    outline: none;
    resize: none;
    width: max-content;
    cursor: pointer;
  }
`

const Label = styled.p`
  /* width: 40%; */
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`
const ButtonContainer = styled.div`
  color: #fff;
  background-color: #366ee3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 30%; */
  margin: 0 auto;
  div.one {
    margin-right: 20px;
  }
`

const Contaner = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  padding: 8px;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 10;
  display: none;
  &.active {
    display: block;
  }
`
const DateContaner = styled.div`
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  &:hover {
    background: ${THEME_COLORS.light_100};
  }
`
const Date = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-size: 16px;
`
