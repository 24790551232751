import React, { useContext } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import verified from "../../../../assets/image/check no-verified.svg";
import two from "../../../../assets/image/check no-two-verified.svg";

import RescheduleSessionpage from "../RescheduleSessionpage";
// import { Context } from "../../../../contexts/Store";

const RescheduleModalStructure = ({
  scheduleNextSession,
  title,
  description,
  handleModal,
  setTimeSlot,
  timeSlot,
  large,
  isModal,
  setModal,
  isRescheduleModal,
  setRescheduleModal,
}) => {
  const closeModal = (e) => {
    const element = document.querySelector(".click-form");
    if (!element.contains(e.target)) {
      handleModal();
    }
  };
  // const { state, dispatch } = useContext(Context);
  // const { isModal } = state;
  return (
    isModal && (
      <>
        <Background onClick={(e) => closeModal(e)}></Background>

        <Form
          scheduleNextSession={scheduleNextSession}
          large={large}
          className="click-form"
        >
          <Top>
            <Left>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </Left>
            <Right>
              {" "}
              <ReasonDiv>
                <Number>
                  <img src={verified} alt="Image" />
                </Number>
                <ReasonText>Reason</ReasonText>
              </ReasonDiv>
              <Line>
                <img
                  src={
                    require("../../../../assets/image/sessionsinglepage/Line.svg")
                      .default
                  }
                  alt="Image"
                />
              </Line>
              <ReasonDiv>
                <Number>
                  <img src={two} alt="Image" />
                </Number>
                <ReasonText>Choose Slot</ReasonText>
              </ReasonDiv>
            </Right>
          </Top>
          <RescheduleSessionpage
            handleModal={handleModal}
            setTimeSlot={setTimeSlot}
            timeSlot={timeSlot}
            setModal={setModal}
            isModal={isModal}
            isRescheduleModal={isRescheduleModal}
            setRescheduleModal={setRescheduleModal}
          />
        </Form>
      </>
    )
  );
};

export default RescheduleModalStructure;

const Background = styled.div`
  top: 0;
  left: 0;
  z-index: 1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  opacity: 0.4;
`;
const Form = styled.form`
  width: ${(props) => (props.large ? "54%" : "46%")};
  background-color: ${THEME_COLORS.white};
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
  padding: 40px;
  position: fixed;
  top: 10px;
  left: 400px;
  z-index: 2;
  @media all and (max-width: 1280px) {
    width: ${(props) => (props.large ? "68%" : "58%")};
    left: 270px;
  }
  @media all and (max-width: 1080px) {
    width: ${(props) => (props.large ? "68%" : "58%")};
    width: 706px;
    left: 230px;
    /* top: 0; */
    padding: 20px;
  }
  @media all and (max-width: 980px) {
    width: ${(props) => (props.large ? "85%" : "68%")};
    padding: 20px;
  }
`;
const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 4px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const Left = styled.div``;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
`;
const ReasonDiv = styled.div`
  display: flex;
  align-items: center;
`;
const Number = styled.div`
  width: 20px;
  margin-right: 5px;
  img {
    display: block;
    width: 100%;
  }
`;
const ReasonText = styled.h6`
  color: ${THEME_COLORS.text_title};
  font-size: 14px;
`;
const Line = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
