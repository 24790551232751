import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig.js";
import ChipRectangle from "../chips/ChipRectangle.jsx";
import ButtonTypeOne from "../buttons/ButtonTypeOne.jsx";
import { Context } from "../../../contexts/Store.js";
import { cases, expert } from "../../../axiosConfig.js";

const SessionStatusModal = ({
    selectedUpdateSessionId,
    setSelectedUpdateSessionId,
    setIsUpdateStatusModal,
    getSessions,
}) => {
    const [activeOption, setActiveOption] = useState(1);
    const { state, context } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

    const [sessionDetails, setSessionDetails] = useState({});
    const [remarks, setRemarks] = useState("");
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const options = [
        {
            id: 1,
            option: "Session Completed",
        },
        // {
        //     id: 2,
        //     option: "Session Cancelled",
        // },
        {
            id: 2,
            option: "Session Disconnected",
        },
        {
            id: 3,
            option: "Client not shown up",
        },
        {
            id: 4,
            option: "Other reason",
        },
    ];

    const getSessionDetails = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        expert
            .get(`/sessions/session-basic-details/${selectedUpdateSessionId}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSessionDetails(data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error.response?.data?.data?.message);
            });
    };

    const handleSubmit = () => {
        setIsSubmissionLoading(true);
        let accessToken = state.user_details.access_token;

        const data = {
            session_status: activeOption,
            reason: remarks,
        };

        cases
            .put(
                `/sessions/session-status-update/${selectedUpdateSessionId}/`,
                data,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then(function (response) {
                const { StatusCode } = response.data;

                if (StatusCode === 6000) {
                    if (errorText) {
                        setErrorText("");
                    }
                    setSuccessText("Status updated successfully");
                    getSessions();
                    setTimeout(() => {
                        setIsUpdateStatusModal(false);
                    }, 500);
                    setIsSubmissionLoading(false);
                } else {
                    setErrorText("Some error occured. please try later");
                    setIsSubmissionLoading(false);
                }
            })
            .catch((error) => {
                setErrorText(error?.response?.data?.data?.message);
                setTimeout(() => {
                    setIsSubmissionLoading(false);
                }, 1000);
            });
    };

    useEffect(() => {
        getSessionDetails();
    }, []);

    useEffect(() => {
        if (activeOption == 1) {
            if (remarks !== "") {
                setRemarks("");
            }
        }
    }, [activeOption, remarks]);

    useEffect(() => {
        if (errorText) {
            setTimeout(() => {
                setErrorText("");
            }, 1500);
        }
    }, [errorText]);
    useEffect(() => {
        if (successText) {
            setTimeout(() => {
                setSuccessText("");
            }, 1500);
        }
    }, [successText]);

    return (
        <Background>
            <Container className="outer-click">
                <Title>Session Status</Title>
                <ClientBox>
                    <Top>
                        <Left>
                            <img
                                src={
                                    sessionDetails?.patient?.profile_photo
                                        ? sessionDetails?.patient?.profile_photo
                                        : require("../../../assets/image/session_summary/profile_picture.svg")
                                              .default
                                }
                                alt="profile"
                            />
                        </Left>
                        <Right>
                            <Text>{sessionDetails?.concern}</Text>
                            {/* <Name>
                                Session ID: {sessionDetails?.session_id}
                            </Name> */}

                            <Name>{sessionDetails?.patient?.full_name}</Name>
                        </Right>
                    </Top>
                    <Bottom>
                        <ChipRectangle
                            image="calendar.svg"
                            image_width="16px"
                            content={sessionDetails?.case_date}
                            bkgcolor={THEME_COLORS.light_200}
                            color={THEME_COLORS.dark_300}
                            pd="4px 8px"
                            br="4px"
                            font_size="12px"
                            font_weight="400"
                        />
                        <ChipRectangle
                            image="time.svg"
                            content={sessionDetails?.session_time}
                            bkgcolor={THEME_COLORS.light_200}
                            color={THEME_COLORS.dark_300}
                            pd="4px 8px"
                            br="4px"
                            font_size="12px"
                            font_weight="400"
                            image_width="16px"
                            mr_left="8px"
                        />

                        {/* <ChipRectangle
                            image="video_green.svg"
                            bkgcolor={THEME_COLORS.chips_green_container}
                            mr_left="10px"
                            pd="4px"
                            br="4px"
                            image_width="16px"
                        /> */}
                    </Bottom>
                </ClientBox>
                <OptionBox>
                    {options.map((item) => {
                        return (
                            <Option
                                onClick={() => setActiveOption(item.id)}
                                className={activeOption === item.id && "active"}
                            >
                                {item.option}
                            </Option>
                        );
                    })}
                </OptionBox>
                {activeOption !== 1 && (
                    <MiddleDiv>
                        <MidHeading>Reason</MidHeading>
                        <FormContainer
                        // className={isFocus ? "active" : ""}
                        >
                            <Input
                                type="text"
                                id="input"
                                placeholder="Enter here"
                                value={remarks}
                                onChange={(e) => {
                                    setRemarks(e.target.value);
                                }}
                                // onFocus={() => setIsFocus(true)}
                                // onBlur={() => setIsFocus(false)}
                                // required
                            />
                        </FormContainer>
                    </MiddleDiv>
                )}

                <div style={{ height: "20px !important", marginTop: "6px" }}>
                    {errorText && <ErrorDiv>{errorText}</ErrorDiv>}
                    {successText && <SuccessDiv>{successText}</SuccessDiv>}
                </div>
                <ButtonContainer>
                    <ButtonTypeOne
                        text="Cancel"
                        color={THEME_COLORS.dark_500}
                        bkgcolor={THEME_COLORS.white}
                        border={THEME_COLORS.light_400}
                        pd="10px 20px"
                        onclick={() => {
                            setIsUpdateStatusModal(false);
                            if (selectedUpdateSessionId) {
                                setSelectedUpdateSessionId("");
                            }
                        }}
                    />
                    <ButtonTypeOne
                        text="Next"
                        color={THEME_COLORS.white}
                        bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                        pd="10px 20px"
                        onclick={() => {
                            if (!activeOption) {
                                setErrorText("Please select an option");
                            } else if (activeOption !== 1 && !remarks) {
                                setErrorText("Please enter a reason");
                            } else {
                                if (!isSubmissionLoading) {
                                    handleSubmit();
                                }
                            }
                        }}
                    />
                </ButtonContainer>
            </Container>
        </Background>
    );
};

export default SessionStatusModal;

const Background = styled.div`
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Container = styled.div`
    position: absolute;
    width: 470px;
    min-height: 360px;
    max-height: 90vh;
    overflow-y: scroll;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
`;
const Title = styled.h4`
    /* font-weight: 700; */
    font-family: "dm_sans_bold";

    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    /* margin-bottom: 20px; */
    margin-bottom: 8px;
`;
const ClientBox = styled.div`
    padding: 12px;
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    background: ${THEME_COLORS.white};
    margin-bottom: 20px;
    margin-top: 16px;
`;
const Top = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
`;
const Left = styled.div`
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 15px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;
const Right = styled.div``;
const Text = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 2px;
`;
const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 2px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Bottom = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
`;
const OptionBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-bottom: -12px;
`;
const Option = styled.div`
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 8px 16px;
    border: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    color: ${THEME_COLORS.text_title};
    font-weight: 500;
    font-size: 14px;
    border-radius: 53px;
    width: 189px;
    height: 36px;
    /* box-sizing: border-box; */
    text-align: center;
    cursor: pointer;
    &.active {
        color: ${THEME_COLORS.chips_blue_on_container_2};
        background: ${THEME_COLORS.chips_blue_container};
        border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const MiddleDiv = styled.div`
    /* box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 14px 14px 10px 14px;
    margin-bottom: 18px; */
    margin-top: 20px;
`;
const MidHeading = styled.h6`
    font-family: "DM_sans_bold";
    color: #3e3e53;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid #f4f4f4;
    background-color: #f4f4f4;
    transition: all 0.2s ease-in-out 0s;
    border-radius: 10px;
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
    }
`;
const Input = styled.textarea`
    font-size: 14px;
    width: 100%;
    height: 18px;
    line-height: 1.4em;
    font-family: "DM_sans";
    color: ${THEME_COLORS.black};
    height: 59px;
    &:focus {
    }
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-resizer {
        display: none;
    }
`;
const ErrorDiv = styled.div`
    font-size: 14px;
    font-family: "DM_sans";
    color: red;
`;
const SuccessDiv = styled.div`
    font-size: 14px;
    font-family: "DM_sans";
    color: green;
`;
