import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME_COLORS } from "../../ThemeConfig";
import Header from "./Header";
//
export default function Page404() {
  return (
    <>
      <Header />
      <Container>
        <Wrapper>
          <ImageContainer>
            <Image src={require("../../assets/image/404.png")} />
          </ImageContainer>
          <Content>
            <Title>Page Not Found</Title>
            <Description>
              We’re sorry, the page you requested could not be found please go
              back to the homepage
            </Description>
            <Button to="/dashboard">
              <img
                src={require("../../assets/image/left-arrow.svg").default}
                alt="left-arrow"
              />
              Back To Home
            </Button>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
const Container = styled.div`
  padding: 150px 0;
`;
const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  @media all and (max-width: 640px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const ImageContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Image = styled.img`
  width: 100%;
  display: block;
`;
const Content = styled.div`
  width: 420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h4`
  font-family: "DM_sans_bold";
  font-size: 32px;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;
const Description = styled.p`
  font-family: "DM_sans_medium";
  font-size: 16px;
  text-align: center;
  color: ${THEME_COLORS.text_label};
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
`;
const Button = styled(Link)`
  display: inline-block;
  display: flex;
  gap: 10px;
  border: 2px solid ${THEME_COLORS.light_400};
  padding: 10px 20px;
  border-radius: 32px;
  font-size: 14px;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.dark_500};
  @media all and (max-width: 480px) {
    padding: 8px 16px;
  }

  img {
    width: 7px;
  }
`;
