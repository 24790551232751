import React, {useState} from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig.js'
import ButtonTypeOne from '../buttons/ButtonTypeOne.jsx';


const SessionDisconnectModal = () => {

    const [activeOption,setActiveOption] = useState(1)
    const [reason, setReason] = useState("")

    const options =[
        {
            id: 1,
            option: "Internet Issues"
        },
        {
            id: 2,
            option: "Google meet Issues"
        },
        {
            id: 3,
            option: "Illness or emergency"
        },
        {
            id: 4,
            option: "Unforeseen circumstances"
        },
        {
            id: 5,
            option: "Other"
        }
    ]

  return (
    <Background >
        <Container className="outer-click">
            <Title>Session Status</Title>
            <ReasonBox>
                <SubTitle>Reason for Disconnet</SubTitle>
                <OptionBox>
                    {
                        options.map(item=>{
                            return(
                                <Label key={item.id} onClick={()=>setActiveOption(item.id)}>{item.option}
                                    <input type="radio" name="reason" value={item.id} onClick={()=>setActiveOption(item.id)}/>
                                    <span className={activeOption === item.id && 'active'}></span>
                                </Label>
                            )
                        })
                    }
                </OptionBox>
                <InputBox>
                    <input 
                        type="text" 
                        placeholder='Enter your answer' 
                        onChange = {(e)=>setReason(e.target.value)}
                    />
                </InputBox>
            </ReasonBox>           
            <ButtonContainer>
                <ButtonTypeOne 
                    text="Close" 
                    color={THEME_COLORS.dark_500}
                    bkgcolor={THEME_COLORS.white} 
                    border={THEME_COLORS.light_400}
                    // onclick ={handleClose}
                />
                <ButtonTypeOne 
                    text="Submit" 
                    color={THEME_COLORS.white}
                    bkgcolor={THEME_COLORS.chips_blue_on_container_2} 
                    // onclick={handleSubmit}
                />
            </ButtonContainer>
        </Container>
    </Background>
  )
}

export default SessionDisconnectModal

const Background = styled.div`
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Container = styled.div`
    position: absolute;
    width: 589px;
    // height: 553px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
`
const Title = styled.h4`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`
const ReasonBox = styled.div`
    padding: 24px;
    border: 1px solid ${THEME_COLORS.light_200};
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    border-radius: 10px;
`
const SubTitle = styled.h5`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    text-transform: uppercase;
    margin-bottom: 24px;
`
const OptionBox = styled.div``
const Label = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    padding-left: 35px;
    input{
        position: absolute;
        opacity: 0;
    }
    span{
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 2px solid ${THEME_COLORS.light_400};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: "";
            // position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: ${THEME_COLORS.chips_green_on_container};
            display: none
       }
    &.active{
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
        &:after{
            display: block;
        }
    }
    }
`
const InputBox = styled.div`
    width: 100%;
    input{
        width: 100%;
        padding: 17px 12px;
        background: ${THEME_COLORS.light_200};
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
    }
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
`
