import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import NotificationCard from "./NotificationCard";
import DashboardHeader from "../../genaral/DashboardHeader";
import { Context } from "../../../contexts/Store";
import { general, socketUrl } from "../../../axiosConfig";
import nodata from "../../../assets/image/dashboard/no-data.svg";

const Notification = () => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const userId = state.user_details.user_id;
    const [notifications, setNotifications] = useState([]);
    const [error, SetError] = useState([]);
    const [query, SetQuery] = useState();

    useEffect(() => {
        const socket = new WebSocket(`${socketUrl}/notifications/${userId}/`);

        socket.onopen = () => {
            console.log("WebSocket connected");
        };

        socket.onmessage = (event) => {
            console.log("webSocket Received message:", event.data);
            getNotifications();
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed");
        };

        return () => {
            socket.close();
        };
    }, []);

    const getNotifications = () => {
        general
            .get("/notifications", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    filter: query,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setNotifications(response.data.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
    };

    useEffect(() => {
        getNotifications();
    }, [query]);

    const options = [
        {
            id: 2,
            option: "Today",
            query: "today",
        },
        {
            id: 3,
            option: "This Week",
            query: "week_ago",
        },
        {
            id: 4,
            option: "A long time ago",
            query: "long_time_ago",
        },
    ];

    const [activeButton, setActiveButton] = useState(2);
    const [list, setList] = useState(notifications);

    useEffect(() => {
        window.scroll(0, 0);
    });

    const handleMarkAsRead = () => {
        const newList = list.map((item) =>
            item.isread === false ? { ...item, isread: true } : item
        );
        setList(newList);
    };

    return (
        <>
            <Wrapper>
                <Head>
                    <Title>Notifications</Title>
                    <BackLink to="/">
                        <img
                            src={
                                require("../../../assets/image/reports/left_arrow_long_blue.svg")
                                    .default
                            }
                            alt="left arrow"
                        />
                        <span>Back to Dashboard</span>
                    </BackLink>
                </Head>
                <Content>
                    {notifications.length != 0 && (
                        <Top>
                            <Left>
                                {options.map((item) => (
                                    <Button
                                        key={item.id}
                                        onClick={() => {
                                            setActiveButton(item.id);
                                            SetQuery(item.query);
                                        }}
                                        className={
                                            activeButton === item.id && "active"
                                        }
                                    >
                                        {item.option}
                                    </Button>
                                ))}
                            </Left>
                            <Right onClick={handleMarkAsRead}>
                                <img
                                    src={
                                        require("../../../assets/image/notification/done_all.svg")
                                            .default
                                    }
                                    alt="tick"
                                />
                                <span>Mark as all read</span>
                            </Right>
                        </Top>
                    )}
                    <Bottom>
                        {notifications.length != 0 ? (
                            <>
                                {notifications?.map((item) => (
                                    <NotificationCard
                                        item={item}
                                        key={item.id}
                                    />
                                ))}
                            </>
                        ) : (
                            <Nodata className="nodata">
                                <img src={nodata} alt="no-data" />
                                <p>You don't have any new notifications</p>
                            </Nodata>
                        )}
                    </Bottom>
                </Content>
            </Wrapper>
        </>
    );
};

export default Notification;

const Wrapper = styled.div`
    width: 50%;
    margin: 0 auto 55px;
    padding: 32px 20px 20px;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.divider};
    @media all and (max-width: 1440px) {
        width: 60%;
    }
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Head = styled.div`
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.h3`
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.text_title};
`;
const BackLink = styled(Link)`
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    img {
        width: 20px;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        color: ${THEME_COLORS.chips_blue_on_container_2};
        margin-left: 10px;
    }
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const Content = styled.div``;
const Top = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div``;
const Button = styled.button`
    padding: 6px 16px;
    background-color: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.light_300};
    color: ${THEME_COLORS.text_title};
    border-radius: 53px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const Right = styled.div`
    border-bottom: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    display: flex;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
        color: ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const Bottom = styled.div``;
const Nodata = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    img {
        margin-bottom: 10px;
    }
`;
