import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ArrowWhite from '../../../assets/image/sessionsinglepage/whiteArrow.svg'
import { cases, expert, patient } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import CaseReport from '../../includes/sessionsinglepage/CaseReport'
import calenderWhite from '.././../../assets/image/sessionsinglepage/calenderWhite.svg'
import SessionDetailed from './SessionDetailed'

function SessionSinglePage() {
  const { state, dispatch } = useContext(Context)
  const { id } = useParams()
  const access_token = state.user_details.access_token
  const [sessionDetails, setSessionDetails] = useState({})
  const [caseDetails, setCaseDetails] = useState({})
  const [patientDetails, setPatientDetails] = useState({})
  const [selectedSession, setSelectedSession] = useState({})
  const [recommendedVideos, setRecommendedVideos] = useState([])
  const [caseId, setCaseId] = useState('')
  const [selectedId, setSelectedId] = useState(id)
  const [
    isBasicFormsSubmittedFields,
    setIsBasicFormsSubmittedFields,
  ] = useState({
    isChiefComplaintsSubmitted: false,
    isPresentingHistorySubmitted: false,
    isPastIllnessSubmitted: false,
    isFamilyHistorySubmitted: false,
    isPersonalHistorySubmitted: false,
    isPremorbidSubmitted: false,
    isMentalStatusSubmitted: false,
  })

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const today = new Date()
  const todaysDate = today.getDate()
  const currentMonth = months[today.getMonth()]
  const currentYear = today.getFullYear()

  const [isItem, setItem] = useState()
  const [isReview, setReview] = useState(false)
  const [isSummary, setSummary] = useState(true)
  const [finalCaseReport, setFinalCaseReport] = useState(false)
  const [isReschedule, setReschedule] = useState(false)
  const [isSession, setSession] = useState(0)

  const getSessionDetails = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    expert
      .get(`/sessions/session/${selectedId}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setSessionDetails(data.data)
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const getCaseDetails = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/case-details/${selectedId}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setCaseDetails(data)
          setIsBasicFormsSubmittedFields({
            isChiefComplaintsSubmitted: data.is_chief_complaints,
            isPresentingHistorySubmitted: data.is_present_illness,
            isPastIllnessSubmitted: data.is_past_illness,
            isFamilyHistorySubmitted: data.is_family_history,
            isPersonalHistorySubmitted: data.is_personal_history,
            isPremorbidSubmitted: data.is_premorbid_personality,
            isMentalStatusSubmitted: data.is_mental_status_examination,
          })
        } else {
          alert('Some error occured, try again later')
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const getPatientDetails = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    patient
      .get(`/patient-details/${selectedId}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setPatientDetails(data)
          // setSessionDetails(data.data);
        } else {
          // alert("");
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const getRecommendedVideos = () => {
    let accessToken = state.user_details.access_token
    cases
      .get(`/recommended-videos/${selectedId}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setCaseId(data.case_report_id)
          setRecommendedVideos(data.videos)
          // console.log(data.videos, "data.videos");
        } else {
          setRecommendedVideos('')
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  useEffect(() => {
    if (id) {
      getSessionDetails()
      getCaseDetails()
      getPatientDetails()
      getRecommendedVideos()
    }
  }, [id, selectedId])

  useEffect(() => {
    if (sessionDetails?.session_records?.length > 0) {
      setSelectedSession(
        sessionDetails?.session_records?.find(
          (record) => record.session_record_status === 'completed',
        ),
      )
    }
  }, [sessionDetails])

  return (
    <>
      <MainCOnatiner>
        <TopContainer>
          <Wrapper>
            <Top>
              <Left>
                <ArrowContainer>
                  <Arrow src={ArrowWhite} alt="Arrow" />
                </ArrowContainer>

                <Heading>Back to DashBoard</Heading>
              </Left>
              <Right>
                <DateContainer>
                  <CalenderIcon>
                    <Icon src={calenderWhite} alt="icon" />
                  </CalenderIcon>
                  <DateText>
                    Today, {currentMonth} {todaysDate}, {currentYear}
                  </DateText>
                </DateContainer>
              </Right>
            </Top>
          </Wrapper>
        </TopContainer>
        <CaseReportContainer>
          <CaseReport
            finalCaseReport={finalCaseReport}
            setFinalCaseReport={setFinalCaseReport}
            sessionDetailsData={sessionDetails}
            caseId={caseId}
          />
          <BottomContainer>
            <Wrapper>
              <SessionListContainer>
                {sessionDetails?.session_records?.map((item) => (
                  <>
                    {item.session_record_status.toLowerCase() == 'completed' ? (
                      <CompletedSessionChip
                        key={item.id}
                        className={
                          selectedSession?.id === item.id ? 'active' : ''
                        }
                        onClick={() => {
                          setSelectedSession(item)
                          setSelectedId(item.id)
                        }}
                      >
                        <TextCompleted>
                          Session {item.current_session_number}
                        </TextCompleted>
                      </CompletedSessionChip>
                    ) : (
                      <SessionChipDisabled>
                        <Text
                          className={
                            selectedSession?.id === item.id ? 'active' : ''
                          }
                        >
                          Session {item.current_session_number}
                        </Text>
                      </SessionChipDisabled>
                    )}
                  </>
                ))}
              </SessionListContainer>
              <>
                <SessionDetailed
                  selectedSession={selectedSession}
                  sessionDetails={sessionDetails}
                  patientDetails={patientDetails}
                  caseDetails={caseDetails}
                  gender={patientDetails.gender}
                  isBasicFormsSubmittedFields={isBasicFormsSubmittedFields}
                  recommendedVideos={recommendedVideos}
                />
              </>
            </Wrapper>
          </BottomContainer>
        </CaseReportContainer>
      </MainCOnatiner>
    </>
  )
}

export default SessionSinglePage
const MainCOnatiner = styled.div``
const Wrapper = styled.section`
  width: 75%;
  margin: 0 auto;
  @media all and (max-width: 1280px) {
    width: 85%;
  }
`
const TopContainer = styled.div`
  padding: 20px 0px;
  background: ${THEME_COLORS.gradient_1};
  height: 145px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
const Left = styled.div`
  display: flex;
  /* align-items: center; */
`
const ArrowContainer = styled.div``
const Arrow = styled.img``
const Heading = styled.p`
  font-size: 14px;
  color: ${THEME_COLORS.white};
`
const Right = styled.div``
const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${THEME_COLORS.white};
  padding: 8px 15px;
  border-radius: 8px;

  @media all and (max-width: 1200px) {
    cursor: pointer;
  }
`
const CalenderIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`
const Icon = styled.img``
const DateText = styled.p`
  font-size: 14px;
  color: ${THEME_COLORS.white};
`
const CaseReportContainer = styled.div`
  margin-top: -60px;
`
const BottomContainer = styled.div`
  /* background-color: aliceblue; */
  /* padding: 200px 0; */
  /* position: absolute; */
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
`
const SessionListContainer = styled.div`
  display: flex;
  align-items: center;
  /* background-color: #000; */
  margin-bottom: 20px;
`
const SessionChip = styled.div``
const SessionChipDisabled = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 53px;
  text-align: center;
  padding: 8px 16px;
  background-color: ${THEME_COLORS.light_100};
  margin-right: 10px;
  cursor: not-allowed;
  opacity: 0.7;
  &.active {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    border-radius: 53px;
    text-align: center;
    padding: 8px 16px;
  }
`
const Text = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  font-weight: 700;
  &.active {
    color: ${THEME_COLORS.text_title};
  }
`
const TextCompleted = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  font-weight: 700;
`
const ReviewContainer = styled.div`
  margin-bottom: 20px;
`
const Title = styled.h6`
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 20px;
`
const ClientReviews = styled.div`
  text-align: center;
  border: 1px dashed ${THEME_COLORS.light_400};
  padding: 16px 10px;
  border-radius: 10px;
`
const Reviews = styled.h5`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
`
const ReviewsCancel = styled.h5`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  margin-bottom: 10px;
`
const SessionsummaryContainer = styled.div``
const SessionsummaryContainerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const SessionsummaryContainerBottom = styled.div`
  border: 1px dashed ${THEME_COLORS.light_400};
  padding: 32px 10px;
  border-radius: 10px;
  text-align: center;
`
const SessionSummaryText = styled.h6`
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 10px;
`
const SessionID = styled.h6`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  margin-right: 10px;
  span {
    font-size: 14px;
    margin-left: 70px;

    color: ${THEME_COLORS.text_title};
  }
`
const SessionIDLeftDiv = styled.div``
const SessionIDRightDiv = styled.div`
  display: flex;
`
const Print = styled.div`
  margin-right: 20px;
`
const Download = styled.div``
const Share = styled.div``
const CaseReportDiv = styled.div`
  width: 10%;
  margin: 0 auto;
`
const LeftSummary = styled.div``
const RightSummary = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Add = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  margin-bottom: 20px;
`
const CompletedSessionChip = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 53px;
  text-align: center;
  padding: 8px 16px;
  margin-right: 10px;
  &.active {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    border-radius: 53px;
    text-align: center;
    padding: 8px 16px;
  }
`
const FinalCaseReportDiv = styled.div``
const RemaindDiv = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BellIcon = styled.div`
  img {
    display: block;
    width: 100%;
  }
  margin-right: 10px;
`
const Remind = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const CenterChip = styled.div`
  display: flex;
  justify-content: center;
`
