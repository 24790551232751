import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Header() {
    return (
        <Head>
            <LogoBox to={"/"}>
                <img
                    src={
                        require("../../assets/image/loginpage/Logofull.svg")
                            .default
                    }
                    alt="Logo"
                />
            </LogoBox>
        </Head>
    );
}

export default Header;
const Head = styled.div`
    border-bottom: 1px solid #efefef;
    height: 50px;
`;
const LogoBox = styled(Link)`
    display: block;
    width: 10%;
    margin: 20px 0 10px 35px;
    @media all and (max-width: 1440px) {
        width: 15%;
    }
    @media all and (max-width: 1280px) {
        width: 18%;
    }
    img {
        width: 100%;
    }
`;
