import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import RenderingChip from "../../session_summary/modals/RenderingChip";
import { Context } from "../../../../contexts/Store";
import { cases } from "../../../../axiosConfig";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";
import { useParams } from "react-router-dom";
import down from "../../../../assets/image/reports/down-arrow.svg";
import up from "../../../../assets/image/reports/up-arrow.svg";

function MentalStatusExamination({
    mentalStatusFormFields,
    setMentalStatusFormFields,
    initialMentalStatusFormValues,
    handleMentalStatusFormSubmit,
    detectChanges,
    drop,
}) {
    const { id } = useParams();
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [height, setHeight] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [item, setitem] = useState("");

    const [other, setOther] = useState([]);

    const [attitude, setAttitude] = useState([]);
    const [cognitiveStatus, setCognitiveStatus] = useState([]);
    const [attention, setAttention] = useState([]);
    const [orientation, setOrientation] = useState([]);
    const [memory, setMemory] = useState([]);
    const [abstract, setAbstract] = useState([]);
    const [judgment, setJudgment] = useState([]);
    const [general, setGeneral] = useState([]);
    const [intelligence, setIntelligence] = useState([]);
    const [thought, setThought] = useState([]);
    const [Form, setForm] = useState([]);
    const [possession, setPossession] = useState([]);
    const [psychotic, setPsychotic] = useState([]);

    const {
        consciousness,
        rapport,
        appearance,
        handedness,
        eyeToEyeContact,
        facialExpression,
        attitudesTowardsExaminer,
        motorBehaviour,
        cognitiveStatuses,
        attentionAndConcentrations,
        attentionAndConcentrationResult,
        orientations,
        orientationResult,
        memorys,
        memoryResult,
        abstractAbilitys,
        judgments,
        test,
        social,
        personal,
        generalInformations,
        calculations,
        intelligences,
        thoughts,
        stream,
        formIds,
        posessions,
        content,
        objectiveAffect,
        appropriateness,
        range,
        communicability,
        sensoryDistortion,
        sensoryDeception,
        psychoticPhenomenas,
        otherPhenomenas,
        insight,
    } = mentalStatusFormFields;

    const handleCancel = () => {
        dispatch({
            type: "UPDATE_USER",
            user_details: {
                formType: "Chief complaints & Summary",
            },
        });
    };

    const getAttitude = () => {
        if (access_token) {
            cases
                .get("/attitudes-category/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setAttitude(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };
    const getCognitiveStatus = () => {
        if (access_token) {
            cases
                .get("/cognitive-status/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setCognitiveStatus(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };
    const getAttention = () => {
        if (access_token) {
            cases
                .get("/attention-and-concentration/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setAttention(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getOrientation = () => {
        if (access_token) {
            cases
                .get("/orientation/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setOrientation(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getMemory = () => {
        if (access_token) {
            cases
                .get("/memory-status/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setMemory(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error)
                    setShowErrors(error.message);
                });
        }
    };

    const getAbstract = () => {
        if (access_token) {
            cases
                .get("/abstract-ability/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setAbstract(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getJudgement = () => {
        if (access_token) {
            cases
                .get("/judgment/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setJudgment(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getGeneralInformation = () => {
        if (access_token) {
            cases
                .get("/general-information/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setGeneral(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getIntelligence = () => {
        if (access_token) {
            cases
                .get("/intelligence/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setIntelligence(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getThoughts = () => {
        if (access_token) {
            cases
                .get("/thought-category/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setThought(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getForm = () => {
        if (access_token) {
            cases
                .get("/form-category/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setForm(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getPossession = () => {
        if (access_token) {
            cases
                .get("/possession/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setPossession(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getPsychotic = () => {
        if (access_token) {
            cases
                .get("/psychotic-phenomena/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setPsychotic(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    const getOther = () => {
        if (access_token) {
            cases
                .get("/other-phenomena/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode == 6000) {
                        setOther(response.data.data);
                    } else if (StatusCode == 6001) {
                        setShowErrors(response);
                        // console.log(showErrors, 'error')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowErrors(error.message);
                });
        }
    };

    // console.log(showErrors, 'showErrors')

    useEffect(() => {
        getAttitude();
        getCognitiveStatus();
        getAttention();
        getOrientation();
        getMemory();
        getAbstract();
        getJudgement();
        getGeneralInformation();
        getIntelligence();
        getThoughts();
        getForm();
        getPossession();
        getPsychotic();
        getOther();
    }, []);

    // const formData = {
    //     session_record: "name",
    //     consciousness: consciousness,
    //     rapport: rapport,
    //     appearance: appearance,
    //     handedness: handedness,
    //     eye_to_eye_contact: eyeToEye,
    //     facial_expression: facial,
    //     attitude_towards_examiner: attitudeId,
    //     motor_behaviour: motor,
    //     cognitive_status: cognitiveStatusId,
    //     attention_and_concentration: attentionId,
    //     attention_and_concentration_result: attentionResult,
    //     orientation: orientationId,
    //     orientation_result: orientationResult,
    //     memory: memoryId,
    //     memory_result: memoryResult,
    //     abstract_ability: abstractId,
    //     judgment: judgmentId,
    //     test: judgmentTest,
    //     social: social,
    //     personal: personal,
    //     general_information: generalId,
    //     calculations: calculations,
    //     intelligence: intelligenceId,
    //     thought: thoughtId,
    //     stream: stream,
    //     form: FormId,
    //     possession: PossessionId,
    //     content: content,
    //     objective_affect: objective,
    //     appropriateness: appropriateness,
    //     range: range,
    //     communicability: communicability,
    //     sensory_distortion: distortion,
    //     sensory_deception: deception,
    //     psychotic_phenomena: PsychoticId,
    //     other_phenomena: OtherId,
    //     insight: insight,
    // };

    // const handleFormSubmit = () => {
    //     if (consciousness) {
    //         setLoading(true);
    //         cases
    //             .put(`/add-mental-status-examination/${id}/`, formData, {
    //                 headers: {
    //                     Authorization: `Bearer ${access_token}`,
    //                 },
    //             })
    //             .then(function (response) {
    //                 const { StatusCode } = response.data;
    //                 const { access_token, refresh_token, message } =
    //                     response.data.data;
    //                 console.log(response.data.data);
    //                 setShowErrors("");
    //                 setLoading(false);
    //             })
    //             .catch((error) => {
    //                 console.error("Something went wrong", error);
    //                 if (error.response.status === 404) {
    //                     setShowErrors("NOT_FOUND");
    //                 }
    //                 setLoading(false);
    //             });
    //     } else {
    //         setShowErrors("These fields are required");
    //     }
    // };

    // console.log(attitudeId, 'attitudeId')
    return (
        <>
            <FormContainer className={height && "active"}>
                <Top>
                    <div>
                        <FormTitle>Mental Status Examination</FormTitle>
                        <Label>
                            Assess the patient's cognitive and emotional
                            well-being
                        </Label>
                    </div>
                </Top>
                <FormInputs>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: consciousness && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="Chief complaints"
                                    className={
                                        consciousness
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Consciousness
                                </FormLabel>
                                <FormText
                                    id="consciousness"
                                    name="consciousness"
                                    value={consciousness}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            consciousness: e.target.value,
                                        }));
                                    }}
                                    placeholder="Consciousness"
                                    className={
                                        consciousness
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !consciousness && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>

                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: rapport && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="rapport"
                                    className={
                                        rapport
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Rapport
                                </FormLabel>
                                <FormText
                                    id="rapport"
                                    name="rapport"
                                    value={rapport}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            rapport: e.target.value,
                                        }));
                                    }}
                                    placeholder="Rapport"
                                    className={
                                        rapport
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !rapport && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>General appearance and behaviour</Title>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: appearance && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="Chief complaints"
                                    className={
                                        appearance
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Appearance
                                </FormLabel>
                                <FormText
                                    id="appearance"
                                    name="appearance"
                                    value={appearance}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            appearance: e.target.value,
                                        }));
                                    }}
                                    placeholder="Appearance"
                                    className={
                                        appearance
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !appearance && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>

                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: handedness && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="handedness"
                                    className={
                                        handedness
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Handedness
                                </FormLabel>
                                <FormText
                                    id="handedness"
                                    name="handedness"
                                    value={handedness}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            handedness: e.target.value,
                                        }));
                                    }}
                                    placeholder="Handedness"
                                    className={
                                        handedness
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !handedness && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: eyeToEyeContact && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="eyeToEye"
                                    className={
                                        eyeToEyeContact
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Eye to eye contact
                                </FormLabel>
                                <FormText
                                    id="eyeToEye"
                                    name="eyeToEye"
                                    value={eyeToEyeContact}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            eyeToEyeContact: e.target.value,
                                        }));
                                    }}
                                    placeholder="Eye to eye contact"
                                    className={
                                        eyeToEyeContact
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !eyeToEyeContact && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: facialExpression && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="facial"
                                    className={
                                        facialExpression
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Facial expression and posture
                                </FormLabel>
                                <FormText
                                    id="facial"
                                    name="facial"
                                    value={facialExpression}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            facialExpression: e.target.value,
                                        }));
                                    }}
                                    placeholder="Facial expression and posture"
                                    className={
                                        facialExpression
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !facialExpression && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Attitude towards examiner</Title>
                    <RenderingChip
                        array={attitude}
                        selectedChips={attitudesTowardsExaminer}
                        setSelectedChipsKey="attitudesTowardsExaminer"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: motorBehaviour && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="motor"
                                    className={
                                        motorBehaviour
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Motor behaviour
                                </FormLabel>
                                <FormText
                                    id="motor"
                                    name="motor"
                                    value={motorBehaviour}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            motorBehaviour: e.target.value,
                                        }));
                                    }}
                                    placeholder="Motor behaviour"
                                    className={
                                        motorBehaviour
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !motorBehaviour && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Cognitive status</Title>
                    <RenderingChip
                        array={cognitiveStatus}
                        selectedChips={cognitiveStatuses}
                        setSelectedChipsKey="cognitiveStatuses"
                        setFunction={setMentalStatusFormFields}
                    />

                    <Title>Attention and concentration</Title>
                    <RenderingChip
                        array={attention}
                        selectedChips={attentionAndConcentrations}
                        setSelectedChipsKey="attentionAndConcentrations"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{
                                height:
                                    attentionAndConcentrationResult && "auto",
                            }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="attentionResult"
                                    className={
                                        attentionAndConcentrationResult
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Result of tests
                                </FormLabel>
                                <FormText
                                    id="attentionResult"
                                    name="attentionResult"
                                    value={attentionAndConcentrationResult}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            attentionAndConcentrationResult:
                                                e.target.value,
                                        }));
                                    }}
                                    placeholder="Result of tests"
                                    className={
                                        attentionAndConcentrationResult
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !attentionAndConcentrationResult && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Orientation</Title>
                    <RenderingChip
                        array={orientation}
                        selectedChips={orientations}
                        setSelectedChipsKey="orientations"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: orientationResult && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="orientationResult"
                                    className={
                                        orientationResult
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Result of tests
                                </FormLabel>
                                <FormText
                                    id="orientationResult"
                                    name="orientationResult"
                                    value={orientationResult}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            orientationResult: e.target.value,
                                        }));
                                    }}
                                    placeholder="Result of tests"
                                    className={
                                        orientationResult
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !orientationResult && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Memory</Title>
                    <RenderingChip
                        array={memory}
                        selectedChips={memorys}
                        setSelectedChipsKey="memorys"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: memoryResult && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="memoryResult"
                                    className={
                                        memoryResult
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Result of tests
                                </FormLabel>
                                <FormText
                                    id="memoryResult"
                                    name="memoryResult"
                                    value={memoryResult}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            memoryResult: e.target.value,
                                        }));
                                    }}
                                    placeholder="Result of tests"
                                    className={
                                        memoryResult
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !memoryResult && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Abstract ability : Proverbs test</Title>
                    <RenderingChip
                        array={abstract}
                        selectedChips={abstractAbilitys}
                        setSelectedChipsKey="abstractAbilitys"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Title>Judgment</Title>
                    <RenderingChip
                        array={judgment}
                        selectedChips={judgments}
                        setSelectedChipsKey="judgments"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: test && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="judgmentTest"
                                    className={
                                        test
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Test
                                </FormLabel>
                                <FormText
                                    id="judgmentTest"
                                    name="judgmentTest"
                                    value={test}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            test: e.target.value,
                                        }));
                                    }}
                                    placeholder="Test"
                                    className={
                                        test
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !test && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: social && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="social"
                                    className={
                                        social
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Social
                                </FormLabel>
                                <FormText
                                    id="social"
                                    name="social"
                                    value={social}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            social: e.target.value,
                                        }));
                                    }}
                                    placeholder="Social"
                                    className={
                                        social
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !social && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: personal && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="personal"
                                    className={
                                        personal
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Personal
                                </FormLabel>
                                <FormText
                                    id="personal"
                                    name="personal"
                                    value={personal}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            personal: e.target.value,
                                        }));
                                    }}
                                    placeholder="Personal"
                                    className={
                                        personal
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !personal && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>General Information</Title>
                    <RenderingChip
                        array={general}
                        selectedChips={generalInformations}
                        setSelectedChipsKey="generalInformations"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: calculations && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="calculations"
                                    className={
                                        calculations
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Calculations
                                </FormLabel>
                                <FormText
                                    id="calculations"
                                    name="calculations"
                                    value={calculations}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            calculations: e.target.value,
                                        }));
                                    }}
                                    placeholder="Calculations"
                                    className={
                                        calculations
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !calculations && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Intelligence</Title>
                    <RenderingChip
                        array={intelligence}
                        selectedChips={intelligences}
                        setSelectedChipsKey="intelligences"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Title>Thought</Title>
                    <RenderingChip
                        array={thought}
                        selectedChips={thoughts}
                        setSelectedChipsKey="thoughts"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: stream && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="stream"
                                    className={
                                        stream
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Stream
                                </FormLabel>
                                <FormText
                                    id="stream"
                                    name="stream"
                                    value={stream}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            stream: e.target.value,
                                        }));
                                    }}
                                    placeholder="Stream"
                                    className={
                                        stream
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !stream && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Form</Title>
                    <RenderingChip
                        array={Form}
                        selectedChips={formIds}
                        setSelectedChipsKey="formIds"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Title>Possession</Title>
                    <RenderingChip
                        array={possession}
                        selectedChips={posessions}
                        setSelectedChipsKey="posessions"
                        setFunction={setMentalStatusFormFields}
                    />
                    {/* <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: content && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="content"
                  className={content ? 'input-label show' : 'input-label'}
                >
                  Content
                </FormLabel>
                <FormText
                  id="content"
                  name="content"
                  value={content}
                  type="text"
                  rows="1"
                  onChange={(e) => {
                    setContent(e.target.value)
                  }}
                  placeholder="Content"
                  className={
                    content ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !content && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper> */}
                    <Wrapper className="onset">
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: content && "auto" }}
                            onClick={() => {
                                setDropDown(!dropDown);
                            }}
                        >
                            <Left
                                onClick={() => {
                                    setDropDown(!dropDown);
                                }}
                                className="arrow"
                            >
                                <FormText
                                    disabled
                                    id="content"
                                    name="content"
                                    value={
                                        content
                                            ? drop.find(
                                                  (item) => item.id == content
                                              )?.name
                                            : null
                                    }
                                    type="text"
                                    rows="1"
                                    onClick={() => {
                                        setDropDown(!dropDown);
                                    }}
                                    placeholder="content"
                                    className={
                                        (content
                                            ? "textarea-value active"
                                            : "textarea-value",
                                        "onset")
                                    }
                                ></FormText>
                                <IconContainer
                                    onClick={() => {
                                        setDropDown(!dropDown);
                                    }}
                                >
                                    {dropDown ? (
                                        <Icon src={up} />
                                    ) : (
                                        <Icon src={down} />
                                    )}
                                </IconContainer>
                            </Left>
                        </InputWrapper>
                        {showErrors && !content && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                        <Contaner className={dropDown && "active"}>
                            {drop.map((item) => (
                                <DateContaner
                                    key={item.id}
                                    onClick={() => {
                                        setitem(item.name);
                                        // setContent(item.id);
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            content: item.id,
                                        }));
                                        setDropDown(false);
                                    }}
                                >
                                    <Date key={item.id}>{item.name}</Date>
                                </DateContaner>
                            ))}
                        </Contaner>
                    </Wrapper>
                    <Title>Mood and affect</Title>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: objectiveAffect && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="objective"
                                    className={
                                        objectiveAffect
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Objective affect
                                </FormLabel>
                                <FormText
                                    id="objective"
                                    name="objective"
                                    value={objectiveAffect}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            objectiveAffect: e.target.value,
                                        }));
                                    }}
                                    placeholder="Objective affect"
                                    className={
                                        objectiveAffect
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !objectiveAffect && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: appropriateness && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="appropriateness"
                                    className={
                                        appropriateness
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Appropriateness
                                </FormLabel>
                                <FormText
                                    id="appropriateness"
                                    name="appropriateness"
                                    value={appropriateness}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            appropriateness: e.target.value,
                                        }));
                                    }}
                                    placeholder="Appropriateness"
                                    className={
                                        appropriateness
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !appropriateness && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: range && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="range"
                                    className={
                                        range
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Range
                                </FormLabel>
                                <FormText
                                    id="range"
                                    name="range"
                                    value={range}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            range: e.target.value,
                                        }));
                                    }}
                                    placeholder="Range"
                                    className={
                                        range
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !range && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: communicability && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="communicability"
                                    className={
                                        communicability
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Communicability
                                </FormLabel>
                                <FormText
                                    id="communicability"
                                    name="communicability"
                                    value={communicability}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            communicability: e.target.value,
                                        }));
                                    }}
                                    placeholder="Communicability"
                                    className={
                                        communicability
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !communicability && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Perception</Title>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: sensoryDistortion && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="distortion"
                                    className={
                                        sensoryDistortion
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Sensory distortion
                                </FormLabel>
                                <FormText
                                    id="distortion"
                                    name="distortion"
                                    value={sensoryDistortion}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            sensoryDistortion: e.target.value,
                                        }));
                                    }}
                                    placeholder="Sensory distortion"
                                    className={
                                        sensoryDistortion
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !sensoryDistortion && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: sensoryDeception && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="deception"
                                    className={
                                        sensoryDeception
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Sensory deception
                                </FormLabel>
                                <FormText
                                    id="deception"
                                    name="deception"
                                    value={sensoryDeception}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            sensoryDeception: e.target.value,
                                        }));
                                    }}
                                    placeholder="Sensory deception"
                                    className={
                                        sensoryDeception
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !sensoryDeception && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Title>Other psychotic phenomena</Title>
                    <RenderingChip
                        array={psychotic}
                        selectedChips={psychoticPhenomenas}
                        setSelectedChipsKey="psychoticPhenomenas"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Title>Any other phenomena</Title>
                    <RenderingChip
                        array={other}
                        selectedChips={otherPhenomenas}
                        setSelectedChipsKey="otherPhenomenas"
                        setFunction={setMentalStatusFormFields}
                    />
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: insight && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="insight"
                                    className={
                                        insight
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Insight
                                </FormLabel>
                                <FormText
                                    id="insight"
                                    name="insight"
                                    value={insight}
                                    type="text"
                                    rows="1"
                                    onChange={(e) => {
                                        setMentalStatusFormFields((prev) => ({
                                            ...prev,
                                            insight: e.target.value,
                                        }));
                                    }}
                                    placeholder="Insight"
                                    className={
                                        insight
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !insight && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                </FormInputs>
                <Buttons>
                    <ButtonTypeOne
                        className={"case"}
                        text="Back"
                        color={THEME_COLORS.dark_500}
                        bkgcolor={THEME_COLORS.white}
                        border={THEME_COLORS.light_400}
                        onclick={() => {
                            if (
                                detectChanges(
                                    initialMentalStatusFormValues,
                                    mentalStatusFormFields
                                )
                            ) {
                                setMentalStatusFormFields(
                                    initialMentalStatusFormValues
                                );
                                handleCancel();
                            } else {
                                handleCancel();
                            }
                        }}
                    />
                    <ButtonTypeOne
                        text="Save"
                        color={THEME_COLORS.white}
                        bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                        onclick={handleMentalStatusFormSubmit}
                    />
                </Buttons>
            </FormContainer>
        </>
    );
}

export default MentalStatusExamination;

const FormContainer = styled.div`
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 50px;
    height: 600px;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const FormInputs = styled.div``;
const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const Wrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
    &.onset {
        cursor: pointer;
    }
`;
const InputWrapper = styled.div``;
const Left = styled.div`
    flex-grow: 1;
    &.arrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const Error = styled.small`
    margin-top: 5px;
`;
const FormLabel = styled.label`
    display: none;
    &.show {
        display: block;
    }
`;
const FormText = styled.textarea`
    &.active {
        font-weight: 500;
        &#symptoms {
            /* color: red; */
            font-weight: 600;
        }
    }
    &.onset {
        font-weight: 400;
        font-size: 16px;
        color: #3e3e53;
        background-color: #f4f4f4;
        border: none;
        outline: none;
        resize: none;
        width: max-content;
        cursor: pointer;
    }
`;

const Label = styled.p`
    /* width: 40%; */
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
    color: #fff;
    background-color: #366ee3;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
`;
const Title = styled.h3`
    font-weight: 600;
    font-size: 14px;
    color: #818181;
    margin-bottom: 20px;
`;
const LabelFelid = styled.div`
    display: flex;
    gap: 15px;
`;
const LabelBox = styled.div``;
const LabelText = styled.p`
    border: 1px solid #e2e2e2;
    width: fit-content;
    border-radius: 25px;
    padding: 5px 20px;

    &.selected {
        border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
        color: ${THEME_COLORS.chips_blue_on_container_2};
        background-color: ${THEME_COLORS.chips_blue_container};
    }
`;
const RoundLabel = styled.div`
    margin-bottom: 50px;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    /* width: 30%; */
    margin: 0 auto;
    div.one {
        margin-right: 20px;
    }
`;
const Contaner = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    padding: 8px;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    right: 0;
    top: 65px;
    z-index: 10;
    display: none;
    height: 300px;
    overflow: scroll;
    &.active {
        display: block;
    }
`;
const DateContaner = styled.div`
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    &:hover {
        background: ${THEME_COLORS.light_100};
    }
`;
const Date = styled.p`
    color: ${THEME_COLORS.dark_500};
    font-size: 16px;
`;
const Icon = styled.img``;
const IconContainer = styled.div``;
