import React from 'react'
import styled from 'styled-components'

const ChipRectangle = ({
  image,
  content,
  bkgcolor,
  color,
  mr_left,
  pd,
  br,
  font_size,
  font_weight,
  image_width,
  onclick,
  clickable,
  hover_color,
  text,
}) => {
  return (
    <Main
      bkgcolor={bkgcolor}
      color={color}
      mr_left={mr_left}
      pd={pd}
      br={br}
      font_size={font_size}
      font_weight={font_weight}
      clickable={clickable}
      onClick={onclick}
      hover_color={hover_color}
      content={content}
      image={image}
    >
      {image && (
        <ImageBox image_width={image_width}>
          <img
            src={require(`../../../assets/image/session_summary/${image}`)}
            alt="icon"
          />
        </ImageBox>
      )}
      {text == true ? (
        <>{content && <span>Completed on {content}</span>}</>
      ) : (
        <>{content && <span> {content}</span>}</>
      )}
    </Main>
  )
}

export default ChipRectangle

const Main = styled.div`
  height: 100%;
  background: ${(props) => props.bkgcolor};
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.pd};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => props.mr_left};
  cursor: ${(props) => props.clickable && 'pointer'};
  span {
    color: ${(props) => props.color};
    margin-left: ${(props) => (props.image ? '8px' : '0')};
    font-size: ${(props) => props.font_size};
    font-weight: ${(props) => props.font_weight};
  }
  &:hover {
    background: ${(props) => props.hover_color && props.hover_color};
  }
`
const ImageBox = styled.div`
  width: ${(props) => props.image_width};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`
