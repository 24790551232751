import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// swiper slider
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'

import { THEME_COLORS } from '../../../ThemeConfig'
export default function FollowUpDateCard({
  cards,
  value,
  setValue,
  active,
  dot,
  setSelectedNewDate,
  selectedDateObject,
}) {
  return (
    <SwiperContainer>
      {active == true ? (
        <></>
      ) : (
        <Button className="arrow-left button-prev">
          <img
            src={
              require('../../../assets/image/session_summary/left_arrow.svg')
                .default
            }
            alt="left arrow"
          />
        </Button>
      )}
      <Swiper
        modules={[Navigation]}
        speed={500}
        spaceBetween={6}
        slidesPerView={7}
        slidesPerGroup={7}
        navigation={{
          nextEl: '.button-next',
          prevEl: '.button-prev',
        }}
        breakpoints={{
          1920: {
            slidesPerView: 7,
          },
          1440: {
            slidesPerView: 7,
          },
          1280: {
            slidesPerView: 5,
          },
          980: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
        }}
      >
        {cards?.map((card) => (
          <SwiperSlide key={card.id}>
            <Card
              onClick={() => {
                setValue(card.date_full)
              }}
              className={
                selectedDateObject.exact_date === card.exact_date && 'active'
              }
            >
              {dot == true ? '' : <Dot></Dot>}
              <ShowDate
                className={
                  selectedDateObject.exact_date === card.exact_date && 'active'
                }
              >
                {card.date}
              </ShowDate>
              <ShowDay
                className={
                  selectedDateObject.exact_date === card.exact_date && 'active'
                }
              >
                {card.day}
              </ShowDay>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      {active == true ? (
        <></>
      ) : (
        <Button className="arrow-right button-next">
          <img
            src={
              require('../../../assets/image/session_summary/right_arrow.svg')
                .default
            }
            alt="right arrow"
          />
        </Button>
      )}
    </SwiperContainer>
  )
}
const SwiperContainer = styled.div`
  display: flex;
  .swiper {
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    padding-bottom: 5px;
  }
`
const Card = styled.div`
  height: 63px;
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 10px;
  padding: 10px;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: ${THEME_COLORS.chips_green_container};
    color: ${THEME_COLORS.chips_green_on_container};
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
  }
`
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${THEME_COLORS.chips_green_on_container};
  position: absolute;
  right: 6px;
  top: 6px;
`
const ShowDate = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  &.active {
    color: ${THEME_COLORS.chips_green_on_container};
  }
`
const ShowDay = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
  &.active {
    color: ${THEME_COLORS.chips_green_on_container};
  }
`
const Button = styled.button`
  width: 57px !important;
  height: 63px !important;
  background-color: ${THEME_COLORS.chips_blue_container};
  border: none;
  border-radius: 10px;
  padding: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${THEME_COLORS.gradient_2};
  }
  &.arrow-left {
    margin-right: 6px;
  }
  &.arrow-right {
    margin-left: 6px;
  }
`
