import React, { useContext, useReducer } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../contexts/Store";

function ConfirmRescheduleModal({ setTimeSlot, timeSlot }) {
    const {
        state: { time_slot, isConfirmReschedule },
        dispatch,
    } = useContext(Context);
    return (
        isConfirmReschedule && (
            <>
                <Cover></Cover>
                <MainContainer>
                    <ContainerWhite>
                        <Heading>Reschedule</Heading>
                        <RedDiv>
                            <RedImage>
                                <img
                                    src={require("../../../../assets/image/sessionsinglepage/greenClock.png")}
                                    alt="Image"
                                />
                            </RedImage>
                            <Message>
                                Session rescheduled, MM admin will verify and
                                inform client.
                            </Message>
                            <DateDiv>
                                <Date>
                                    {timeSlot?.date?.date}{" "}
                                    {timeSlot?.date?.month},{" "}
                                    {timeSlot?.date?.day_full}
                                </Date>
                            </DateDiv>
                            <TimeDiv>
                                <Right>
                                    <Time>{timeSlot?.time?.start_time}</Time>
                                </Right>
                                <To>to</To>
                                <Left>
                                    <Time>{timeSlot?.time?.end_time}</Time>
                                </Left>
                            </TimeDiv>
                        </RedDiv>

                        <BottomContainer>
                            <Cancel>Edit</Cancel>
                            <RescheduleButton
                                onClick={() => {
                                    dispatch({
                                        type: "RESCHEDULE_CONFIRM",
                                        isConfirmReschedule: false,
                                    });
                                    dispatch({
                                        type: "UPDATE_SLOT",
                                        status: "Reschedule",
                                    });
                                }}
                            >
                                Done
                            </RescheduleButton>
                        </BottomContainer>
                    </ContainerWhite>
                </MainContainer>
            </>
        )
    );
}

export default ConfirmRescheduleModal;
const Cover = styled.div`
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    opacity: 0.4;
`;
const MainContainer = styled.div`
    position: fixed;
    top: 100px;
    right: 550px;
    z-index: 2;
    width: 520px;
    cursor: default;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 12px;
    padding-top: 5px;
    @media all and (max-width: 1280px) {
        right: 380px;
        top: 50px;
    }
`;
const ContainerWhite = styled.div`
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-radius: 10px;
    border: 2px solid ${THEME_COLORS.white};
`;
const Heading = styled.h5`
    color: ${THEME_COLORS.text_title};
    font-size: 24px;
    margin-bottom: 40px;
`;
const RedDiv = styled.div`
    /* width: 440px; */
    background-color: ${THEME_COLORS.chips_green_container};
    padding: 32px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 30px;
`;
const RedImage = styled.div`
    margin-bottom: 20px;
`;
const Message = styled.p`
    margin: 0 auto;
    width: 50%;
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const Cancel = styled.button`
    padding: 14px 24px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.dark_500};
    margin-right: 10px;
`;
const RescheduleButton = styled.button`
    padding: 14px 24px;
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.chips_blue_on_container_2};
`;
const DateDiv = styled.div`
    width: 65%;
    margin: 10px auto;
    padding: 10px;
    background: ${THEME_COLORS.white};
    border-radius: 8px;
`;
const Date = styled.h5`
    color: ${THEME_COLORS.chips_green_on_container};
    font-size: 20px;
`;
const TimeDiv = styled.div`
    width: 65%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Right = styled.div`
    padding: 10px;
    background: ${THEME_COLORS.white};
    border-radius: 8px;
`;
const Left = styled.div`
    padding: 10px;
    background: ${THEME_COLORS.white};
    border-radius: 8px;
`;
const To = styled.p`
    color: ${THEME_COLORS.chips_green_on_container};
    font-size: 20px;
`;
const Time = styled.p`
    color: ${THEME_COLORS.chips_green_on_container};
    font-size: 20px;
`;
