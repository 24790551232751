import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

function PatientDetailsFinal({ patientDetails }) {
    // console.log(patientDetails, "patientDetailspatientDetails");
    return (
        <>
            <Container>
                <Heading>Patient Details</Heading>
                {patientDetails ? (
                    <PatientDetailsDiv>
                        {/* {PersonalDetails.map((item) => ( */}
                        {/* {patientDetails?.full_name && ( */}
                        {patientDetails?.full_name ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Patient Name</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.full_name}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {/* )} */}
                        {patientDetails?.informant ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Informant</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.informant}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.age ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Age</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>{patientDetails?.age}</NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.age ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Gender</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.gender}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.dob ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Date of Birth</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>{patientDetails?.dob}</NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.marital_status ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Marital Status</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.marital_status}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.religion ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Religion</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.religion}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.education ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Education</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.education}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.occupation ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Occupation</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.occupation}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {patientDetails?.family ? (
                            <PatientNameDiv>
                                <PatientName>
                                    <Text>Family</Text>
                                </PatientName>
                                <span>:</span>
                                <Name>
                                    {" "}
                                    <NameText>
                                        {patientDetails?.family}
                                    </NameText>
                                </Name>
                            </PatientNameDiv>
                        ) : (
                            ""
                        )}
                        {/* ))} */}
                    </PatientDetailsDiv>
                ) : (
                    ""
                )}
            </Container>
        </>
    );
}

export default PatientDetailsFinal;
const Container = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    padding: 10px;
    border-radius: 8px;
    box-shadow: ${THEME_COLORS.elevation_expert_e1};
    /* margin-bottom: 20px; */
`;
const Heading = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 10px;
`;
const PatientNameDiv = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    :last-child {
        margin-bottom: 0;
    }
`;
const PatientName = styled.div`
    width: 50%;
`;
const Name = styled.h5`
    width: 40%;
`;
const PatientDetailsDiv = styled.ul``;
const Text = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const NameText = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;
