import React from 'react'

import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

const Pagination = ({ list, itemsPerPage, activePage, setActivePage }) => {
  let numberOfPages = []

  for (let i = 1; i <= Math.ceil(list?.length / itemsPerPage); i++) {
    numberOfPages.push(i)
  }

  const showNextList = (pageNumber) => {
    window.scrollTo(0, 0)
    setActivePage(pageNumber)
  }

  return (
    <>
      {numberOfPages?.length > 1 && (
        <Container>
          <List
            onClick={() => activePage !== 1 && showNextList(activePage - 1)}
            className={activePage > 1 ? 'arrow' : 'disable arrow'}
          >
            <img
              src={
                require('../../../assets/image/reports/left_arrow_black.svg')
                  .default
              }
              alt="left arrow"
            />
            <Text className="previous">Previous</Text>
          </List>

          {numberOfPages?.length <= 3 ? (
            numberOfPages.map((pageNumber) => (
              <List
                key={pageNumber}
                onClick={() => showNextList(pageNumber)}
                className={pageNumber === activePage ? 'active-page' : ''}
              >
                {pageNumber}
              </List>
            ))
          ) : (
            <>
              <List
                onClick={() =>
                  showNextList(
                    activePage < numberOfPages.length - 1
                      ? activePage
                      : activePage === numberOfPages.length
                      ? numberOfPages[0]
                      : activePage - 1,
                  )
                }
                className={
                  activePage < numberOfPages.length - 1 ? 'active-page' : ''
                }
              >
                {activePage < numberOfPages.length - 1
                  ? activePage
                  : activePage === numberOfPages.length
                  ? numberOfPages[0]
                  : '...'}
              </List>
              <List
                onClick={() =>
                  showNextList(
                    activePage >= numberOfPages.length - 1
                      ? numberOfPages.length - 1
                      : activePage + 1,
                  )
                }
                className={
                  activePage === numberOfPages.length - 1 ? 'active-page' : ''
                }
              >
                {activePage === numberOfPages.length - 1
                  ? numberOfPages.length - 1
                  : '...'}
              </List>
              <List
                onClick={() => showNextList(numberOfPages.length)}
                className={
                  numberOfPages.length === activePage ? 'active-page' : ''
                }
              >
                {numberOfPages.length}
              </List>
            </>
          )}

          <List
            onClick={() =>
              activePage !== numberOfPages.length &&
              showNextList(activePage + 1)
            }
            className={
              activePage < numberOfPages.length ? 'arrow' : 'disable arrow'
            }
          >
            <Text className="next">Next</Text>
            <img
              src={
                require('../../../assets/image/reports/right_arrow_black.svg')
                  .default
              }
              alt="right arrow"
            />
          </List>
        </Container>
      )}
    </>
  )
}

export default Pagination

const Container = styled.ul`
  display: flex;
  justify-content: right;
`
const List = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 10px;
  color: ${THEME_COLORS.text_title};
  &.active-page {
    background-color: ${THEME_COLORS.chips_blue_container};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
  }
  &.arrow {
    width: auto;
    padding: 8px 15px;
  }
  &.disable {
    // visibility: hidden;
    // pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
`
const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  &.previous {
    margin-left: 10px;
  }
  &.next {
    margin-right: 10px;
  }
`
