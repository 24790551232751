const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      const user_details = {
        ...state.user_details,
        ...action.user_details,
      }
      localStorage.setItem('user_details', JSON.stringify(user_details))
      return {
        ...state,
        user_details: user_details,
      }
    case 'FOLLOWUP_ACTIVE':
      return {
        ...state,
        isFollowUpActive: action.isFollowUpActive,
      }
    case 'PROFILE_OPTION':
      return {
        ...state,
        activeOption: action.activeOption,
      }
    case 'RESCHEDULE_CONFIRM':
      return {
        ...state,
        isConfirmReschedule: action.isConfirmReschedule,
      }
    case 'DATE_TIME_SLOT':
      return {
        ...state,
        time_slot: action.time_slot,
      }
    case 'UPDATE_SLOT':
      return {
        ...state,
        status: action.status,
      }
    case 'UPDATE_NEW_REGISTERATION_STATUS':
      localStorage.setItem('nrs', JSON.stringify(action.nrs))
      // console.log(action.nrs, "nrs action");
      return {
        ...state,
        nrs: action.nrs,
      }

    // case "NEW_REGISTERATION_FINISHED":
    //     localStorage.setItem("nrs", JSON.stringify(false));
    //     return {
    //         ...state,
    //         nrs: false,
    //     };
    default:
      return state
  }

  // async function storeData(user_data) {
  // 	try {
  // 		const jsonValue = JSON.stringify(user_data);
  // 		localStorage.setItem("user_details", jsonValue);
  // 		// localStorage.getItem("user_details", (err, value) => {
  // 		// 	if (err) {
  // 		// 	} else {
  // 		// 		JSON.parse(value); // boolean false
  // 		// 	}
  // 		// });
  // 	} catch (e) {
  // 		// saving error
  // 	}
  // }

  // const user_details = {
  // 	...state.user_details,
  // 	...action.user_details,
  // };

  // switch (action.type) {
  // 	case "UPDATE_USER":
  // 		storeData(user_details);
  // 		return {
  // 			...state,
  // 			user_details: user_details,
  // 		};
  // 	default:
  // 		return state;
  // }
}

export default Reducer
