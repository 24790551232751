import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

function SessionCountChip({ total_session, current_session, incoming }) {
    // console.log(total_session, "total_session");
    return (
        <>
            {incoming ? (
                <ChipContainer>
                    <>
                        {total_session > 1 ? (
                            <Date>
                                <span>{total_session}</span> Sessions
                            </Date>
                        ) : (
                            <Date>
                                <span>{total_session}</span> Session
                            </Date>
                        )}
                    </>
                </ChipContainer>
            ) : (
                <ChipContainer>
                    {current_session !== "" || incoming == false ? (
                        <Date>
                            <span>{current_session}</span> of{" "}
                            <span>{total_session}</span> Sessions
                        </Date>
                    ) : (
                        <>
                            {total_session > 1 ? (
                                <Date>
                                    <span>{total_session}</span> Sessions
                                </Date>
                            ) : (
                                <Date>
                                    <span>{total_session}</span> Session
                                </Date>
                            )}
                        </>
                    )}
                </ChipContainer>
            )}
        </>
    );
}

export default SessionCountChip;

const ChipContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
    background: ${THEME_COLORS.chips_blue_container};
    /* cursor: pointer; */
`;

const Date = styled.p`
    color: ${THEME_COLORS.chips_blue_on_container_2};

    font-family: "DM_sans_medium";
    font-size: 12px;
    margin-right: 5px;
`;
