import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import TransactionCard from "./TransactionCard";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import { expert } from "../../../axiosConfig";

const Transactions = () => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [transactions, setTransactions] = useState([]);
    const [wallet, setWallet] = useState();
    const [error, SetError] = useState([]);

    useEffect(() => {
        expert
            .get("/wallet/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    // console.log(response.data, "response.data");
                    setTransactions(response.data.data.transactions);
                    setWallet(response.data.data.wallet);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
    }, []);

    return (
        <Main>
            <Head>Earnings</Head>
            <Top>
                <Cover>
                    <EarningsBox>
                        <EarningsTop>
                            <ImageBox>
                                <img
                                    src={
                                        require("../../../assets/image/my_account/earnings.svg")
                                            .default
                                    }
                                    alt="earnings"
                                />
                            </ImageBox>
                            <Earnings>
                                <Title>Total Earnings</Title>
                                <Value className="earnings">
                                    {wallet?.total_earnings} INR
                                </Value>
                            </Earnings>
                            <ArrowBox to={"/transactions-list"}>
                                <img
                                    src={
                                        require("../../../assets/image/my_account/right_arrow_long_blue.svg")
                                            .default
                                    }
                                    alt="arrow"
                                />
                            </ArrowBox>
                        </EarningsTop>
                        <EarningsBottom>
                            <Section>
                                <Title>Pending Amount</Title>
                                <Value>{wallet?.pending_amount} INR</Value>
                            </Section>
                            <Section>
                                <Title>Balance Amount</Title>
                                <Value>{wallet?.current_balance} INR</Value>
                            </Section>
                            <Section>
                                <Title>Withdrawn Amount</Title>
                                <Value>{wallet?.withdrawn} INR</Value>
                            </Section>
                            <Section>
                                <Title>Requested Amount</Title>
                                <Value>{wallet?.withdrawn_request} INR</Value>
                            </Section>
                        </EarningsBottom>
                    </EarningsBox>
                </Cover>
            </Top>
            {/* {transactions.length > 0 && ( */}
            <Bottom>
                <HeadContainer>
                    <SubHead>Recent Transactions</SubHead>
                    <Line></Line>
                    <ViewLink to={"/transactions-list"}>View all</ViewLink>
                </HeadContainer>
                {transactions?.length > 0 ? (
                    <TransactionCards>
                        {transactions.slice(0, 5).map((data) => {
                            return (
                                <TransactionCard key={data.id} data={data} />
                            );
                        })}
                    </TransactionCards>
                ) : (
                    <EmptyCover>
                        <img
                            src={
                                require("../../../assets/image/dashboard/no-data.svg")
                                    .default
                            }
                            alt="empty"
                        />
                        <p> You Don't have any transactions yet</p>
                    </EmptyCover>
                )}
            </Bottom>
            {/* )} */}
        </Main>
    );
};

export default Transactions;

const Main = styled.div`
    // width: 31%;
    width: 440px;
    padding: 30px;
    @media all and (max-width: 1440px) {
        width: 31%;
    }
    @media all and (max-width: 1300px) {
        margin-right: 30px;
        margin-left: 30px;
        width: 100%;
        padding: 16px;
    }
`;
const Head = styled.h5`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.black};
`;
const Top = styled.div``;
const Cover = styled.div`
    width: 100%;
    height: 200px;
    padding: 20px;
    background: ${THEME_COLORS.gradient_3};
    border-radius: 10px;
    margin-bottom: 80px;
    @media all and (max-width: 1280px) {
        margin-bottom: 110px;
    }
`;
const EarningsBox = styled.div`
    width: 100%;
    /* height: 200px; */
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_200};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    border-radius: 8px;
    padding: 20px;
`;
const EarningsTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 16px;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
    margin-bottom: 20px;
`;
const ImageBox = styled.div`
    width: 40px;
    height: 40px;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 8px;
    margin-right: 20px;
    padding: 10px;
    img {
        width: 20px;
        height: 20px;
    }
`;
const Earnings = styled.div`
    flex-grow: 1;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const Title = styled.h5`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_label};
`;
const Value = styled.p`
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.black};
    &.earnings {
        font-size: 20px;
    }
`;
const ArrowBox = styled(Link)`
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    img {
        width: 100%;
        height: 100%;
    }
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const EarningsBottom = styled.div`
    /* display: flex;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* justify-items: center; */
    row-gap: 15px;
    @media all and (max-width: 1280px) {
        row-gap: 40px;
    }
`;
const Section = styled.div`
    /* width: 45%; */
`;
const Bottom = styled.div`
    padding: 16px;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_300};
    box-shadow: ${THEME_COLORS.elevation_expert_e1};
    border-radius: 8px;
`;
const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
const SubHead = styled.h6`
    font-weight: 500;
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.black};
`;
const Line = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    flex-grow: 1;
    margin: 0 10px;
`;
const ViewLink = styled(Link)`
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const TransactionCards = styled.div`
    /* height: 400px;
    overflow: scroll; */
    // max-height: 912px;
    // overflow-y: scroll;
    // &::-webkit-scrollbar{
    //     display: none;
    // }
`;
const EmptyCover = styled.div`
    height: 300px;
    width: 100%;
    /* background: ${THEME_COLORS.light_200}; */
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 60px;
        height: 60px;
        margin-bottom: 42px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        color: ${THEME_COLORS.dark_400};
    }
`;
