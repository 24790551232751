import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne";
import AttentionModal from "./modals/AttentionModal";
import { expert } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import CancelWarningModal from "./modals/CancelWarningModal";
import { useEffect } from "react";

const YourBio = ({
    setShowSuccessToast,
    showAttentionModal,
    setShowAttentionModal,
}) => {
    const [yourBio, setYourBio] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const { state, dispatch } = useContext(Context);
    const [err, setErr] = useState("");

    const access_token = state.user_details.access_token;

    const navigate = useNavigate();

    const handleSubmit = (close) => {
        if (yourBio) {
            setShowSuccessToast(true);
            if (close === "close") {
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
            }
        } else {
            setShowErrors(true);
        }
    };

    const getBio = () => {
        let accessToken = state.user_details.access_token;
        expert
            .get(`/get-bio/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                // console.log(response.data, "response.data");
                if (StatusCode === 6000) {
                    setYourBio(data.bio);
                } else {
                    // alert(
                    //     "Some error occured while loading qualifications, please refresh the page"
                    // );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const handleCancel = () => {
        if (yourBio) setShowAttentionModal(true);
        else {
            navigate(-1);
        }
    };

    const handlefinalSubmit = () => {
        const data = {
            bio: yourBio,
        };
        if (yourBio) {
            expert
                .put("/add-bio/", data, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(function (response) {
                    const { StatusCode } = response.data;
                    const { access_token, refresh_token, message } =
                        response.data.data;
                    setErr("");
                    handleSubmit();
                })
                .catch((error) => {
                    console.error(
                        "Error creating post personal-infooo:",
                        error
                    );
                    if (error.response.status === 404) {
                        setErr("NOT_FOUND");
                    }
                });
        } else {
            setErr("*This file is mandatory");
        }
    };

    useEffect(() => {
        getBio();
    }, []);

    return (
        <Form>
            <FormData>
                <Head>Your Bio</Head>
                <TitleWrapper>
                    <Title>Describe Yourself*</Title>
                    <SubTitle>Describe yourself in a short brief.</SubTitle>
                </TitleWrapper>
                <Wrapper>
                    <InputWrapper className="input-wrapper">
                        <Left>
                            <FormLabel
                                htmlFor="bio"
                                className={
                                    yourBio ? "input-label show" : "input-label"
                                }
                            >
                                Enter a Brief Bio*
                            </FormLabel>
                            <FormInput
                                id="bio"
                                name="yourBio"
                                value={yourBio}
                                type="text"
                                onChange={(e) => setYourBio(e.target.value)}
                                placeholder="Enter a Brief Bio*"
                                className={
                                    yourBio
                                        ? "input-value active"
                                        : "input-value"
                                }
                            ></FormInput>
                        </Left>
                    </InputWrapper>
                    {err && !yourBio && (
                        <Error className="error">This field is required.</Error>
                    )}
                </Wrapper>
            </FormData>
            <FormButtons>
                <ButtonTypeOne
                    text="Cancel"
                    color={THEME_COLORS.dark_500}
                    bkgcolor={THEME_COLORS.white}
                    border={THEME_COLORS.light_400}
                    onclick={handleCancel}
                />
                <ButtonTypeOne
                    text="Save"
                    color={THEME_COLORS.white}
                    bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                    onclick={handlefinalSubmit}
                />
            </FormButtons>
            {showAttentionModal && (
                <CancelWarningModal
                    isModal={showAttentionModal}
                    setIsModal={setShowAttentionModal}
                />
            )}
        </Form>
    );
};

export default YourBio;

const Form = styled.form`
    min-height: calc(100vh - 229.6px);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const FormData = styled.div``;

const Head = styled.h4`
    margin-bottom: 60px;
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.black};
`;
const TitleWrapper = styled.div`
    margin-bottom: 16px;
`;
const Title = styled.h5`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const Wrapper = styled.div`
    margin-bottom: 16px;
`;
const InputWrapper = styled.div``;
const Left = styled.div`
    flex-grow: 1;
`;
const Error = styled.small`
    color: red;
    font-size: 14px;
    margin-top: 10px;
`;
const FormLabel = styled.label`
    &.show {
        display: block;
    }
`;
const FormInput = styled.input`
    &.active {
        font-weight: 500;
        font-size: 16px !important;
    }
`;
const FormButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
