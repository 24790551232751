import React from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig';


const ChipCircle = ({
    content, 
    bkgcolor, 
    color, 
    border_color, 
    width, 
    height,
    font_size,
    font_weight,
    active
}) => {
  return (
    <Main
        bkgcolor={bkgcolor}
        color={color}
        border_color={border_color}
        width={width}
        height={height}
        font_size={font_size}
        font_weight={font_weight}
        active={active}
    >
        {content}
    </Main>
  )
}

export default ChipCircle

const Main = styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    border: 1px solid ${props=>props.active? props.border_color : THEME_COLORS.light_300};
    border-radius: 50%;
    color: ${props=>props.active ? props.color : THEME_COLORS.text_title};
    background-color: ${props=>props.active? props.bkgcolor : THEME_COLORS.white};
    font-size: ${props=>props.font_size};
    font-weight: ${props=>props.font_weight};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    &:last-child{
        margin-right: 0;
    }
`