import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../ThemeConfig'
import Header from './Header'
import comingsoon from '../../assets/image/coming-soon.svg'
import insta from '../../assets/image/insta.svg'
import youtube from '../../assets/image/youtube.svg'
import fb from '../../assets/image/fb.svg'
import twitter from '../../assets/image/twitter.svg'
import left from '../../assets/image/left-leaf.svg'
import right from '../../assets/image/right-leaf.svg'

export default function ComingSoon() {
  return (
    <>
      <Header />
      <Container>
        <Wrapper>
          <ImageContainer>
            <Image src={comingsoon} />
          </ImageContainer>
          <Content>
            <Title>We're working on something exciting!</Title>
            <Description>
              Get ready for a powerful addition to Mind Mitra, Designed to{' '}
              enhance your therapy experience.
            </Description>
            <Button to="/dashboard">
              <img
                src={require('../../assets/image/left-arrow.svg').default}
                alt="left-arrow"
              />
              Back To Home
            </Button>
          </Content>

          <BottomContainer>
            <Text>Follow us on social media for updates</Text>
            <IconContainer>
              <Image src={fb} />
            </IconContainer>
            <IconContainer>
              <Image src={twitter} />
            </IconContainer>
            <IconContainer>
              <Image src={insta} />
            </IconContainer>
            <IconContainer>
              <Image src={youtube} />
            </IconContainer>
          </BottomContainer>
        </Wrapper>
        <div className="leaf">
          <LeafContainer className="left">
            <Image src={left} />
          </LeafContainer>
          <LeafContainer className="right">
            <Image src={right} />
          </LeafContainer>
        </div>
      </Container>
    </>
  )
}
const Container = styled.div`
  padding-top: 100px;
  height: calc(100vh - 70px);
  position: relative;
  div.leaf {
    display: flex;
    justify-content: space-between;
  }
`
const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  @media all and (max-width: 640px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`
const ImageContainer = styled.div`
  width: 30%;
  margin: 0 auto;
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 768px) {
    width: 50%;
  }
`
const Image = styled.img`
  width: 100%;
  display: block;
`
const Content = styled.div`
  /* width: fit-content; */
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`
const Title = styled.h4`
  font-family: 'DM_sans_bold';
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`
const Description = styled.p`
  font-family: 'DM_sans_medium';
  font-size: 16px;
  text-align: center;
  color: ${THEME_COLORS.text_label};
  margin-bottom: 20px;
  width: 70%;
  @media all and (max-width: 980px) {
    width: 90%;
  }
  @media all and (max-width: 480px) {
    font-size: 12px;
    width: 100%;
  }
`
const Button = styled(Link)`
  display: inline-block;
  display: flex;
  gap: 10px;
  border: 2px solid ${THEME_COLORS.light_400};
  padding: 10px 20px;
  border-radius: 32px;
  font-size: 14px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.dark_500};
  @media all and (max-width: 480px) {
    padding: 8px 16px;
  }

  img {
    width: 7px;
  }
`
const IconContainer = styled.div`
  margin-right: 15px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  /* background: aqua; */
  height: 35px;
  align-content: center;
  border-radius: 20px;
  /* box-shadow: ${THEME_COLORS.elevation_expert_e2}; */
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
`
const Text = styled.p`
  font-size: 14px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.dark_500};
  margin-right: 20px;
`
const LeafContainer = styled.div`
  width: 80px;
  position: absolute;
  &.right {
    right: 0;
    bottom: 0;
  }
  &.left {
    left: 0;
    bottom: 0;
  }
`
