import React, { useState } from 'react'
import styled from 'styled-components'
import check from '../../../assets/image/dashboard/Check box.svg'

import { THEME_COLORS } from '../../../ThemeConfig'

function TimeSlot({
  time,
  timeSlot,
  setTimeSlot,
  setTimeSlotID,
  title,
  timeSlotID,
}) {
  const renderAfterNoonSlots = () => {
    return time.map((slot) => (
      <>
        <SlotText
          key={slot.id}
          className={slot.booked == true ? 'selected' : ''}
        >
          {slot.start_time}
        </SlotText>
      </>
    ))
  }
  return (
    <>
      <Morning>
        <Title>{title}</Title>
      </Morning>

      <Slot>
        <SlotField>
          {renderAfterNoonSlots()}
          <SlotBox></SlotBox>
        </SlotField>
      </Slot>
    </>
  )
}

export default TimeSlot

const SlotBox = styled.div``
const SlotText = styled.p`
  background-color: #ffff;
  border: 1px solid #e2e2e2;
  border-radius: 53px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  color: ${THEME_COLORS.dark_500};

  &.selected {
    background-color: #e0f8f2;
    color: #1da687;
    border: 1px solid #1da687;
  }
`
const Slot = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 15px;
`
const SlotField = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
`
const Title = styled.div`
  color: ${THEME_COLORS.dark_300};
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
`
const Morning = styled.div`
  margin-bottom: 10px;
`
