import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import { CalendarScheduler } from "../../includes/CalendarScheduler";
import FilterDropdown from "../../includes/dropdown/FilterDropdown";
import FilterModal from "../../includes/modals/FilterModal";
import ScheduleSlotModal from "../session_summary/modals/ScheduleSlotModal";
import DefaultSlotCard from "../session_summary/modals/DefaultSlotCard";
import SuccessToast from "../manage_profile/toast/SuccessToast";
import SetDefaultSlotMdal from "../session_summary/modals/SetDefaultSlotMdal";
import editIcon from "../../../assets/image/edit.svg";
function ScheduleCalendar() {
    const [modal, setModal] = useState(false);
    const [datemodal, setDateModal] = useState(false);
    const [filtermodal, setFilterModal] = useState(false);
    const [month, setMonth] = useState("January");
    const [mothId, setMothId] = useState("");
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [isDefaultTimeSlotModal, setIsDefaultTimeSlotModal] = useState(false);
    useEffect(() => {
        // Disable scrolling on the body element when the modal is open
        document.body.classList.add("modal-open");

        // Cleanup function to re-enable scrolling when the modal is closed
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, []);
    const handleModal = () => {
        setIsDefaultTimeSlotModal(false);
    };

    const closeModal = (e) => {
        const element = document.querySelector(".click-form");
        if (!element.contains(e.target)) {
            handleModal();
        }
    };
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [dayModal, setDayModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container>
                <LeftContainer>
                    <TopContainer>
                        <DefaultButton
                            onClick={() => {
                                setIsDefaultTimeSlotModal(true);
                            }}
                        >
                            <EditIcon src={editIcon} alt="icon" />
                            Default Time
                        </DefaultButton>
                        <Filter>
                            <FilterDropdown
                                setDateModal={setDateModal}
                                datemodal={datemodal}
                                filtermodal={filtermodal}
                                setFilterModal={setFilterModal}
                                filter={filter}
                            />
                            <FilterModal
                                setFilterModal={setFilterModal}
                                filtermodal={filtermodal}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        </Filter>
                    </TopContainer>
                    <BottomContainer>
                        <CalendarContainer>
                            <CalendarScheduler
                                filtermonth={mothId}
                                search={search}
                                filter={filter}
                            />
                        </CalendarContainer>
                    </BottomContainer>
                </LeftContainer>
                <RightContainer>
                    <ScheduleSlotModal />
                </RightContainer>
                {isDefaultTimeSlotModal && (
                    <SetDefaultSlotMdal
                        setModal={setIsDefaultTimeSlotModal}
                        handleModal={handleModal}
                        isModal={isDefaultTimeSlotModal}
                    />
                )}
            </Container>
        </>
    );
}

export default ScheduleCalendar;

const Modal = styled.div``;

const Container = styled.section`
    padding: 30px 16px 30px 30px;
    display: flex;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 70%;
    margin-right: 30px;
    /* height: 100vh; */
    @media all and (max-width: 1080px) {
        width: 100%;
        margin-bottom: 30px;
        margin-right: 0;
    }
`;
const RightContainer = styled.div`
    width: 30%;
    @media all and (max-width: 1080px) {
        width: 100%;
    }
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`;
const Date = styled.div`
    position: relative;
`;
const Filter = styled.div`
    position: relative;
`;
const Search = styled.div``;
const BottomContainer = styled.div``;
const CalendarContainer = styled.section`
    position: relative;
    .react-calendar {
        width: auto;
        border: none;
    }
    .react-calendar button {
        border: 1px solid #e2e2e2;

        /* color: ${THEME_COLORS.text_title}; */
    }
    .react-calendar__month-view__days__day--neighboringMonth {
        color: #abaaaa;
    }
    .react-calendar__month-view__days__day--weekend {
        color: ${THEME_COLORS.text_title};
    }
    .react-calendar__tile {
        /* width: 100px; */
        height: 120px;
    }
    .react-calendar__navigation button {
        border: none;
    }
    .react-calendar__navigation {
        position: absolute;
        top: 100px;
        width: 200px;
    }
    .react-calendar__month-view {
        border: 1px solid #e2e2e2;
        border-radius: 12px;
    }
    .react-calendar__tile--now {
        background: #ffff76;
    }
    .react-calendar__navigation__prev2-button {
        background: ${THEME_COLORS.chips_blue_container};
        display: none;
    }
    .react-calendar__navigation__next2-button {
        display: none;
    }
    .react-calendar__month-view__weekdays__weekday {
        border: 1px solid #e2e2e2;
    }
    .react-calendar__tile--now {
        background: #fff;
    }
    .react-calendar__tile--active {
        background: ${THEME_COLORS.light_300};
        color: #000;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: ${THEME_COLORS.light_200};
        color: #000;
    }
    .fc .fc-col-header-cell-cushion {
        font-family: "DM_Sans_medium";
        color: #3e3e53;
    }
    div.fc-toolbar-chunk div.fc-button-group .fc-prev-button {
        padding: 9px 10px;
    }
    .fc-toolbar-chunk .fc-today-button {
        color: blue;
        background-color: ${THEME_COLORS.chips_blue_container};
        border: none;
        padding: 8px 42px;
    }
`;
const DefaultButton = styled.div`
    /* width: 130px; */
    width: fit-content;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 10px;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 8px;
    background: ${THEME_COLORS.white};
    cursor: pointer;
    color: ${THEME_COLORS.dark_500};
    font-family: "DM_sans_medium";
    font-size: 14px;
`;
const EditIcon = styled.img`
    width: 30px;
    display: block;
`;
