import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";
import ChipOval from "../../../includes/chips/ChipOval";
import { Context } from "../../../../contexts/Store";
import { patient } from "../../../../axiosConfig";
const SocioDemographicDetails = ({ handleModal }) => {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [error, SetError] = useState("");
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [informant, setInformant] = useState("");
    const [occupation, setOccupation] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [religion, setReligion] = useState("");
    const [education, setEducation] = useState("");
    const [family, setFamily] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [maritalData, setMaritalData] = useState([]);
    const [religionData, setReligionData] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [familyData, setFamilyData] = useState([]);

    const getMaritalData = () => {
        // if (access_token) {
        patient
            .get("/marital-status/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                // params: {
                //   type: sessionType,
                // },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setMaritalData(response?.data?.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
        // }
    };
    const getReligionData = () => {
        // if (access_token) {
        patient
            .get("/religion/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setReligionData(response?.data?.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
        // }
    };

    const getEducationData = () => {
        // if (access_token) {
        patient
            .get("/education/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setEducationData(response?.data?.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
        // }
    };
    const getFamilyData = () => {
        // if (access_token) {
        patient
            .get("/family/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setFamilyData(response?.data?.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
        // }
    };

    const clearStates = () => {
        setName("");
        setGender("");
        setDateOfBirth("");
        setInformant("");
        setOccupation("");
        setMaritalStatus("");
        setReligion("");
        setEducation("");
        setFamily("");
        setShowErrors(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && gender) {
            clearStates();
            handleModal(); //go to session summary page
        } else {
            setShowErrors(true);
        }
    };

    const handleCancel = () => {
        clearStates();
        handleModal();
    };
    useEffect(() => {
        getMaritalData();
        getReligionData();
        getEducationData();
        getFamilyData();
    }, [handleModal]);

    const formData = {
        patient: name,
        dob: dateOfBirth,
        informant: informant,
        marital_status: maritalStatus.id,
        religion: religion.id,
        occupation: occupation,
        family: family.id,
    };

    const handleFormSubmit = () => {
        if (name && dateOfBirth) {
            setLoading(true);
            patient
                .put("/add-socio-demographic-details/", formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then(function (response) {
                    const { StatusCode } = response.data;
                    const { access_token, refresh_token, message } =
                        response.data.data;
                    SetError("");
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                    if (error.response.status === 404) {
                        SetError("NOT_FOUND");
                    }
                    setLoading(false);
                });
        } else {
            SetError("These fields are required");
        }
    };
    return (
        <>
            <BoxWrapper>
                <Wrapper>
                    <InputWrapper className="input-wrapper">
                        <Left>
                            <FormLabel
                                htmlFor="name"
                                className={
                                    name ? "input-label show" : "input-label"
                                }
                            >
                                Patient ID
                            </FormLabel>
                            <FormInput
                                id="name"
                                name="name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Patient ID"
                                className={
                                    name ? "input-value active" : "input-value"
                                }
                            ></FormInput>
                        </Left>
                    </InputWrapper>
                    {showErrors && !name && (
                        <Error className="error">This field is required</Error>
                    )}
                </Wrapper>

                <Wrapper>
                    <InputWrapper className="input-wrapper">
                        <Left>
                            <FormLabel
                                htmlFor="gender"
                                className={
                                    gender ? "input-label show" : "input-label"
                                }
                            >
                                Gender
                            </FormLabel>
                            <FormInput
                                id="gender"
                                name="gender"
                                type="text"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                placeholder="Gender"
                                className={
                                    gender
                                        ? "input-value active"
                                        : "input-value"
                                }
                            ></FormInput>
                        </Left>
                    </InputWrapper>
                    {showErrors && !gender && (
                        <Error className="error">This field is required</Error>
                    )}
                </Wrapper>

                <Wrapper>
                    <InputWrapper className="input-wrapper">
                        <Left>
                            <FormLabel
                                htmlFor="dob"
                                className={
                                    dateOfBirth
                                        ? "input-label show"
                                        : "input-label"
                                }
                            >
                                Date Of Birth
                            </FormLabel>
                            <FormInput
                                id="dob"
                                name="dob"
                                type="text"
                                onFocus={(e) => (e.target.type = "date")}
                                onBlur={(e) => (e.target.type = "text")}
                                min="1900-01-01"
                                max={new Date().toISOString().split("T")[0]}
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                placeholder="Date Of Birth"
                                className={
                                    dateOfBirth
                                        ? "input-value active"
                                        : "input-value"
                                }
                            ></FormInput>
                        </Left>
                    </InputWrapper>
                </Wrapper>

                <Wrapper>
                    <InputWrapper className="input-wrapper">
                        <Left>
                            <FormLabel
                                htmlFor="informant"
                                className={
                                    informant
                                        ? "input-label show"
                                        : "input-label"
                                }
                            >
                                Informant
                            </FormLabel>
                            <FormInput
                                id="informant"
                                name="informant"
                                type="text"
                                value={informant}
                                onChange={(e) => setInformant(e.target.value)}
                                placeholder="Informant"
                                className={
                                    informant
                                        ? "input-value active"
                                        : "input-value"
                                }
                            ></FormInput>
                        </Left>
                    </InputWrapper>
                </Wrapper>
            </BoxWrapper>
            <SubTitle>Marital Status</SubTitle>
            <ChipWrapper>
                {maritalData?.map((item) => {
                    return (
                        <ChipOval
                            key={item.id}
                            obj={item}
                            content={item.name}
                            value={maritalStatus}
                            setValue={setMaritalStatus}
                            disabled={false}
                        />
                    );
                })}
            </ChipWrapper>
            <SubTitle>Religion</SubTitle>
            <ChipWrapper>
                {religionData?.map((item) => {
                    return (
                        <ChipOval
                            key={item.id}
                            obj={item}
                            content={item.name}
                            value={religion}
                            setValue={setReligion}
                            disabled={false}
                        />
                    );
                })}
            </ChipWrapper>
            <SubTitle>Education</SubTitle>
            <ChipWrapper>
                {educationData?.map((item) => {
                    return (
                        <ChipOval
                            key={item.id}
                            obj={item}
                            content={item.name}
                            value={education}
                            setValue={setEducation}
                            disabled={false}
                        />
                    );
                })}
            </ChipWrapper>
            <OccupationWrapper className="input-wrapper">
                <Left>
                    <FormLabel
                        htmlFor="occupation"
                        className={
                            occupation ? "input-label show" : "input-label"
                        }
                    >
                        Occupation
                    </FormLabel>
                    <FormInput
                        id="occupation"
                        name="occupation"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        placeholder="Occupation"
                        className={
                            occupation ? "input-value active" : "input-value"
                        }
                    ></FormInput>
                </Left>
            </OccupationWrapper>

            <SubTitle>Family</SubTitle>
            <ChipWrapper>
                {familyData?.map((item) => {
                    return (
                        <ChipOval
                            key={item.id}
                            obj={item}
                            content={item.name}
                            value={family}
                            setValue={setFamily}
                            disabled={false}
                        />
                    );
                })}
            </ChipWrapper>
            {error ? (
                <ErrorRed className="error-message">{error}</ErrorRed>
            ) : (
                ""
            )}
            <FormButtons>
                <ButtonTypeOne
                    text="Cancel"
                    color={THEME_COLORS.dark_500}
                    bkgcolor={THEME_COLORS.white}
                    border={THEME_COLORS.light_200}
                    onclick={handleCancel}
                />
                <ButtonTypeOne
                    text="Submit"
                    ml="20px"
                    color={THEME_COLORS.white}
                    bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                    onclick={handleFormSubmit}
                />
            </FormButtons>
        </>
    );
};

export default SocioDemographicDetails;

const BoxWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px;
`;
const Wrapper = styled.div`
    margin-bottom: 16px;
    width: 49%;
`;
const InputWrapper = styled.div`
    background-color: ${THEME_COLORS.light_300};
    border: 2px solid ${THEME_COLORS.light_300};
`;
const Left = styled.div`
    flex-grow: 1;
`;
const FormLabel = styled.label`
    &.show {
        display: block;
    }
`;
const FormInput = styled.input`
    &.active {
        font-weight: 500;
    }
`;
const Error = styled.small``;
const ChipWrapper = styled.div`
    margin-bottom: 14px;
`;
const SubTitle = styled.h4`
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const OccupationWrapper = styled(InputWrapper)`
    background-color: ${THEME_COLORS.light_200};
    border: 2px solid ${THEME_COLORS.light_200};
    width: 100%;
    margin-bottom: 24px;
`;
const FormButtons = styled.div`
    text-align: right;
    margin-top: 16px;
    display: flex;
    float: right;
`;
const ErrorRed = styled.div`
    color: red;
    font-size: 14px;
    margin-top: 20px;
`;
