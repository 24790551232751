import React from 'react'
import styled from "styled-components";

import ReportList from './ReportList';


const SessionSummaryReportList = () => {
  return (
    <Main>
        <ReportList type="summary_report" />
    </Main>
  )
}

export default SessionSummaryReportList

const Main = styled.div``