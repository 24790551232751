import React, { useContext, useEffect, useState } from 'react'
import Slide from 'react-reveal/Slide'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import edit from '../../../assets/image/reports/edit.svg'
import { cases, patient } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ButtonTypeTwo from '../../includes/buttons/ButtonTypeTwo'
import ChipRectangle from '../../includes/chips/ChipRectangle'
import AttentionModal from '../manage_profile/modals/AttentionModal'
import SuccessToast from '../manage_profile/toast/SuccessToast'
import ModalStructure from '../session_summary/modals/ModalStructure'
import BasicReport from './BasicReport'
import FollowUp from './FollowUp'
import RecommendedVideos from './RecommendedVideos'
import useScrollBlock from '../../includes/useScrollBlock'
import SocioDemographicDetails from '../session_summary/modals/SocioDemographicDetails'
import SocioDemoGraphicModalComponent from '../../includes/modals/PatientDetailsModal'

function CaseReportPage() {
  const { state, dispatch } = useContext(Context)
  const access_token = state?.user_details?.access_token
  const activeOption = state.user_details.activeOption
  const formType = state.user_details.formType
  const navigate = useNavigate()

  const [symptoms, setSymptoms] = useState('')
  const [diagnosis, setDiagnosis] = useState('')
  const [recommendation, setRecommendation] = useState('')
  const [prescription, setPrescription] = useState('')
  const [treatmentPlan, setTreatmentPlan] = useState('')
  const [notes, setNotes] = useState('')

  const [patientDetails, setPatientDetails] = useState([])
  const [caseDetails, setCaseDetails] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [showAttentionModal, setShowAttentionModal] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [
    isUpdatePatientDetailsModal,
    setIsUpdatePatientDetailsModal,
  ] = useState(false)
  const [completedOptions, setCompletedOptions] = useState([])
  const [
    isBasicFormsSubmittedFields,
    setIsBasicFormsSubmittedFields,
  ] = useState({
    isChiefComplaintsSubmitted: false,
    isPresentingHistorySubmitted: false,
    isPastIllnessSubmitted: false,
    isFamilyHistorySubmitted: false,
    isPersonalHistorySubmitted: false,
    isPremorbidSubmitted: false,
    isMentalStatusSubmitted: false,
  })
  const [isFollowUp, setIsFollowUp] = useState(false)

  // session id
  const { id } = useParams()
  const [draftModal, setdraftModal] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (isUpdatePatientDetailsModal) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [isUpdatePatientDetailsModal])

  const openPatientModal = () => {
    setIsUpdatePatientDetailsModal(true)
    // document.body.style.overflow = "hidden"; // Disable background scrolling
  }

  const closeMPatientodal = () => {
    setIsUpdatePatientDetailsModal(false)
    // document.body.style.overflow = "auto"; // Enable background scrolling
  }

  const handleModalClose = () => {
    setIsUpdatePatientDetailsModal(false)
  }

  const clearStates = () => {
    setSymptoms('')
    setDiagnosis('')
    setRecommendation('')
    setPrescription('')
    setTreatmentPlan('')
    setNotes('')
    setShowErrors(false)
    setIsFollowUp(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (symptoms && diagnosis && recommendation && treatmentPlan) {
      setShowSuccessToast(true)
      clearStates()
      const timeId = setTimeout(() => {
        setShowSuccessToast(false)
        clearTimeout(timeId)
        navigate(-1)
      }, 2000)
    } else {
      setShowErrors(true)
    }
  }

  const handleCancel = () => {
    if (
      symptoms ||
      diagnosis ||
      recommendation ||
      treatmentPlan ||
      notes
      // videoLinksArray.length > 0 ||
      // Object.keys(timeSlot).length !== 0
    )
      setShowAttentionModal(true)
    else {
      navigate(-1)
    }
  }

  const getPatientData = () => {
    if (access_token) {
      patient
        .get(`/patient-details/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const { StatusCode } = response.data
          if (StatusCode == 6000) {
            setPatientDetails(response.data.data)
          } else if (StatusCode == 6001) {
            setShowErrors(response)
            // console.log(showErrors, 'error')
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setShowErrors(error.message)
        })
    }
  }

  const getCaseData = () => {
    if (access_token) {
      cases
        .get(`/case-details/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const { StatusCode, data } = response.data
          if (StatusCode == 6000) {
            setCaseDetails(data)
            setIsBasicFormsSubmittedFields({
              isChiefComplaintsSubmitted: data.is_chief_complaints,
              isPresentingHistorySubmitted: data.is_present_illness,
              isPastIllnessSubmitted: data.is_past_illness,
              isFamilyHistorySubmitted: data.is_family_history,
              isPersonalHistorySubmitted: data.is_personal_history,
              isPremorbidSubmitted: data.is_premorbid_personality,
              isMentalStatusSubmitted: data.is_mental_status_examination,
            })
          } else if (StatusCode == 6001) {
            setShowErrors(response)
            // console.log(showErrors, 'error')
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setShowErrors(error.message)
        })
    }
  }

  useEffect(() => {
    window.scroll(0, 0)
    getPatientData()
    getCaseData()
  }, [id])

  return (
    <Background>
      <Main
        className={
          showAttentionModal == true || draftModal == true ? 'modal-active' : ''
        }
      >
        {isUpdatePatientDetailsModal && (
          <SocioDemoGraphicModalComponent
            isUpdatePatientDetailsModal={isUpdatePatientDetailsModal}
            closeMPatientModal={closeMPatientodal}
            sessionId={id}
            getPatientData={getPatientData}
            patientDetails={patientDetails}
          />
        )}
        <Wrapper>
          <Head>
            <Title>Case Report</Title>
            <CaseId>
              Case ID : <span>{id}</span>
            </CaseId>
          </Head>
          <Content>
            <Left>
              <Box className="name">
                <Top>
                  <ImageBox>
                    <img
                      src={
                        require('../../../assets/image/session_summary/profile_picture.svg')
                          .default
                      }
                      alt="profile"
                    />
                  </ImageBox>
                  <Data>
                    <h5>{caseDetails.concern}</h5>
                    <p>{patientDetails.full_name}</p>
                  </Data>
                </Top>
                <Bottom>
                  {/* date */}
                  <ChipRectangle
                    text={true}
                    image="calendar.svg"
                    content={caseDetails.case_date}
                    bkgcolor={THEME_COLORS.chips_blue_container}
                    color={THEME_COLORS.chips_blue_on_container_2}
                    pd="4px 8px 4px 3px"
                    br="4px"
                    font_size="12px"
                    font_weight="400"
                    image_width="11.33px"
                  />
                  {/* video */}
                  {caseDetails.meet_mode == 'Video' ? (
                    <ChipRectangle
                      image="video_camera.svg"
                      bkgcolor={THEME_COLORS.chips_green_container}
                      mr_left="10px"
                      pd="4px"
                      br="4px"
                      image_width="13.33px"
                    />
                  ) : (
                    <ChipRectangle
                      image="call_green.svg"
                      bkgcolor={THEME_COLORS.chips_green_container}
                      mr_left="10px"
                      pd="4px"
                      br="4px"
                      image_width="13.33px"
                    />
                  )}
                </Bottom>
              </Box>
              <Box>
                <DetailsTop>
                  <SubTitle>Patient Details</SubTitle>
                  <ButtonTypeTwo
                    edit={edit}
                    text="Update details"
                    onclick={() => {
                      window.scroll(0, 0)
                      // setIsUpdatePatientDetailsModal(
                      //     true
                      // );
                      openPatientModal()
                    }}
                  />
                </DetailsTop>
                <PatientDetailsBottom>
                  <Item>
                    <Label>Patient Name</Label>
                    <Value>
                      :<span>{patientDetails.full_name}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Informant</Label>
                    <Value>
                      :<span>{patientDetails.informant}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Age</Label>
                    <Value>
                      :<span>{patientDetails.age}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Gender</Label>
                    <Value>
                      :<span>{patientDetails.gender}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Date of Birth</Label>
                    <Value>
                      :<span>{patientDetails.dob}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Marital Status</Label>
                    <Value>
                      :<span>{patientDetails.marital_status}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Education</Label>
                    <Value>
                      :<span>{patientDetails.education}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Religion</Label>
                    <Value>
                      :<span>{patientDetails.religion}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Occupation</Label>
                    <Value>
                      :<span>{patientDetails.occupation}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Family</Label>
                    <Value>
                      :<span>{patientDetails.family}</span>
                    </Value>
                  </Item>
                </PatientDetailsBottom>
              </Box>
              <Box>
                <DetailsTop>
                  <SubTitle>Case Details</SubTitle>
                </DetailsTop>
                <CaseDetailsBottom>
                  <Item>
                    <Label>Case ID</Label>
                    <Value>
                      :<span>{caseDetails.session_id}</span>
                    </Value>
                  </Item>
                  <Item>
                    <Label>No of Sessions</Label>
                    <Value>
                      :<span>{caseDetails.total_sessions}</span>
                    </Value>
                  </Item>{' '}
                  <Item>
                    <Label>Current Session</Label>
                    <Value>
                      :<span>{caseDetails.current_session_number}</span>
                    </Value>
                  </Item>
                  <SessionDetailsBottom>
                    <Item>
                      <Label>Session ID</Label>
                      <Value>
                        :<span>{caseDetails.session_record_id}</span>
                        {/* <span>34567890</span> */}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Expert Name</Label>
                      <Value>
                        :<span>{caseDetails.expert_name}</span>
                      </Value>
                    </Item>
                    <Item>
                      <Label>Date of session</Label>
                      <Value>
                        :<span>{caseDetails.case_date}</span>
                      </Value>
                    </Item>
                    <Item>
                      <Label>Session Type</Label>
                      <Value>
                        :<span>{caseDetails.meet_mode}</span>
                      </Value>
                    </Item>
                  </SessionDetailsBottom>
                </CaseDetailsBottom>
              </Box>
            </Left>
            <Right>
              <RightTop>
                {formType == 'Chief complaints & Summary' && (
                  <Options>
                    <OptionWrapper>
                      <Circle
                        className={
                          (activeOption === 1 ||
                            completedOptions.includes(1)) &&
                          'active'
                        }
                      >
                        {completedOptions.includes(1) ? (
                          <img
                            src={
                              require('../../../assets/image/session_summary/check_white.svg')
                                .default
                            }
                            alt="tick"
                          />
                        ) : (
                          '1'
                        )}
                      </Circle>
                      <Option className={activeOption === 1 && 'active'}>
                        Basic Report
                      </Option>
                    </OptionWrapper>
                    <Line></Line>
                    <OptionWrapper>
                      <Circle
                        className={
                          (activeOption === 2 ||
                            completedOptions.includes(2)) &&
                          'active'
                        }
                      >
                        {completedOptions.includes(2) ? (
                          <img
                            src={
                              require('../../../assets/image/session_summary/check_white.svg')
                                .default
                            }
                            alt="tick"
                          />
                        ) : (
                          '2'
                        )}
                      </Circle>
                      <Option className={activeOption === 2 && 'active'}>
                        Recommended Videos
                      </Option>
                    </OptionWrapper>
                    <Line></Line>
                    <OptionWrapper>
                      <Circle
                        className={
                          (activeOption === 3 ||
                            completedOptions.includes(3)) &&
                          'active'
                        }
                      >
                        {completedOptions.includes(3) ? (
                          <img
                            src={
                              require('../../../assets/image/session_summary/check_white.svg')
                                .default
                            }
                            alt="tick"
                          />
                        ) : (
                          '3'
                        )}
                      </Circle>
                      <Option className={activeOption === 3 && 'active'}>
                        Follow Up
                      </Option>
                    </OptionWrapper>
                  </Options>
                )}
                <Form>
                  {activeOption === 1 && (
                    <BasicReport
                      formType={formType}
                      setdraftModal={setdraftModal}
                      draftModal={draftModal}
                      setShowAttentionModal={setShowAttentionModal}
                      activeOption={activeOption}
                      isBasicFormsSubmittedFields={isBasicFormsSubmittedFields}
                      gender={patientDetails.gender}
                      id={id}
                      getCaseData={getCaseData}
                    />
                  )}
                  {activeOption === 2 && (
                    <RecommendedVideos
                      caseDetails={caseDetails}
                      // setActiveOption={setActiveOption}
                      setShowAttentionModal={setShowAttentionModal}
                      activeOption={activeOption}
                      isVideosAdded={
                        isBasicFormsSubmittedFields.isChiefComplaintsSubmitted
                      }
                    />
                  )}
                  {activeOption === 3 && (
                    <FollowUp
                      // setActiveOption={setActiveOption}
                      setShowAttentionModal={setShowAttentionModal}
                      activeOption={activeOption}
                      isFollowUp={isFollowUp}
                      setIsFollowUp={setIsFollowUp}
                      currentSession={caseDetails?.current_session_number}
                    />
                  )}
                </Form>
              </RightTop>
            </Right>
          </Content>
        </Wrapper>
        {showAttentionModal && (
          <AttentionModal
            setShowAttentionModal={setShowAttentionModal}
            showAttentionModal={showAttentionModal}
            handleSubmit={handleSubmit}
          />
        )}
        {/* {draftModal && (
            <DraftModal
              setShowAttentionModal={setdraftModal}
              showAttentionModal={draftModal}
              // handleSubmit={handleSubmit}
            />
          )} */}
        {showSuccessToast && <SuccessToast />}
      </Main>
      {/* </Slide> */}
    </Background>
  )
}

export default CaseReportPage

const Background = styled.div`
    width: 100%;
    /* padding-top: 69.6px; */
    /* background: ${THEME_COLORS.elevation_expert_e7}; */
    /* /* position: absolute; */
    /* top: 0;
    right: 0;
    left: 0;
    z-index: 3000; */
    /* height: -webkit-fill-available; */
`
const Main = styled.div`
  background-color: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 40px 0;
  height: -webkit-fill-available;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.modal-active {
    max-height: calc(100vh - 69.6px);
    overflow: hidden;
  }
`
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  /* overflow: hidden; */
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`
const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: ${THEME_COLORS.black};
`
const CaseId = styled.p`
  color: ${THEME_COLORS.text_para};
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  span {
    color: ${THEME_COLORS.text_title};
    font-weight: 400;
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  width: 35%;
  @media all and (max-width: 1280px) {
    width: 40%;
  }
  @media all and (max-width: 980px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 32px;
  }
`
const Box = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  box-shadow: ${THEME_COLORS.elevation_expert_e1};
  background-color: ${THEME_COLORS.white};
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 12px;
  @media all and (max-width: 980px) {
    width: 49%;
    &.name {
      width: 100%;
    }
  }
`
const Top = styled.div`
  display: flex;
  align-items: center;
`
const ImageBox = styled.div`
  width: 46px;
  margin-right: 10px;
  img {
    width: 100%;
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 50%;
  }
`
const Data = styled.div`
  h5 {
    font-size: 16px;
    font-weight: 500;
    color: ${THEME_COLORS.text_title};
    line-height: 20px;
    margin-bottom: 5px;
  }
  p {
    color: ${THEME_COLORS.chips_blue_on_container};
    font-size: 14px;
    font-weight: 400;
  }
`
const Bottom = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 10px;
`
const DetailsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${THEME_COLORS.text_title};
`
const PatientDetailsBottom = styled.div`
  margin-top: 10px;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  max-width: 450px;

  &:first-child {
    margin-top: 0;
  }
`
const Label = styled.p`
  width: 40%;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
`
const Value = styled.p`
  width: -webkit-fill-available;
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  span {
    color: ${THEME_COLORS.text_title};
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
  }
`
const CaseDetailsBottom = styled.div`
  margin-top: 10px;
`
const SessionDetailsBottom = styled.div`
  background: ${THEME_COLORS.light_200};
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px;
`
const Right = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media all and (max-width: 1280px) {
    width: 55%;
  }
  @media all and (max-width: 980px) {
    width: 100%;
  }
`
const RightTop = styled.div`
  /* border-bottom: 1px solid #d3d3d3; */
`

const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 16px;
  border-bottom: 1px solid ${THEME_COLORS.light_400};
  margin-bottom: 32px;
`
const OptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  &.last-child {
    margin-right: 0;
  }
`
const Circle = styled.div`
  width: 25px;
  height: 25px;
  background: ${THEME_COLORS.white};
  color: ${THEME_COLORS.chips_blue_on_container_2};
  border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  &.active {
    background: ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.white};
    opacity: 1;
  }
`
const Option = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
`
const Line = styled.div`
  width: 12px;
  border: 1px solid ${THEME_COLORS.light_400};
  margin-right: 12px;
`
const Form = styled.div`
  /* height: -webkit-fill-available; */
`
const RightBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #d3d3d3;

  @media all and (max-width: 980px) {
    margin-top: 50px;
  }
`
const ButtonLeft = styled.div``
const ButtonRight = styled.div`
  display: flex;
`
const TagContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`
const Tag = styled.li`
  margin-right: 10px;
  margin-bottom: 10px;
`
const SocioDemoGraphicModalBg = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SocioDemoGraphicModal = styled.div`
  position: absolute;
  width: 720px;
  min-height: 360px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 40px;
  background: ${THEME_COLORS.white};
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
`
