import React from 'react'
import styled from 'styled-components'

function ProfileHeader({ completed }) {
  let path = window.location.pathname

  return (
    <>
      <Main>
        <Wrapper>
          <Left>
            <Logo>
              <LogoImage
                src={
                  require('../../assets/image/loginpage/Logofull.svg').default
                }
                alt="logo_full"
              />
            </Logo>
          </Left>
          <Right>
            <Container>
              <IconContainer>
                {path !== '/auth/profile-about-you' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/tik.svg').default
                    }
                    alt="tik_icon"
                  />
                ) : (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/onefiled.svg')
                        .default
                    }
                    alt="onefiled"
                  />
                )}
              </IconContainer>
              <Text
                className={path == '/auth/profile-about-you' ? 'active' : ''}
              >
                About you
              </Text>
            </Container>
            <BarContainer>
              <Bar
                src={require('../../assets/image/loginpage/line.svg').default}
                alt="line_small"
              />
            </BarContainer>
            <Container>
              <IconContainer>
                {path == '/auth/profile-about-you' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/twoblank.svg')
                        .default
                    }
                    alt="tik"
                  />
                ) : path == '/auth/expert-details' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/twofiled.svg')
                        .default
                    }
                    alt="twofiled"
                  />
                ) : (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/tik.svg').default
                    }
                    alt="tik"
                  />
                )}
              </IconContainer>
              <Text className={path === '/auth/expert-details' ? 'active' : ''}>
                Expert Details
              </Text>
            </Container>
            <BarContainer>
              <Bar
                src={require('../../assets/image/loginpage/line.svg').default}
                alt="line_small"
              />
            </BarContainer>
            <Container>
              <IconContainer>
                {path == '/auth/working-status' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/threefiled.svg')
                        .default
                    }
                    alt="icon"
                  />
                ) : path == '/auth/your-bio' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/tik.svg').default
                    }
                    alt="icon"
                  />
                ) : (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/threeblank.svg')
                        .default
                    }
                    alt="icon"
                  />
                )}
              </IconContainer>
              <Text className={path === '/auth/working-status' ? 'active' : ''}>
                Working Status
              </Text>
            </Container>
            <BarContainer>
              <Bar
                src={require('../../assets/image/loginpage/line.svg').default}
                alt="icon"
              />
            </BarContainer>
            <Container>
              <IconContainer>
                {path == '/auth/your-bio' ? (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/fourfilled.svg')
                        .default
                    }
                    alt="icon"
                  />
                ) : (
                  <Icon
                    src={
                      require('../../assets/image/loginpage/four.svg').default
                    }
                    alt="icon"
                  />
                )}
              </IconContainer>
              <Text className={path === '/auth/your-bio' ? 'active' : ''}>
                Your Bio
              </Text>
            </Container>
          </Right>
        </Wrapper>
      </Main>
    </>
  )
}

export default ProfileHeader
const Main = styled.div`
  border-bottom: 1px solid #efefef;
`
const Wrapper = styled.div`
  width: 80%;
  padding: 16px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media all and (max-width: 900px) {
    width: 90%;
  }
`
const Left = styled.div`
  width: 156px;
  height: 44px;
  display: flex;
  align-items: center;
  @media all and (max-width: 580px) {
    width: 120px;
  }
  @media all and (max-width: 480px) {
    width: 100px;
  }
`
const Logo = styled.div`
  width: 100%;
`
const LogoImage = styled.img`
  display: block;
  width: 100%;
`
const Right = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  @media all and (max-width: 480px) {
    gap: 8px;
  }
`
const Container = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  @media all and (max-width: 850px) {
    flex-direction: column;
  }
`
const IconContainer = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 580px) {
    width: 20px;
    height: 20px;
  }
`
const Icon = styled.img`
  display: block;
  width: 100%;
`
const Text = styled.span`
  color: #9f9fa9;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  &.active {
    color: #3e3e53;
  }
  @media all and (max-width: 480px) {
    display: none;
    &.active {
      display: block;
    }
  }
  @media all and (max-width: 580px) {
    font-size: 12px;
  }
`
const BarContainer = styled.div`
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 680px) {
    display: none;
  }
`

const Bar = styled.img``
