import React, { useEffect } from 'react'
import { useContext } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Context } from '../../../contexts/Store'

function AuthRoute({ children }) {
  const { state, dispatch } = useContext(Context)

  let user_details = localStorage.getItem('user_details')
  user_details = JSON.parse(user_details)

  const is_verified = state.user_details.is_verified
  const [searchParams] = useSearchParams()
  const nextPath = searchParams.get('next') ? searchParams.get('next') : '/'
  return !is_verified ? children : <Navigate to={nextPath} />
}

export default AuthRoute
