import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import DropDown from '../../includes/dropdown/DropDown'
import AttentionModal from './modals/AttentionModal'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import CancelWarningModal from './modals/CancelWarningModal'

const WorkingStatus = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const [statuses, setStatuses] = useState([])
  const [selectedStatusName, setSelectedStatusName] = useState('')
  const [selectedStatusID, setSelectedStatusID] = useState('')
  const [employerName, setEmployerName] = useState('')
  const [employerLocation, setEmployerLocation] = useState('')

  const [showWorkingStatus, setShowWorkingStatus] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const navigate = useNavigate()
  const [statusesModal, setStatusesModal] = useState(false)
  const [err, setErr] = useState('')

  useEffect(() => {
    // if (access_token) {
    expert
      .get('/working-statuses/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        setStatuses(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
    // }
  }, [access_token])

  const handleSubmit = (close) => {
    setShowSuccessToast(true)
    if (close === 'close') {
      setTimeout(() => {
        navigate(-1)
      }, 2000)
    }
  }

  const handleCancel = () => {
    if (selectedStatusID || employerName || employerLocation)
      setShowAttentionModal(true)
    else {
      navigate(-1)
    }
  }

  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setShowWorkingStatus(false)
    }
  }
  const handleStatusSelection = (name, id) => {
    setSelectedStatusName(name)
    setSelectedStatusID(id)
    setStatusesModal(false)
  }

  const renderStatuses = () => {
    return statuses.map((status) => (
      <DropDownsText
        key={status.id}
        onClick={() => handleStatusSelection(status.name, status.id)}
      >
        {status.name}
      </DropDownsText>
    ))
  }

  const getWorkingStatus = () => {
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-working-status/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        console.log(response.data, 'response.data')
        if (StatusCode === 6000) {
          setSelectedStatusName(data.working_status_name)
          setSelectedStatusID(data.working_status)
          setEmployerName(data.employer_name)
          setEmployerLocation(data.location)
        } else {
        }
      })
      .catch((error) => {})
  }
  useEffect(() => {
    getWorkingStatus()
  }, [])

  const handleFinalSubmit = () => {
    if (selectedStatusID && employerName) {
      const data = {
        working_status: selectedStatusID,
        employer_name: employerName,
        location: employerLocation,
      }
      expert
        .put('/add-working-status/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data
          // console.log(response.data.data)
          handleSubmit()
          setErr('')
        })
        .catch((error) => {
          console.error('Error creating post personal-infooo:', error)
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
        })
    } else {
      setErr('*This field is mandatory')
    }
  }
  // console.log(selectedID, 'selectedID')
  return (
    <Form onClick={(e) => showWorkingStatus && handleOuterClick(e)}>
      <FormData>
        <Head>Working Status</Head>
        <TitleWrapper>
          <Title>Employer Details*</Title>
          <SubTitle>Provide your Employer Details</SubTitle>
        </TitleWrapper>
        <Details>
          <PersonalBox onClick={() => setStatusesModal(!statusesModal)}>
            <SelectedDataBox>
              <p>Working Status *</p>
              <span>{selectedStatusName && selectedStatusName}</span>{' '}
            </SelectedDataBox>
            <ListDown>
              <ListIcon
                src={
                  require('../../../assets/image/loginpage/list_down.svg')
                    .default
                }
                alt="list_down"
              />
            </ListDown>
            <DropList className={statusesModal && 'view-modal'}>
              {renderStatuses()}
            </DropList>
          </PersonalBox>
          {selectedStatusID == '' && err ? (
            <ErrorRed className="error-message">{err}</ErrorRed>
          ) : (
            ''
          )}
        </Details>

        <Wrapper>
          <InputWrapper className="input-wrapper">
            <Left>
              <FormLabel
                htmlFor="employer_name"
                className={employerName ? 'input-label show' : 'input-label'}
              >
                Name of Employer*
              </FormLabel>
              <FormInput
                id="employer_name"
                name="employerName"
                value={employerName}
                type="text"
                onChange={(e) => setEmployerName(e.target.value)}
                placeholder="Name of Employer*"
                className={employerName ? 'input-value active' : 'input-value'}
              ></FormInput>
            </Left>
          </InputWrapper>
          {showErrors && !employerName && (
            <ErrorRed className="error">This field is required.</ErrorRed>
          )}
        </Wrapper>

        <Wrapper>
          <InputWrapper className="input-wrapper">
            <Left>
              <FormLabel
                htmlFor="employer_location"
                className={
                  employerLocation ? 'input-label show' : 'input-label'
                }
              >
                Location of Employer
              </FormLabel>
              <FormInput
                id="employer_location"
                name="employerLocation"
                value={employerLocation}
                type="text"
                onChange={(e) => setEmployerLocation(e.target.value)}
                placeholder="Location of Employer"
                className={
                  employerLocation ? 'input-value active' : 'input-value'
                }
              ></FormInput>
            </Left>
          </InputWrapper>
          {showErrors && !employerLocation && (
            <ErrorRed className="error">This field is required.</ErrorRed>
          )}
        </Wrapper>
      </FormData>
      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_400}
          onclick={handleCancel}
        />
        <ButtonTypeOne
          text="Save"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
          onclick={handleFinalSubmit}
        />
      </FormButtons>
      {showAttentionModal && (
        <CancelWarningModal
          isModal={showAttentionModal}
          setIsModal={setShowAttentionModal}
        />
      )}
    </Form>
  )
}

export default WorkingStatus

const Form = styled.form`
  min-height: calc(100vh - 229.6px);
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const FormData = styled.div``

const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const TitleWrapper = styled.div`
  margin-bottom: 16px;
`
const Title = styled.h5`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
`
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const Wrapper = styled.div`
  margin-bottom: 16px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small``

const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const FormInput = styled.input`
  &.active {
    font-weight: 500;
    font-size: 16px !important;
  }
`
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PersonalBox = styled.div`
  user-select: none;
  cursor: pointer;
  p {
    color: #818181;
    margin: 0;
    font-size: 14px;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 9%;
  width: 100%;
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;

  //new
  position: relative;
`
const ListDown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const ListIcon = styled.img``
const SelectedDataBox = styled.div``
const DropList = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: all 0.5 ease-in-out;

  &.view-modal {
    height: auto;
    transition: all 0.5 ease-in-out;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  }
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 64px;
  left: 0;
  border-radius: 5px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: scroll;
`
const DropDownsText = styled.div`
  padding: 10px 25px;
`

const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 20px;
`
const Details = styled.div`
  margin-bottom: 3%;
`
