import React from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'

const DraftsModal = ({ handleModal }) => {
  return (
    <>
      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_200}
          onclick={handleModal}
        />
        <ButtonTypeOne
          text="Submit"
          ml="20px"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
        />
      </FormButtons>
    </>
  )
}

export default DraftsModal

const FormButtons = styled.div`
  text-align: right;
  margin-top: 16px;
  display: flex;
  float: right;
  /* width: 30%; */
`
