import React, { useState } from 'react'
import { THEME_COLORS } from '../../../ThemeConfig'
import styled from 'styled-components'
import down_arrow from '../../../assets/image/dashboard/arrows.svg'

function DateDropdown({ setDateModal, datemodal, month }) {
  return (
    <>
      <DateContainer
        onClick={() => {
          setDateModal(!datemodal)
        }}
      >
        <Date>{month}</Date>
        <Icon src={down_arrow} alt="icon" />
      </DateContainer>
    </>
  )
}

export default DateDropdown

const Date = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-family: 'DM_sans_medium';
  font-size: 14px;
  margin-right: 5px;
`
const Icon = styled.img``
const DateContainer = styled.div`
  width: 130px;
  display: flex;
  justify-content: center;
  padding: 8px 15px;
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 8px;
  background: ${THEME_COLORS.white};
  cursor: pointer;
`
