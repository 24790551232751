import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";
import { Context } from "../../../../contexts/Store";
// import PendingWarningModal from "./PendingWarningModal";
import { expert } from "../../../../axiosConfig";
import PendingWarningModal from "./PendingWarningModal";

const DeleteWarnigModal = ({ isModal, setIsModal }) => {
    const navigate = useNavigate();

    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [PendingModal, setdPendingModal] = useState(false);
    const [error, setError] = useState("");
    const active_session = state.user_details.active_session;
    const [isLoading, setIsLoading] = useState(false);

    const handleOuterClick = (e) => {
        const element = document.querySelector(".outer-click");
        if (!element.contains(e.target)) {
            setIsModal(false);
        }
    };

    const handleCancel = () => {
        setIsModal(false);
    };

    const handleSaveAndCancel = (e) => {
        if (active_session == false) {
            expert
                .post(
                    "/expert-account-delete/",
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then(function (response) {
                    const { StatusCode } = response.data;
                    const { access_token, refresh_token, message } =
                        response.data.data;

                    if (StatusCode == 6000) {
                        localStorage.clear();
                        window.location = "/";
                        navigate("/");
                        alert("user deleted successfully");
                    } else if (StatusCode == 6001) {
                        setError(response.data.data.message);
                        setIsLoading(false);
                    } else {
                        alert("Some error occured. Please try later");
                        setIsLoading(false);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.data.message);
                    setIsLoading(false);
                });
        } else {
            setdPendingModal(true);
        }
    };

    return (
        <>
            <Background
                onClick={(e) => isModal && handleOuterClick(e)}
            ></Background>

            <Container className="outer-click">
                <Title>⚠️ Are you sure you want to Delete Your Account?</Title>
                <SubTitle>
                    This action is irreversible and will permanently remove all
                    of your account data
                </SubTitle>
                <ButtonContainer>
                    <ButtonTypeOne
                        text="Cancel"
                        color={THEME_COLORS.dark_500}
                        bkgcolor={THEME_COLORS.white}
                        border={THEME_COLORS.light_400}
                        onclick={handleCancel}
                    />
                    <ButtonTypeOne
                        text="Delete"
                        color={THEME_COLORS.white}
                        bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                        onclick={handleSaveAndCancel}
                    />
                </ButtonContainer>
            </Container>
            {PendingModal && (
                <PendingWarningModal
                    isModal={PendingModal}
                    setIsModal={setdPendingModal}
                    DeleteWarnigModal={setIsModal}
                />
            )}
        </>
    );
};

export default DeleteWarnigModal;

const Background = styled.div`
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    opacity: 0.4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8888;
`;
const Container = styled.div`
    position: fixed;
    width: 411px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    height: 300px;
    flex-direction: column;
    justify-content: center;
`;
const Title = styled.h4`
    font-weight: 600;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 16px;
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
