import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

function CaseReportChip({ Icon, Text }) {
    return (
        <MainContainer>
            <CaseReportDiv>
                <Left>
                    <img src={Icon} alt="Icon" />
                </Left>
                <Right>{Text}</Right>
            </CaseReportDiv>
        </MainContainer>
    );
}

export default CaseReportChip;
const MainContainer = styled.div``;
const CaseReportDiv = styled.div`
    display: flex;
    justify-content: center;
    width: max-content;
    padding: 8px 15px;
    border-radius: 8px;
    background: ${THEME_COLORS.chips_blue_container};
    margin-right: 10px;
`;
const Left = styled.div`
    margin-right: 5px;
    img {
        display: block;
        width: 100%;
    }
`;
const Right = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;
