import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import { expert } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import TimeSlotChip from './TimeSlotChip'

const DefaultSlotCard = ({
  closeModal,
  setModal,
  showSuccessToast,
  setShowSuccessToast,
}) => {
  const [isSlotsLoading, setIsSlotsLoading] = useState(false)
  const [isLeave, setIsLeave] = useState(false)
  const [allMorningSlots, setAllMorningSlots] = useState([])
  const [allAfternoonSlots, setAllAfternoonSlots] = useState([])
  const [allEveningSlots, setAllEveningSlots] = useState([])
  const [allNightSlots, setAllNightSlots] = useState([])

  const [selectedSlots, setSelectedSlots] = useState([])

  // old states
  const [slots, setSlots] = useState([])
  const [day, setDay] = useState(new Date().getDay() - 1)
  const [isChecked, setIsChecked] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [morning, setMorning] = useState([])
  const [morningSlots, setMorningSlots] = useState([])
  const [morningSlotID, setMorningSlotID] = useState([])

  // useEffect(() => {
  //     console.log(morningSlots, "morningSlots");
  // }, [morningSlots]);

  // useEffect(() => {
  //     console.log(selectedSlots, "selectedSlots");
  // }, [selectedSlots]);

  const [afterNoon, setAfterNoon] = useState([])
  const [afterNoonSlot, setAfternoonSlot] = useState([])
  const [afterNoonSlotID, setAfternoonSlotID] = useState([])

  const [evening, setEvening] = useState([])
  const [eveningSlot, setEveningSlot] = useState([])
  const [eveningSlotID, setEveningSlotID] = useState([])

  const [night, setNight] = useState([])
  const [nightSlot, setNightSlot] = useState([])
  const [nightSlotID, setNightSlotID] = useState([])

  const [selectedID, setSelectedID] = useState([])
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  const handleToggle = () => {
    setIsLeave((prev) => !prev)
    if (selectedSlots.length > 0) {
      setSelectedSlots([])
    } else {
      // setLeaveError(true);
      alert('Please select at least a time slot')
    }
  }

  const weekdayNames = [
    {
      id: 0,
      day: 'Monday',
    },
    {
      id: 1,
      day: 'Tuesday',
    },
    {
      id: 2,
      day: 'Wednesday',
    },
    {
      id: 3,
      day: 'Thursday',
    },
    {
      id: 4,
      day: 'Friday',
    },
    {
      id: 5,
      day: 'Saturday',
    },
    {
      id: 6,
      day: ' Sunday',
    },
  ]
  const [selectedWeekDay, setSelectedWeekDay] = useState(weekdayNames[0].id)

  useEffect(() => {
    setIsSlotsLoading(true)
    // if (access_token) {
    expert
      .get(`/default-time-slots/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          week_day: selectedWeekDay,
        },
      })
      .then((response) => {
        // console.log(response.data, 'specialization data')
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setAllMorningSlots(data.morning)
          setAllAfternoonSlots(data.after_noon)
          setAllEveningSlots(data.evening)
          setAllNightSlots(data.night)
          setSelectedSlots(
            Object.values(data).flatMap((section) =>
              section.filter((item) => item.active).map((item) => item.id),
            ),
          )
          setTimeout(() => {
            setIsSlotsLoading(false)
          }, 100)
        } else {
          setIsSlotsLoading(false)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setIsSlotsLoading(false)
      })
    // }
  }, [selectedWeekDay])

  useEffect(() => {
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...morningSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...afterNoonSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...eveningSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...nightSlotID])

    return () => {
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !morningSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !afterNoonSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !eveningSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !nightSlotID.includes(id)),
      )
    }
  }, [morningSlotID, afterNoonSlotID, eveningSlotID, nightSlotID])

  const formData = {
    week_day: selectedWeekDay,
    time_slots: selectedSlots,
  }
  const handleSubmit = () => {
    if (selectedSlots.length > 0) {
      setLoading(true)
      expert
        .put('/add-default-time-slots/', formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data
          setShowSuccessToast(true)
          setErr('')
          if (selectedWeekDay !== 6) {
            setSelectedWeekDay((prev) => prev + 1)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error('Something went wrong', error)
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
          setLoading(false)
        })
    } else {
      setErr('select at least one slot')
    }
  }

  useEffect(() => {
    if (showSuccessToast) {
      setTimeout(() => {
        setShowSuccessToast(false)
      }, 3000)
    }
  }, [showSuccessToast])

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr('')
      }, 1000)
    }
  }, [err])

  useEffect(() => {
    if (selectedSlots.length > 0) {
      if (isLeave) {
        setIsLeave(false)
      }
    } else {
      setIsLeave(true)
    }
  }, [selectedSlots, isLeave])

  return (
    <>
      <ContentBox className="click-form">
        <SubTitleWrapper2>
          <DateContainer>
            {weekdayNames.map((item) => (
              <Dates
                className={selectedWeekDay == item.id ? 'active' : ''}
                onClick={() => {
                  setSelectedWeekDay(item.id)
                }}
              >
                {item.day}
              </Dates>
            ))}
          </DateContainer>
        </SubTitleWrapper2>

        <Middle>
          <SubTitleWrap>
            {!isSlotsLoading && (
              <>
                <TitleWrap>
                  <DefaultTitle>
                    <TitleSlot>Leave</TitleSlot>
                    <Describtion>Turn on if you are leave</Describtion>
                  </DefaultTitle>
                  <ToggleEvent>
                    <ToggleContainer>
                      <ToggleLabel>
                        <ToggleInputChecked
                          type="checkbox"
                          checked={isLeave}
                          onChange={handleToggle}
                        />
                        <ToggleSlider />
                      </ToggleLabel>
                    </ToggleContainer>
                  </ToggleEvent>
                </TitleWrap>
                <div className="border"></div>
              </>
            )}
          </SubTitleWrap>

          {!isSlotsLoading && (
            <>
              <TimeSlotsContainer>
                <TimeSlotChip
                  time={morning}
                  timeSlots={allMorningSlots}
                  setTimeSlots={setAllMorningSlots}
                  selectedSlots={selectedSlots}
                  setSelectedSlots={setSelectedSlots}
                  timeSlotID={morningSlotID}
                  title={'MORNING'}
                />
                <TimeSlotChip
                  time={afterNoon}
                  timeSlots={allAfternoonSlots}
                  setTimeSlots={setAllAfternoonSlots}
                  selectedSlots={selectedSlots}
                  setSelectedSlots={setSelectedSlots}
                  timeSlotID={afterNoonSlotID}
                  title={'AFTER NOON'}
                />
                <TimeSlotChip
                  time={evening}
                  timeSlots={allEveningSlots}
                  setTimeSlots={setAllEveningSlots}
                  selectedSlots={selectedSlots}
                  setSelectedSlots={setSelectedSlots}
                  timeSlotID={eveningSlotID}
                  title={'EVENING'}
                />
                <TimeSlotChip
                  time={night}
                  timeSlots={allNightSlots}
                  setTimeSlots={setAllNightSlots}
                  selectedSlots={selectedSlots}
                  setSelectedSlots={setSelectedSlots}
                  timeSlotID={nightSlotID}
                  title={'NIGHT'}
                />

                {err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )}
              </TimeSlotsContainer>

              <BottomContainer>
                <Deatil
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  close
                </Deatil>
                <Deatil
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Update
                </Deatil>
              </BottomContainer>
            </>
          )}
        </Middle>
      </ContentBox>
    </>
  )
}

export default DefaultSlotCard

const ContentBox = styled.div`
  width: 100%;
  padding: 0 24px;
  background-color: ${THEME_COLORS.white};
  border-radius: 10px;
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  display: flex;
  align-items: flex-start;
  /* gap: 30px; */
  /* height: 90vh; */
`

const SubTitleWrap = styled.div`
  margin-bottom: 20px;
  padding: 20px 10px;
  div.border {
    margin-left: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
  }
`
const SubTitleWrapper2 = styled.div`
  width: 250px;
  /* height: 90vh; */
`
const DateContainer = styled.div`
  padding: 10px;
`
const Dates = styled.div`
  padding: 8px 15px;
  border-radius: 7px;
  margin-bottom: 10px;
  font-size: 16px;
  &.active {
    background: #f4f4f4;
  }
  &:hover {
    background: #f4f4f4;
  }
`
const Middle = styled.div`
  border-left: 1px solid #e2e2e2;
  padding-left: 20px;
`

const UpdateButton = styled.div`
  display: flex;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 8px;

  border: 2px solid #d3d3d3;
  margin-right: 30px;
`
const Deatil = styled.div`
  padding: 8px 15px;
  background: ${THEME_COLORS.chips_blue_on_container_2};
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  color: #fff;
  justify-content: center;
  @media all and (max-width: 1080px) {
    width: 350px;
  }
`
const Content = styled.p`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const ToggleEvent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
const DefaultTitle = styled.div``
const TitleSlot = styled.h3`
  color: ${THEME_COLORS.text_title};
  font-size: 16px;
  font-family: 'DM_sans_medium';
`
const Describtion = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`
const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`
const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`
const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${THEME_COLORS.light_400};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`
const ToggleInputChecked = styled(ToggleInput)`
  &:checked + ${ToggleSlider} {
    background-color: ${THEME_COLORS.chips_green_on_container};
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`
const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 30px;
`
const TimeSlotsContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
