import React from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";

const NotificationCard = ({ item, large }) => {
    return (
        <Card isRead={item.isread} large={large}>
            <Left large={large}>
                <ImageBox>
                    <img
                        src={
                            require("../../../assets/image/dashboard/bell_black.svg")
                                .default
                        }
                        alt="bell icon"
                    />
                </ImageBox>
            </Left>
            <Right>
                <Title large={large} isRead={item.isread}>
                    {item.title}
                </Title>
                <Content large={large} isRead={item.isread}>
                    {item?.message?.length > 96
                        ? `${item.message.substring(0, 96)}...`
                        : item.message}
                </Content>
                <Time>{item.date}</Time>
            </Right>
        </Card>
    );
};

export default NotificationCard;

const Card = styled.div`
    background: ${(props) =>
        !props.isRead && THEME_COLORS.chips_blue_container};
    border: ${(props) => props.isRead && `1px solid ${THEME_COLORS.light_200}`};
    border-bottom: ${(props) =>
        !props.isRead && `1px solid ${THEME_COLORS.divider}`};
    border-radius: 8px;
    padding: ${(props) => (props.large ? "12px" : "8px")};
    margin-bottom: ${(props) => (props.large ? "12px" : "6px")};
    display: flex;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Left = styled.div`
    margin-right: ${(props) => (props.large ? "12px" : "10px")};
`;
const ImageBox = styled.div`
    width: 36px;
    height: 36px;
    background: ${THEME_COLORS.white};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Right = styled.div`
    flex-grow: 1;
`;
const Title = styled.h5`
    font-weight: 500;
    font-size: ${(props) => (props.large ? "20px" : "14px")};
    color: ${(props) =>
        props.isRead ? THEME_COLORS.text_title : THEME_COLORS.black};
    margin-bottom: ${(props) => (props.large ? "8px" : "4px")};
`;
const Content = styled.p`
    font-weight: 400;
    font-size: ${(props) => (props.large ? "16px" : "12px")};
    color: ${(props) =>
        props.isRead ? THEME_COLORS.text_para : THEME_COLORS.black};
    margin-bottom: 8px;
`;
const Time = styled.p`
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
