import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

const Settings = () => {
    const navigate = useNavigate();
    const [pushNotification, setPushNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    let accountNumber = "9754234597515646";
    const { dispatch } = useContext(Context);

    useEffect(() => {
        return dispatch({
            type: "PROFILE_OPTION",
            activeOption: "about",
        });
    }, []);

    const handleProfile = (option) => {
        dispatch({
            type: "PROFILE_OPTION",
            activeOption: option,
        });
        navigate("/manage-profile");
    };

    return (
        <>
            <Wrapper>
                <Head>
                    <Title>Settings</Title>
                    <BackLink onClick={() => navigate(-1)}>
                        <img
                            src={
                                require("../../../assets/image/reports/left_arrow_long_blue.svg")
                                    .default
                            }
                            alt="left arrow"
                        />
                        <span>Back</span>
                    </BackLink>
                </Head>
                <SettingsBox>
                    <Box>
                        <Left>
                            <SubTitle>Account Settings</SubTitle>
                            <Description>
                                Change your Personal Info, Password, Expert
                                details, Working status, Bio, Documents, Bank
                                account details
                            </Description>
                        </Left>
                        <Right onClick={() => handleProfile("about")}>
                            <Button>Manage</Button>
                        </Right>
                    </Box>
                    <Box className="notification">
                        <SubTitle className="notification">
                            Notification Settings
                        </SubTitle>
                        <NotificationBox>
                            <Left>
                                <Type>Push Notifications</Type>
                                <Description>
                                    You will get notified on new session
                                    updates, new cases, time scheduled etc.
                                </Description>
                            </Left>
                            <Right
                                onClick={() =>
                                    setPushNotification(!pushNotification)
                                }
                            >
                                {pushNotification ? (
                                    <img
                                        src={
                                            require("../../../assets/image/settings/button_on.svg")
                                                .default
                                        }
                                        alt="on button"
                                    />
                                ) : (
                                    <img
                                        src={
                                            require("../../../assets/image/settings/button_off.svg")
                                                .default
                                        }
                                        alt="off button"
                                    />
                                )}
                            </Right>
                        </NotificationBox>
                        <NotificationBox>
                            <Left>
                                <Type>Email Notification</Type>
                                <Description>Notify through email.</Description>
                            </Left>
                            <Right
                                onClick={() =>
                                    setEmailNotification(!emailNotification)
                                }
                            >
                                {emailNotification ? (
                                    <img
                                        src={
                                            require("../../../assets/image/settings/button_on.svg")
                                                .default
                                        }
                                        alt="on button"
                                    />
                                ) : (
                                    <img
                                        src={
                                            require("../../../assets/image/settings/button_off.svg")
                                                .default
                                        }
                                        alt="off button"
                                    />
                                )}
                            </Right>
                        </NotificationBox>
                    </Box>
                    <Box>
                        <Left>
                            <SubTitle>Change Password</SubTitle>
                            <Description>
                                Update your current password to new password.
                            </Description>
                        </Left>
                        <Right onClick={() => handleProfile("password")}>
                            <Button>Update</Button>
                        </Right>
                    </Box>
                    <Box>
                        <Left>
                            <SubTitle className="payment">
                                Payment Settings
                            </SubTitle>
                            <AccountBox>
                                <BankName>ICICI Bank</BankName>
                                <AccountNumber>
                                    {accountNumber
                                        .split("")
                                        .map((number, index) => {
                                            if (index < 2) {
                                                return number;
                                            } else if (
                                                index <
                                                accountNumber.length - 4
                                            ) {
                                                if (index % 4 === 0)
                                                    return " X";
                                                else return "X";
                                            } else {
                                                if (index % 4 === 0)
                                                    return ` ${number}`;
                                                else return number;
                                            }
                                        })}
                                </AccountNumber>
                            </AccountBox>
                            <Description>
                                Update your payment method to send and receive
                                payment from mind mitra
                            </Description>
                        </Left>
                        <Right onClick={() => handleProfile("account")}>
                            <Button>Update</Button>
                        </Right>
                    </Box>
                </SettingsBox>
                <DeleteBox>
                    <Delete>Delete Account</Delete>
                    <Description>
                        *Deleting your account will erase all your data
                        permanently.
                    </Description>
                </DeleteBox>
                <ButtonBox>
                    <BottomButton onClick={() => navigate("/faqs")}>
                        <img
                            src={
                                require("../../../assets/image/settings/faq.svg")
                                    .default
                            }
                            alt="button"
                        />
                        <Name>FAQ</Name>
                    </BottomButton>
                    <BottomButton onClick={() => navigate("/contact")}>
                        <img
                            src={
                                require("../../../assets/image/settings/support.svg")
                                    .default
                            }
                            alt="button"
                        />
                        <Name>Support</Name>
                    </BottomButton>
                </ButtonBox>
            </Wrapper>
        </>
    );
};

export default Settings;

const Wrapper = styled.div`
    width: 50%;
    margin: 46px auto 81px;
    background: ${THEME_COLORS.white};
    @media all and (max-width: 1440px) {
        width: 60%;
    }
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Head = styled.div`
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.h3`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
`;
const BackLink = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    img {
        width: 20px;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        color: ${THEME_COLORS.chips_blue_on_container_2};
        margin-left: 10px;
    }
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const SettingsBox = styled.div`
    margin-bottom: 31px;
`;
const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
    border-bottom: 1px solid ${THEME_COLORS.light_500};
    &.notification {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Left = styled.div`
    width: 55%;
`;
const SubTitle = styled.h4`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
    &.notification {
        margin-bottom: 16px;
    }
    &.payment {
        margin-bottom: 8px;
    }
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const NotificationBox = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Type = styled.h5`
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
`;
const Right = styled.div``;
const AccountBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 7px;
    width: max-content;
    margin-bottom: 8px;
`;
const BankName = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-right: 40px;
`;
const AccountNumber = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    &:hover {
        border: 1px solid ${THEME_COLORS.light_300};
    }
`;
const DeleteBox = styled.div`
    margin-bottom: 31px;
`;
const Delete = styled.button`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.chips_red_on_container};
    margin-bottom: 14px;
    padding: 4px 8px;
    border-radius: 6px;
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;

const ButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const BottomButton = styled.button`
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border-radius: 7px;
`;
const Name = styled.h3`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-left: 12px;
`;
