import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function NotificationDateChip({ date, time }) {
  return (
    <>
      <ChipContainer>
        <Date>
          Scheduled for:{' '}
          {date ? (
            <span>
              {' '}
              {date}, {time}
            </span>
          ) : (
            { time }
          )}
        </Date>
      </ChipContainer>
    </>
  )
}

export default NotificationDateChip

const ChipContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${THEME_COLORS.white};
  /* cursor: pointer; */
  margin-right: 10px;
`

const Date = styled.p`
  color: ${THEME_COLORS.chips_green_on_container};

  font-family: 'DM_sans';
  font-size: 12px;
  margin-right: 5px;
`
