import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import DateCard from '../../../includes/cards/DateCard'
import ChipOval from '../../../includes/chips/ChipOval'
import FollowUpDateCard from '../../../includes/cards/FollowUpDateCard'

const DateTimeSlot = ({
  selectedTime,
  selectedDate,
  setSelectedTime,
  setSelectedDate,
  setTimeSlot,
  caseReport,
  timeSlots,
  datesListArray,
  selectedNewDate,
  selectedDateObject,
  currentSession,
}) => {
  const [datecards, setDateCards] = useState([])
  const [initialdate, setinitialdate] = useState('day')

  let today = new Date()
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
    }
    return date.toLocaleDateString('en-US', options)
  }
  const originalDateString = today
  const formattedDate = formatDate(originalDateString)

  const showDates = () => {
    let today = new Date()
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    let days_full = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let days_show = 30
    let dates = []

    for (let i = 0; i < days_show; i++) {
      let next_date = new Date(new Date().setDate(today.getDate() + i))
      let date = next_date.getDate()
      let day = days[next_date.getDay()]
      let day_full = days_full[next_date.getDay()]
      let month = months[next_date.getMonth()]
      let year = next_date.getFullYear()

      dates.push({
        id: i + 1,
        date: date,
        day: day,
        day_full: day_full,
        month: month,
        year: year,
      })
    }
    setDateCards(dates)
  }

  useEffect(() => {
    showDates()
  }, [])

  return (
    <>
      <ContentBox>
        {!caseReport && <SubTitleGreen>choose slot</SubTitleGreen>}
        <SubTitleWrapper>
          <SubTitle>
            {selectedDateObject && Object.keys(selectedDateObject).length !== 0
              ? selectedDateObject.day_full +
                ', ' +
                selectedDateObject.date +
                ' ' +
                selectedDateObject.month_name
              : formattedDate}
          </SubTitle>
          <div></div>
        </SubTitleWrapper>

        <Dates>
          <FollowUpDateCard
            cards={datesListArray}
            value={selectedNewDate}
            setValue={setSelectedDate}
            setinitialdate={setinitialdate}
            initialdate={initialdate}
            selectedDateObject={selectedDateObject}
          />
        </Dates>
        <SubTitleWrapper>
          <SubTitle>Choose an available slot</SubTitle>
          <div></div>
        </SubTitleWrapper>
        <Morning>
          <Title>MORNING</Title>
          {timeSlots?.morning?.map((time) => {
            return (
              <ChipOval
                key={time.id}
                obj={time}
                content={time.start_time}
                value={selectedTime}
                setValue={setSelectedTime}
                disabled={
                  time.active == false || time.booked == true ? true : false
                }
                active_green
              />
            )
          })}
        </Morning>
        <Afternoon>
          <Title>AFTER NOON</Title>
          {timeSlots?.after_noon?.map((time) => {
            return (
              <ChipOval
                key={time.id}
                obj={time}
                content={time.start_time}
                value={selectedTime}
                setValue={setSelectedTime}
                disabled={
                  time.active == false || time.booked == true ? true : false
                }
                active_green
              />
            )
          })}
        </Afternoon>
        <Afternoon>
          <Title>EVENING</Title>
          {timeSlots?.evening?.map((time) => {
            return (
              <ChipOval
                key={time.id}
                obj={time}
                content={time.start_time}
                value={selectedTime}
                setValue={setSelectedTime}
                disabled={
                  time.active == false || time.booked == true ? true : false
                }
                active_green
              />
            )
          })}
        </Afternoon>
        <Night>
          <Title>NIGHT</Title>
          {timeSlots?.night?.map((time) => {
            return (
              <ChipOval
                key={time.id}
                obj={time}
                content={time.start_time}
                value={selectedTime}
                setValue={setSelectedTime}
                disabled={
                  time.active == false || time.booked == true ? true : false
                }
                active_green
              />
            )
          })}
        </Night>
      </ContentBox>
      {selectedDateObject &&
        Object.keys(selectedDateObject).length !== 0 &&
        Object.keys(selectedTime).length !== 0 && (
          <Bottom>
            You’ve scheduled the session {currentSession + 1} into,{' '}
            <span>
              {selectedTime.start_time} to {selectedTime.end_time}
            </span>{' '}
            on{' '}
            <span>
              {selectedDateObject.date} {selectedDateObject.month_name},{' '}
              {selectedDateObject.day_full}
            </span>
          </Bottom>
        )}
    </>
  )
}

export default DateTimeSlot

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  margin-top: 32px;
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 10px;
`
const SubTitleGreen = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  text-transform: uppercase;
  color: ${THEME_COLORS.chips_green_on_container};
  margin-bottom: 24px;
`
const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  div {
    margin-left: 10px;
    flex-grow: 1;
    border-top: 1px solid ${THEME_COLORS.light_300};
  }
`
const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const Dates = styled.div`
  margin-bottom: 20px;
`
const Bottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  color: ${THEME_COLORS.text_title};
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const Morning = styled.div`
  margin-bottom: 20px;
`
const Afternoon = styled.div`
  margin-bottom: 20px;
`
const Night = styled.div``
const Title = styled.div`
  color: ${THEME_COLORS.dark_300};
  font-size: 14px;
  margin-bottom: 5px;
`
