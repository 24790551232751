import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const CountdownTimer = ({ timeData, timerZero, setTimerZero }) => {
  const [countdown, setCountdown] = useState(timeData)

  useEffect(() => {
    const interval = setInterval(() => {
      // Decrement the seconds
      setCountdown((prevCountdown) => {
        const { hours, minutes, seconds } = prevCountdown
        if (hours === 0 && minutes <= 3) {
          // Timer reached zero, set the state to true
          setTimerZero(true)
          // console.log(timerZero, 'Timer')
          clearInterval(interval)
          return prevCountdown
        }

        let newSeconds = seconds - 1
        let newMinutes = minutes
        let newHours = hours

        if (newSeconds < 0) {
          newSeconds = 59
          newMinutes -= 1
        }
        if (newMinutes < 0) {
          newMinutes = 59
          newHours -= 1
        }

        return {
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds,
        }
      })
    }, 1000)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval)
  }, [])
  return (
    <div>
      <TimeContainer>
        <TimeDiv className="time">
          {' '}
          <Time>{String(countdown.hours).padStart(2, '0')}</Time>
          <p>Hour</p>
        </TimeDiv>
        :
        <TimeDiv>
          <Time>{String(countdown.minutes).padStart(2, '0')}</Time>
          <p>Min</p>
        </TimeDiv>
        :
        <TimeDiv>
          <Time>{String(countdown.seconds).padStart(2, '0')}</Time>
          <p>Sec</p>
        </TimeDiv>
      </TimeContainer>
    </div>
  )
}

export default CountdownTimer

const TimeContainer = styled.div`
  display: flex;
  gap: 5px;
  color: #fff;
  font-size: 16px;
  font-family: 'DM_sans_medium';
`

const TimeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 12px;
  }
`

const Time = styled.div`
  color: #fff;
  font-size: 16px;
  font-family: 'DM_sans_medium';
`
