import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Checkbox from './CustomCheckbox'

import { THEME_COLORS } from '../../../ThemeConfig'

const ChipOval = ({
  obj,
  content,
  value,
  setValue,
  disabled,
  active_green,
  multiple,
  active,
  checkbox,
  isInPatientModal,
}) => {
  let found = false
  const handleMultipleValue = (found) => {
    if (found) {
      setValue(value.filter((item) => item.id !== obj.id))
    } else {
      setValue([...value, obj])
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
    setValue(obj)
  }

  const handlePatientModalChange = () => {
    setIsChecked((prev) => !prev)
    setValue(obj)
  }

  useEffect(() => {
    setIsChecked(active)
  }, [active])

  const [isChecked, setIsChecked] = useState(false)

  return multiple ? (
    <>
      {(found = value.some((item) => item.id === obj.id))}
      <Item
        onClick={() => handleMultipleValue(found)}
        className={found && 'active_blue'}
      >
        {checkbox && (
          <Checkbox checked={found} onChange={() => handleCheckboxChange()} />
        )}
        <span>{content}</span>
      </Item>
    </>
  ) : isInPatientModal ? (
    <Item
      onClick={(e) => !disabled && setValue(obj.id)}
      className={
        active === true &&
        value === obj.id &&
        active_green &&
        disabled === false
          ? 'active-green'
          : value === obj.id && disabled === false && 'active_blue'
      }
      disabled={disabled}
      active_green={active_green}
    >
      {checkbox && (
        <Checkbox
          checked={isChecked}
          onChange={() => handlePatientModalChange()}
        />
      )}
      <span>{content}</span>
    </Item>
  ) : (
    <Item
      onClick={(e) => !disabled && setValue(obj)}
      className={
        active === true &&
        value.id === obj.id &&
        active_green &&
        disabled === false
          ? 'active-green'
          : value.id === obj.id && disabled === false && 'active_blue'
      }
      disabled={disabled}
      active_green={active_green}
    >
      {checkbox && (
        <Checkbox checked={isChecked} onChange={() => handleCheckboxChange()} />
      )}
      <span>{content}</span>
    </Item>
  )
}

export default ChipOval

const Item = styled.div`
  background-color: ${(props) =>
    props.disabled ? THEME_COLORS.light_200 : THEME_COLORS.white};
  color: ${(props) =>
    props.disabled ? THEME_COLORS.light_400 : THEME_COLORS.text_title};
  border: 1px solid
    ${(props) => (props.disabled ? 'none' : THEME_COLORS.light_300)};
  border-radius: 53px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  margin-right: ${(props) => (props.active_green ? '10px' : '10px')};
  margin-bottom: ${(props) => (props.active_green ? '10px' : '10px')};
  span {
    font-weight: 500;
    font-size: 14px;
  }
  &.active_blue {
    background-color: ${THEME_COLORS.chips_blue_container};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
  }
  &.active-green {
    background-color: ${THEME_COLORS.chips_green_container};
    color: ${THEME_COLORS.chips_green_on_container};
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
  }
`
