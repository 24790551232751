import React from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig.js'


const TeamCard = ({person}) => {
  return (
    <Card>
        <Image>
            <img src={require(`../../../assets/image/about/${person.image}`)} alt={person.name} />
        </Image>
        <Name>{person.name}</Name>
        <Designation>{person.designation}</Designation>
    </Card>
  )
}

export default TeamCard

const Card = styled.li`
    text-align: center;
    width: 16%;
`
const Image = styled.div`
    margin-bottom: 12px;
    width: 100%;
    img{
        width: 100%;
    }
`
const Name = styled.h5`
    font-weight: 500;
    font-size: 16px;
    color: ${THEME_COLORS.black};
    margin-bottom: 6px;
`
const Designation = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`
