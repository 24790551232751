import React, { useState } from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { THEME_COLORS } from '../../../../ThemeConfig'
import Calendar from 'react-calendar'
import CalenderAppointmentModal from '../../../includes/modals/CalenderAppointmentModal'

function CalenderModal({ calendarModal, setModal, reports, my_account }) {
  const [dayModal, setDayModal] = useState(false)

  return (
    <>
      {calendarModal && (
        <CardContainer>
          <Card>
            <Calendar
              onClickDay={() => {
                setDayModal(!dayModal)
              }}
            />
            {dayModal && <CalenderAppointmentModal />}
          </Card>
        </CardContainer>
      )}
    </>
  )
}

export default CalenderModal
const CardContainer = styled.div`
  /* box-shadow: 10px 12px 48px rgba(0, 0, 0, 0.25); */
  border-radius: 12px;
  width: 35%;
  position: absolute;
  top: 100px;
  right: 55px;
  cursor: default;
  z-index: 1111;
  display: none;
  @media all and (max-width: 1200px) {
    display: block;
  }
`
const Card = styled.div`
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  .react-calendar {
    padding: 8px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: auto;
    border-radius: 10px;
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    @media all and (max-width: 1400px) {
      width: 100%;
    }
  }

  .react-calendar button {
    display: flex !important;
    font-size: 14px;
    padding: 12px;
    border-radius: 48px;
    width: 35px !important;
    height: 55px;
    justify-content: center;
    align-items: center;
    /* flex: 0 0 13.2857% !important; */
    @media all and (max-width: 1400px) {
      font-size: 14px;
      height: 60px !important;
    }

    &:hover {
      background: ${THEME_COLORS.chips_green_container};
    }

    @media all and (max-width: 1400px) {
    }
  }

  .react-calendar__tile--now {
    background: ${THEME_COLORS.chips_green_container};
  }

  .react-calendar__tile--active {
    background: ${THEME_COLORS.chips_green_on_container} !important;
    color: white;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    &:hover {
      background: none;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    font-family: 'DM_sans_medium';
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    span {
      font-family: 'DM_sans_medium' !important;
    }
  }
  .react-calendar__navigation__prev2-button {
    background: ${THEME_COLORS.chips_blue_container};
    /* display: none; */
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__prev2-button {
    display: none !important;
  }
  .react-calendar__tile .react-calendar__year-view__months__month {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__label {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar button {
    display: flex;
  }
`

const Middle = styled(Link)`
  display: flex;
  border-radius: 8px;
  padding: 8px 10px;
  cursor: pointer;
  &.signout {
    justify-content: center;
  }
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`

const Left = styled.div`
  margin-right: 10px;
`

const Icon = styled.img``

const Right = styled.div``

const Text = styled.h3`
  font-size: 14px;
  &.signout {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
