import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import "../../../assets/css/Calendar.css";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
import { expert } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import SessionDisconnectModal from "../../includes/modals/SessionDisconnectModal";
import SessionNoClientModal from "../../includes/modals/SessionNoClientModal";
import SessionStatusModal from "../../includes/modals/SessionStatusModal";
import NotificationContainer from "./NotificationContainer";
import UpComingSessions from "./UpcomingSessions";

function Dashboard() {
    const [calendarModal, setCalendarModal] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [isUpdateStatusModal, setIsUpdateStatusModal] = useState(false);
    const [selectedUpdateSessionId, setSelectedUpdateSessionId] = useState("");
    const [sessionDisconnectModal, setSessionDisconnectModal] = useState(false);
    const [sessionNoClientModal, setSessionNoClientModal] = useState(false);
    const [NextSessionData, setNextSessionData] = useState([]);
    const [isDefaultTimeSlotModal, setIsDefaultTimeSlotModal] = useState(false);
    const { state, dispatch } = useContext(Context);
    const is_default_time_slot = state.user_details.is_default_time_slot;
    const [action, setAction] = useState();
    const [sessionData, setSessionData] = useState([]);
    const [expertData, setExpertData] = useState([]);
    const [error, SetError] = useState("");
    const [sessionType, setsessionType] = useState("upcoming");
    const [monthId, setMonthId] = useState("");
    function getCurrentMonth() {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const currentMonth = months[currentMonthIndex];
        return currentMonth;
    }
    const currentMonth = getCurrentMonth();
    const [month, setMonth] = useState(currentMonth);

    let user_details = localStorage.getItem("user_details");
    user_details = JSON.parse(user_details);
    const access_token = user_details.access_token;

    const getSessions = () => {
        expert
            .get("/sessions/committed/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    type: sessionType,
                    month: monthId,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setSessionData(response.data.data);
                    setNextSessionData(response.data.data.next_session);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
            });
    };
    const getExpertData = () => {
        expert
            .get("/profile/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode == 6000) {
                    setExpertData(response.data.data);
                    if (data.is_default_time_slot == false) {
                        setIsDefaultTimeSlotModal(true);
                    }
                    setIsProfileLoading(false);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    setIsProfileLoading(false);
                    console.log(error, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                SetError(error.message);
                setIsProfileLoading(false);
            });
    };
    useEffect(() => {
        getSessions();
        getExpertData();
        if (expertData.can_delete_account === false) {
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    active_session: true,
                },
            });
        } else {
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    active_session: false,
                },
            });
        }
    }, [sessionType, monthId, action]);

    return (
        <>
            {isUpdateStatusModal && (
                <SessionStatusModal
                    isUpdateStatusModal={isUpdateStatusModal}
                    setIsUpdateStatusModal={setIsUpdateStatusModal}
                    selectedUpdateSessionId={selectedUpdateSessionId}
                    setSelectedUpdateSessionId={setSelectedUpdateSessionId}
                    getSessions={getSessions}
                />
            )}
            {sessionDisconnectModal && <SessionDisconnectModal />}
            {sessionNoClientModal && <SessionNoClientModal />}

            {!isProfileLoading ? (
                <DashboardContainer
                    className={
                        (isUpdateStatusModal ||
                            sessionDisconnectModal ||
                            sessionNoClientModal) == true
                            ? "modal-active"
                            : is_default_time_slot == false
                            ? "modal-active"
                            : ""
                    }
                >
                    <Container>
                        <UpComingSessions
                            isDefaultTimeSlotModal={isDefaultTimeSlotModal}
                            setIsDefaultTimeSlotModal={
                                setIsDefaultTimeSlotModal
                            }
                            selectedUpdateSessionId={selectedUpdateSessionId}
                            setSelectedUpdateSessionId={
                                setSelectedUpdateSessionId
                            }
                            isUpdateStatusModal={isUpdateStatusModal}
                            setIsUpdateStatusModal={setIsUpdateStatusModal}
                            expertData={expertData}
                            setCalendarModal={setCalendarModal}
                            calendarModal={calendarModal}
                            NextSessionData={NextSessionData}
                            sessionData={sessionData}
                            getSessions={getSessions}
                            setsessionType={setsessionType}
                            sessionType={sessionType}
                            setMonthId={setMonthId}
                            setMonth={setMonth}
                            month={month}
                        />
                    </Container>
                    <RightContiner>
                        <NotificationDiv>
                            <NotificationContainer
                                setAction={setAction}
                                Sessions={getSessions}
                                action={action}
                                data={expertData?.session_exist}
                            />
                        </NotificationDiv>
                    </RightContiner>
                </DashboardContainer>
            ) : (
                <Lottie
                    animationData={LoaderLottie}
                    style={{
                        height: "100vh",
                        width: 100,
                        margin: "auto",
                    }}
                />
            )}
        </>
    );
}

export default Dashboard;

const DashboardContainer = styled.section`
    display: flex;
    position: relative;
    &.modal-active {
        height: calc(100vh - 69.6px);
    }
    &.verify {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 69.6px);
    }
    @media all and (max-width: 1200px) {
        flex-direction: column;
    }
`;
const Container = styled.section`
    padding: 30px 15px 30px 30px;
    width: 70%;
    @media all and (max-width: 1400px) {
        width: 75%;
        margin-right: 0px;
    }
    @media all and (max-width: 1200px) {
        width: 100%;
        padding: 30px;
    }
`;

const RightContiner = styled.div`
    padding: 30px 30px 30px 15px;
    margin: 0 auto;
    width: 30%;

    @media all and (max-width: 1400px) {
    }
    @media all and (max-width: 1200px) {
        /* display: none; */
        padding: 30px;
        margin: 0 auto;
        width: 70%;
    }
`;
const NotificationDiv = styled.div``;
