import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "../../genaral/Header";
import RegisterPage from "../../screens/RegisterPage";
import LoginPage from "../../screens/LoginPage";
import { Context } from "../../../contexts/Store";
import OtpPage from "../../screens/OtpPage";
import ForgotPassword from "../../screens/forgot_password/ForgotPassword";
import Verify from "../../screens/forgot_password/Verify";
import SetPassword from "../../screens/forgot_password/SetPassword";
import ProfileHeader from "../../genaral/ProfileHeader";
import AboutYou from "../../includes/AboutYou";
import ExpertDetails from "../../includes/ExpertDetails";
import WorkingStatus from "../../includes/WorkingStatus";
import YourBio from "../../includes/YourBio";
import ProfileVerification from "../../genaral/ProfileVerification";
import Page404 from "../../genaral/Page404";
import Dashboard from "../../screens/dashboard/Dashboard";
import ContactPage from "../../screens/contact/ContactPage";
import TermsAndConditions from "../../screens/terms_and_conditions/TermsAndConditions";

const AuthRouter = () => {
    const { state, dispatch } = useContext(Context);

    const [completed, setCompleted] = useState({
        about_you: "",
        expert_details: "",
        working_status: "",
        your_bio: "",
    });
    const [phoneNumber, setPhoneNumber] = useState("");
    const stringToBoolean = (str) => {
        // Convert to lowercase to handle case-insensitivity
        console.log(str, typeof str);
        let lowercasedStr = "";
        // if (str !== "") {
        //     lowercasedStr = str.toLowerCase();
        // }

        // // Check if the lowercased string is "true"
        // if (lowercasedStr === "true") {
        //     return true;
        // }

        // // Check if the lowercased string is "false"
        // if (lowercasedStr === "false") {
        //     return false;
        // }

        // // If the string is neither "true" nor "false", return undefined or handle accordingly
        // return undefined;
    };
    useEffect(() => {
        let isRegisterationStarted = localStorage.getItem("nrs");

        // Check if isRegisterationStarted is not null before parsing
        if (isRegisterationStarted !== null) {
            const nrs = JSON.parse(isRegisterationStarted);
            // console.log(isRegisterationStarted, "w json");
            // const nrs = stringToBoolean(isRegisterationStarted);

            dispatch({
                type: "UPDATE_NEW_REGISTERATION_STATUS",
                nrs: nrs,
            });
        } else {
            dispatch({
                type: "UPDATE_NEW_REGISTERATION_STATUS",
                nrs: false,
            });
        }
    }, []);

    return (
        <Routes>
            <Route
                path="/login-page"
                element={[<Header />, <LoginPage />]}
            ></Route>
            <Route
                path="/register-page"
                element={[<Header />, <RegisterPage />]}
            ></Route>
            <Route
                path="/otp-page"
                element={[<Header />, <OtpPage phoneNumber={phoneNumber} />]}
            ></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
                path="/login-page"
                element={[<Header />, <LoginPage />]}
            ></Route>
            <Route path="/verify-otp" element={<Verify />}></Route>
            <Route path="/set-password" element={<SetPassword />}></Route>
            <Route
                path="/profile-about-you"
                element={[
                    <ProfileHeader completed={completed} />,
                    <AboutYou
                        completed={completed}
                        setCompleted={setCompleted}
                    />,
                ]}
            ></Route>
            <Route
                path="/expert-details"
                element={[
                    <ProfileHeader completed={completed} />,
                    <ExpertDetails
                        completed={completed}
                        setCompleted={setCompleted}
                    />,
                ]}
            ></Route>
            <Route
                path="/working-status"
                element={[
                    <ProfileHeader completed={completed} />,
                    <WorkingStatus
                        completed={completed}
                        setCompleted={setCompleted}
                    />,
                ]}
            ></Route>
            <Route
                path="/your-bio"
                element={[
                    <ProfileHeader completed={completed} />,
                    <YourBio
                        completed={completed}
                        setCompleted={setCompleted}
                    />,
                ]}
            ></Route>
            <Route
                path="/profile-verification"
                element={[<Header />, <ProfileVerification />]}
            />
            <Route
                path="/contact"
                element={[<Header />, <ContactPage />]}
            ></Route>
            <Route
                path="/terms-and-conditions"
                element={
                    // <PrivateRoute>
                    [<Header />, <TermsAndConditions />]
                    // </PrivateRoute>
                }
            ></Route>
            <Route exact path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AuthRouter;
