import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import '../../../assets/css/style.css'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ComingSoon from '../../genaral/ComingSoon'
import DashboardHeader from '../../genaral/DashboardHeader'
import Page404 from '../../genaral/Page404'
import AboutPage from '../../screens/about/AboutPage'
import CaseReportPage from '../../screens/case_report/CaseReportPage'
import ContactPage from '../../screens/contact/ContactPage'
import Dashboard from '../../screens/dashboard/Dashboard'
import FaqsPage from '../../screens/faqs/FaqsPage'
import FeedbackPage from '../../screens/feedback/FeedbackPage'
import ManageProfilePage from '../../screens/manage_profile/ManageProfilePage'
import MyAccountPage from '../../screens/my_account/MyAccountPage'
import Notification from '../../screens/notification/Notification'
import CaseReportList from '../../screens/reports_list/CaseReportList'
import ReportsListPage from '../../screens/reports_list/ReportsListPage'
import SessionSummaryReportList from '../../screens/reports_list/SessionSummaryReportList'
import ScheduleCalendar from '../../screens/schedule-calendar/ScheduleCalendar'
import SlotDetailedView from '../../screens/schedule-calendar/SlotDetailedView'
import SessionSummaryPage from '../../screens/session_summary/SessionSummaryPage'
import SessionSinglePage from '../../screens/sessionsinglepage/SessionSinglePage'
import Settings from '../../screens/settings/Settings'
import TermsAndConditions from '../../screens/terms_and_conditions/TermsAndConditions'
import PrivateRoute from '../routes/PrivateRoute'
import Lottie from 'lottie-react'
import LoaderLottie from '../../../assets/lottie/LoaderLottie.json'
import NewSlotDetailPage from '../../screens/schedule-calendar/NewSlotDetailPage'
import UpdateSlotModal from '../../screens/session_summary/modals/UpdateSlotModal'
import TransactionListPage from '../../screens/my_account/TransactionListPage'

function AppRouter() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [expertData, setExpertData] = useState([])
  const [error, SetError] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const getExpertData = () => {
    setIsLoading(true)
    // if (access_token) {
    expert
      .get('/profile/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setExpertData(response.data.data)
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              is_default_time_slot: response.data.data?.is_default_time_slot,
              session_exist: response.data.data?.session_exist,
            },
          })
          setIsLoading(false)
        } else if (StatusCode == 6001) {
          SetError(response)
          setIsLoading(false)

          // console.log(error, "error");
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
        setIsLoading(false)
      })
    // }
  }
  useEffect(() => {
    getExpertData()
  }, [])

  return isLoading ? (
    <Lottie
      animationData={LoaderLottie}
      style={{
        height: '100vh',
        width: 150,
        margin: 'auto',
      }}
    />
  ) : (
    <>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/session-single-page/:id"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <SessionSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-case-report/:id"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <CaseReportPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <ContactPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/faqs"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <FaqsPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/feedback"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <FeedbackPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/terms-and-conditions"
          element={
            // <PrivateRoute>
            [
              <DashboardHeader expertData={expertData} />,
              <TermsAndConditions />,
            ]
            // </PrivateRoute>
          }
        ></Route>
        <Route
          path="/about"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <AboutPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-profile"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <ManageProfilePage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reports-list"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <ReportsListPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reports-list/case-reports"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <CaseReportList />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/transactions-list"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />{' '}
              <TransactionListPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reports-list/summary-reports"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />{' '}
              <SessionSummaryReportList />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <MyAccountPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} />
              <Notification />
            </PrivateRoute>
          }
        ></Route>

        {/* <Route
          path="/schedule-calendar"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <ScheduleCalendar />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/schedule-page"
          element={
            <PrivateRoute>
              <DashboardHeader expertData={expertData} /> <NewSlotDetailPage />
            </PrivateRoute>
          }
        />

        <Route exact path="/coming-soon" element={<ComingSoon />} />

        <Route exact path="/404" element={<Page404 />} />
        <Route exact path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  )
}

export default AppRouter
