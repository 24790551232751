import React, { useContext } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import { Context } from "../../../../contexts/Store";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";

const PendingWarningModal = ({ isModal, setIsModal, DeleteWarnigModal }) => {
    const handleOuterClick = (e) => {
        const element = document.querySelector(".outer-click");
        if (!element.contains(e.target)) {
            setIsModal(false);
        }
    };

    const handleSaveAndCancel = (e) => {
        DeleteWarnigModal(false);
    };

    return (
        <>
            <Background
                onClick={(e) => isModal && handleOuterClick(e)}
            ></Background>

            <Container className="outer-click">
                <Title>⚠️ You can't delete your account</Title>
                <SubTitle>
                    You have pending sessions to complete, Please finish the
                    sessions before deleting your account
                </SubTitle>
                <ButtonContainer>
                    <ButtonTypeOne
                        text="Okay"
                        color={THEME_COLORS.white}
                        bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                        onclick={handleSaveAndCancel}
                    />
                </ButtonContainer>
            </Container>
        </>
    );
};

export default PendingWarningModal;

const Background = styled.div`
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    opacity: 0.4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8888;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 411px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    height: 300px;
`;
const Title = styled.h4`
    font-weight: 600;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 16px;
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 32px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;
