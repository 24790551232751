import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import video from "../../../assets/image/dashboard/session-card/gmeet_blue.svg";
import audio from "../../../assets/image/dashboard/session-card/audio-blue.svg";
import icon from "../../../assets/image/dashboard/session-card/Link.svg";
import report from "../../../assets/image/dashboard/session-card/add-report.svg";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";

function SessionCallChip({
    status,
    call_type,
    link,
    type,
    session_status,
    id,
    isNextSession,
    timerZero,
    setIsUpdateStatusModal,
    setSelectedUpdateSessionId,
}) {
    const { state, dispatch } = useContext(Context);

    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const handleCopyLink = () => {
        navigator.clipboard.writeText(link);
        setShowCopiedPopup(true);
        // Hide the popup after a short delay
        setTimeout(() => {
            setShowCopiedPopup(false);
        }, 1500);
    };

    useEffect(() => {
        if (isWaiting) {
            setTimeout(() => {
                setIsWaiting(false);
            }, 2000);
        }
    }, [isWaiting]);

    return (
        <>
            {session_status == "Completed" ? (
                <ChipContainerTwo
                    onClick={() => {
                        dispatch({
                            type: "UPDATE_USER",
                            user_details: {
                                activeOption: 1,
                            },
                        });
                    }}
                    to={`/add-case-report/${id}`}
                >
                    <IconContainer>
                        <Icon src={report} alt="icon" />
                    </IconContainer>
                    <Date>Summary</Date>
                </ChipContainerTwo>
            ) : session_status == "Cancelled" ? (
                <ChipContainer
                    href={link}
                    target="_blank"
                    className={"cancelled"}
                >
                    <IconContainer>
                        <Icon src={report} alt="icon" />
                    </IconContainer>
                    <Date>View Reason</Date>
                </ChipContainer>
            ) : session_status == "Followups" ? (
                <UpdateStatusButton
                    // href={link}
                    // target="_blank"
                    className={"cancelled"}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsUpdateStatusModal(true);
                        setSelectedUpdateSessionId(id);
                    }}
                >
                    {/* <IconContainer>
                        <Icon src={report} alt="icon" />
                    </IconContainer> */}
                    <Date>Update status</Date>
                    <Dot></Dot>
                </UpdateStatusButton>
            ) : session_status == "Upcoming" ? (
                <>
                    {isNextSession ? (
                        <>
                            {timerZero && (
                                <LinkIcon
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopyLink();
                                    }}
                                >
                                    <Icon src={icon} />
                                    {showCopiedPopup && (
                                        <CopiedPopup>
                                            Link Copied to Clipboard
                                        </CopiedPopup>
                                    )}
                                </LinkIcon>
                            )}
                            <ChipContainer
                                href={timerZero && link}
                                target="_blank"
                                className={timerZero ? "active" : "disabled"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!timerZero) {
                                        setIsWaiting(true);
                                    }
                                }}
                            >
                                <IconContainer>
                                    <Icon
                                        src={
                                            call_type === "Video"
                                                ? video
                                                : audio
                                        }
                                        alt="icon"
                                    />
                                </IconContainer>
                                <Date>
                                    {call_type === "Video" ? "Open" : "Call"}
                                </Date>
                                {isWaiting && (
                                    <WaitingPopup>
                                        You can only join the meeting 3 minutes
                                        before the start time.
                                    </WaitingPopup>
                                )}
                            </ChipContainer>
                        </>
                    ) : null}
                    {/* {call_type === "Video" ? (
                        <>
                            {type == "next" ? (
                                <LinkIcon className="next">
                                    <Icon src={icon} />
                                </LinkIcon>
                            ) : (
                                <LinkIcon onClick={handleCopyLink}>
                                    <Icon src={icon} />
                                    {showCopiedPopup && (
                                        <CopiedPopup>
                                            Link Copied to Clipboard
                                        </CopiedPopup>
                                    )}
                                </LinkIcon>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                    <ChipContainer
                        href={link}
                        target="_blank"
                        className={status === "queued" ? "disabled" : ""}
                    >
                        <IconContainer>
                            <Icon
                                src={call_type === "Video" ? video : audio}
                                alt="icon"
                            />
                        </IconContainer>
                        <Date>{call_type === "Video" ? "Open" : "Call"}</Date>
                    </ChipContainer> */}
                </>
            ) : null}
        </>
    );
}

export default SessionCallChip;

const UpdateStatusButton = styled.div`
    position: relative;
    display: flex;
    border: 1px solid #e2e2e2;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    background: ${THEME_COLORS.chips_blue_container};
    justify-content: space-between;
    &.disabled {
        opacity: 0.7;
    }
    &.active {
        opacity: 1;
    }
    &.cancelled {
        background: none;
    }
`;
const ChipContainer = styled.a`
    position: relative;
    display: flex;
    font-weight: bold;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    background: ${THEME_COLORS.chips_blue_container};
    justify-content: space-between;
    &.disabled {
        opacity: 0.7;
    }
    &.active {
        opacity: 1;
    }
    &.cancelled {
        background: none;
    }
`;
const ChipContainerTwo = styled(Link)`
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    background: ${THEME_COLORS.chips_blue_container};
    justify-content: space-between;
    &.disabled {
        filter: opacity(0.5);
    }
    &.cancelled {
        background: none;
    }
`;
const Date = styled.p`
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans_medium";
    font-size: 12px;
`;
const IconContainer = styled.div`
    width: 16px;
    margin-right: 5px;
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;
const LinkIcon = styled.div`
    margin-right: 10px;
    cursor: pointer;
    position: relative;
`;

const CopiedPopup = styled.div`
    position: absolute;
    top: -2px;
    background: #366ee3;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0.9;
    width: max-content;
    right: 35px;
`;

const WaitingPopup = styled.div`
    position: absolute;
    bottom: 4px;
    background: rgba(248, 237, 224, 1);
    color: rgba(235, 137, 47, 1);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
    width: max-content;
    right: 85px;
`;
const Dot = styled.div`
    background: #fe6666;
    width: 5px;
    height: 5px;
    border-radius: 20px;
    margin-right: 10px;
    position: absolute;
    top: 5px;
    right: 0;
`;
