import React from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'

const ButtonTypeTwo = ({ text, onclick, edit }) => {
  return (
    <Button onClick={onclick}>
      {edit ? <img src={edit} /> : ''}
      {text}
    </Button>
  )
}
export default ButtonTypeTwo

const Button = styled.button`
  font-size: 14px;
  font-weight: 500;
  color: ${THEME_COLORS.dark_500};
  border: 2px solid ${THEME_COLORS.light_400};
  border-radius: 8px;
  padding: 6px 16px;
  cursor: pointer;
  background-color: ${THEME_COLORS.white};
  font-family: 'DM_sans';
  display: flex;
  img {
    margin-right: 10px;
  }
`
