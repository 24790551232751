import React from 'react'
import styled from "styled-components";

import ReportList from './ReportList';


const CaseReportList = () => {
  return (
    <Main>
        <ReportList type="case_report" />
    </Main>
  )
}

export default CaseReportList

const Main = styled.div``
