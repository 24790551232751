import React from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig.js'


const ServiceCard = ({service}) => {
  return(
        <Card>
            <Image>
                <img src={require(`../../../assets/image/about/${service.image}`)} alt={service.name} />
            </Image>
            <Name>{service.name}</Name>
            <Description>{service.description}</Description>
        </Card>
  )
}

export default ServiceCard

const Card = styled.div`
    width: 220px;
    text-align: center;
`
const Image = styled.div`
    margin-bottom: 13px;
`
const Name = styled.h5`
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.black};
`
const Description = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`