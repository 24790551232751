import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import FileUploadButton from './FileUploadButton'
import { Link, useNavigate } from 'react-router-dom'
import { THEME_COLORS } from '../../ThemeConfig'
import { expert } from '../../axiosConfig'
import { Context } from '../../contexts/Store'
import CancelSignupModal from './modals/CancelSignupModal'

function YourBio({ completed, setCompleted }) {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(Context)
  const [inputFocused, setInputFocused] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const access_token = state.user_details.access_token

  const [attr, setAttr] = useState('')
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [education, setEducation] = useState(null)
  const [checked, setChecked] = useState(false)
  const [checkedTwo, setCheckedTwo] = useState(false)
  const [yourBio, setYourBio] = useState('')

  const [selectedFiles, setSelectedFiles] = useState({
    IdDocument: null,
    Education: null,
    RCI: null,
    Lsnc: null,
    PM: null,
    Photo: null,
  })

  const setAttribute = (attr) => {
    setAttr(attr)
  }

  const handleFileChange = (event, contentName) => {
    const file = event.target.files[0]
    const edu = event.target.files[0]

    setEducation(edu)
    setSelectedFiles((prev) => ({
      ...prev,
      [contentName]: file,
    }))
  }

  const handleClear = (contentName) => {
    setSelectedFiles((prev) => ({
      ...prev,
      [contentName]: null,
    }))
    document.getElementById(`${contentName}FileInput`).value = ''
  }

  const handleInputFocus = (fieldName) => {
    setInputFocused(fieldName)
  }

  const handleInputBlur = () => {
    setInputFocused('')
  }

  const Checkbox = ({ label, onChange }) => {
    const handleChange = () => {
      setChecked(!checked)
      if (onChange) {
        onChange(!checked)
      }
    }

    return (
      <StyledCheckbox>
        <label>
          <input type="checkbox" checked={checked} onChange={handleChange} />
          {label}
        </label>
      </StyledCheckbox>
    )
  }
  const CheckboxTwo = ({ label, onChange }) => {
    const handleChange = () => {
      setCheckedTwo(!checkedTwo)
      if (onChange) {
        onChange(!checkedTwo)
      }
    }

    return (
      <StyledCheckbox>
        <label>
          <input type="checkbox" checked={checkedTwo} onChange={handleChange} />
          {label}
        </label>
      </StyledCheckbox>
    )
  }
  const formData = new FormData()
  const data = {
    bio: yourBio,
    identity_document: selectedFiles.IdDocument,
    education_and_training: selectedFiles.Education,
    rci_registration: selectedFiles.RCI,
    licensing: selectedFiles.Lsnc,
    professional_membership: selectedFiles.PM,
    photo: selectedFiles.Photo,
    profile_update: false,
  }
  const handleSubmit = () => {
    // if (checked && checkedTwo) {
    if (
      selectedFiles.Id !== null &&
      selectedFiles.Education !== null &&
      selectedFiles.Photo !== null
    ) {
      expert
        .put('/add-bio/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data
          if (StatusCode === 6000) {
            setErr('')
            setLoading(false)
            dispatch({
              type: 'UPDATE_NEW_REGISTERATION_STATUS',
              nrs: false,
            })
            navigate('/auth/profile-verification') // Navigate to Expert-Details page
            // }
          } else {
            setErr(response.data.message)
            console.log(response, response)
          }
          // console.log(response.data.data)
        })
        .catch((error) => {
          console.log(error.response.data.data.message, 'catch')
          setErr(error.response.data.data.message)
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
          setLoading(false) // Make sure to set loading to false in case of an error
        })
    } else {
      setErr('*This file is mandatory')
    }
    // }
  }

  useEffect(() => {
    const isNewRegitrationStarted = state.nrs
    if (isNewRegitrationStarted !== true) {
      navigate('/auth/login-page/')
    }
  }, [state])

  return (
    <>
      {isCancelModalOpen && (
        <CancelSignupModal
          isModalOpen={isCancelModalOpen}
          setIsModalOpen={setIsCancelModalOpen}
        />
      )}
      <Div></Div>
      <Main>
        <Wrapper>
          <TitileBox>
            <Titile>Your Bio</Titile>
            <ExitBox
              onClick={() => {
                setIsCancelModalOpen(true)
              }}
            >
              <Exit
                src={require('../../assets/image/loginpage/close.svg').default}
                alt="Exit_icon"
              />
              Cancel
            </ExitBox>
          </TitileBox>
          <ItemFlexContainer style={{ marginTop: '60px' }}>
            <Left>
              <Heading>
                <TopBox>
                  <SubHeading>Describe Yourself</SubHeading>
                  <SubTitile>Describe yoursef in a short brief</SubTitile>
                </TopBox>
              </Heading>
            </Left>
            <Right>
              <InputFieldWrapper>
                <InputField
                  className={inputFocused === 'bio' ? 'active' : ''}
                  for="bio"
                >
                  <SubHead>Enter a Brief Bio</SubHead>
                  <NameBox
                    type="text"
                    id="bio"
                    value={yourBio}
                    onChange={(e) => setYourBio(e.target.value)}
                    onFocus={() => {
                      handleInputFocus('bio')
                    }}
                    onBlur={handleInputBlur}
                  />
                </InputField>
              </InputFieldWrapper>
            </Right>
          </ItemFlexContainer>

          <ItemFlexContainer style={{ marginTop: '44px' }}>
            <Left>
              <SubHeading>Upload Documents</SubHeading>

              <ListItems>
                <Items>
                  <ItemText>1.</ItemText>
                  <ItemText>
                    Education and Training: A minimum of a Master's degree in
                    psychology or a related field is required. Some roles may
                    require a Doctorate degree (Ph.D. or Psy.D.)
                  </ItemText>
                </Items>
                <Items>
                  <ItemText>2.</ItemText>
                  <ItemText>
                    Registration: Experts in India are required to register with
                    the Rehabilitation Council of India (RCI) in order to
                    practice legally
                  </ItemText>
                </Items>
                <Items>
                  <ItemText>3.</ItemText>
                  <ItemText>
                    Licensing: Some states in India have their own licensing
                    boards which require an additional registration and
                    certification process.
                  </ItemText>
                </Items>
                <Items>
                  <ItemText>4.</ItemText>
                  <ItemText>
                    Professional Membership: Such as the Indian Association of
                    Clinical Psychologists (IACP) or the Indian Psychiatric
                    Society (IPS).
                  </ItemText>
                </Items>
              </ListItems>
            </Left>
            <Right>
              <UploadBox>
                <Contents>
                  <Content>Identity Document (Govt Approved)*</Content>
                  <FileUploadButton
                    contentName="IdDocument"
                    selectedFile={selectedFiles.IdDocument}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>
                {selectedFiles.IdDocument == null && err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )}
                <Contents>
                  <Content>Education and Training *</Content>
                  <FileUploadButton
                    contentName="Education"
                    selectedFile={selectedFiles.Education}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>
                {selectedFiles.Education == null && err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )}

                <Contents>
                  <Content>RCI Registration (If any)</Content>
                  <FileUploadButton
                    contentName="RCI"
                    selectedFile={selectedFiles.RCI}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>
                {/* {selectedFiles.RCI == null && err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )} */}

                <Contents>
                  <Content>Licensing (If any)</Content>
                  <FileUploadButton
                    contentName="Lsnc"
                    selectedFile={selectedFiles.Lsnc}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>

                <Contents>
                  <Content>Professional Membership (If any)</Content>
                  <FileUploadButton
                    contentName="PM"
                    selectedFile={selectedFiles.PM}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>

                <Contents>
                  <Content>Upload Photo*</Content>
                  <FileUploadButton
                    contentName="Photo"
                    selectedFile={selectedFiles.Photo}
                    handleFileChange={handleFileChange}
                    handleClear={handleClear}
                  />
                </Contents>
                {selectedFiles.Photo == null || err ? (
                  <ErrorRed className="error-message">{err}</ErrorRed>
                ) : (
                  ''
                )}
              </UploadBox>
              {/* <CheckContainer>
                <Checkbox
                  label={
                    <span>
                      I Agree to MindMitra{' '}
                      <span
                        style={{
                          color: '#366ee3',
                          textDecoration: 'underline',
                        }}
                      >
                        Terms and Policy
                      </span>
                    </span>
                  }
                />
                <CheckboxTwo
                  label={
                    <span>
                      I Agree to Expert{' '}
                      <span
                        style={{
                          color: '#366ee3',
                          textDecoration: 'underline',
                        }}
                      >
                        Code of Conduct
                      </span>
                    </span>
                  }
                />
              </CheckContainer> */}
              <Bottom>
                <LeftBox>*This field is mandatory</LeftBox>
                <RightBox>
                  <Previous
                    onClick={() => {
                      completed.expert_details = ''
                      navigate('/auth/working-status')
                    }}
                  >
                    Previous
                  </Previous>
                  <Save
                    // className={checked && checkedTwo && 'active'}
                    onClick={() => {
                      handleSubmit()
                      // navigate('/dashboard')
                    }}
                  >
                    Save & Continue
                  </Save>
                </RightBox>
              </Bottom>
            </Right>
          </ItemFlexContainer>

          {/* <Right>
                       
                    </Right> */}
        </Wrapper>
      </Main>
    </>
  )
}

export default YourBio
const Main = styled.div`
  padding: 68px 0;
  height: calc(100vh - 80px);
  /* box-sizing: border-box; */
  overflow-y: scroll;
  scroll-behavior: smooth;
`
const Wrapper = styled.div`
  /* display: flex;
    justify-content: space-between; */
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  @media all and (max-width: 900px) {
    width: 90%;
  }
`
const ItemFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Div = styled.div`
  border-top: 2px solid #366eef;
  width: 100%;
`
const Left = styled.div`
  width: 45%;
`
const TitileBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Titile = styled.h1`
  font-size: 30px;
  /* font-weight: 500; */
  font-family: 'dm_sans_bold';
`
const Right = styled.div`
  width: 64%;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  /* @media all and (max-width: 980px) {
        align-items: unset;
    } */
`
const LeftBox = styled.p`
  color: #818181;
  font-size: 14px;
  text-align: start;
  margin-bottom: 15px;
`
const RightBox = styled.div`
  align-items: flex-end;
  display: flex;
`

//     background-color: #fff;
//     border: 3px solid #d3d3d3;
//     padding: 13px 30px;
//     border-radius: 8px;
//     font-size: 17px;
//     margin-right: 14px;
//     cursor: pointer;
//     @media all and (max-width: 1280px) {
//         padding: 10px 20px;
//     }
//     @media all and (max-width: 1050px) {
//         font-size: 15px;
//     }
//     @media all and (max-width: 980px) {
//         font-size: 12px;
//     }
// `;
// const Save = styled.button`
//     background-color: #366ee3;
//     padding: 15px 30px;
//     border-radius: 8px;
//     border: none;
//     color: #fff;
//     font-size: 17px;
//     cursor: pointer;
//     opacity: 0.5;
//     @media all and (max-width: 1280px) {
//         padding: 13px 18px;
//     }
//     @media all and (max-width: 1050px) {
//         font-size: 15px;
//     }
//     @media all and (max-width: 980px) {
//         font-size: 12px;
//     }
//     &.active {
//         opacity: 1;
//     }
// `;
const InputFieldWrapper = styled.div`
  /* position: relative; */
`
const InputField = styled.label`
  display: block;
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;
  /* margin-right: 3%; */
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  border: 2px solid #f4f4f4;
  &.active {
    border: 2px solid #366eef;
  }
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`
const Save = styled.button`
  background-color: #366ee3;
  border: 2px solid #366ee3 !important;
  padding: 14px 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  opacity: 0.5;
  cursor: pointer;
  font-family: 'DM_sans_medium';
  opacity: 1;
  /* &.active {
   
  } */
`
const Previous = styled.button`
  background-color: #fff;
  border: 2px solid #d3d3d3;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  font-family: 'DM_sans_medium';
`
const TopBox = styled.div``
const SubHeading = styled.h3`
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #3e3e53;
  font-family: 'dm_sans_medium';
`
const SubTitile = styled.h5`
  color: #818181;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
`
const Heading = styled.div`
  .working {
    margin-bottom: 50px;
  }
`
const ExitBox = styled.div`
  /* justify-content: end; */
  align-items: center;
  display: flex;
  /* margin-right: 5px; */
  color: #1da687;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  gap: 4px;
  padding: 6px;
  margin: 0 0 0 auto;
  width: fit-content;
  font-family: 'DM_sans_medium';
`
const Exit = styled.img`
  display: block;
  width: 12px;

  margin-right: 5px;
`
const Name = styled.div`
  margin-bottom: 8%;
`
const Empname = styled.div`
  margin-bottom: 3%;
  height: auto;
  background-color: #f4f4f4;
  padding: 11px 20px;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    border: 2px solid #366eef;
  }
`
const SubHead = styled.div`
  color: #818181;
  margin: 0;
  font-size: 14px;
  width: 100%;
  display: block;
`
const NameBox = styled.input`
  border: none;
  width: 90%;
  height: 50%;
  font-size: 18px;
  background-color: #f4f4f4;
  outline: none;
  text-transform: capitalize;
`
const Documents = styled.div`
  width: 80%;
`
const ListItems = styled.ol`
  list-style: auto;
  width: 80%;
`
const Items = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  gap: 2px;
`
const ItemText = styled.span`
  color: #818181;
  font-size: 14px;
`
const Skip = styled.button`
  background-color: #fff;
  border: 3px solid #d3d3d3;
  padding: 13px 30px;
  border-radius: 8px;
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
  @media all and (max-width: 1280px) {
    padding: 10px 20px;
  }
  @media all and (max-width: 1050px) {
    font-size: 15px;
  }
  @media all and (max-width: 980px) {
    font-size: 12px;
  }
`
const BottomBox = styled.div``
const UploadBox = styled.div`
  margin-bottom: 60px;
`
const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
`
const Content = styled.p`
  color: #3e3e53;
  font-size: 16px;
  font-family: 'DM_sans_medium';
`
const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    margin: 0;
    margin-right: 10px;
  }
  label {
    color: ${THEME_COLORS.text_para};
  }
`
const CheckContainer = styled.div`
  /* margin-bottom: 40px; */
`
const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  width: 60%;
  /* margin-top: 20px; */
`
