import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// swiper slider
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";

import { THEME_COLORS } from "../../../ThemeConfig.js";
import ServiceCard from "./ServiceCard.jsx";
import TeamCard from "./TeamCard.jsx";
import ButtonTypeOne from "../../includes/buttons/ButtonTypeOne";
SwiperCore.use([Autoplay, Navigation]);

const AboutPage = () => {
    const services = [
        {
            id: 1,
            name: "Depression",
            description:
                "Depression is common and seroius medical illness that negatively ",
            image: "depression.png",
        },
        {
            id: 2,
            name: "Personal Growth",
            description: "Personal growth is self aware and  and entirety",
            image: "personal_growth.png",
        },
        {
            id: 3,
            name: "Anxiety",
            description: "Anxiety is a feeling of fear, dread & uneasiness",
            image: "anxiety.png",
        },
        {
            id: 4,
            name: "Addiction",
            description: "Addiction is defined as a chronic, relapsing.",
            image: "addiction.png",
        },
        {
            id: 5,
            name: "Anger Management",
            description:
                "Depression is common and seroius medical illness that negatively ",
            image: "anxiety.png",
        },
        {
            id: 6,
            name: "Depression",
            description:
                "Depression is common and seroius medical illness that negatively ",
            image: "depression.png",
        },
        {
            id: 7,
            name: "Personal Growth",
            description: "Personal growth is self aware and  and entirety",
            image: "personal_growth.png",
        },
        {
            id: 8,
            name: "Anxiety",
            description: "Anxiety is a feeling of fear, dread & uneasiness",
            image: "anxiety.png",
        },
        {
            id: 9,
            name: "Addiction",
            description: "Addiction is defined as a chronic, relapsing.",
            image: "addiction.png",
        },
        {
            id: 10,
            name: "Anger Management",
            description:
                "Depression is common and seroius medical illness that negatively ",
            image: "anxiety.png",
        },
    ];

    const teams = [
        {
            id: 1,
            name: "Jhon Ebraham",
            designation: "Founder and CEO ",
            image: "jhon_ebraham.png",
        },
        {
            id: 2,
            name: "Ahmad Vetrovs",
            designation: "Founder and COO ",
            image: "ahmad_vetrovs.png",
        },
        {
            id: 3,
            name: "Charlie Rosser",
            designation: "Founder and CTO ",
            image: "charlie_rosser.png",
        },
        {
            id: 4,
            name: "Davis Philips",
            designation: "Director and CMO ",
            image: "davis_philips.png",
        },
        {
            id: 5,
            name: "Alice stephen",
            designation: "Director",
            image: "alice_stephen.png",
        },
    ];
    return (
        <Main>
            <Spotlight>
                <Left>
                    <img
                        src={require("../../../assets/image/about/blue_shade.png")}
                        alt="shade"
                    />
                    <Title>
                        Enbrace Mindfulness <br />
                        Elevate your well-being
                    </Title>
                    <SubTitle>About Mind Mitra</SubTitle>
                    <Description>
                        Mental health is a significant issue in many countries,
                        with many individuals struggling to access timely and
                        effective care due to a variety of barriers. One
                        potential solution to this issue is the use of online
                        platforms for mental health appointments. These
                        platforms can provide a convenient and private way for
                        individuals to access therapy from the comfort of their
                        own home.
                    </Description>
                </Left>
                <Right>
                    <img
                        src={require("../../../assets/image/about/spotlight.png")}
                        alt="spotlight"
                    />
                </Right>
            </Spotlight>
            <MissionAndVision>
                <Content>
                    <Data>
                        <img
                            src={require("../../../assets/image/about/blue_shade.png")}
                            alt="shade"
                        />
                        <Head>Mission</Head>
                        <Description>
                            Mental health is a significant issue in many
                            countries, with many individuals struggling to
                            access timely and effective care due to a variety of
                            barriers. One potential solution to this issue is
                            the use of online platforms for mental health
                            appointments. These platforms can provide a
                            convenient and private way for individuals to access
                            therapy from the comfort of their own home.
                        </Description>
                    </Data>
                    <Image>
                        <img
                            src={require("../../../assets/image/about/mission.png")}
                            alt="mission"
                        />
                        <img
                            src={require("../../../assets/image/about/blue_bg.png")}
                            alt="blue background"
                            className="blue-bg"
                        />
                    </Image>
                </Content>
                <Content>
                    <Data>
                        <Head>Vision</Head>
                        <Description>
                            {" "}
                            This case study suggests that online mental therapy
                            appointment apps can be an effective way to improve
                            access to mental health care for individuals who may
                            face barriers to traditional in-person appointments.
                            Further research is needed to examine the long-term
                            effectiveness of these platforms, as well as their
                            potential to reach underserved populations.
                        </Description>
                    </Data>
                    <Image>
                        <img
                            src={require("../../../assets/image/about/vision.png")}
                            alt="vision"
                        />
                        <img
                            src={require("../../../assets/image/about/blue_bg.png")}
                            alt="blue background"
                            className="blue-bg"
                        />
                    </Image>
                </Content>
            </MissionAndVision>
            <Services>
                <Wrapper>
                    <Top>
                        <ServicesLeft>
                            <Head>
                                psychological issues that <br />
                                We deal with
                            </Head>
                        </ServicesLeft>
                        <ServicesRight>
                            <Description>
                                Overall, the results of this study suggest that
                                the online mental therapy appointment app was
                                effective in improving access to mental health
                                care for the participants.
                            </Description>
                        </ServicesRight>
                    </Top>
                    <Bottom>
                        <Swiper
                            slidesPerView={5}
                            spaceBetween={32}
                            speed={500}
                            autoplay={{ delay: 1000 }}
                            loop={true}
                            navigation={false}
                            breakpoints={{
                                1440: {
                                    slidesPerView: 4.5,
                                },
                                1280: {
                                    slidesPerView: 3.5,
                                },
                                980: {
                                    slidesPerView: 3.25,
                                },
                                768: {
                                    slidesPerView: 2.5,
                                },
                            }}
                        >
                            {services.map((service) => (
                                <SwiperSlide key={service.id}>
                                    <ServiceCard service={service} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Bottom>
                </Wrapper>
            </Services>
            <Team>
                <img
                    src={require("../../../assets/image/about/blue_shade.png")}
                    alt="shade"
                    className="shade"
                />
                <Head>Our Team</Head>
                <Cards>
                    {teams.map((person) => {
                        return <TeamCard person={person} key={person.id} />;
                    })}
                </Cards>
            </Team>
            <Results>
                <Items>
                    <Item>
                        <Count>1000+</Count>
                        <Type>Happy clients</Type>
                    </Item>
                    <Item>
                        <Count>200+</Count>
                        <Type>Experts</Type>
                    </Item>
                    <Item>
                        <Count>2400+</Count>
                        <Type>Session</Type>
                    </Item>
                </Items>
            </Results>
            <Join>
                <JoinLeft>
                    <img
                        src={require("../../../assets/image/about/phone.png")}
                        alt="phone"
                    />
                </JoinLeft>
                <JoinRight>
                    <JoinHead>
                        Transform your mind,
                        <br />
                        <span>Transform your life</span>
                    </JoinHead>
                    <Description>
                        Further research is needed to examine the long-term
                        effectiveness of these platforms, as well as their
                        potential to reach underserved populations.
                    </Description>
                    <JoinButton to="/">
                        <ButtonTypeOne
                            text="Join Today"
                            color={THEME_COLORS.white}
                            bkgcolor={THEME_COLORS.primary}
                        />
                    </JoinButton>
                </JoinRight>
            </Join>
        </Main>
    );
};

export default AboutPage;

const Main = styled.div``;
const Spotlight = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
`;
const Left = styled.div`
    width: 50%;
    position: relative;
    img {
        position: absolute;
        top: -180px;
        left: -180px;
    }
    @media all and (max-width: 1280px) {
        width: 55%;
    }
`;
const Title = styled.h3`
    font-weight: 700;
    font-size: 40px;
    color: ${THEME_COLORS.black};
    margin-bottom: 20px;
`;
const SubTitle = styled.h4`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.wireframe_500};
`;
const Right = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    img {
        display: block;
        width: 100%;
    }
`;
const MissionAndVision = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
    position: relative;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(2) {
        flex-direction: row-reverse;
        img {
            &.blue-bg {
                left: -20px;
            }
        }
    }
`;
const Data = styled.div`
    width: 48%;
    img {
        position: absolute;
        top: 0;
        left: -180px;
    }
`;
const Head = styled.div`
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.black};
    margin-bottom: 10px;
`;
const Image = styled.div`
    position: relative;
    width: 48%;
    img {
        position: relative;
        width: 100%;
        z-index: 100;
        &.blue-bg {
            position: absolute;
            z-index: 1;
            bottom: -20px;
            right: -20px;
        }
    }
`;
const Services = styled.div`
    padding: 80px 0;
    background-color: ${THEME_COLORS.chips_green_container};
`;
const Wrapper = styled.div`
    width: 80%;
    margin: 0 auto;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
`;
const ServicesLeft = styled.div`
    width: 50%;
`;
const ServicesRight = styled.div`
    width: 50%;
`;
const Bottom = styled.div`
    display: flex;
    &.swiper-slide {
        width: auto !important;
    }
`;
const Team = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
    position: relative;
    img {
        &.shade {
            position: absolute;
            top: -100px;
            left: -180px;
        }
    }
`;
const Cards = styled.ul`
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
`;
const Results = styled.div`
    background-color: ${THEME_COLORS.chips_green_container};
    padding: 30px 0;
`;
const Items = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
const Item = styled.div`
    text-align: center;
`;
const Count = styled.h5`
    color: ${THEME_COLORS.black};
    font-weight: 700;
    font-size: 32px;
`;
const Type = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_para};
`;
const Join = styled.div`
    padding: 80px 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const JoinLeft = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const JoinRight = styled.div`
    width: 45%;
`;
const JoinHead = styled.div`
    font-weight: 700;
    font-size: 32px;
    color: ${THEME_COLORS.black};
    margin-bottom: 14px;
    span {
        color: ${THEME_COLORS.primary};
        font-weight: 700;
        font-size: 32px;
    }
`;
const JoinButton = styled(Link)`
    margin-top: 14px;
    display: inline-block;
`;
