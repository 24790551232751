import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import validator from 'validator'

import { THEME_COLORS } from '../../../ThemeConfig'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import ChipRectangle from '../../includes/chips/ChipRectangle'
import DropDown from '../../includes/dropdown/DropDown'
import AttentionModal from './modals/AttentionModal'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import Lottie from 'lottie-react'
import LoaderLottie from '../../../assets/lottie/LoaderLottie.json'
import CancelWarningModal from './modals/CancelWarningModal'

const AboutYou = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [profilePicture, setProfilePicture] = useState(null)
  const [specialization, setSpecialization] = useState('')
  const [specializationData, setSpecializationData] = useState([])
  const [specializationID, setSpecializationID] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [genderName, setGenderName] = useState('')
  const [age, setAge] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [location, setLocation] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [showGenderItems, setShowGenderItems] = useState(false)
  const [showSpecialization, setShowSpecialization] = useState(false)
  // const [showAttentionModal, setShowAttentionModal] = useState(false);
  const [emailError, setEmailError] = useState(false)
  const [isGetDataLoading, setGetDataLoading] = useState(false)
  const [err, setErr] = useState('')

  const options = [
    {
      id: 10,
      name: 'Female',
    },

    {
      id: 20,
      name: 'Male',
    },

    {
      id: 30,
      name: 'Others',
    },
  ]

  useEffect(() => {
    // if (access_token) {
    expert
      .get('/specializations/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        // console.log(response.data, "specialization data");
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setSpecializationData(data)
        }
        // console.log(data, "specializations data ");
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      })
    // }
  }, [])

  const navigate = useNavigate()

  const addProfilePicture = (e) => {
    const file = new FileReader()
    file.addEventListener('load', () => {
      setProfilePicture(file.result)
    })
    file.readAsDataURL(e.target.files[0])
  }

  const handleDelete = () => {
    setProfilePicture(null)
  }

  const handleGender = (gender, id) => {
    setGenderName(gender)
    setGender(id)
    setShowGenderItems(!showGenderItems)
  }

  const handleSpecialization = (specialization, Id) => {
    setSpecialization(specialization)
    setSpecializationID(Id)
    setShowSpecialization(!showSpecialization)
  }

  const handleDropdown = (type) => {
    if (type === 'gender') {
      setShowGenderItems(!showGenderItems)
      setShowSpecialization(false)
    }
    if (type === 'specialization') {
      setShowSpecialization(!showSpecialization)
      setShowGenderItems(false)
    }
  }

  const handleEmail = () => {
    if (validator.isEmail(email)) {
      setEmailError(false)
      return true
    } else {
      setEmailError(true)
      return false
    }
  }

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode
    if (keyCode < 48 || keyCode > 57) {
      // only allow numeric key codes
      event.preventDefault()
    }
  }

  const clearStates = () => {
    setProfilePicture('')
    setSpecialization('')
    setFirstName('')
    setLastName('')
    setGender('')
    setAge('')
    setEmail('')
    setPhoneNumber('')
    setLocation('')
    setShowErrors(false)
  }

  const handleSubmit = (close) => {
    // e.preventDefault()
    if (specialization && firstname && gender && age && email) {
      if (handleEmail()) {
        setShowSuccessToast(true)
        // clearStates();
        if (close === 'close') {
          setTimeout(() => {
            navigate(-1)
          }, 2000)
        }
      }
    } else {
      setShowErrors(true)
    }
  }

  const handleCancel = () => {
    if (
      profilePicture ||
      specialization ||
      firstname ||
      lastname ||
      gender ||
      age ||
      email ||
      phoneNumber ||
      location
    )
      setShowAttentionModal(true)
    else {
      clearStates()
      navigate(-1)
    }
  }

  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setShowSpecialization(false)
      setShowGenderItems(false)
    }
  }

  const getAboutDetails = () => {
    setGetDataLoading(true)
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-personal-info/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        // console.log(response.data, "response.data");

        if (StatusCode === 6000) {
          // console.log(data, "Details");
          if (data.photo) {
            setProfilePicture(data.photo)
          }
          if (data.specialization_name) {
            setSpecialization(data.specialization_name)
          }
          if (data.first_name) {
            setFirstName(data.first_name)
          }
          if (data.last_name) {
            setLastName(data.last_name)
          }

          if (data.gender) {
            setGender(data.gender)
          }
          if (data.gender_name) {
            setGenderName(data.gender_name)
          }
          if (data.age) {
            setAge(data.age)
          }
          if (data.email) {
            setEmail(data.email)
          }
          if (data.phone) {
            setPhoneNumber(data.phone)
          }
          if (data.current_location_of_practice) {
            setLocation(data.current_location_of_practice)
          }
          if (data.specialization) {
            setSpecializationID(data.specialization)
          }
          // if (data.gender) {
          //     setGenderSelect(data.gender);
          // }

          setTimeout(() => {
            setGetDataLoading(false)
          }, 100)
        } else {
          // setLoading(false);
          setGetDataLoading(false)
        }
      })
      .catch((error) => {
        // alert(error.response.data.data.message);
        setGetDataLoading(false)
      })
  }

  const handleFinalSubmit = () => {
    if (
      specializationID &&
      firstname &&
      lastname &&
      gender &&
      email &&
      phoneNumber &&
      age
    ) {
      const data = {
        profile_picture: profilePicture,
        specialization: specializationID,
        first_name: firstname,
        last_name: lastname,
        gender: gender,
        email: email,
        age: age,
        phone: phoneNumber,
        location: location,
      }

      expert
        .put('/add-personal-info/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data

          setErr('')
          handleSubmit()
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              phone: phoneNumber,
            },
          })
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              first_name: firstname,
              last_name: lastname,
              phone: phoneNumber,
              email: email,
              age: age,
              location: location,
              gender: gender,
            },
          })
        })
        .catch((error) => {
          console.error('Error creating post personal-infooo:', error.response)
          //   if (error.response.status === 404) {
          setErr('NOT_FOUND')
          //   }
        })
    } else {
      setErr('*This field is mandatory')
    }
  }

  useEffect(() => {
    getAboutDetails()
  }, [])

  // console.log(phoneNumber, "specializationID");

  return (
    <Form
      onClick={(e) =>
        (showSpecialization || showGenderItems) && handleOuterClick(e)
      }
    >
      <Head>About you</Head>
      {!isGetDataLoading ? (
        <>
          <PhotoWrapper>
            <Photo>
              {profilePicture ? <img src={profilePicture} alt="" /> : ''}
            </Photo>
            <ProfileInput
              type="file"
              id="add-image"
              onChange={addProfilePicture}
              accept="image/png, image/jpeg, image/jpg"
            />
            <ProfileLabel htmlFor="add-image">
              <ChipRectangle
                content="Upload New Picture"
                bkgcolor={THEME_COLORS.chips_blue_container}
                color={THEME_COLORS.chips_blue_on_container_2}
                pd="4px 8px"
                br="6px"
                font_size="14px"
                font_weight="500"
                hover_color={THEME_COLORS.gradient_2}
              />
            </ProfileLabel>
            <Button>
              <ChipRectangle
                content="Delete"
                color={THEME_COLORS.chips_blue_on_container_2}
                pd="4px 8px"
                br="6px"
                font_size="14px"
                font_weight="500"
                onclick={handleDelete}
              />
            </Button>
          </PhotoWrapper>
          <PersonalInfo>
            <Title>Personal Info*</Title>
            <SubTitle>Provide your Personal Info</SubTitle>
            <LongWrapper>
              <InputWrapper className="input-wrapper">
                <Left>
                  <SelectLabel
                    className={
                      specialization ? 'select-label active' : 'select-label'
                    }
                  >
                    Specialization *
                  </SelectLabel>
                  <SelectValue className="select-value">
                    {specialization}
                  </SelectValue>
                </Left>

                <Right onClick={() => handleDropdown('specialization')}>
                  <img
                    src={
                      require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                        .default
                    }
                    alt="down arrow"
                  />
                </Right>
                {showSpecialization && (
                  <DropDown
                    list={specializationData}
                    handleMethod={handleSpecialization}
                    state={specialization}
                  />
                )}
              </InputWrapper>
              {showErrors && !specialization && (
                <Error className="error">This field is required.</Error>
              )}
            </LongWrapper>

            <Wrapper>
              <ShortWrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="firstname"
                      className={firstname ? 'input-label show' : 'input-label'}
                    >
                      Firstname*
                    </FormLabel>
                    <FormInput
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Firstname*"
                      className={
                        firstname ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !firstname && (
                  <Error className="error">This field is required.</Error>
                )}
              </ShortWrapper>

              <ShortWrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="lastname"
                      className={lastname ? 'input-label show' : 'input-label'}
                    >
                      Lastname
                    </FormLabel>
                    <FormInput
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Lastname"
                      className={
                        lastname ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
              </ShortWrapper>

              <ShortWrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <SelectLabel
                      className={
                        gender ? 'select-label active' : 'select-label'
                      }
                    >
                      Gender*
                    </SelectLabel>
                    <SelectValue className="select-value">
                      {genderName}
                    </SelectValue>
                  </Left>
                  <Right onClick={() => handleDropdown('gender')}>
                    <img
                      src={
                        require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                          .default
                      }
                      alt="down arrow"
                    />
                  </Right>
                  {showGenderItems && (
                    <DropDown
                      list={options}
                      handleMethod={handleGender}
                      state={gender}
                    />
                  )}
                </InputWrapper>
                {showErrors && !gender && (
                  <Error className="error">This field is required.</Error>
                )}
              </ShortWrapper>

              <ShortWrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="age"
                      className={age ? 'input-label show' : 'input-label'}
                    >
                      Age*
                    </FormLabel>
                    <FormInput
                      id="age"
                      name="age"
                      value={age}
                      type="number"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        e.target.value.length <= 3 && setAge(e.target.value)
                      }}
                      placeholder="Age*"
                      className={age ? 'input-value active' : 'input-value'}
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !age && (
                  <Error className="error">This field is required.</Error>
                )}
              </ShortWrapper>
            </Wrapper>
          </PersonalInfo>

          <ContactInfo>
            <Title>Contact Info*</Title>
            <SubTitle>Provide your Contact Informations</SubTitle>
            <Wrapper>
              <ShortWrapper>
                <InputWrapper className="input-wrapper disable">
                  <Left>
                    <FormLabel
                      htmlFor="email"
                      className={email ? 'input-label show' : 'input-label'}
                    >
                      Email ID*
                    </FormLabel>
                    <FormInput
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      readOnly="true"
                      onChange={(e) => setEmail(e.target.value)}
                      // onChange={(e)=>handleEmail(e)}
                      placeholder="Email ID*"
                      className={'input-value active'}
                    ></FormInput>
                  </Left>
                </InputWrapper>
                {showErrors && !email && (
                  <Error className="error">This field is required.</Error>
                )}
                {email && emailError && (
                  <Error className="error">Enter valid email</Error>
                )}
              </ShortWrapper>

              <ShortWrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="phone_number"
                      className={
                        phoneNumber ? 'input-label show' : 'input-label'
                      }
                    >
                      Phone No.
                    </FormLabel>
                    <FormInput
                      id="phone_number"
                      name="phone_number"
                      value={phoneNumber}
                      type="number"
                      // onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setPhoneNumber(e.target.value)
                      }}
                      placeholder="Phone No."
                      // disabled={true}
                      className={
                        phoneNumber ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
              </ShortWrapper>
            </Wrapper>

            <LongWrapper>
              <InputWrapper className="input-wrapper">
                <Left>
                  <FormLabel
                    htmlFor="location"
                    className={location ? 'input-label show' : 'input-label'}
                  >
                    Current Location of practice
                  </FormLabel>
                  <FormInput
                    id="location"
                    name="location"
                    value={location}
                    type="text"
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Current Location of practice"
                    className={location ? 'input-value active' : 'input-value'}
                  ></FormInput>
                </Left>
              </InputWrapper>
            </LongWrapper>
          </ContactInfo>

          <FormButtons>
            <ButtonTypeOne
              text="Cancel"
              color={THEME_COLORS.dark_500}
              bkgcolor={THEME_COLORS.white}
              border={THEME_COLORS.light_400}
              onclick={handleCancel}
            />
            <ButtonTypeOne
              text="Save"
              color={THEME_COLORS.white}
              bkgcolor={THEME_COLORS.chips_blue_on_container_2}
              onclick={() => {
                handleFinalSubmit()
              }}
            />
          </FormButtons>
        </>
      ) : (
        <Lottie
          animationData={LoaderLottie}
          style={{
            height: '300px',
            width: 100,
            margin: 'auto',
          }}
        />
      )}

      {showAttentionModal && (
        <CancelWarningModal
          isModal={showAttentionModal}
          setIsModal={setShowAttentionModal}
        />
      )}
    </Form>
  )
}

export default AboutYou

const Form = styled.form``
const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`
const Photo = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* border-radius: 4px; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 4px solid ${THEME_COLORS.white};
  img {
    /* border-radius: 4px; */
    border-radius: 50%;

    width: 100%;
  }
`
const Button = styled.div`
  cursor: pointer;
  margin-left: 16px;
  border-radius: 6px;
  &:hover {
    background-color: ${THEME_COLORS.light_200};
  }
`
const ProfileLabel = styled.label`
  cursor: pointer;
  margin-left: 16px;
`
const ProfileInput = styled.input`
  display: none;
`
const PersonalInfo = styled.div`
  margin-bottom: 32px;
`
const Title = styled.h5`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
`
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const LongWrapper = styled.div`
  width: 100%;
  margin-top: 18px;
`
const ShortWrapper = styled.div`
  width: 48%;
  margin-top: 18px;
`
const InputWrapper = styled.div`
  &.disable:focus-within {
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    box-shadow: none;
  }
`
const Left = styled.div`
  flex-grow: 1;
`
const Right = styled.div``

const Error = styled.small`
  margin-top: 10px;
`
const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const SelectLabel = styled.label`
  &.active {
    font-size: 12px;
  }
`
const FormInput = styled.input`
    &.active {
        font-weight: 500;
        font-size: 16px !important;
    }
    /* cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')}; */
`
const SelectValue = styled.p``
const ContactInfo = styled.div`
  margin-bottom: 32px;
`
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;
`
