import React, { useState } from 'react'
import { THEME_COLORS } from '../../../ThemeConfig'
import styled from 'styled-components'
import icon from '../../../assets/image/dashboard/sort-black.svg'

function FilterDropdown({ setFilterModal, filtermodal, month, setDateModal }) {
  return (
    <>
      <DateContainer
        onClick={() => {
          setFilterModal(!filtermodal)
          setDateModal(false)
        }}
      >
        <Date>Filter By</Date>
        <Icon src={icon} alt="icon" />
      </DateContainer>
    </>
  )
}

export default FilterDropdown

const Date = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-family: 'DM_sans_medium';
  font-size: 14px;
  margin-right: 5px;
`
const Icon = styled.img``
const DateContainer = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-around;
  padding: 8px 15px;
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 8px;
  background: ${THEME_COLORS.white};
  cursor: pointer;
`
