import React, { useRef, useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import { patient } from '../../../axiosConfig'
import ChipOval from '../chips/ChipOval'
import ButtonTypeOne from '../buttons/ButtonTypeOne'

// const SocioDemographicDetails = () => {
//     return <div>{/* Your Socio-Demographic Details component content */}</div>;
// };

const SocioDemoGraphicModalComponent = ({
  isUpdatePatientDetailsModal,
  closeMPatientModal,
  sessionId,
  getPatientData,
  patientDetails,
}) => {
  const modalRef = useRef()
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [error, SetError] = useState('')
  const [loading, setLoading] = useState(false)

  const [inputFocused, setInputFocused] = useState('')
  const [genderModal, setGenderModal] = useState(false)

  const [name, setName] = useState('')
  const [gender, setGender] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [informant, setInformant] = useState('')
  const [occupation, setOccupation] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [religion, setReligion] = useState('')
  const [education, setEducation] = useState('')
  const [family, setFamily] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [maritalData, setMaritalData] = useState([])
  const [religionData, setReligionData] = useState([])
  const [educationData, setEducationData] = useState([])
  const [familyData, setFamilyData] = useState([])

  const genderOptions = [
    {
      id: 1,
      value: 10,
      name: 'Male',
    },
    {
      id: 2,
      value: 20,
      name: 'Female',
    },
    {
      id: 3,
      value: 30,
      name: 'Other',
    },
  ]

  const getMaritalData = () => {
    // if (access_token) {
    patient
      .get('/marital-status/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        // params: {
        //   type: sessionType,
        // },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setMaritalData(response.data.data)
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
    // }
  }
  const getReligionData = () => {
    // if (access_token) {
    patient
      .get('/religion/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setReligionData(response?.data?.data)
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
    // }
  }

  const getEducationData = () => {
    // if (access_token) {
    patient
      .get('/education/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setEducationData(response?.data?.data)
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
    // }
  }
  const getFamilyData = () => {
    // if (access_token) {
    patient
      .get('/family/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setFamilyData(response?.data?.data)
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
    // }
  }

  const clearStates = () => {
    setName('')
    setGender('')
    setDateOfBirth('')
    setInformant('')
    setOccupation('')
    setMaritalStatus('')
    setReligion('')
    setEducation('')
    setFamily('')
    setShowErrors(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (name && gender) {
      // submit functionality
      clearStates()
      closeMPatientModal() //go to session summary page
    } else {
      setShowErrors(true)
    }
  }

  const handleCancel = () => {
    clearStates()
    closeMPatientModal()
  }
  useEffect(() => {
    getMaritalData()
    getReligionData()
    getEducationData()
    getFamilyData()
  }, [])

  const formData = {
    // patient: name,
    dob: dateOfBirth,
    informant: informant,
    marital_status: maritalStatus,
    religion: religion,
    education: education,
    occupation: occupation,
    family: family,
  }

  const genderDropdownRef = useRef()
  const handleClickOutsideGender = (event) => {
    if (
      genderDropdownRef.current &&
      !genderDropdownRef.current.contains(event.target)
    ) {
      setInputFocused('')
      setTimeout(() => {
        setGenderModal(false)
      }, 50)
    }
  }
  useEffect(() => {
    if (genderModal) {
      document.addEventListener('mousedown', handleClickOutsideGender)

      return () => {
        document.removeEventListener('mousedown', handleClickOutsideGender)
      }
    }
  }, [genderModal])

  const handleFormSubmit = () => {
    // if (name && dateOfBirth) {
    setLoading(true)
    // console.log(formData, "formData------");
    patient
      .put(`/add-socio-demographic-details/${sessionId}/`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(function (response) {
        const { StatusCode } = response.data
        const { access_token, refresh_token, message } = response.data.data
        SetError('')
        getPatientData()
        setLoading(false)
        closeMPatientModal()
      })
      .catch((error) => {
        console.error('Something went wrong', error)
        if (error.response.status === 404) {
          SetError('NOT_FOUND')
        }
        setLoading(false)
      })
    // } else {
    //     SetError("These fields are required");
    // }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeMPatientModal()
      }
    }

    if (isUpdatePatientDetailsModal) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.body.style.overflow = 'hidden'
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.body.style.overflow = 'auto'
    }
  }, [isUpdatePatientDetailsModal, closeMPatientModal])

  useEffect(() => {
    if (patientDetails?.informant) {
      setInformant(patientDetails.informant)
    }
    if (patientDetails?.dob) {
      setDateOfBirth(patientDetails.dob)
    }
    if (patientDetails?.occupation) {
      setOccupation(patientDetails.occupation)
    }
    if (patientDetails?.family_id) {
      setFamily(patientDetails.family_id)
    }
    if (patientDetails?.education_id) {
      setEducation(patientDetails.education_id)
    }
    if (patientDetails?.marital_status_id) {
      setMaritalStatus(patientDetails.marital_status_id)
    }
    if (patientDetails?.religion_id) {
      setReligion(patientDetails.religion_id)
    }
  }, [patientDetails])

  return (
    <Container modalOpen={isUpdatePatientDetailsModal}>
      {isUpdatePatientDetailsModal && (
        <SocioDemoGraphicModalBg>
          <SocioDemoGraphicModalContent ref={modalRef}>
            {/* <SocioDemographicDetails /> */}
            <Title>Socio Demographic Details</Title>
            <SubHeading>Add your client's Socio Demographic Details</SubHeading>
            <BoxWrapper>
              {/* <Wrapper> */}
              {/* <InputWrapper className="input-wrapper">
                                    <Left>
                                        <FormLabel
                                            htmlFor="name"
                                            className={
                                                name
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Patient ID
                                        </FormLabel>
                                        <FormInput
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            placeholder="Patient ID"
                                            className={
                                                name
                                                    ? "input-value active"
                                                    : "input-value"
                                            }
                                        ></FormInput>
                                    </Left>
                                </InputWrapper>
                                {showErrors && !name && (
                                    <Error className="error">
                                        This field is required
                                    </Error>
                                )} */}
              {/* </Wrapper> */}

              {/* <Wrapper> */}
              {/* <InputWrapper className="input-wrapper">
                                    <Left>
                                        <FormLabel
                                            htmlFor="gender"
                                            className={
                                                gender
                                                    ? "input-label show"
                                                    : "input-label"
                                            }
                                        >
                                            Gender
                                        </FormLabel>
                                        <FormInput
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            value={gender}
                                            onChange={(e) =>
                                                setGender(e.target.value)
                                            }
                                            placeholder="Gender"
                                            className={
                                                gender
                                                    ? "input-value active"
                                                    : "input-value"
                                            }
                                        ></FormInput>
                                    </Left>
                                </InputWrapper>
                                {showErrors && !gender && (
                                    <Error className="error">
                                        This field is required
                                    </Error>
                                )} */}
              {/* </Wrapper> */}
              {/* <InputFieldWrapper>
                                <Gender
                                    ref={genderDropdownRef}
                                    // tabIndex={4}
                                    className={
                                        inputFocused === "gender"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setGenderModal(!genderModal);
                                        if (!genderModal) {
                                            setInputFocused("gender");
                                        }
                                        if (inputFocused == "gender") {
                                            setInputFocused("");
                                        }
                                    }}
                                >
                                    <GenderBox>
                                        <SubHead>Gender *</SubHead>
                                        <span
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        >
                                            {gender && gender}
                                        </span>
                                    </GenderBox>

                                    <Down isOpen={genderModal}>
                                        <img
                                            src={
                                                require("../../../assets/image/loginpage/list_down.svg")
                                                    .default
                                            }
                                            alt="list_down_icon"
                                        />
                                    </Down>

                                    <GenderList
                                        className={genderModal && "view-modal"}
                                    >
                                        {genderOptions.map((genderItem) => (
                                            <ListGender
                                                key={genderItem.id}
                                                value={genderItem.name}
                                                onClick={() => {
                                                    setGenderSelect(
                                                        genderItem.value
                                                    );
                                                    setGender(genderItem.name);
                                                    // console.log(
                                                    //     genderSelect,
                                                    //     "genderSelectgenderSelect"
                                                    // );
                                                }}
                                            >
                                                {genderItem.name}
                                            </ListGender>
                                        ))}
                                    </GenderList>
                                </Gender>
                                {genderSelect == "" && err && (
                                    <ErrorRed className="error-message">
                                        {err}
                                    </ErrorRed>
                                )}
                            </InputFieldWrapper> */}

              <Wrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="dob"
                      className={
                        dateOfBirth ? 'input-label show' : 'input-label'
                      }
                    >
                      Date Of Birth
                    </FormLabel>
                    <FormInput
                      id="dob"
                      name="dob"
                      type="text"
                      onFocus={(e) => (e.target.type = 'date')}
                      onBlur={(e) => (e.target.type = 'text')}
                      min="1900-01-01"
                      max={new Date().toISOString().split('T')[0]}
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="Date Of Birth"
                      className={
                        dateOfBirth ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
              </Wrapper>

              <Wrapper>
                <InputWrapper className="input-wrapper">
                  <Left>
                    <FormLabel
                      htmlFor="informant"
                      className={informant ? 'input-label show' : 'input-label'}
                    >
                      Informant
                    </FormLabel>
                    <FormInput
                      id="informant"
                      name="informant"
                      type="text"
                      value={informant}
                      onChange={(e) => setInformant(e.target.value)}
                      placeholder="Informant"
                      className={
                        informant ? 'input-value active' : 'input-value'
                      }
                    ></FormInput>
                  </Left>
                </InputWrapper>
              </Wrapper>
            </BoxWrapper>
            <SubTitle>Marital Status</SubTitle>
            <ChipWrapper>
              {maritalData?.map((item) => {
                return (
                  <ChipOval
                    key={item.id}
                    obj={item}
                    content={item.name}
                    value={maritalStatus}
                    setValue={setMaritalStatus}
                    disabled={false}
                    isInPatientModal={true}
                  />
                )
              })}
            </ChipWrapper>
            <SubTitle>Religion</SubTitle>
            <ChipWrapper>
              {religionData?.map((item) => {
                return (
                  <ChipOval
                    key={item.id}
                    obj={item}
                    content={item.name}
                    value={religion}
                    setValue={setReligion}
                    disabled={false}
                    isInPatientModal={true}
                  />
                )
              })}
            </ChipWrapper>
            <SubTitle>Education</SubTitle>
            <ChipWrapper>
              {educationData?.map((item) => {
                return (
                  <ChipOval
                    key={item.id}
                    obj={item}
                    content={item.name}
                    value={education}
                    setValue={setEducation}
                    disabled={false}
                    isInPatientModal={true}
                  />
                )
              })}
            </ChipWrapper>
            <OccupationWrapper className="input-wrapper">
              <Left>
                <FormLabel
                  htmlFor="occupation"
                  className={occupation ? 'input-label show' : 'input-label'}
                >
                  Occupation
                </FormLabel>
                <FormInput
                  id="occupation"
                  name="occupation"
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                  placeholder="Occupation"
                  className={occupation ? 'input-value active' : 'input-value'}
                ></FormInput>
              </Left>
            </OccupationWrapper>

            <SubTitle>Family</SubTitle>
            <ChipWrapper>
              {familyData?.map((item) => {
                return (
                  <ChipOval
                    key={item.id}
                    obj={item}
                    content={item.name}
                    value={family}
                    setValue={setFamily}
                    disabled={false}
                    isInPatientModal={true}
                  />
                )
              })}
            </ChipWrapper>
            {error ? (
              <ErrorRed className="error-message">{error}</ErrorRed>
            ) : (
              ''
            )}
            <FormButtons>
              <ButtonTypeOne
                text="Cancel"
                color={THEME_COLORS.dark_500}
                bkgcolor={THEME_COLORS.white}
                border={THEME_COLORS.light_200}
                onclick={handleCancel}
              />
              <ButtonTypeOne
                text="Submit"
                ml="20px"
                color={THEME_COLORS.white}
                bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                onclick={handleFormSubmit}
              />
            </FormButtons>
          </SocioDemoGraphicModalContent>
        </SocioDemoGraphicModalBg>
      )}
    </Container>
  )
}

export default SocioDemoGraphicModalComponent

const Title = styled.h6`
  color: #3e3e53;
  font-size: 24px;
  font-family: 'Dm_sans_bold';
  margin-bottom: 4px;
`
const SubHeading = styled.p`
  color: #818181;
  font-size: 14px;
  font-family: 'DM_sans';
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${(props) => (props.modalOpen ? 'hidden' : 'auto')};
`

const SocioDemoGraphicModalBg = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocioDemoGraphicModalContent = styled.div`
  width: 720px;
  min-height: 360px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 40px;
  background: ${THEME_COLORS.white};
  border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
`
const BoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
  margin-top: 20px;
`
const Wrapper = styled.div`
  margin-bottom: 16px;
  width: 49%;
`
const InputWrapper = styled.div`
  background-color: ${THEME_COLORS.light_300};
  border: 2px solid ${THEME_COLORS.light_300};
`
const Left = styled.div`
  flex-grow: 1;
`
const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const FormInput = styled.input`
  &.active {
    font-weight: 500;
  }
`
const Error = styled.small``
const ChipWrapper = styled.div`
  margin-bottom: 14px;
`
const SubTitle = styled.h4`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const OccupationWrapper = styled(InputWrapper)`
  background-color: ${THEME_COLORS.light_200};
  border: 2px solid ${THEME_COLORS.light_200};
  width: 100%;
  margin-bottom: 24px;
`
const FormButtons = styled.div`
  text-align: right;
  margin-top: 16px;
  display: flex;
  float: right;
`
const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 20px;
`
const InputFieldWrapper = styled.div`
  /* position: relative; */
`
const Gender = styled.div`
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  border: 2px solid #f4f4f4;

  &.active {
    border: 2px solid #366eef;
  }
`
const GenderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    font-size: 18px;
    margin-top: 0px;
  }
`
const SubHead = styled.div`
  color: #818181;
  margin: 0;
  font-size: 14px;
  width: 100%;
  display: block;
`
const Down = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    display: block;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : null)};
    transition: 0.1s;
  }
`
const GenderList = styled.div`
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  display: none;
  overflow: hidden;
  transition: all 0.5 ease-in-out;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 62px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  &.view-modal {
    height: auto;
    transition: all 0.5 ease-in-out;
    display: block;
  }
`
const ListGender = styled.div`
  cursor: pointer;
  padding: 11px 20px;
  border-radius: 8px;
  margin: 10px;
  transition: all 0.2s;
  :hover {
    background: #f4f4f4;
  }
`
