import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import DateDropdown from '../../includes/dropdown/DateDropdown'
import DateModal from '../../includes/modals/DateModal'
import calender from '.././../../assets/image/dashboard/calender.svg'
import WelcomePage from '../login_dashboard/WelcomePage'
import SetDefaultSlotMdal from '../session_summary/modals/SetDefaultSlotMdal'
import SessionCard from './SessionCard'

function UpComingSessions({
  setCalendarModal,
  calendarModal,
  data,
  getSessions,
  NextSessionData,
  sessionData,
  sessionType,
  setsessionType,
  expertData,
  setMonth,
  setMonthId,
  month,
  isDefaultTimeSlotModal,
  setIsDefaultTimeSlotModal,
  isUpdateStatusModal,
  setIsUpdateStatusModal,
  setSelectedUpdateSessionId,
}) {
  const [datemodal, setDateModal] = useState(false)
  const { state, dispatch } = useContext(Context)
  const is_default_time_slot = state.user_details.is_default_time_slot
  const session_exist = state.user_details.session_exist
  const [error, SetError] = useState('')

  const handleModal = () => {
    setIsDefaultTimeSlotModal(false)
  }
  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setDateModal(false)
    }
  }
  function getFormattedDate() {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const currentDate = new window.Date()
    const month = months[currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }
  const formattedToday = getFormattedDate()

  return (
    <>
      <Container
        onClick={(e) => datemodal && handleOuterClick(e)}
        className={is_default_time_slot == false ? 'modal-active' : ''}
      >
        <Top>
          <Left>
            <Heading>
              Welcome Back,{' '}
              <span>
                {expertData?.first_name} {expertData?.last_name}
              </span>{' '}
            </Heading>
          </Left>
          <Right>
            <DateContainer
              onClick={() => {
                setCalendarModal(!calendarModal)
              }}
            >
              <CalenderIcon>
                <Icon src={calender} alt="icon" />
              </CalenderIcon>
              <Date>Today, {formattedToday}</Date>
            </DateContainer>
          </Right>
        </Top>
        {session_exist == true ? (
          <SessionContainer>
            <TopSection>
              <Left>
                {sessionData.next_session ? (
                  <Head>Next Session</Head>
                ) : (
                  <Head>Committed Sessions</Head>
                )}
              </Left>
              <Right>
                <DateDropdown
                  datemodal={datemodal}
                  setDateModal={setDateModal}
                  month={month}
                />
                <DateModal
                  setMothId={setMonthId}
                  datemodal={datemodal}
                  setDateModal={setDateModal}
                  month={month}
                  setMonth={setMonth}
                />
              </Right>
            </TopSection>
            <SessionCard
              getSessions={getSessions}
              error={error}
              sessionData={sessionData}
              sessionType={sessionType}
              NextSessionData={NextSessionData}
              setsessionType={setsessionType}
              setIsUpdateStatusModal={setIsUpdateStatusModal}
              setSelectedUpdateSessionId={setSelectedUpdateSessionId}
            />
          </SessionContainer>
        ) : (
          <SessionEmptyContainer>
            <WelcomePage />
            {isDefaultTimeSlotModal && (
              <SetDefaultSlotMdal
                setModal={setIsDefaultTimeSlotModal}
                handleModal={handleModal}
                isModal={isDefaultTimeSlotModal}
              />
            )}
          </SessionEmptyContainer>
        )}
      </Container>
    </>
  )
}

export default UpComingSessions
const Container = styled.section`
  cursor: default;
  &.modal-active {
    max-height: calc(100vh - 69.6px);
    overflow: hidden;
  }
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const Left = styled.div``
const Right = styled.div`
  position: relative;
`
const Heading = styled.h5`
  font-size: 24px;
  color: ${THEME_COLORS.text_title};
  span {
    font-family: 'DM_sans_medium';
  }
`
const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${THEME_COLORS.light_200};
  padding: 8px 15px;
  border-radius: 8px;

  @media all and (max-width: 1200px) {
    cursor: pointer;
  }
`
const CalenderIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`
const Icon = styled.img``
const Date = styled.p`
  color: ${THEME_COLORS.dark_400};
  font-size: 14px;
  font-family: 'DM_sans_medium';
`
const SessionContainer = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
`
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const Head = styled.h5`
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  font-family: 'DM_sans_medium';
`
const SessionEmptyContainer = styled.div`
  margin-top: 70px;
`
