import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import { expert, general } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import DropDown from '../../includes/dropdown/DropDown'
import CancelWarningModal from './modals/CancelWarningModal'

const ExpertDetails = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const [showQualification, setShowQualification] = useState(false)
  const [showExperience, setShowExperience] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [err, setErr] = useState('')

  const [showQualifications, setShowQualifications] = useState(false)

  const [attitudeId, setAttitudeId] = useState([])

  const navigate = useNavigate()

  // qualifications related states and functions
  const [qualifications, setQualifications] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState([])
  const [selectedQualificationIds, setSelectedQualificationIds] = useState([])
  const handleQualificationSelection = (name, id) => {
    if (selectedQualifications.includes(name)) {
      setSelectedQualifications((prev) => prev.filter((item) => item !== name))
      setSelectedQualificationIds((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedQualifications((prev) => [...prev, name])
      setSelectedQualificationIds((prev) => [...prev, id])
    }
  }
  const handleCancelQualification = (qualificationName, id) => {
    setSelectedQualifications((prev) =>
      prev.filter((item) => item !== qualificationName),
    )
    setSelectedQualificationIds((prev) => prev.filter((item) => item !== id))
  }

  // expertise related states & functions
  const [expertises, setExpertises] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState([])
  const [seleectedExpertiseIds, setSelectedExpertiseIds] = useState([])
  const handleExpertiseClick = (expertise, id) => {
    if (selectedExpertises.includes(expertise)) {
      setSelectedExpertises((prev) => prev.filter((item) => item !== expertise))
      setSelectedExpertiseIds((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedExpertises((prev) => [...prev, expertise])
      setSelectedExpertiseIds((prev) => [...prev, id])
    }
  }

  // experience related states and functions
  const [selectedExperienceId, setSelectedExperienceId] = useState('')
  const [selectedExperienceYear, setSelectedExperienceYear] = useState('')
  const handleExperience = (experience, id) => {
    setSelectedExperienceId(id)
    setSelectedExperienceYear(experience)
    setShowExperience(!showExperience)
  }

  // language states and functions
  const [languages, setLanguages] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [languageId, setLanguageId] = useState([])
  const handleLanguageClick = (language, id) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages((prev) => prev.filter((item) => item !== language))
      setLanguageId((prev) => prev.filter((item) => item !== id))
    } else {
      setSelectedLanguages((prev) => [...prev, language])
      setLanguageId((prev) => [...prev, id])
    }
  }

  useEffect(() => {
    // if (access_token) {
    expert
      .get('/qualifications/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        setQualifications(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    expert
      .get('/area-of-expertises/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        // console.log(response.data, 'area-of-expertises datas')
        const { StatusCode, data } = response.data
        setExpertises(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
    // }

    general
      .get('/languages/')
      .then((response) => {
        // console.log(response.data, 'languages datas')

        const { StatusCode, data } = response.data
        setLanguages(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [access_token])

  const [experienceOptions, setExperienceOptions] = useState([
    {
      id: 1,
      name: '0-1 years',
    },
    {
      id: 2,
      name: '1-2 years',
    },
    {
      id: 3,
      name: '2-3 years',
    },
    {
      id: 4,
      name: '3-4 years',
    },
    {
      id: 5,
      name: '5 + years',
    },
  ])

  const handleDropdown = (type) => {
    if (type === 'qualification') {
      setShowQualification(!showQualification)
      setShowExperience(false)
    }
    if (type === 'experience') {
      setShowExperience(!showExperience)
      setShowQualification(false)
    }
  }

  const Final = (close) => {
    setShowSuccessToast(true)
    if (close === 'close') {
      setTimeout(() => {
        navigate(-1)
      }, 2000)
    }
  }

  const handleCancel = () => {
    if (
      selectedQualificationIds.length > 0 ||
      seleectedExpertiseIds.length > 0 ||
      selectedExperienceId ||
      selectedLanguages.length > 0
    )
      setShowAttentionModal(true)
    else {
      navigate(-1)
    }
  }

  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setShowQualification(false)
      setShowExperience(false)
    }
  }

  const renderQualification = () => {
    if (qualifications) {
      return qualifications?.map((qualification) => {
        const isQualificationSelected = selectedQualifications.includes(
          qualification.name,
        )

        return (
          <div key={qualification.id}>
            <QualificationText
              onClick={() => {
                handleQualificationSelection(
                  qualification.name,
                  qualification.id,
                )
                setShowQualifications(false)
              }}
              className={
                isQualificationSelected ? 'selected text active' : 'text'
              }
            >
              {qualification.name}
            </QualificationText>
          </div>
        )
      })
    }
  }

  const CancelButton = ({ qualificationName, qualificationId }) => {
    return (
      <Button
        onClick={() =>
          handleCancelQualification(qualificationName, qualificationId)
        }
      >
        X
      </Button>
    )
  }

  const getProfessionalDetails = () => {
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-professional-info/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        // console.log(response.data, "response.data");
        if (StatusCode === 6000) {
          if (data.qualification_names.length > 0) {
            setSelectedQualifications(data.qualification_names)
          }
          if (data.qualifications.length > 0) {
            setSelectedQualificationIds(data.qualifications)
          }
          if (data.area_of_expertise_names.length > 0) {
            setSelectedExpertises(data.area_of_expertise_names)
          }
          if (data.area_of_expertises.length > 0) {
            setSelectedExpertiseIds(data.area_of_expertises)
          }
          if (data.experience) {
            setSelectedExperienceId(parseInt(data.experience))
          }

          if (data.experience) {
            setSelectedExperienceYear(
              experienceOptions.find(
                (item) => item.id == parseInt(data.experience),
              ).name,
            )
          }
          setSelectedLanguages(data.language_preference_names)
          setLanguageId(data.language_preferences)
        } else {
          // alert(
          //     "Some error occured while loading qualifications, please refresh the page"
          // );
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  const handlSubmit = () => {
    const qualification = JSON.stringify(selectedQualificationIds)
    const selectedExpertises = JSON.stringify(seleectedExpertiseIds)
    const Experience = selectedExperienceId
    const Language = JSON.stringify(languageId)

    if (
      selectedQualificationIds &&
      seleectedExpertiseIds &&
      selectedExperienceId &&
      languageId
    ) {
      const data = {
        qualifications: qualification,
        area_of_expertises: selectedExpertises,
        total_relevant_experience: Experience,
        language_preferences: Language,
      }

      expert
        .post('/add-professional-info/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          if (StatusCode === 6000) {
            setErr('')
            Final()
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
        })
    } else {
      setErr('*This field is mandatory')
    }
  }
  useEffect(() => {
    getProfessionalDetails()
  }, [])

  return (
    <Form
      onClick={(e) =>
        (showQualification || showExperience) && handleOuterClick(e)
      }
    >
      <Head>Expert Details</Head>
      <PersonalInfo>
        <TitleWrapper>
          <Title>Professional Info*</Title>
          <SubTitle>Provide your Personal Info</SubTitle>
        </TitleWrapper>
        <Qualification>Qualification</Qualification>
        <Details>
          {selectedQualifications && (
            <Flex>
              {selectedQualifications?.map((qualification) => (
                <>
                  <WrapperSection key={qualification}>
                    <QualificationText className="selected">
                      {qualification}
                    </QualificationText>
                    <CancelButton
                      qualificationName={qualification}
                      qualificationId={selectedQualificationIds}
                    />
                  </WrapperSection>
                </>
              ))}
            </Flex>
          )}
          <ExpertBox onClick={() => setShowQualifications(!showQualifications)}>
            <SelectedDataBox>
              <p>Select your Qualification*</p>
            </SelectedDataBox>
            <ListDown>
              <ListIcon
                src={
                  require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                    .default
                }
                alt="list_down"
              />
            </ListDown>
            <DropList className={showQualifications && 'view-modal'}>
              {renderQualification()}
              {/* <DropDowns></DropDowns> */}
            </DropList>
          </ExpertBox>
          {selectedQualificationIds.length == 0 && err ? (
            <ErrorRed className="error-message">
              This field is required
            </ErrorRed>
          ) : (
            ''
          )}
        </Details>
      </PersonalInfo>

      <ExpertiseSection>
        <TitleWrapper>
          <Title>Area of Expertise*</Title>
        </TitleWrapper>

        <Textfelid>
          {expertises.map((expertise) => (
            <ExpertiseText
              key={expertise.id}
              onClick={() => handleExpertiseClick(expertise.name, expertise.id)}
              className={
                selectedExpertises.includes(expertise.name) ? 'selected' : ''
              }
            >
              {expertise.name}
            </ExpertiseText>
          ))}
        </Textfelid>

        {/* <RenderingChip
                    array={attitude}
                    selectedChip={selectedAttitude}
                    setSelectedChip={setSelectedAttitude}
                    setChipID={setAttitudeId}
                /> */}
        {err && attitudeId.length === 0 && (
          <ErrorRed className="error">This field is required.</ErrorRed>
        )}
      </ExpertiseSection>

      <Experience>
        <TitleWrapper>
          <Title>Experience*</Title>
          <SubTitle>Provide your Experience</SubTitle>
        </TitleWrapper>
        <Wrapper>
          <InputWrapper
            className="input-wrapper"
            onClick={() => handleDropdown('experience')}
          >
            <Left>
              <SelectLabel
                className={
                  selectedExperienceYear
                    ? 'select-label active'
                    : 'select-label'
                }
              >
                Total Relevant Experience*
              </SelectLabel>
              <SelectValue className="select-value">
                {selectedExperienceYear}
              </SelectValue>
            </Left>
            <Right onClick={() => handleDropdown('experience')}>
              <img
                src={
                  require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                    .default
                }
                alt="down arrow"
              />
            </Right>
            {showExperience && (
              <DropDown
                list={experienceOptions}
                handleMethod={handleExperience}
                state={selectedExperienceYear}
              />
            )}
          </InputWrapper>
          {err && selectedExperienceYear.length === 0 && (
            <ErrorRed className="error">This field is required.</ErrorRed>
          )}
        </Wrapper>
      </Experience>

      <Language>
        <TitleWrapper>
          <Title>Language Preference*</Title>
        </TitleWrapper>

        <LanguageFelid>
          {languages.map((language) => (
            <Languagetext
              key={language.id}
              onClick={() => handleLanguageClick(language.name, language.id)}
              className={
                selectedLanguages.includes(language.name) ? 'selected' : ''
              }
            >
              {language.name}
            </Languagetext>
          ))}
        </LanguageFelid>

        {err && selectedLanguages.length === 0 && (
          <ErrorRed className="error">This field is required.</ErrorRed>
        )}
      </Language>

      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_400}
          onclick={handleCancel}
        />
        <ButtonTypeOne
          text="Save"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
          onclick={handlSubmit}
        />
      </FormButtons>
      {showAttentionModal && (
        <CancelWarningModal
          isModal={showAttentionModal}
          setIsModal={setShowAttentionModal}
        />
      )}
    </Form>
  )
}

export default ExpertDetails

const Form = styled.form``
const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const PersonalInfo = styled.div`
  margin-bottom: 32px;
`
const TitleWrapper = styled.div`
  margin-bottom: 16px;
`
const Title = styled.h5`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
`
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const Qualification = styled.h6`
  font-weight: 500;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 16px;
`
const QualificationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
const QualificationItem = styled.li`
  margin-right: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${THEME_COLORS.light_200};
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
  margin-bottom: 8px;
  display: flex;
  img {
    width: 100%;
  }
`
const ImageBox = styled.div`
  margin-left: 12px;
  width: 10px;
`
const Wrapper = styled.div``
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Right = styled.div``
const Error = styled.small`
  margin-top: 10px;
`
const SelectLabel = styled.label`
  &.active {
    font-size: 12px;
  }
`
const SelectValue = styled.p``
const ExpertiseSection = styled.div`
  margin-bottom: 32px;
`
const ExpertiseDetails = styled.div``
const Experience = styled.div`
  margin-bottom: 18px;
`
const LanguageDetails = styled.div``
const Language = styled.div``
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 44px;
`
const QualificationText = styled.div`
    /* padding: 4px 8px; */
    margin-right: 5px;
    /* margin-bottom: 10px; */
    font-size: 14px;
    &.active {
        color: ${THEME_COLORS.chips_blue_on_container_2};
        /* border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
    border-radius: 5px;
    padding: 0 10px;
    width: fit-content; */
    }
    &.text {
        margin-bottom: 10px;
    }
    &.text:last-child {
        margin-bottom: 0px;
    }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
const WrapperSection = styled.div`
  display: flex;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #333;
  text-align: center;

  padding: 7px;
`
const ExpertBox = styled.div`
  user-select: none;
  cursor: pointer;
  p {
    color: #818181;
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 10px;
  width: 95%;
  background-color: #f4f4f4;
  padding: 11px 20px;
  position: relative;
`
const Details = styled.div`
  margin-bottom: 3%;
`
const Button = styled.button`
  color: #333;
  cursor: pointer;
`

const SelectedDataBox = styled.div``
const ListIcon = styled.img``

const ListDown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const TitileBox = styled.div``
const Titile = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 60px;
  margin-top: 0;
  font-family: 'dm_sans_bold';
`

const Div = styled.div`
  border-top: 2px solid #366eef;
  width: 50%;
`
const Heading = styled.div`
  .expert {
    height: 500px;
  }
`
const Exit = styled.img`
  width: 2%;
  margin-right: 5px;
`
const DropDowns = styled.div`
  cursor: pointer;
  padding: 11px 20px;
  border-radius: 8px;
  margin: 10px;
  transition: all 0.2s;

  :hover {
    background: #f4f4f4;
  }
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
  &.example::-webkit-scrollbar {
    display: none;
  }
`
const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 20px;
`
const DropList = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: all 0.5 ease-in-out;

  &.view-modal {
    height: auto;
    transition: all 0.5 ease-in-out;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    padding: 15px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 41px;
  left: 0;
  border-radius: 5px;
  background-color: #fff;
  max-height: 250px;
  overflow-y: scroll;
`

const Textfelid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
const ExpertiseText = styled.p`
  font-family: 'DM_sans';

  border: 1px solid #e2e2e2;
  width: fit-content;
  padding: 5px 20px;
  border-radius: 25px;
  cursor: pointer;
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
`
const Languagetext = styled.p`
  border: 1px solid #e2e2e2;
  width: fit-content;
  border-radius: 25px;
  padding: 5px 20px;
  cursor: pointer;
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: ${THEME_COLORS.chips_blue_on_container_2};
    background-color: ${THEME_COLORS.chips_blue_container};
  }
`
const LanguageFelid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`
