import React from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";

const SuccessToast = ({ sentMessage, message }) => {
    return (
        <Container>
            <Title>
                {sentMessage || message
                    ? "✅  Sent Successfully"
                    : "✅  Saved Successfully"}
            </Title>
            <SubTitle>
                {sentMessage
                    ? "Your message sent successfully."
                    : message
                    ? message
                    : "Your changes have been successfully saved."}
            </SubTitle>
        </Container>
    );
};

export default SuccessToast;

const Container = styled.div`
    position: absolute;
    width: 411px;
    height: 99px;
    left: 76px;
    bottom: 80px;
    padding: 24px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
`;
const Title = styled.h4`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 8px;
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;
