import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import calender from '.././../../assets/image/dashboard/calender.svg'

function DateChip() {
  function getFormattedDate() {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const currentDate = new window.Date()
    const month = months[currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }

  const formattedToday = getFormattedDate()

  return (
    <DateContainer>
      <CalenderIcon>
        <Icon src={calender} alt="icon" />
      </CalenderIcon>
      <Date>Today, {formattedToday}</Date>
    </DateContainer>
  )
}

export default DateChip
const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${THEME_COLORS.light_200};
  padding: 8px 15px;
  border-radius: 8px;

  @media all and (max-width: 1200px) {
    cursor: pointer;
  }
`
const CalenderIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`
const Icon = styled.img``
const Date = styled.p`
  color: ${THEME_COLORS.dark_400};
  font-size: 14px;
`
