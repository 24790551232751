import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import upcomimng from '../../../assets/image/dashboard/session-card/upcoming.svg'
import reschedule from '../../../assets/image/dashboard/session-card/rescheduled.svg'
import followup from '../../../assets/image/dashboard/session-card/followup.svg'
import completed from '../../../assets/image/dashboard/session-card/completed.svg'
import cancelled from '../../../assets/image/dashboard/session-card/cancelled.svg'

function SessionSchedulechip({ session_record_status }) {
  const status = session_record_status?.toLowerCase()

  return (
    <>
      <ChipContainer status={status}>
        <IconContainer>
          {status == 'upcoming' ? (
            <Icon src={upcomimng} alt="icon" />
          ) : status == 'Rescheduled' ? (
            <Icon src={reschedule} alt="icon" />
          ) : status == 'Follow Ups' ? (
            <Icon src={followup} alt="icon" />
          ) : status == 'completed' ? (
            <Icon src={completed} alt="icon" />
          ) : (
            <Icon src={cancelled} alt="icon" />
          )}
        </IconContainer>
        <Date status={status}>{status}</Date>
      </ChipContainer>
    </>
  )
}

export default SessionSchedulechip

const ChipContainer = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  margin-right: 10px;
  padding: 4px 8px 4px 3px;
  border-radius: 4px;
  background: ${(props) =>
    props.status == 'upcoming'
      ? THEME_COLORS.chips_green_container
      : props.status == 'Rescheduled'
      ? THEME_COLORS.chips_violet_container
      : props.status == 'Follow Ups'
      ? THEME_COLORS.chips_orange_container
      : props.status == 'completed'
      ? THEME_COLORS.chips_blue_container
      : THEME_COLORS.chips_red_container};
  justify-content: space-between;
  /* cursor: pointer; */
`

const Date = styled.p`
  color: ${(props) =>
    props.status == 'upcoming'
      ? THEME_COLORS.chips_green_on_container
      : props.status == 'rescheduled'
      ? THEME_COLORS.chips_violet_on_container
      : props.status == 'Follow Ups'
      ? THEME_COLORS.chips_orange_on_container
      : props.status == 'completed'
      ? THEME_COLORS.chips_blue_on_container_2
      : props.status == 'cancelled'
      ? THEME_COLORS.chips_red_on_container
      : ''};

  font-family: 'DM_sans_medium';
  font-size: 12px;
`
const IconContainer = styled.div`
  width: 16px;
  margin-right: 5px;
`
const Icon = styled.img`
  display: block;
  width: 100%;
`
