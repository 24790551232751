import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import ModalStructure from "../../session_summary/modals/ModalStructure";
import DraftModal from "../../session_summary/modals/DraftModal";
import { useParams } from "react-router-dom";
import { cases } from "../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";

function ChiefComplaints(props) {
    const {
        draftModal,
        setdraftModal,
        showErrors,
        chiefFormFields,
        setChiefFormFields,
        initialchiefFormFieldValues,
    } = props;

    const {
        chiefComplaints,
        summary,
        diagnosis,
        recommendation,
        treatmentPlan,
        suggestions,
    } = chiefFormFields;

    const { id } = useParams();
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;

    // input field ref's
    const chiefComplaintsRef = useRef(null);
    const diagnosisRef = useRef(null);
    const recommendationRef = useRef(null);
    const summaryRef = useRef(null);
    const treatementRef = useRef(null);
    const suggestionsRef = useRef(null);

    const [height, setHeight] = useState(false);

    const handleModal = () => {
        setdraftModal(false);
    };

    useEffect(() => {
        if (chiefComplaints) chiefComplaintsRef.current.rows = 3;
        if (diagnosis) diagnosisRef.current.rows = 3;
        if (recommendation) recommendationRef.current.rows = 3;
        if (summary) summaryRef.current.rows = 3;
        if (treatmentPlan) treatementRef.current.rows = 3;
        if (suggestions) suggestionsRef.current.rows = 3;
    }, []);

    const handleKeyUp = (e, refType) => {
        if (e.target.value !== "") {
            const textareaLineHeight = 24;
            const textareaMaxHeight = textareaLineHeight * 3;
            const newRows = Math.ceil(
                e.target.scrollHeight / textareaLineHeight
            );
            if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
                refType.current.rows = newRows;
            }
        } else {
            refType.current.rows = 1;
        }
    };
    const formData = {
        session_record: id,
        chief_complaints: chiefComplaints,
        summary: summary,
        diagnosis: diagnosis,
        recommendation: recommendation,
        treatment_plan: treatmentPlan,
        suggestions: suggestions,

        // if case for is_draft
        // is_draft: true,
    };
    const handleChiefFormSubmit = () => {
        cases
            .post(`/add-case-report/${id}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode } = response.data;
                // const { access_token, refresh_token, message } =
                //     response.data.data;
                // handleNext()
                dispatch({
                    type: "UPDATE_USER",
                    user_details: {
                        // activeOption: 2,
                    },
                });
            })
            .catch((error) => {
                console.error("Something went wrong", error);
                if (error.response.status === 404) {
                    // setShowErrors('NOT_FOUND')
                }
            });
    };
    return (
        <>
            <FormContainer className={height && "active"}>
                <Top>
                    <div>
                        <FormTitle>Chief complaints & Summary *</FormTitle>
                        <Label>
                            This part will be visible to the patient as a
                            summary report
                        </Label>
                    </div>
                </Top>

                <FormInputs>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: chiefComplaints && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="Chief complaints"
                                    className={
                                        chiefComplaints
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Chief complaints *
                                </FormLabel>
                                <FormText
                                    id="symptoms"
                                    name="symptoms"
                                    value={chiefComplaints}
                                    type="text"
                                    rows="1"
                                    ref={chiefComplaintsRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            chiefComplaints: e.target.value,
                                        }));
                                        handleKeyUp(e, chiefComplaintsRef);
                                    }}
                                    placeholder="Chief complaints *"
                                    className={
                                        chiefComplaints
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !chiefComplaints && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>

                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: summary && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="summary"
                                    className={
                                        summary
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Summary & Diagnostic formulation *
                                </FormLabel>
                                <FormText
                                    id="summary"
                                    name="summary"
                                    value={summary}
                                    type="text"
                                    rows="1"
                                    ref={summaryRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            summary: e.target.value,
                                        }));
                                        handleKeyUp(e, diagnosisRef);
                                    }}
                                    // onKeyUp={(e)=>handleKeyUp(e)}
                                    placeholder="Summary & Diagnostic formulation *"
                                    className={
                                        summary
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !summary && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: diagnosis && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="diagnosis"
                                    className={
                                        diagnosis
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Diagnosis *
                                </FormLabel>
                                <FormText
                                    id="diagnosis"
                                    name="diagnosis"
                                    value={diagnosis}
                                    type="text"
                                    rows="1"
                                    ref={diagnosisRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            diagnosis: e.target.value,
                                        }));
                                        // setdraftModal(true);
                                        handleKeyUp(e, diagnosisRef);
                                    }}
                                    // onKeyUp={(e)=>handleKeyUp(e)}
                                    placeholder="Diagnosis *"
                                    className={
                                        diagnosis
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !diagnosis && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: recommendation && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="recommendation"
                                    className={
                                        recommendation
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Recommendation and intervention *
                                </FormLabel>
                                <FormText
                                    id="recommendation"
                                    name="recommendation"
                                    value={recommendation}
                                    type="text"
                                    rows="1"
                                    ref={recommendationRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            recommendation: e.target.value,
                                        }));
                                        handleKeyUp(e, recommendationRef);
                                    }}
                                    placeholder="Recommendation and intervention *"
                                    className={
                                        recommendation
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !recommendation && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: treatmentPlan && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="treatmentplan"
                                    className={
                                        treatmentPlan
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Treatment plan *
                                </FormLabel>
                                <FormText
                                    id="treatmentplan"
                                    name="treatmentplan"
                                    value={treatmentPlan}
                                    type="text"
                                    rows="1"
                                    ref={treatementRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            treatmentPlan: e.target.value,
                                        }));
                                        handleKeyUp(e, treatementRef);
                                    }}
                                    placeholder="Treatment plan *"
                                    className={
                                        treatmentPlan
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !treatmentPlan && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>

                    <Wrapper>
                        <InputWrapper
                            className="input-wrapper"
                            style={{ height: suggestions && "auto" }}
                        >
                            <Left>
                                <FormLabel
                                    htmlFor="Notes"
                                    className={
                                        suggestions
                                            ? "input-label show"
                                            : "input-label"
                                    }
                                >
                                    Suggestions*
                                </FormLabel>
                                <FormText
                                    id="notes"
                                    name="notes"
                                    value={suggestions}
                                    type="text"
                                    rows="1"
                                    ref={suggestionsRef}
                                    onChange={(e) => {
                                        setChiefFormFields((prev) => ({
                                            ...prev,
                                            suggestions: e.target.value,
                                        }));
                                        handleKeyUp(e, suggestionsRef);
                                    }}
                                    placeholder="Suggestions*"
                                    className={
                                        suggestions
                                            ? "textarea-value active"
                                            : "textarea-value"
                                    }
                                ></FormText>
                            </Left>
                        </InputWrapper>
                        {showErrors && !suggestions && (
                            <Error className="error">
                                This field is required.
                            </Error>
                        )}
                    </Wrapper>
                </FormInputs>

                {/* <RightBottom>
          <ButtonLeft>
            <ButtonTypeOne
              text="Cancel"
              color={THEME_COLORS.chips_blue_on_container_2}
              bkgcolor={THEME_COLORS.white}
              border="none"
              onclick={handleCancel}
            />
          </ButtonLeft>
          <ButtonRight>
            {activeOption !== 1 && (
              <ButtonTypeOne
                text="Previous"
                color={THEME_COLORS.dark_500}
                bkgcolor={THEME_COLORS.white}
                border={THEME_COLORS.light_400}
                onclick={handlePrevious}
              />
            )}
            <ButtonTypeOne
              text={activeOption !== 3 ? 'Next' : 'Submit'}
              ml="10px"
              color={THEME_COLORS.white}
              bkgcolor={THEME_COLORS.chips_blue_on_container_2}
              onclick={activeOption !== 3 ? handleNext : handleSubmit}
            />
          </ButtonRight>
        </RightBottom> */}
            </FormContainer>
        </>
    );
}

export default ChiefComplaints;

const FormContainer = styled.div`
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 50px;
`;
const FormInputs = styled.div``;
const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const Wrapper = styled.div`
    margin-bottom: 20px;
`;
const InputWrapper = styled.div``;
const Left = styled.div`
    flex-grow: 1;
`;
const Error = styled.small`
    margin-top: 5px;
`;
const FormLabel = styled.label`
    display: none;
    &.show {
        display: block;
    }
`;
const FormText = styled.textarea`
    &.active {
        font-weight: 500;
        &#symptoms {
            /* color: red; */
            font-weight: 600;
        }
    }
`;

const Label = styled.p`
    /* width: 40%; */
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
    color: #fff;
    background-color: #366ee3;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
`;

const RightBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #d3d3d3;

    @media all and (max-width: 980px) {
        margin-top: 50px;
    }
`;
const ButtonLeft = styled.div``;
const ButtonRight = styled.div`
    display: flex;
`;
