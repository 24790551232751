import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import { expert } from '../../../axiosConfig'

const AccountDetails = () => {
  const navigate = useNavigate()

  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const user_details = state.user_details
  // console.log(user_details, "user_details");
  const [expertData, setExpertData] = useState({})
  const [basicDetails, setBasicDetails] = useState({})
  const [expertDetails, setExpertDetails] = useState({})
  const [experienceDetails, setExperienceDetails] = useState({})
  const [employerDetails, setEmployerDetails] = useState({})
  const [error, SetError] = useState('')

  const handleEdit = (option) => {
    dispatch({
      type: 'PROFILE_OPTION',
      activeOption: option,
    })
    navigate('/manage-profile')
  }

  useEffect(() => {
    expert
      .get('/expert-details/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode == 6000) {
          setExpertData(data)
          setBasicDetails(data?.basic_details)
          setExpertDetails(data?.expert_details)
          setExperienceDetails(data?.experience_details)
          setEmployerDetails(data?.employer_details)
          dispatch({
            type: 'UPDATE_USER',
            user_details: {
              expert_Id: response.data.data.basic_details.id,
              first_name: data?.basic_details?.first_name,
              last_name: data?.basic_details?.last_name,
              phone: data?.basic_details?.phone,
              email: data?.basic_details?.email,
            },
          })
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [access_token])

  // console.log(basicDetails, "basicDetails");

  return (
    <Main>
      <DetailsBox>
        <NameBox>
          <Top>
            <ImageBox>
              {basicDetails.photo ? (
                <img src={basicDetails.photo} alt="profile" />
              ) : (
                <img
                  src={
                    require('../../../assets/image/my_account/profile-avatar.svg')
                      .default
                  }
                  alt="profile"
                />
              )}
            </ImageBox>
            <GradeBox>
              {/* <Grade>Grade 2</Grade> */}
              <StarBox>
                <Stars>
                  {Array.from(
                    { length: basicDetails.rating },
                    (value, index) => (
                      <img
                        key={index}
                        src={
                          require('../../../assets/image/my_account/full_star_green.svg')
                            .default
                        }
                        alt="star"
                      />
                    ),
                  )}
                </Stars>
                {basicDetails.rating > 0 ? (
                  <Rate>{basicDetails.rating}.0</Rate>
                ) : (
                  ''
                )}
              </StarBox>
            </GradeBox>
          </Top>
          <Bottom>
            <Name>
              {basicDetails.first_name} {basicDetails.last_name}{' '}
            </Name>
            <Designation>{basicDetails.specialization}</Designation>
          </Bottom>
        </NameBox>
        <EditBoxContainer>
          <EditBox>
            <Head>
              <Title>Bio</Title>
              <Line></Line>
              <EditButton onClick={() => handleEdit('bio')}>
                <img
                  src={
                    require('../../../assets/image/my_account/edit_blue.svg')
                      .default
                  }
                  alt="edit"
                />
              </EditButton>
            </Head>
            <Content>
              <Text>{basicDetails.bio}</Text>
            </Content>
          </EditBox>
          <EditBox>
            <Head>
              <Title>Basic Details</Title>
              <Line></Line>
              <EditButton onClick={() => handleEdit('about')}>
                <img
                  src={
                    require('../../../assets/image/my_account/edit_blue.svg')
                      .default
                  }
                  alt="edit"
                />
              </EditButton>
            </Head>
            <Content>
              {basicDetails?.address && (
                <Row>
                  <LabelBox>
                    <Label>Address</Label>
                    <Dot>:</Dot>
                  </LabelBox>
                  <Value>{basicDetails?.address}</Value>
                </Row>
              )}
              <Row>
                <LabelBox>
                  <Label>Mail</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{basicDetails?.email}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Phone no</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{basicDetails?.phone}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Gender</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{basicDetails?.gender}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Age</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{basicDetails?.age}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Expert ID</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{basicDetails?.expert_e_id}</Value>
              </Row>
            </Content>
          </EditBox>
          <EditBox>
            <Head>
              <Title>Expert Details</Title>
              <Line></Line>
              <EditButton onClick={() => handleEdit('details')}>
                <img
                  src={
                    require('../../../assets/image/my_account/edit_blue.svg')
                      .default
                  }
                  alt="edit"
                />
              </EditButton>
            </Head>
            <Content>
              <Row className="qualification">
                <LabelBox>
                  <Label>Qualification</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <div className="qualification">
                  {' '}
                  {expertDetails?.qualification_names?.map((item) => (
                    <Value className="qualification">{item}</Value>
                  ))}
                </div>

                {/* <Value>{expertDetails?.qualifications}</Value> */}
              </Row>
              <Row>
                <LabelBox>
                  <Label>Experience</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>
                  {experienceDetails?.total_relevant_experience_name}
                </Value>
              </Row>
              <Row className="qualification">
                <LabelBox>
                  <Label>Language Preference</Label>
                  <Dot>:</Dot>
                </LabelBox>
                {experienceDetails?.language_preference_names?.map((item) => (
                  <Value className="qualification">{item}</Value>
                ))}
                {/* <Value>{experienceDetails?.language_preferences}</Value> */}
              </Row>
              <Row>
                <LabelBox>
                  <Label>Area of Expertise</Label>
                  <Dot>:</Dot>
                </LabelBox>
              </Row>
              <ExpertiseList>
                {expertDetails?.area_of_expertise_names?.map((item) => (
                  <ListItem>{item}</ListItem>
                ))}
              </ExpertiseList>
            </Content>
          </EditBox>
          <EditBox>
            <Head>
              <Title>Employer Details</Title>
              <Line></Line>
              <EditButton onClick={() => handleEdit('status')}>
                <img
                  src={
                    require('../../../assets/image/my_account/edit_blue.svg')
                      .default
                  }
                  alt="edit"
                />
              </EditButton>
            </Head>
            <Content>
              <Row>
                <LabelBox>
                  <Label>Working Status</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{employerDetails?.working_status}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Name of Employer</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{employerDetails?.employer_name}</Value>
              </Row>
              <Row>
                <LabelBox>
                  <Label>Location of Employer</Label>
                  <Dot>:</Dot>
                </LabelBox>
                <Value>{employerDetails?.location}</Value>
              </Row>
            </Content>
          </EditBox>
        </EditBoxContainer>
      </DetailsBox>
    </Main>
  )
}

export default AccountDetails

const Main = styled.div`
  // width: 30%;
  width: 438px;
  padding: 30px;
  @media all and (max-width: 1440px) {
    width: 30%;
  }
  @media all and (max-width: 1300px) {
    width: 48%;
  }
  @media all and (max-width: 1280px) {
    width: 100%;
    padding: 30px 16px 16px;
    margin-right: 30px;
    margin-left: 30px;
  }
`
const DetailsBox = styled.div`
  padding: 16px;
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  border-radius: 10px;
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
`
const NameBox = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${THEME_COLORS.chips_green_container};
  border: 1px solid ${THEME_COLORS.chips_green_on_container_2};
  box-shadow: ${THEME_COLORS.elevation_expert_e8};
  border-radius: 6px;
  margin-bottom: 24px;
  @media all and (max-width: 1280px) {
    margin-bottom: 32px;
  }
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`
const ImageBox = styled.div`
  width: 80px;
  height: 80px;
  /* height: max-content; */
  border: 4px solid ${THEME_COLORS.white};
  border-radius: 9px;
  img {
    width: 100%;
    display: block;
    height: 100%;
  }
`
const GradeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const Grade = styled.h5`
  width: max-content;
  padding: 2px 6px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 4px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_title};
`
const StarBox = styled.div`
  display: flex;
`
const Stars = styled.div`
  img {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
`
const Rate = styled.h5`
  margin-left: 4px;
`
const Bottom = styled.div``
const Name = styled.h3`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 4px;
`
const Designation = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const EditBoxContainer = styled.div`
  @media all and (max-width: 1280px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -24px;
  }
`
const EditBox = styled.div`
  margin-bottom: 24px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media all and (max-width: 1280px) {
    width: 49%;
    padding: 10px;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 9px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 20px;
    }
  }
`
const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const Title = styled.h4`
  font-weight: 700;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  margin-right: 8px;
`
const Line = styled.div`
  margin-right: 8px;
  border-top: 1px solid ${THEME_COLORS.light_300};
  flex-grow: 1;
`
const EditButton = styled.button``
const Content = styled.div``
const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &.qualification {
    justify-content: flex-start;
  }
  div.qualification {
    display: flex;
    flex-direction: column;
  }
`
const LabelBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const Label = styled.label``
const Dot = styled.div`
  margin-left: 16px;
`
const Value = styled.p`
  width: 50%;
  padding-left: 16px;
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  word-wrap: break-word;
  &.qualification {
    word-wrap: normal;
    width: auto;
  }
`
const ExpertiseList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
const ListItem = styled.li`
  padding: 5px 10px;
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 53px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_title};
`
