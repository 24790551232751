import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { Context } from '../../../../contexts/Store'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'

function PersonalHistory({
  personalHistoryFormFields,
  setpersonalHistoryFormFields,
  initialPersonalHistoryFormValues,
  handlePersonalHistoryFormSubmit,
  detectChanges,
  gender,
}) {
  const { id } = useParams()
  const birthRef = useRef(null)
  const milestoneRef = useRef(null)
  const presenceRef = useRef(null)
  const homeRef = useRef(null)
  const scholasticRef = useRef(null)
  const occupationRef = useRef(null)
  const maritalRef = useRef(null)
  const sexualRef = useRef(null)
  const substanceRef = useRef(null)
  const interpersonalRef = useRef(null)
  const menstrualRef = useRef(null)

  const {
    birthAndEarlyDevolopement,
    milestoneDevolopment,
    childhoodDisorders,
    homeAtmosphere,
    extraCurricular,
    occupationHistory,
    maritalHistory,
    sexualHistory,
    substanceHistory,
    interPersonalRelations,
    menstrualHistory,
  } = personalHistoryFormFields

  const [showErrors, setShowErrors] = useState(false)
  const [height, setHeight] = useState(false)
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [loading, setLoading] = useState(false)

  const [birth, setBirth] = useState('')
  const [milestone, setMilestone] = useState('')
  const [presence, setPresence] = useState('')
  const [home, setHome] = useState('')
  const [scholastic, setScholastic] = useState('')
  const [occupation, setOccupation] = useState('')
  const [marital, setMarital] = useState('')
  const [sexual, setSexual] = useState('')
  const [substance, setSubstance] = useState('')
  const [interpersonal, setInterpersonal] = useState('')
  const [menstrual, setMenstrual] = useState('')

  // console.log(showErrors, 'showErrors')

  const handleCancel = () => {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        formType: 'Chief complaints & Summary',
      },
    })
  }

  const handleKeyUp = (e, refType) => {
    if (e.target.value !== '') {
      const textareaLineHeight = 24
      const textareaMaxHeight = textareaLineHeight * 3
      const newRows = Math.ceil(e.target.scrollHeight / textareaLineHeight)
      if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
        refType.current.rows = newRows
      }
    } else {
      refType.current.rows = 1
    }
  }

  return (
    <>
      <FormContainer className={height && 'active'}>
        <Top>
          <div>
            <FormTitle>Personal History</FormTitle>
            <Label>Describe past health and medical experiences</Label>
          </div>
        </Top>

        <FormInputs>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{
                height: birthAndEarlyDevolopement && 'auto',
              }}
            >
              <Left>
                <FormLabel
                  htmlFor="Chief complaints"
                  className={
                    birthAndEarlyDevolopement
                      ? 'input-label show'
                      : 'input-label'
                  }
                >
                  Birth and early development
                </FormLabel>
                <FormText
                  id="birth"
                  name="birth"
                  value={birthAndEarlyDevolopement}
                  type="text"
                  rows="1"
                  ref={birthRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      birthAndEarlyDevolopement: e.target.value,
                    }))
                    handleKeyUp(e, birthRef)
                  }}
                  placeholder=" Birth and early development"
                  className={
                    birthAndEarlyDevolopement
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !birthAndEarlyDevolopement && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: milestoneDevolopment && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="milestone"
                  className={
                    milestoneDevolopment ? 'input-label show' : 'input-label'
                  }
                >
                  Milestone development
                </FormLabel>
                <FormText
                  id="milestone"
                  name="milestone"
                  value={milestoneDevolopment}
                  type="text"
                  rows="1"
                  ref={milestoneRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      milestoneDevolopment: e.target.value,
                    }))
                    handleKeyUp(e, milestoneRef)
                  }}
                  placeholder="Milestone development"
                  className={
                    milestoneDevolopment
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !milestoneDevolopment && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: childhoodDisorders && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="presence"
                  className={
                    childhoodDisorders ? 'input-label show' : 'input-label'
                  }
                >
                  Presence of childhood disorders
                </FormLabel>
                <FormText
                  id="presence"
                  name="presence"
                  value={childhoodDisorders}
                  type="text"
                  rows="1"
                  ref={presenceRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      childhoodDisorders: e.target.value,
                    }))
                    handleKeyUp(e, presenceRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Presence of childhood disorders"
                  className={
                    childhoodDisorders
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !childhoodDisorders && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: homeAtmosphere && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="home"
                  className={
                    homeAtmosphere ? 'input-label show' : 'input-label'
                  }
                >
                  Home atmosphere in childhood and adolescence
                </FormLabel>
                <FormText
                  id="home"
                  name="home"
                  value={homeAtmosphere}
                  type="text"
                  rows="1"
                  ref={homeRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      homeAtmosphere: e.target.value,
                    }))
                    handleKeyUp(e, homeRef)
                  }}
                  placeholder="Home atmosphere in childhood and adolescence"
                  className={
                    homeAtmosphere ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !homeAtmosphere && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: extraCurricular && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="scholastic"
                  className={
                    extraCurricular ? 'input-label show' : 'input-label'
                  }
                >
                  Scholastic and extracurricular activities
                </FormLabel>
                <FormText
                  id="scholastic"
                  name="scholastic"
                  value={extraCurricular}
                  type="text"
                  rows="1"
                  ref={scholasticRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      extraCurricular: e.target.value,
                    }))
                    handleKeyUp(e, scholasticRef)
                  }}
                  placeholder="Scholastic and extracurricular activities"
                  className={
                    extraCurricular ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !extraCurricular && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: occupationHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="occupation"
                  className={
                    occupationHistory ? 'input-label show' : 'input-label'
                  }
                >
                  Occupation history
                </FormLabel>
                <FormText
                  id="occupation"
                  name="occupation"
                  value={occupationHistory}
                  type="text"
                  rows="1"
                  ref={occupationRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      occupationHistory: e.target.value,
                    }))
                    handleKeyUp(e, occupationRef)
                  }}
                  placeholder="Occupation history"
                  className={
                    occupationHistory
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: maritalHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="Chief complaints"
                  className={
                    maritalHistory ? 'input-label show' : 'input-label'
                  }
                >
                  Marital history and family tree
                </FormLabel>
                <FormText
                  id="marital"
                  name="marital"
                  value={maritalHistory}
                  type="text"
                  rows="1"
                  ref={maritalRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      maritalHistory: e.target.value,
                    }))
                    handleKeyUp(e, maritalRef)
                  }}
                  placeholder="Marital history and family tree"
                  className={
                    maritalHistory ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !maritalHistory && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: sexualHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="sexual"
                  className={sexualHistory ? 'input-label show' : 'input-label'}
                >
                  Sexual History
                </FormLabel>
                <FormText
                  id="sexual"
                  name="sexual"
                  value={sexualHistory}
                  type="text"
                  rows="1"
                  ref={sexualRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      sexualHistory: e.target.value,
                    }))
                    handleKeyUp(e, sexualRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Sexual History"
                  className={
                    sexualHistory ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !sexualHistory && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: substanceHistory && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="substance"
                  className={
                    substanceHistory ? 'input-label show' : 'input-label'
                  }
                >
                  Substance history
                </FormLabel>
                <FormText
                  id="substance"
                  name="substance"
                  value={substanceHistory}
                  type="text"
                  rows="1"
                  ref={substanceRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      substanceHistory: e.target.value,
                    }))
                    handleKeyUp(e, substanceRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Substance history"
                  className={
                    substanceHistory
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !substanceHistory && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: interPersonalRelations && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="interpersonal"
                  className={
                    interPersonalRelations ? 'input-label show' : 'input-label'
                  }
                >
                  Interpersonal relations and social activity
                </FormLabel>
                <FormText
                  id="interpersonal"
                  name="interpersonal"
                  value={interPersonalRelations}
                  type="text"
                  rows="1"
                  ref={interpersonalRef}
                  onChange={(e) => {
                    setpersonalHistoryFormFields((prev) => ({
                      ...prev,
                      interPersonalRelations: e.target.value,
                    }))
                    handleKeyUp(e, interpersonalRef)
                  }}
                  placeholder="Interpersonal relations and social activity"
                  className={
                    interPersonalRelations
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !interPersonalRelations && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          {gender == 'Female' && (
            <Wrapper>
              <InputWrapper
                className="input-wrapper"
                style={{ height: menstrualHistory && 'auto' }}
              >
                <Left>
                  <FormLabel
                    htmlFor="menstrual"
                    className={
                      menstrualHistory ? 'input-label show' : 'input-label'
                    }
                  >
                    Menstrual history
                  </FormLabel>
                  <FormText
                    id="menstrual"
                    name="menstrual"
                    value={menstrualHistory}
                    type="text"
                    rows="1"
                    ref={menstrualRef}
                    onChange={(e) => {
                      setpersonalHistoryFormFields((prev) => ({
                        ...prev,
                        menstrualHistory: e.target.value,
                      }))
                      handleKeyUp(e, menstrualRef)
                    }}
                    placeholder="Menstrual history"
                    className={
                      menstrualHistory
                        ? 'textarea-value active'
                        : 'textarea-value'
                    }
                  ></FormText>
                </Left>
              </InputWrapper>
              {showErrors && !menstrualHistory && (
                <Error className="error">This field is required.</Error>
              )}
            </Wrapper>
          )}
        </FormInputs>
        <Buttons>
          <ButtonTypeOne
            className={'case'}
            text="Back"
            color={THEME_COLORS.dark_500}
            bkgcolor={THEME_COLORS.white}
            border={THEME_COLORS.light_400}
            onclick={() => {
              if (
                detectChanges(
                  initialPersonalHistoryFormValues,
                  personalHistoryFormFields,
                )
              ) {
                setpersonalHistoryFormFields(initialPersonalHistoryFormValues)
              } else {
                handleCancel()
              }
            }}
          />
          <ButtonTypeOne
            text="Save"
            color={THEME_COLORS.white}
            bkgcolor={THEME_COLORS.chips_blue_on_container_2}
            onclick={handlePersonalHistoryFormSubmit}
          />
        </Buttons>
      </FormContainer>
    </>
  )
}

export default PersonalHistory

const FormContainer = styled.div`
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
  height: 600px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
const FormInputs = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Wrapper = styled.div`
  margin-bottom: 20px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small`
  margin-top: 5px;
`
const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
`

const Label = styled.p`
  /* width: 40%; */
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`
const ButtonContainer = styled.div`
  color: #fff;
  background-color: #366ee3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 30%; */
  margin: 0 auto;
  div.one {
    margin-right: 20px;
  }
`
