import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import ButtonTypeOne from "../../../includes/buttons/ButtonTypeOne";

const AttentionModal = ({
    showAttentionModal,
    setShowAttentionModal,
    handleSubmit,
}) => {
    // const navigate = useNavigate()

    const handleOuterClick = (e) => {
        const element = document.querySelector(".outer-click");
        if (!element.contains(e.target)) {
            setShowAttentionModal(false);
        }
    };

    const handleCancel = () => {
        setShowAttentionModal(false);
    };

    const handleSaveAndCancel = (e) => {
        setShowAttentionModal(false);
        // navigate(-1)
        handleSubmit(e, "close");
    };

    return (
        <>
            <Background
                onClick={(e) => showAttentionModal && handleOuterClick(e)}
            ></Background>

            <Container className="outer-click">
                <Title>⚠️ Attention!</Title>
                <SubTitle>
                    "Your progress will not be saved if you quit. Are you sure
                    you want to proceed with closing this process?"
                </SubTitle>
                <ButtonContainer>
                    <ButtonTypeOne
                        text="Cancel"
                        color={THEME_COLORS.dark_500}
                        bkgcolor={THEME_COLORS.white}
                        border={THEME_COLORS.light_400}
                        onclick={handleCancel}
                    />
                    <ButtonTypeOne
                        text="Save and Close"
                        color={THEME_COLORS.white}
                        bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                        onclick={handleSaveAndCancel}
                    />
                </ButtonContainer>
            </Container>
        </>
    );
};

export default AttentionModal;

const Background = styled.div`
    background: ${THEME_COLORS.elevation_expert_e9};
    position: fixed;
    opacity: 0.4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8888;
`;
const Container = styled.div`
    position: fixed;
    width: 411px;
    padding: 40px;
    background: ${THEME_COLORS.white};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
const Title = styled.h4`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 16px;
`;
const SubTitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 32px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
