import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { expert } from '../../axiosConfig'
import { Context } from '../../contexts/Store'
import CancelSignupModal from './modals/CancelSignupModal'

function AboutYou({ completed, setCompleted }) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [inputFocused, setInputFocused] = useState('')
  const menuRef = useRef()
  const logoutRef = useRef()
  const [specializationModal, setSpecializationModal] = useState(false)
  const [genderModal, setGenderModal] = useState(false)
  const [selected, setSelected] = useState('')
  const [specializations, setSpecializations] = useState([])
  const { state, dispatch } = useContext(Context)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [loading, setLoading] = useState(false) // Add loading state
  const [err, setErr] = useState('')
  const [gender, setGender] = useState([])
  const [age, setAge] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [specializationID, setSpecializationID] = useState('')
  const [genderSelect, setGenderSelect] = useState('')
  const [isGetDataLoading, setGetDataLoading] = useState(false)

  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Age: '',
    Gender: '',
    Phone: '',
  })

  const genderOptions = [
    {
      id: 1,
      value: 10,
      name: 'Male',
    },
    {
      id: 2,
      value: 20,
      name: 'Female',
    },
    {
      id: 3,
      value: 30,
      name: 'Other',
    },
  ]

  window.addEventListener('click', (e) => {
    if (e.target !== menuRef.current && e.target !== logoutRef.current) {
      setOpen(false)
    }
  })

  const handleSearch = (event) => {
    let value = event.target.value
    setValue(value)
  }

  const onSearch = (searchItem) => {
    setValue(searchItem)
  }

  const getSpecializations = () => {
    let accessToken = state.user_details.access_token
    expert
      .get(`/specializations/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setSpecializations(data)
        } else {
        }
      })
      .catch((error) => {})
  }

  const getAboutDetails = () => {
    setGetDataLoading(true)
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-personal-info/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          if (data.specialization_name) {
            setSelected(data.specialization_name)
          }
          if (data.first_name) {
            setFirstName(data.first_name)
          }
          if (data.last_name) {
            setLastName(data.last_name)
          }

          if (data.gender_name) {
            setGender(data.gender_name)
          }
          if (data.age) {
            setAge(data.age)
          }
          if (data.email) {
            setEmail(data.email)
          }
          if (data.phone) {
            setPhone(data.phone)
          }
          if (data.current_location_of_practice) {
            setValue(data.current_location_of_practice)
          }
          if (data.specialization) {
            setSpecializationID(data.specialization)
          }
          if (data.gender) {
            setGenderSelect(data.gender)
          }

          setTimeout(() => {
            setGetDataLoading(false)
          }, 100)
        } else {
          setGetDataLoading(false)
        }
      })
      .catch((error) => {
        setGetDataLoading(false)
      })
  }

  const handleSpecializationSelection = (name) => {
    setSelected(name)
    setSpecializationModal(false)
  }

  const renderSpecialization = () => {
    return specializations.map((specialization) => (
      <DropDownsText
        key={specialization.id}
        onClick={() => {
          setSpecializationID(specialization.id)
          handleSpecializationSelection(specialization.name)
        }}
      >
        {specialization.name}
      </DropDownsText>
    ))
  }

  const handleSubmit = () => {
    let accessToken = state.user_details.access_token
    if (
      specializationID &&
      firstName &&
      lastName &&
      genderSelect &&
      email &&
      phone &&
      age
    ) {
      setLoading(true)
      const data = {
        specialization: specializationID,
        first_name: firstName,
        last_name: lastName,
        gender: genderSelect,
        email: email,
        age: age,
        phone: phone,
        current_location_of_practice: value,
      }

      expert
        .put('/add-personal-info/', data, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data

          setErr('')
          setLoading(false)
          completed.about_you = 'tick'
          navigate('/auth/expert-details')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setErr('NOT_FOUND')
          }
          setLoading(false)
        })
    } else {
      setErr('*This field is mandatory')
    }
  }

  const handleAgeKeyDown = (e) => {
    if (
      e.key.toLowerCase() === 'e' ||
      e.key == '-' ||
      e.key == '+' ||
      e.key == '.'
    ) {
      e.preventDefault()
    }
  }
  const handleAgeChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setAge(e.target.value.split(0, 2))
    }
  }

  const [isValid, setIsValid] = useState(true)

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value
    setEmail(inputEmail.slice(0, 30))
    setIsValid(validateEmail(inputEmail.slice(0, 30)))
  }

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  const specializationDropdownRef = useRef()
  const genderDropdownRef = useRef()

  const handleClickOutsideSpecialization = (event) => {
    if (
      specializationDropdownRef.current &&
      !specializationDropdownRef.current.contains(event.target)
    ) {
      setInputFocused('')
      setTimeout(() => {
        setSpecializationModal(false)
      }, 50)
    }
  }
  const handleClickOutsideGender = (event) => {
    if (
      genderDropdownRef.current &&
      !genderDropdownRef.current.contains(event.target)
    ) {
      setInputFocused('')
      setTimeout(() => {
        setGenderModal(false)
      }, 50)
    }
  }

  useEffect(() => {
    if (specializationModal) {
      document.addEventListener('mousedown', handleClickOutsideSpecialization)

      return () => {
        document.removeEventListener(
          'mousedown',
          handleClickOutsideSpecialization,
        )
      }
    }
  }, [specializationModal])
  useEffect(() => {
    if (genderModal) {
      document.addEventListener('mousedown', handleClickOutsideGender)

      return () => {
        document.removeEventListener('mousedown', handleClickOutsideGender)
      }
    }
  }, [genderModal])

  const handleInputFocus = (fieldName) => {
    setInputFocused(fieldName)
  }

  const handleInputBlur = () => {
    setInputFocused('')
  }

  useEffect(() => {
    getSpecializations()
  }, [])

  useEffect(() => {
    if (state.user_details.phone) {
      setPhone(state.user_details.phone)
    }
  }, [])
  useEffect(() => {
    getAboutDetails()
  }, [])

  useEffect(() => {
    const isNewRegitrationStarted = state.nrs
    if (isNewRegitrationStarted !== true) {
      navigate('/auth/login-page/')
    }
  }, [state])

  console.log(phone, 'phone')

  return (
    <>
      {isCancelModalOpen && (
        <CancelSignupModal
          isModalOpen={isCancelModalOpen}
          setIsModalOpen={setIsCancelModalOpen}
        />
      )}
      <Div></Div>
      <Main>
        <Wrapper>
          <TitileBox>
            <Titile>About you</Titile>
            <ExitBox
              onClick={() => {
                setIsCancelModalOpen(true)
              }}
            >
              <Exit
                src={require('../../assets/image/loginpage/close.svg').default}
                alt="Exit_icon"
              />
              Cancel
            </ExitBox>
          </TitileBox>
          {!isGetDataLoading && (
            <>
              <ItemFlexContainer style={{ marginTop: '60px' }}>
                <Left>
                  <Heading>
                    <TopBox className="personal">
                      <SubHeading>Personal Info *</SubHeading>
                      <SubTitile>Provide your Personal Info</SubTitile>
                    </TopBox>
                  </Heading>
                </Left>
                <Right>
                  <Details>
                    <PersonalBox
                      ref={specializationDropdownRef}
                      className={
                        inputFocused === 'specialization' ? 'active' : ''
                      }
                      onClick={() => {
                        setSpecializationModal(!specializationModal)
                        if (!specializationModal) {
                          setInputFocused('specialization')
                        }
                        if (inputFocused == 'specialization') {
                          setInputFocused('')
                        }
                      }}
                    >
                      <SelectedDataBox>
                        <p>Specialization *</p>
                        <span style={{ fontSize: '15px' }}>
                          {selected && selected}
                        </span>
                      </SelectedDataBox>
                      <ListDown>
                        <ListIcon
                          src={
                            require('../../assets/image/loginpage/list_down.svg')
                              .default
                          }
                          alt="list_down"
                          isOpen={specializationModal}
                        />
                      </ListDown>
                      <DropList className={specializationModal && 'view-modal'}>
                        {renderSpecialization()}
                      </DropList>
                    </PersonalBox>
                    {specializationID == '' && err && (
                      <ErrorRed className="error-message">{err}</ErrorRed>
                    )}
                  </Details>

                  <GridWrapper>
                    <InputFieldWrapper>
                      <InputField
                        className={inputFocused === 'firstName' ? 'active' : ''}
                        htmlFor="first-name"
                      >
                        <SubHead>Firstname *</SubHead>
                        <NameBox
                          type="text"
                          id="first-name"
                          value={firstName}
                          onChange={(e) => {
                            let inputValue = e.target.value
                            inputValue = e.target.value.replace(
                              /[^A-Za-z " "]/g,
                              '',
                            )
                            setFirstName(inputValue.slice(0, 30))
                          }}
                          onFocus={() => {
                            handleInputFocus('firstName')
                          }}
                          onBlur={handleInputBlur}
                          required
                        />
                      </InputField>
                      {firstName == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>

                    <InputFieldWrapper>
                      <InputField
                        className={inputFocused === 'lastName' ? 'active' : ''}
                        htmlFor="last-name"
                      >
                        <SubHead>Last name *</SubHead>{' '}
                        <NameBox
                          type="text"
                          id="last-name"
                          value={lastName}
                          onChange={(e) => {
                            let inputValue = e.target.value
                            inputValue = e.target.value.replace(
                              /[^A-Za-z " "]/g,
                              '',
                            )
                            setLastName(inputValue.slice(0, 30))
                          }}
                          onFocus={() => {
                            handleInputFocus('lastName')
                          }}
                          onBlur={handleInputBlur}
                        />
                      </InputField>
                      {lastName == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>
                    <InputFieldWrapper>
                      <Gender
                        ref={genderDropdownRef}
                        className={inputFocused === 'gender' ? 'active' : ''}
                        onClick={() => {
                          setGenderModal(!genderModal)
                          if (!genderModal) {
                            setInputFocused('gender')
                          }
                          if (inputFocused == 'gender') {
                            setInputFocused('')
                          }
                        }}
                      >
                        <GenderBox>
                          <SubHead>Gender *</SubHead>
                          <span
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            {gender && gender}
                          </span>
                        </GenderBox>

                        <Down isOpen={genderModal}>
                          <img
                            src={
                              require('../../assets/image/loginpage/list_down.svg')
                                .default
                            }
                            alt="list_down_icon"
                          />
                        </Down>

                        <GenderList className={genderModal && 'view-modal'}>
                          {genderOptions.map((genderItem) => (
                            <ListGender
                              key={genderItem.id}
                              value={genderItem.name}
                              onClick={() => {
                                setGenderSelect(genderItem.value)
                                setGender(genderItem.name)
                              }}
                            >
                              {genderItem.name}
                            </ListGender>
                          ))}
                        </GenderList>
                      </Gender>
                      {genderSelect == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>

                    <InputFieldWrapper>
                      <InputField
                        className={inputFocused === 'age' ? 'active' : ''}
                        htmlFor="age"
                      >
                        <SubHead>Age *</SubHead>
                        <NameBox
                          type="number"
                          id="age"
                          value={age}
                          onKeyDown={handleAgeKeyDown}
                          onChange={(e) => {
                            setAge(e.target.value.slice(0, 2))
                          }}
                          onFocus={() => {
                            handleInputFocus('age')
                          }}
                          onBlur={handleInputBlur}
                        />
                      </InputField>
                      {age == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>
                  </GridWrapper>
                </Right>
              </ItemFlexContainer>

              <ItemFlexContainer style={{ marginTop: '44px' }}>
                <Left>
                  <Heading>
                    <TopBox>
                      <SubHeading>Contact Info *</SubHeading>
                      <SubTitile>Provide your Contact Informations</SubTitile>
                    </TopBox>
                  </Heading>
                </Left>
                <Right>
                  <GridWrapper>
                    <InputFieldWrapper>
                      <InputField
                        className={inputFocused === 'email' ? 'active' : ''}
                        htmlFor="email"
                      >
                        {' '}
                        <SubHead>Email ID *</SubHead>
                        <EmailBox
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => handleEmailChange(e)}
                          onFocus={() => {
                            handleInputFocus('email')
                          }}
                          onBlur={handleInputBlur}
                        />
                      </InputField>
                      {!isValid && email && (
                        <ErrorRed>Invalid email address</ErrorRed>
                      )}
                      {email == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>

                    <InputFieldWrapper>
                      <InputField
                        className={inputFocused === 'mobile' ? 'active' : ''}
                        disabled={state.user_details.phone ? true : false}
                        htmlFor="mobile"
                      >
                        <SubHead>Phone No *</SubHead>
                        <Phone
                          type="tel"
                          inputmode="tel"
                          id="mobile"
                          maxLength={10}
                          className={inputFocused === 'mobile' ? 'active' : ''}
                          value={phone}
                          onChange={(e) => {
                            let inputValue = e.target.value
                            inputValue = inputValue
                              .replace(/\D/g, '')
                              .slice(0, 10)
                            setPhone(inputValue)
                          }}
                          onFocus={() => {
                            handleInputFocus('mobile')
                          }}
                          onBlur={handleInputBlur}
                          disabled={state.user_details.phone ? true : false}
                        />
                      </InputField>
                      {phone == '' && err && (
                        <ErrorRed className="error-message">{err}</ErrorRed>
                      )}
                    </InputFieldWrapper>
                  </GridWrapper>
                  <LocationWrapper>
                    <InputField
                      className={inputFocused === 'location' ? 'active' : ''}
                      htmlFor="location"
                    >
                      <SubHead>Current Location of practice</SubHead>
                      <NameBox
                        type="text"
                        id="location"
                        value={value}
                        onChange={handleSearch}
                        onFocus={() => {
                          handleInputFocus('location')
                        }}
                        onBlur={handleInputBlur}
                      />
                    </InputField>
                  </LocationWrapper>
                </Right>
              </ItemFlexContainer>
              <ItemFlexContainer style={{ marginTop: '100px' }}>
                <Left></Left>
                <Right>
                  <Bottom>
                    <LeftBox>*This field is mandatory</LeftBox>
                    <RightBox>
                      <Save
                        onClick={() => {
                          handleSubmit()
                          completed.about_you = 'tick'
                        }}
                      >
                        Save & Continue
                      </Save>
                    </RightBox>
                  </Bottom>
                </Right>
              </ItemFlexContainer>
            </>
          )}
        </Wrapper>
      </Main>
    </>
  )
}

export default AboutYou
const GenderList = styled.div`
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  display: none;
  overflow: hidden;
  transition: all 0.5 ease-in-out;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 62px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  &.view-modal {
    height: auto;
    transition: all 0.5 ease-in-out;
    display: block;
  }
`
const ListGender = styled.div`
  cursor: pointer;
  padding: 11px 20px;
  border-radius: 8px;
  margin: 10px;
  transition: all 0.2s;
  :hover {
    background: #f4f4f4;
  }
`

const GenderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    font-size: 18px;
    margin-top: 0px;
  }
`
const SelectedDataBox = styled.div``
const Save = styled.button`
  background-color: #366ee3;
  border: 2px solid #366ee3 !important;
  padding: 14px 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-family: 'DM_sans_medium';
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const RightBox = styled.div`
  align-items: flex-end;
  display: flex;
  align-items: center;
`
const LeftBox = styled.p`
  color: #818181;
  font-size: 14px;
  text-align: start;
`

const DropDownsText = styled.div`
  padding: 10px 25px;
  font-size: 14px;
`
const DropList = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: all 0.5 ease-in-out;

  &.view-modal {
    height: auto;
    transition: all 0.5 ease-in-out;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  }
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 64px;
  left: 0;
  border-radius: 5px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: scroll;
`
const TopBox = styled.div``

const EmailBox = styled.input`
  margin-right: 3%;
  border-radius: 8px;
  border: none;
  width: 100%;
  font-size: 15px;
  &:focus {
    outline: none;
  }
`
const Down = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    display: block;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : null)};
    transition: 0.1s;
  }
`
const Phone = styled.input`
  border-radius: 8px;
  border: none;
  font-size: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

  &:focus {
    outline: none;
  }
  ::placeholder {
    outline: none;
    text-align: start;
    font-size: 16px;
  }
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px 16px;
`
const LocationWrapper = styled.div`
  margin-top: 18px;
`

const Gender = styled.div`
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  border: 2px solid #f4f4f4;

  &.active {
    border: 2px solid #366eef;
  }
`

const InputFieldWrapper = styled.div``
const InputField = styled.label`
  display: block;
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  border: 2px solid #f4f4f4;
  &.active {
    border: 2px solid #366eef;
  }
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`

const Details = styled.div`
  margin-bottom: 18px;
`
const ExitBox = styled.div`
  align-items: center;
  display: flex;
  color: #1da687;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  gap: 4px;
  padding: 6px;
  margin: 0 0 0 auto;
  width: fit-content;
  font-family: 'DM_sans_medium';
`
const Exit = styled.img`
  display: block;
  width: 12px;

  margin-right: 5px;
`
const PersonalBox = styled.div`
  user-select: none;
  cursor: pointer;
  p {
    color: #818181;
    margin: 0;
    font-size: 14px;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  height: 64px;
  background-color: #f4f4f4;
  padding: 11px 20px;
  border: 2px solid #f4f4f4;
  position: relative;
  &.active {
    border: 2px solid #366eef;
  }
`
const ListDown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const ListIcon = styled.img`
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : null)};
  transition: 0.1s;
`

const Main = styled.div`
  padding: 68px 0;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  scroll-behavior: smooth;
`
const Div = styled.div`
  border-top: 2px solid #366eef;
  width: 25%;
`
const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  @media all and (max-width: 900px) {
    width: 90%;
  }
`
const ItemFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Left = styled.div`
  width: 45%;
  @media all and (max-width: 1200px) {
    width: 31%;
  }
`
const Right = styled.div`
  width: 64%;
  @media all and (max-width: 1200px) {
    width: 63%;
  }
`
const TitileBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Titile = styled.h1`
  font-size: 30px;
  font-family: 'dm_sans_bold';
`
const SubHead = styled.div`
  color: #818181;
  margin: 0;
  font-size: 14px;
  width: 100%;
  display: block;
`
const SubHeading = styled.h3`
  font-size: 20px;
  color: #3e3e53;
  font-family: 'DM_sans_medium';
`
const SubTitile = styled.h5`
  color: #818181;
  font-size: 14px;
  font-family: 'DM_sans';
`
const Heading = styled.div``

const Location = styled.div`
  border-radius: 8px;
  height: 54px;
  background-color: #f4f4f4;
  cursor: pointer;
  &.active {
    border: 2px solid #366eef;
  }
`
const SearchMenu = styled.input`
  background-color: #f4f4f4;
  border: none;

  font-size: 14px;

  ::placeholder {
    font-size: 14px;
  }
`

const ErrorRed = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`
const NameBox = styled.input`
  border: none;
  width: 100%;
  height: 50%;
  font-size: 15px;
  background-color: #f4f4f4;
  outline: none;
  text-transform: capitalize;
`
