import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import { Link } from 'react-router-dom'
import Pagination from '../../includes/pagination/Pagination'
import SearchButton from '../../includes/buttons/SearchButton'
import TransactionsListCard from './TransactionsListCard'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import DateDropdown from '../../includes/dropdown/DateDropdown'
import DateModal from '../../includes/modals/DateModal'
import FilterDropdown from '../../includes/dropdown/FilterDropdown'
import FilterModal from '../../includes/modals/FilterModal'
import WithdrawRequestModal from '../session_summary/modals/WithdrawRequestModal'

const TransactionListPage = () => {
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [transactions, setTransactions] = useState([])
  const [wallet, setWallet] = useState()
  const [error, SetError] = useState([])
  const [datemodal, setDateModal] = useState(false)
  const [monthId, setMonthId] = useState('')
  const [filter, setFilter] = useState('')
  const [modal, setModal] = useState(false)
  const [filtermodal, setFilterModal] = useState(false)
  const [isDefaultTimeSlotModal, setIsDefaultTimeSlotModal] = useState(false)

  useEffect(() => {
    // Disable scrolling on the body element when the modal is open
    document.body.classList.add('modal-open')

    // Cleanup function to re-enable scrolling when the modal is closed
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const handleModal = () => {
    setIsDefaultTimeSlotModal(false)
  }
  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setDateModal(false)
    }
  }

  const currentDate = new Date()
  const currentMonthNumber = currentDate.getMonth() + 1
  function getCurrentMonth() {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const currentDate = new Date()
    const currentMonthIndex = currentDate.getMonth()
    const currentMonth = months[currentMonthIndex]
    return currentMonth
  }
  const currentMonth = getCurrentMonth()
  const [month, setMonth] = useState(currentMonth)
  useEffect(() => {
    getCurrentMonth()
  }, [])

  useEffect(() => {
    if (!monthId) {
      setMonthId(currentMonthNumber)
    }
    expert
      .get('/wallet/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          month: monthId,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setTransactions(response.data.data.transactions)
          setWallet(response.data.data.wallet)
        } else if (StatusCode == 6001) {
          SetError(response)
          console.log(error, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
  }, [monthId])

  const dates = [
    {
      id: 1,
      number: 1,
      status: 'Upcoming',
    },
    {
      id: 2,
      number: 2,
      status: 'Completed',
    },
  ]

  return (
    <Main
      onClick={(e) => datemodal && handleOuterClick(e)}
      className={isDefaultTimeSlotModal ? 'modal wrapper' : 'wrapper'}
    >
      <TopContainer>
        <Head>Transactions</Head>
        <ArrowBox to={'/my-account'}>
          <ImgDiv>
            <img
              src={
                require('../../../assets/image/my_account/right_arrow_long_blue.svg')
                  .default
              }
              alt="arrow"
            />
          </ImgDiv>
          Back
        </ArrowBox>
      </TopContainer>

      <Top>
        <EarningsBox>
          <EarningsTop>
            <ImageBox>
              <img
                src={
                  require('../../../assets/image/my_account/earnings.svg')
                    .default
                }
                alt="earnings"
              />
            </ImageBox>
            <Earnings>
              <Title>Total Earnings</Title>
              <Value className="earnings">{wallet?.total_earnings} INR</Value>
            </Earnings>
            <WithdrawButton
              onClick={() => {
                setIsDefaultTimeSlotModal(true)
              }}
            >
              Withdraw Money
            </WithdrawButton>
          </EarningsTop>
          <EarningsBottom>
            <Section>
              <Title>Pending Amount</Title>
              <Value>{wallet?.pending_amount} INR</Value>
            </Section>
            <Section>
              <Title>Balance Amount</Title>
              <Value>{wallet?.current_balance} INR</Value>
            </Section>
            <Section>
              <Title>Withdrawn Amount</Title>
              <Value>{wallet?.withdrawn} INR</Value>
            </Section>
            <Section>
              <Title>Requested Amount</Title>
              <Value>{wallet?.withdrawn_request} INR</Value>
            </Section>
          </EarningsBottom>
        </EarningsBox>
      </Top>

      <MainLeft>
        <TableContainer>
          <TableTitleContainer>
            <Value className="earnings">Recent Transactions</Value>

            <SearchBox>
              <Right>
                <DateDropdown
                  datemodal={datemodal}
                  setDateModal={setDateModal}
                  month={month}
                />
                <DateModal
                  setMothId={setMonthId}
                  datemodal={datemodal}
                  setDateModal={setDateModal}
                  month={month}
                  setMonth={setMonth}
                  wallet={true}
                  handleModal={handleModal}
                />
              </Right>

              <SearchButton placeholder="Search transactions" />
            </SearchBox>
          </TableTitleContainer>
          {transactions?.length > 0 ? (
            <TableCover>
              <TableHeading>
                <Items>
                  <Label type="date">Transaction ID</Label>
                  <Label type="name">Date and Time</Label>
                  <Label type="date">Details</Label>
                  <Label type="name">Type</Label>
                  <Label type="report">Amount</Label>
                </Items>
              </TableHeading>
              <ClientCardCover className={transactions?.length > 4 && 'active'}>
                {transactions?.length > 0 ? (
                  transactions.map((client, index) => {
                    return (
                      <TransactionsListCard key={client.id} client={client} />
                    )
                  })
                ) : (
                  <p style={{ textAlign: 'center' }}>No transcatons found</p>
                )}
              </ClientCardCover>
            </TableCover>
          ) : (
            <EmptyCover>
              <img
                src={
                  require('../../../assets/image/dashboard/no-data.svg').default
                }
                alt="empty"
              />
              <p> No transcations found for the selected month</p>
            </EmptyCover>
          )}
        </TableContainer>
        <Pagination />
        {isDefaultTimeSlotModal && (
          <WithdrawRequestModal
            approved_amount={wallet?.current_balance}
            setModal={setIsDefaultTimeSlotModal}
            handleModal={handleModal}
            isModal={isDefaultTimeSlotModal}
          />
        )}
      </MainLeft>
    </Main>
  )
}

export default TransactionListPage

const Main = styled.div`
  // width: 31%;
  height: calc(100vh - 90px);
  padding: 30px;
  &.modal {
    height: 700px;
    overflow: hidden;
  }
  @media all and (max-width: 1440px) {
    /* width: 31%; */
  }
`
const Head = styled.h5`
  font-weight: 500;
  font-size: 18px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.black};
`
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`
const Top = styled.div``
const Cover = styled.div`
  width: 100%;
  height: 200px;
  padding: 20px;
  background: ${THEME_COLORS.gradient_3};
  border-radius: 10px;
  margin-bottom: 80px;
  @media all and (max-width: 1280px) {
    margin-bottom: 110px;
  }
`
const EarningsBox = styled.div`
  width: 100%;
  /* height: 200px; */
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e4};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 40px;
`
const EarningsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 16px;
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  margin-bottom: 20px;
`
const ImageBox = styled.div`
  width: 40px;
  height: 40px;
  background: ${THEME_COLORS.chips_blue_container};
  border-radius: 8px;
  margin-right: 20px;
  padding: 10px;
  img {
    width: 20px;
    height: 20px;
  }
`
const Earnings = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Title = styled.h5`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_label};
`
const Value = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.black};
  &.earnings {
    font-size: 20px;
  }
`
const ArrowBox = styled(Link)`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background: ${THEME_COLORS.light_200};
  }
`
const WithdrawButton = styled.div`
  padding: 8px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: #366ee3;
  color: ${THEME_COLORS.white};
  font-size: 14px;
  font-family: 'DM_sans_medium';
`
const ImgDiv = styled.div`
  width: 20px;
  img {
    transform: rotate(180deg);
    display: block;
    width: 100%;
  }
`
const EarningsBottom = styled.div`
  /* display: flex;
    justify-content: space-between; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: left;
  width: 60%;
  @media all and (max-width: 1280px) {
    row-gap: 40px;
  }
`
const Section = styled.div`
  /* width: 45%; */
`
const Bottom = styled.div`
  padding: 16px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_300};
  box-shadow: ${THEME_COLORS.elevation_expert_e1};
  border-radius: 8px;
`
const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
const SubHead = styled.h6`
  font-weight: 500;
  font-size: 16px;
  font-family: 'DM_sans_medium';
  color: ${THEME_COLORS.black};
`
// const Line = styled.div`
//     border: 1px solid ${THEME_COLORS.light_300};
//     flex-grow: 1;
//     margin: 0 10px;
// `;
// const ViewLink = styled(Link)`
//     padding: 4px 8px;
//     border-radius: 6px;
//     font-weight: 500;
//     font-size: 14px;
//     color: ${THEME_COLORS.chips_blue_on_container_2};
//     &:hover {
//         background: ${THEME_COLORS.light_200};
//     }
// `;
const TransactionCards = styled.div`
  // max-height: 912px;
  // overflow-y: scroll;
  // &::-webkit-scrollbar{
  //     display: none;
  // }
`
const MainLeft = styled.div`
  /* width: 70%;
    padding: 30px; */

  @media all and (max-width: 1268px) {
    width: 100%;
    padding-bottom: 0;
  }
`
const ChipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`
const Button = styled.button`
  width: 18.5%;
`
const TableContainer = styled.div`
  margin-bottom: 12px;
`
const TableTitleContainer = styled.div`
  margin-bottom: 20px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TableTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.dark_500};
`
const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Right = styled.div`
  position: relative;
`
const Filter = styled.div`
  position: relative;
`
const TableCover = styled.div`
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 10px;
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
const EmptyCover = styled.div`
  height: 300px;
  width: 100%;
  background: ${THEME_COLORS.light_200};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 82px;
    height: 82px;
    margin-bottom: 42px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.dark_400};
  }
`
const TableHeading = styled.div`
  min-width: 100%;
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  height: 56px;
`
const Items = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  height: 100%;
`
const Label = styled.li`
  font-size: 14px;
  font-weight: 500;
  color: ${THEME_COLORS.dark_200};
  /* padding-left: 14px; */
  padding: 10px;
  width: ${(props) =>
    props.type === 'no'
      ? '5%'
      : props.type === 'count'
      ? '8%'
      : props.type === 'date'
      ? '20%'
      : props.type === 'name'
      ? '15%'
      : props.type === 'report'
      ? '20%'
      : '10%'};
  text-align: ${(props) => (props.type === 'count' ? 'center' : 'left')};
  /* &.slno {
        padding-left: 10px;
    } */

  @media all and (max-width: 1268px) {
    width: ${(props) =>
      props.type === 'no'
        ? '5%'
        : props.type === 'count'
        ? '20%'
        : props.type === 'date'
        ? '20%'
        : props.type === 'name'
        ? '20%'
        : props.type === 'report'
        ? '25%'
        : '10%'};
    &.tab-view {
      display: none;
    }
  }
`
const ClientCardCover = styled.div`
  height: max-content;
  &.active {
    height: 250px;
    overflow-y: scroll;
  }
`
const MainRight = styled.div`
  /* width: 30%; */
  padding: 30px 30px 26px 16px;

  @media all and (max-width: 1268px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 20px 26px;
  }
`
const CardContainer = styled.div`
  width: 100%;
  padding: 13px 14px;
  margin-bottom: 24px;
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  @media all and (max-width: 1268px) {
    width: 48%;
    margin-bottom: 0;
  }
`
const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`
const CardTitle = styled.h4`
  font-family: 'DM_sans_medium';
  font-size: 18px;
  margin-right: 8px;
  color: ${THEME_COLORS.text_title};
`
const EmptyCard = styled.div`
  height: 342px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 42px;
    height: 42px;
    margin-bottom: 20px;
  }
`
const Line = styled.div`
  flex-grow: 1;
  border: 1px solid ${THEME_COLORS.light_300};
`
const ViewButton = styled.div`
  height: 36px;
  &.empty {
    opacity: 0.35;
    pointer-events: none;
  }
`
const ViewLink = styled(Link)`
  color: #366ee3;
  font-size: 14px;
`
