import React, { useContext, useState } from "react";
import styled from "styled-components";
import ChooseDateTimeSlotModal from "./modals/ChooseDateTimeModal";
import { THEME_COLORS } from "../../../ThemeConfig";
import ConfirmRescheduleModal from "./modals/ConfirmRescheduleModal";
import { Context } from "../../../contexts/Store";

const RescheduleSessionpage = ({
    handleModal,
    setTimeSlot,
    timeSlot,
    setModal,
    isModal,
    setRescheduleModal,
    isRescheduleModal,
}) => {
    const [selectedTime, setSelectedTime] = useState({});
    const [selectedDate, setSelectedDate] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const { time_slot, dispatch } = useContext(Context);

    const clearStates = () => {
        setSelectedTime({});
        setSelectedDate({});
        setShowErrors(false);
    };

    // console.log(timeSlot, "Sessionnpage");

    const handleSubmit = (e) => {
        if (
            Object.keys(selectedDate).length !== 0 &&
            Object.keys(selectedTime).length !== 0
        ) {
            setTimeSlot({
                type: "reschdule_session",
                time: selectedTime,
                date: selectedDate,
            });
            clearStates();
            // handleModal();
            e.preventDefault();
            setModal(false);
            dispatch({
                type: "DATE_TIME_SLOT",
                time_slot: timeSlot,
            });
            dispatch({
                type: "RESCHEDULE_CONFIRM",
                isConfirmReschedule: true,
            });
        } else {
            setShowErrors(true);
            e.preventDefault();
        }
    };

    // const handleCancel = () => {
    //     setModal(false);
    // };
    const handlePrevious = (e) => {
        e.preventDefault();
        setRescheduleModal(true);
        setModal(false);
    };
    // console.log(selectedDate, selectedTime, "in conformmm");
    return (
        <>
            <ConfirmRescheduleModal />
            <>
                {showErrors && Object.keys(selectedTime).length == 0 && (
                    <Error className="error">Choose a time</Error>
                )}
                {showErrors && Object.keys(selectedDate).length == 0 && (
                    <Error className="error">Choose a date</Error>
                )}
                <ChooseDateTimeSlotModal
                    selectedTime={selectedTime}
                    selectedDate={selectedDate}
                    setSelectedTime={setSelectedTime}
                    setSelectedDate={setSelectedDate}
                />

                <FormButtons>
                    <Cancel>Cancel</Cancel>
                    <Right>
                        <Cancel onClick={handlePrevious}>Previous</Cancel>
                        <RescheduleButton onClick={handleSubmit}>
                            Submit
                        </RescheduleButton>
                    </Right>
                </FormButtons>
            </>
        </>
    );
};

export default RescheduleSessionpage;

const FormButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`;
const Error = styled.small``;
const Cancel = styled.button`
    padding: 14px 24px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.dark_500};
`;
const RescheduleButton = styled.button`
    margin-left: 10px;
    padding: 14px 24px;
    border-radius: 8px;
    font-size: 14px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.chips_blue_on_container_2};
`;
const Right = styled.div``;
const Description = styled.p`
    color: ${THEME_COLORS.text_title};
    font-size: 14px;
    margin-top: 20px;
    span {
        font-size: 14px;
        color: ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
