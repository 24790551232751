import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function DateModal({ datemodal, setMonth, setDateModal, setMothId, wallet }) {
  const dates = [
    {
      id: 1,
      month: 'January',
      is_selected: false,
    },
    {
      id: 2,
      month: 'February',
      is_selected: false,
    },
    {
      id: 3,
      month: 'March',
      is_selected: false,
    },
    {
      id: 4,
      month: 'April',
      is_selected: false,
    },
    {
      id: 5,
      month: 'May',
      is_selected: false,
    },
    {
      id: 6,
      month: 'June',
      is_selected: false,
    },
    {
      id: 7,
      month: 'July',
      is_selected: false,
    },
    {
      id: 8,
      month: 'Augest',
      is_selected: false,
    },
    {
      id: 9,
      month: 'September',
      is_selected: false,
    },
    {
      id: 10,
      month: 'October',
      is_selected: false,
    },
    {
      id: 11,
      month: 'November',
      is_selected: false,
    },
    {
      id: 12,
      month: 'December',
      is_selected: false,
    },
  ]

  return (
    <>
      <Contaner
        wallet={wallet}
        className={datemodal ? 'active outer-click' : 'outer-click'}
      >
        {dates.map((item) => (
          <DateContaner
            key={item.id}
            onClick={() => {
              setMonth(item.month)
              setMothId(item.id)
              setDateModal(false)
            }}
          >
            <Date key={item.id}>{item.month}</Date>
          </DateContaner>
        ))}
      </Contaner>
    </>
  )
}

export default DateModal

const Contaner = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  padding: 8px;
  border-radius: 10px;
  width: 145px;
  position: absolute;
  right: 0%;
  top: 50px;
  z-index: 10;
  display: none;
  height: ${(props) => (props.wallet ? '300px' : 'auto')};
  overflow: scroll;
  &.active {
    display: block;
  }
`
const DateContaner = styled.div`
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  &:hover {
    background: ${THEME_COLORS.light_100};
  }
`
const Date = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-size: 14px;
`
