import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { Context } from '../../../../contexts/Store'
import ButtonTypeOne from '../../../includes/buttons/ButtonTypeOne'

function PremorbidPersonality({
  premorbidPersonalityFormFields,
  setPremorbidPersonalityFormFields,
  initialPremorbidPersonalityFormValues,
  handlePremorbidPersonalityFormSubmit,
  detectChanges,
}) {
  const { id } = useParams()
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [loading, setLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const {
    selfData,
    relations,
    workAndLeisure,
    mood,
    attitudesAndStandard,
    habits,
  } = premorbidPersonalityFormFields

  const selfDataRef = useRef(null)
  const relationsRef = useRef(null)
  const workRef = useRef(null)
  const moodRef = useRef(null)
  const attitudesRef = useRef(null)
  const habitsRef = useRef(null)

  const [height, setHeight] = useState(false)

  useEffect(() => {
    if (selfData) selfDataRef.current.rows = 3
    if (relations) relationsRef.current.rows = 3
    if (workAndLeisure) workRef.current.rows = 3
    if (mood) moodRef.current.rows = 3
    if (attitudesAndStandard) attitudesRef.current.rows = 3
    if (habits) habitsRef.current.rows = 3
  }, [])

  const handleCancel = () => {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        formType: 'Chief complaints & Summary',
      },
    })
  }

  const handleKeyUp = (e, refType) => {
    if (e.target.value !== '') {
      const textareaLineHeight = 24
      const textareaMaxHeight = textareaLineHeight * 3
      const newRows = Math.ceil(e.target.scrollHeight / textareaLineHeight)
      if (newRows > 1 && e.target.scrollHeight <= textareaMaxHeight) {
        refType.current.rows = newRows
      }
    } else {
      refType.current.rows = 1
    }
  }

  //     session_record: "",
  //     self_data: symptoms,
  //     relations: summary,
  //     work_and_leisure: diagnosis,
  //     mood: recommendation,
  //     attitudes_and_standard: treatmentPlan,
  //     habits: notes,
  // };

  // const handleFormSubmit = () => {
  //     if (symptoms && summary) {
  //         setLoading(true);
  //         cases
  //             .put(`/add-premorbid-personality/${id}/`, formData, {
  //                 headers: {
  //                     Authorization: `Bearer ${access_token}`,
  //                 },
  //             })
  //             .then(function (response) {
  //                 const { StatusCode } = response.data;
  //                 const { access_token, refresh_token, message } =
  //                     response.data.data;
  //                 console.log(response.data.data);
  //                 setShowErrors("");
  //                 setLoading(false);
  //             })
  //             .catch((error) => {
  //                 console.error("Something went wrong", error);
  //                 if (error.response.status === 404) {
  //                     setShowErrors("NOT_FOUND");
  //                 }
  //                 setLoading(false);
  //             });
  //     } else {
  //         setShowErrors("These fields are required.");
  //     }
  // };
  return (
    <>
      <FormContainer className={height && 'active'}>
        <Top>
          <div>
            <FormTitle>Premorbid personality</FormTitle>
            <Label>
              Describe patient's personality traits and characteristics before
              illness
            </Label>
          </div>
        </Top>

        <FormInputs>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: selfData && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="Chief complaints"
                  className={selfData ? 'input-label show' : 'input-label'}
                >
                  Self
                </FormLabel>
                <FormText
                  id="symptoms"
                  name="symptoms"
                  value={selfData}
                  type="text"
                  rows="1"
                  ref={selfDataRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      selfData: e.target.value,
                    }))
                    handleKeyUp(e, selfDataRef)
                  }}
                  placeholder="Self"
                  className={
                    selfData ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !selfData && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: relations && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="summary"
                  className={relations ? 'input-label show' : 'input-label'}
                >
                  Relations
                </FormLabel>
                <FormText
                  id="summary"
                  name="summary"
                  value={relations}
                  type="text"
                  rows="1"
                  ref={relationsRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      relations: e.target.value,
                    }))
                    handleKeyUp(e, relationsRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Relations"
                  className={
                    relations ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !relations && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: workAndLeisure && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="diagnosis"
                  className={
                    workAndLeisure ? 'input-label show' : 'input-label'
                  }
                >
                  Work and leisure
                </FormLabel>
                <FormText
                  id="diagnosis"
                  name="diagnosis"
                  value={workAndLeisure}
                  type="text"
                  rows="1"
                  ref={workRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      workAndLeisure: e.target.value,
                    }))
                    handleKeyUp(e, workRef)
                  }}
                  // onKeyUp={(e)=>handleKeyUp(e)}
                  placeholder="Work and leisure"
                  className={
                    workAndLeisure ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !workAndLeisure && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: mood && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="Mood"
                  className={mood ? 'input-label show' : 'input-label'}
                >
                  Mood
                </FormLabel>
                <FormText
                  id="recommendation"
                  name="recommendation"
                  value={mood}
                  type="text"
                  rows="1"
                  ref={moodRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      mood: e.target.value,
                    }))
                    handleKeyUp(e, moodRef)
                  }}
                  placeholder="Mood"
                  className={mood ? 'textarea-value active' : 'textarea-value'}
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !mood && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>
          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: attitudesAndStandard && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="treatmentplan"
                  className={
                    attitudesAndStandard ? 'input-label show' : 'input-label'
                  }
                >
                  Attitudes and standard
                </FormLabel>
                <FormText
                  id="treatmentplan"
                  name="treatmentplan"
                  value={attitudesAndStandard}
                  type="text"
                  rows="1"
                  ref={attitudesRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      attitudesAndStandard: e.target.value,
                    }))
                    handleKeyUp(e, attitudesRef)
                  }}
                  placeholder="Attitudes and standard"
                  className={
                    attitudesAndStandard
                      ? 'textarea-value active'
                      : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
            {showErrors && !attitudesAndStandard && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper
              className="input-wrapper"
              style={{ height: habits && 'auto' }}
            >
              <Left>
                <FormLabel
                  htmlFor="Notes"
                  className={habits ? 'input-label show' : 'input-label'}
                >
                  Habits
                </FormLabel>
                <FormText
                  id="notes"
                  name="notes"
                  value={habits}
                  type="text"
                  rows="1"
                  ref={habitsRef}
                  onChange={(e) => {
                    setPremorbidPersonalityFormFields((prev) => ({
                      ...prev,
                      habits: e.target.value,
                    }))
                    handleKeyUp(e, habitsRef)
                  }}
                  placeholder="Habits"
                  className={
                    habits ? 'textarea-value active' : 'textarea-value'
                  }
                ></FormText>
              </Left>
            </InputWrapper>
          </Wrapper>
        </FormInputs>
        <Buttons>
          <ButtonTypeOne
            className={'case'}
            text="Back"
            color={THEME_COLORS.dark_500}
            bkgcolor={THEME_COLORS.white}
            border={THEME_COLORS.light_400}
            onclick={() => {
              if (
                detectChanges(
                  initialPremorbidPersonalityFormValues,
                  premorbidPersonalityFormFields,
                )
              ) {
                setPremorbidPersonalityFormFields(
                  initialPremorbidPersonalityFormValues,
                )
                handleCancel()
              } else {
                handleCancel()
              }
            }}
          />
          <ButtonTypeOne
            text="Save"
            color={THEME_COLORS.white}
            bkgcolor={THEME_COLORS.chips_blue_on_container_2}
            onclick={handlePremorbidPersonalityFormSubmit}
          />
        </Buttons>
      </FormContainer>
    </>
  )
}

export default PremorbidPersonality

const FormContainer = styled.div`
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
  /* height: 600px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  } */
`
const FormInputs = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
`
const Wrapper = styled.div`
  margin-bottom: 20px;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Error = styled.small`
  margin-top: 5px;
`
const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
`

const Label = styled.p`
  /* width: 40%; */
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`
const ButtonContainer = styled.div`
  color: #fff;
  background-color: #366ee3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 30%; */
  margin: 0 auto;
  div.one {
    margin-right: 20px;
  }
`
