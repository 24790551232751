import React from "react";
import styled from "styled-components";

function FileUploadButton({
    contentName,
    selectedFile,
    handleFileChange,
    handleClear,
}) {
    return (
        <ButtonUpload
            isSelected={selectedFile ? true : false}
            onClick={() =>
                document.getElementById(`${contentName}FileInput`).click()
            }
        >
            <Maindiv>
                {!selectedFile && (
                    <input
                        type="file"
                        onChange={(e) => handleFileChange(e, contentName)}
                        style={{ display: "none" }}
                        id={`${contentName}FileInput`}
                    />
                )}
                <NameText selectedFile={selectedFile} style={{}}>
                    {selectedFile ? selectedFile.name : `+ Upload File`}
                </NameText>
                {selectedFile && (
                    <button
                        onClick={() => handleClear(contentName)}
                        style={{
                            backgroundColor: "#E0F8F2",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            marginLeft: "5px",
                        }}
                    >
                        <div>
                            <img
                                alt="CloseButton"
                                src={
                                    require("../../assets/image/loginpage/closebuttonicon.svg")
                                        .default
                                }
                            />
                        </div>
                    </button>
                )}
            </Maindiv>
        </ButtonUpload>
    );
}

export default FileUploadButton;
const Maindiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;
const ButtonUpload = styled.button`
    min-width: 120px;
    max-width: 48%;
    padding: 6px;
    height: 36px;
    background-color: #fff;
    border: 3px solid #d3d3d3;
    /* padding: 8px 20px; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    color: #3e3e53;
    font-size: 16px;
    /* @media all and (max-width: 1440px) {
        padding: 11px 20px;
        width: 24%;
    }
    @media all and (max-width: 1280px) {
        padding: 12px 20px;
        width: 26%;
    }
    @media all and (max-width: 1280px) {
        padding: 12px 18px;
        width: 33%;
    } */

    ${(props) =>
        props.isSelected &&
        `
    background-color: #E0F8F2;
    border-color: #E0F8F2;
    color: #1DA687;
  `}
`;
const NameText = styled.div`
    background-color: ${(props) =>
        props.selectedFile ? "#E0F8F2" : "initial"};
    border: none;
    cursor: ${(props) => (props.selectedFile ? "auto" : "pointer")};
    width: calc(100% - 18px);
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    overflow-x: scroll;
    font-size: 14px;
    font-family: "DM_sans_medium";

    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;
