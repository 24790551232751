import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../../ThemeConfig'
import DateCard from '../../../includes/cards/DateCard'
import ChipOval from '../../../includes/chips/ChipOval'
import slot from '../../../../assets/image/dashboard/update-slot-blue.svg'
import { expert } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import TimeSlotChip from './TimeSlotChip'
import moment from 'moment'

const SelectSlotCard = ({
  updatemodal,
  setUpdateModal,
  setShowSuccessToast,
  calendarMonth,
  selectedDateInFormat,
}) => {
  const [datecards, setDateCards] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [date, setDate] = useState(new Date().getDate())
  const [day, setDay] = useState(new Date().getDay())
  const [active, setActive] = useState(false)
  const [dot, setDot] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const { state, dispatch } = useContext(Context)

  const access_token = state.user_details.access_token
  const expertId = state.user_details.expert_Id

  const [slots, setSlots] = useState([])
  const [morning, setMorning] = useState([])
  const [morningSlots, setMorningSlots] = useState([])
  const [morningSlotID, setMorningSlotID] = useState([])

  const [afterNoon, setAfterNoon] = useState([])
  const [afterNoonSlot, setAfternoonSlot] = useState([])
  const [afterNoonSlotID, setAfternoonSlotID] = useState([])

  const [evening, setEvening] = useState([])
  const [eveningSlot, setEveningSlot] = useState([])
  const [eveningSlotID, setEveningSlotID] = useState([])

  const [night, setNight] = useState([])
  const [nightSlot, setNightSlot] = useState([])
  const [nightSlotID, setNightSlotID] = useState([])

  const [allMorningSlots, setAllMorningSlots] = useState([])
  const [allAfternoonSlots, setAllAfternoonSlots] = useState([])
  const [allEveningSlots, setAllEveningSlots] = useState([])
  const [allNightSlots, setAllNightSlots] = useState([])
  const [selectedSlots, setSelectedSlots] = useState([])

  const [selectedID, setSelectedID] = useState([])
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSlotsLoading, setIsSlotsLoading] = useState(false)

  const handleToggle = () => {
    setIsChecked((prev) => !prev)
  }

  useEffect(() => {
    expert
      .get(`/date-time-slots/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          date: selectedDateInFormat,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setAllMorningSlots(data.morning)
          setAllAfternoonSlots(data.after_noon)
          setAllEveningSlots(data.evening)
          setAllNightSlots(data.night)
          setSelectedSlots(
            Object.values(data).flatMap((section) =>
              section.filter((item) => item.active).map((item) => item.id),
            ),
          )
          setTimeout(() => {
            setIsSlotsLoading(false)
          }, 100)
        } else {
          setIsSlotsLoading(false)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setIsSlotsLoading(false)
      })
    // }
  }, [selectedDateInFormat])

  useEffect(() => {
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...morningSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...afterNoonSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...eveningSlotID])
    setSelectedID((prevSelectedID) => [...prevSelectedID, ...nightSlotID])

    return () => {
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !morningSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !afterNoonSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !eveningSlotID.includes(id)),
      )
      setSelectedID((prevSelectedID) =>
        prevSelectedID.filter((id) => !nightSlotID.includes(id)),
      )
    }
  }, [morningSlotID, afterNoonSlotID, eveningSlotID, nightSlotID])

  const formData = {
    expert: expertId,
    date: selectedDateInFormat,
    time_slots: selectedSlots,
  }
  const handleSubmit = () => {
    setLoading(true)
    expert
      .put('/update-time-slots/', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(function (response) {
        const { StatusCode } = response.data
        const { access_token, refresh_token, message } = response.data.data
        setShowSuccessToast(true)
        setTimeout(() => {
          setShowSuccessToast(false)
        }, 3000)

        setErr('')
        setLoading(false)
        // }
      })
      .catch((error) => {
        console.error('Something went wrong', error)
        if (error.response.status === 404) {
          setErr('NOT_FOUND')
        }
        setLoading(false)
      })
  }

  const weekdayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const currentDate = new Date()
  const currentDayOfWeek = weekdayNames[currentDate.getDay()]

  const showDates = () => {
    let today = new Date()
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    let days_full = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let days_show = 30
    let dates = []

    for (let i = 0; i < days_show; i++) {
      let next_date = new Date(new Date().setDate(today.getDate() + i))
      let date = next_date.getDate()
      let day = days[next_date.getDay()]
      let day_full = days_full[next_date.getDay()]
      let month = months[next_date.getMonth()]
      let year = next_date.getFullYear()

      dates.push({
        id: Math.random() * 100,
        date: date,
        day: day,
        day_full: day_full,
        month: month,
        year: year,
      })
    }
    setDateCards(dates)
  }

  function getFormattedDate() {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    const monthsOfYear = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const date = new Date()
    const dayOfWeek = daysOfWeek[date.getDay()]
    const dayOfMonth = date.getDate()
    const monthOfYear = monthsOfYear[date.getMonth()]
    return `${dayOfWeek}, ${dayOfMonth} ${monthOfYear}`
  }
  const formattedDate = getFormattedDate()

  useEffect(() => {
    showDates()
  }, [])

  return (
    <>
      <Cover>
        <ContentBox>
          <SubTitleWrapper2>
            <Text>
              Today,{' '}
              {moment(selectedDateInFormat, 'YYYY-MM-DD').format(
                'MMM DD, YYYY',
              )}
            </Text>

            <div className="border"></div>
          </SubTitleWrapper2>

          <TimeSlotsContainer>
            <TimeSlotChip
              time={morning}
              timeSlots={allMorningSlots}
              setTimeSlots={setAllMorningSlots}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              timeSlotID={morningSlotID}
              title={'MORNING'}
            />
            <TimeSlotChip
              time={afterNoon}
              timeSlots={allAfternoonSlots}
              setTimeSlots={setAllAfternoonSlots}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              timeSlotID={afterNoonSlotID}
              title={'AFTER NOON'}
            />
            <TimeSlotChip
              time={evening}
              timeSlots={allEveningSlots}
              setTimeSlots={setAllEveningSlots}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              timeSlotID={eveningSlotID}
              title={'EVENING'}
            />
            <TimeSlotChip
              time={night}
              timeSlots={allNightSlots}
              setTimeSlots={setAllNightSlots}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              timeSlotID={nightSlotID}
              title={'NIGHT'}
            />
          </TimeSlotsContainer>
        </ContentBox>
        <BottomContainer>
          <UpdateButton
            onClick={() => {
              setUpdateModal(false)
            }}
          >
            <Content>Cancel</Content>
          </UpdateButton>
          <Deatil
            onClick={() => {
              handleSubmit()
            }}
          >
            <Content>Update</Content>
          </Deatil>
        </BottomContainer>
      </Cover>
    </>
  )
}

export default SelectSlotCard
const Cover = styled.section``

const ContentBox = styled.div`
  width: 100%;
  padding: 0 24px 24px 24px;
  background-color: ${THEME_COLORS.white};
  border-radius: 10px;
`
const SubTitleGreen = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  text-transform: uppercase;
  color: ${THEME_COLORS.chips_green_on_container};
  margin-bottom: 24px;
`
const SubTitleWrap = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  div.border {
    margin-left: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
  }
`
const SubTitleWrapper2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  border-radius: 8px;

  div.border {
    margin-left: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
  }
`
const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.dark_300};
`
const Dates = styled.div`
  margin-bottom: 50px;
`
const Bottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  color: ${THEME_COLORS.text_title};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const Text = styled.div``
const Middle = styled.div``

const UpdateButton = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 30px;
`
const Deatil = styled.div`
  padding: 8px 15px;
  background: ${THEME_COLORS.chips_blue_container};
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @media all and (max-width: 1080px) {
    /* width: 350px; */
  }
`
const Content = styled.p`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Icon = styled.img`
  margin-right: 10px;
`
const Title = styled.div`
  color: ${THEME_COLORS.dark_300};
  font-size: 14px;
  margin-bottom: 5px;
`
const Morning = styled.div`
  margin-bottom: 20px;
`
const Afternoon = styled.div`
  margin-bottom: 20px;
`
const Night = styled.div``
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const ToggleEvent = styled.div`
  display: flex;
  align-items: center;
`
const DefaultTitle = styled.div``
const TitleSlot = styled.h3`
  color: ${THEME_COLORS.text_title};
  font-size: 16px;
`
const Describtion = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
`
const DeatilChange = styled.div`
  margin-right: 10px;
`
const ContentChange = styled.div`
  display: flex;
  height: 36px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
  color: ${THEME_COLORS.chips_blue_on_container_2};
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`
const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`
const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`
const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${THEME_COLORS.light_400};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`
const ToggleInputChecked = styled(ToggleInput)`
  &:checked + ${ToggleSlider} {
    background-color: ${THEME_COLORS.chips_green_on_container};
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`
const TimeSlotsContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
