import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import avatar from "../../../assets/image/sessionsinglepage/avatar.svg";
import print from "../../../assets/image/sessionsinglepage/print.svg";
import download from "../../../assets/image/sessionsinglepage/download.svg";
import share from "../../../assets/image/sessionsinglepage/share.svg";
import completed from "../../../assets/image/dashboard/session-card/completed.svg";
import CaseReportChip from "../../includes/sessionsinglepage/CaseReportChip";
import CallTypeChip from "../../includes/Sessionchips/CallTypeChip";
import moment from "moment";
import PatientDetailsFinal from "../../includes/sessionsinglepage/PatientDetailsFinal";
import SessionDetailsFinal from "../../includes/sessionsinglepage/SessionDetailsFinal";
import down_arrow from "../../../assets/image/dashboard/arrows.svg";
import FormModal from "../../includes/modals/FormModal";
import youTubeIcon from "../../../assets/image/sessionsinglepage/youtube-icon.svg";
import { Context } from "../../../contexts/Store";
import { cases } from "../../../axiosConfig";
import SessionCallChip from "../../includes/Sessionchips/SessionCallChip";
import report from "../../../assets/image/dashboard/session-card/add-report.svg";
// import report from "../../../assets/image/dashboard/session-card/add-report.svg";

export default function SessionDetailed({
    selectedSession,
    sessionDetails,
    patientDetails,
    caseDetails,
    gender,
    isBasicFormsSubmittedFields,
    recommendedVideos,
}) {
    // console.log(recommendedVideos, "recommendedVideos in detailed page");
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const { id } = useParams();
    const [isBasicReportDropdown, setIsBasicReportDropdown] = useState(false);
    const [selectedCategory, setSelectedcategory] =
        useState("Chief complaints");
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [isDetailedLoading, setIsDetailedLoading] = useState(false);
    const {
        isChiefComplaintsSubmitted,
        isPresentingHistorySubmitted,
        isPastIllnessSubmitted,
        isFamilyHistorySubmitted,
        isPersonalHistorySubmitted,
        isPremorbidSubmitted,
        isMentalStatusSubmitted,
    } = isBasicFormsSubmittedFields;
    const [chiefFormFields, setChiefFormFields] = useState({
        chiefComplaints: "",
        summary: "",
        diagnosis: "",
        recommendation: "",
        treatmentPlan: "",
        suggestions: "",
    });
    const [presentingIllnessFormFields, setPresentingIllnessFormFields] =
        useState({
            precipitatingEvents: "",
            relevantHistory: "",
            onset: "",
        });
    const [pastIllnessFormFields, setpastIllnessFormFields] = useState({
        pastMedical: "",
        psychiatricHistory: "",
    });
    const [familyHistoryFormFields, setFamilyHistoryFormFields] = useState({
        familyTree: "",
        mentalIllnesses: [],
        dementias: [],
    });
    const [personalHistoryFormFields, setpersonalHistoryFormFields] = useState({
        birthAndEarlyDevolopement: "",
        milestoneDevolopment: "",
        childhoodDisorders: "",
        homeAtmosphere: "",
        extraCurricular: "",
        occupationHistory: "",
        maritalHistory: "",
        sexualHistory: "",
        substanceHistory: "",
        interPersonalRelations: "",
        menstrualHistory: "",
    });
    const [premorbidPersonalityFormFields, setPremorbidPersonalityFormFields] =
        useState({
            selfData: "",
            relations: "",
            workAndLeisure: "",
            mood: "",
            attitudesAndStandard: "",
            habits: "",
        });
    const [mentalStatusFormFields, setMentalStatusFormFields] = useState({
        consciousness: "",
        rapport: "",
        appearance: "",
        handedness: "",
        eyeToEyeContact: "",
        facialExpression: "",
        attitudesTowardsExaminer: [],
        motorBehaviour: "",
        cognitiveStatuses: [],
        attentionAndConcentrations: [],
        attentionAndConcentrationResult: "",
        orientations: [],
        orientationResult: "",
        memorys: [],
        memoryResult: "",
        abstractAbilitys: [],
        judgments: [],
        test: "",
        social: "",
        personal: "",
        generalInformations: [],
        calculations: "",
        intelligences: [],
        thoughts: [],
        stream: "",
        formIds: [],
        posessions: [],
        content: "",
        objectiveAffect: "",
        appropriateness: "",
        range: "",
        communicability: "",
        sensoryDistortion: "",
        sensoryDeception: "",
        psychoticPhenomenas: [],
        otherPhenomenas: [],
        insight: "",
    });
    const selectorRef = useRef(null);
    const selectorDropDownRef = useRef(null);

    const basicReportCategories = [
        {
            id: 1,
            name: "Chief complaints",
            // value: "chief_complaints",
        },
        {
            id: 9,
            name: "Summary and Diagnostic formulation",
            // value: "",
        },
        {
            id: 2,
            name: "History of Presenting Illness",
            // value: "",
        },
        {
            id: 3,
            name: "Past Illness",
            // value: "",
        },
        {
            id: 5,
            name: "Family history",
            // value: "",
        },
        {
            id: 6,
            name: "Personal History",
            // value: "",
        },

        {
            id: 7,
            name: "Premorbid personality",
            // value: "",
        },
        {
            id: 8,
            name: "Mental status examination",
            // value: "",
        },
        {
            id: 9,
            name: "Summary and Diagnostic formulation",
            // value: "",
        },
    ];

    const VideosData = [
        {
            thumbnail: require("../../../assets/image/sessionsinglepage/Thumbnail1.png"),
            youtubelink: "https://www.youtube.com/watch?v=eBSeCp__xhI",
            description:
                "FOCUS ON YOURSELF NOT OTHERS - Best Motivational Speech 2022",
        },
        {
            thumbnail: require("../../../assets/image/sessionsinglepage/Thumbnail2.png"),
            youtubelink: "https://www.youtube.com/watch?v=TLKxdTmk-zc",
            description:
                "The Most Eye Opening 10 Minutes Of Your Life | David Goggins",
        },
        {
            thumbnail: require("../../../assets/image/sessionsinglepage/Thubmail3.png"),
            youtubelink: "https://www.youtube.com/watch?v=7sxpKhIbr0E",
            description:
                "The psychology of self-motivation | Scott Geller | TEDxVirginiaTech",
        },
    ];

    function capitalizeFirstWord(paragraph) {
        // Split the paragraph into words
        const words = paragraph.split(" ");

        // Capitalize the first letter of the first word
        if (words.length > 0) {
            words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        }

        // Join the words back into a paragraph
        const capitalizedParagraph = words.join(" ");

        return capitalizedParagraph;
    }

    const handleCopyClick = async (linkToCopy) => {
        try {
            await navigator.clipboard.writeText(linkToCopy);
            setTooltipVisible(true);

            setTimeout(() => {
                setTooltipVisible(false);
            }, 3000);
            //   setCopySuccess('Copied!');
        } catch (err) {
            console.error("Unable to copy text: ", err);
            //   setCopySuccess('Copy failed');
        }
    };

    const getChiefFormDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/chief-complaints/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setChiefFormFields({
                        chiefComplaints: data.chief_complaints,
                        summary: data.summary,
                        diagnosis: data.diagnosis,
                        recommendation: data.recommendation,
                        treatmentPlan: data.treatment_plan,
                        suggestions: data.suggestions,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getPresentingIllnessFormDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/present-illness/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPresentingIllnessFormFields({
                        precipitatingEvents: data.precipitating_events,
                        relevantHistory: data.negative_history,
                        onset: data.onset,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getPastIllnessFormDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/past-illness/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setpastIllnessFormFields({
                        pastMedical: data.past_medical,
                        psychiatricHistory: data.psychiatric_history,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getFamilyHistoryFormDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/family-history/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFamilyHistoryFormFields({
                        familyTree: data.family_tree,
                        mentalIllnesses: data.history_of_mental_illness,
                        // mentalIllnesses: data.history_of_mental_illness.map(
                        //     (item) => item.id
                        // ),
                        dementias: data.history_of_dementia,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getPersonalHistoryDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/personal-history/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setpersonalHistoryFormFields({
                        birthAndEarlyDevolopement:
                            data.birth_and_early_development,
                        milestoneDevolopment: data.milestone_development,
                        childhoodDisorders: data.childhood_disorders,
                        homeAtmosphere: data.home_atmosphere,
                        extraCurricular:
                            data.scholastic_and_extracurricular_activities,
                        occupationHistory: data.occupation_history,
                        maritalHistory: data.marital_history,
                        sexualHistory: data.sexual_history,
                        substanceHistory: data.substance_history,
                        interPersonalRelations: data.interpersonal_relations,
                        menstrualHistory: data.menstrual_history,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getPremorbidPersonalityDatas = () => {
        setIsDetailedLoading(true);

        let accessToken = state.user_details.access_token;
        cases
            .get(`/premorbid-personality/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPremorbidPersonalityFormFields({
                        selfData: data.self_data,
                        relations: data.relations,
                        workAndLeisure: data.work_and_leisure,
                        mood: data.mood,
                        attitudesAndStandard: data.attitudes_and_standard,
                        habits: data.habits,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };
    const getMentalStatusDatas = () => {
        setIsDetailedLoading(true);
        let accessToken = state.user_details.access_token;
        cases
            .get(`/mental-status-examination/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setMentalStatusFormFields({
                        consciousness: data.consciousness,
                        rapport: data.rapport,
                        appearance: data.appearance,
                        handedness: data.handedness,
                        eyeToEyeContact: data.eye_to_eye_contact,
                        facialExpression: data.facial_expression,
                        attitudesTowardsExaminer:
                            data.attitude_towards_examiner,

                        motorBehaviour: data.motor_behaviour,
                        cognitiveStatuses: data.cognitive_status,
                        attentionAndConcentrations:
                            data.attention_and_concentration,
                        attentionAndConcentrationResult:
                            data.attentionAndConcentrationResult,
                        orientations: data.orientation,
                        orientationResult: data.orientation_result,
                        memorys: data.memory,
                        memoryResult: data.memory_result,
                        abstractAbilitys: data.abstract_ability,
                        judgments: data.judgment,
                        test: data.test,
                        social: data.social,
                        personal: data.personal,
                        generalInformations: data.general_information,
                        calculations: data.calculations,
                        intelligences: data.intelligence,
                        thoughts: data.thought,
                        stream: data.stream,
                        formIds: data.form,
                        posessions: data.possession,
                        content: data.content,
                        objectiveAffect: data.objective_affect,
                        appropriateness: data.appropriateness,
                        range: data.range,
                        communicability: data.communicability,
                        sensoryDistortion: data.sensory_distortion,
                        sensoryDeception: data.sensory_deception,
                        psychoticPhenomenas: data.psychotic_phenomena,
                        otherPhenomenas: data.other_phenomena,
                        insight: data.insight,
                    });

                    setTimeout(() => {
                        setIsDetailedLoading(false);
                    }, 50);
                } else {
                    setIsDetailedLoading(false);
                    if (data.message) {
                        alert(data.message);
                    } else {
                        alert("Some error occured, please try later");
                    }
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
                setIsDetailedLoading(false);
            });
    };

    useEffect(() => {
        // Function to set the width of the left container based on the right container
        const setDropDownWidth = () => {
            const selectorContainerWidth = selectorRef?.current?.clientWidth;

            // Set the width of the left container
            if (selectorDropDownRef.current) {
                selectorDropDownRef.current.style.width = `${selectorContainerWidth}px`;
            }
        };

        // Call the function initially and add a resize event listener
        setDropDownWidth();
        window.addEventListener("resize", setDropDownWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", setDropDownWidth);
        };
    }, [selectorRef]);

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // console.log("sucess");
        // if (activeOption == 1) {
        if (
            selectedCategory == "Chief complaints" ||
            selectedCategory == "Summary and Diagnostic formulation"
        ) {
            if (isChiefComplaintsSubmitted) {
                getChiefFormDatas();
            }
        } else if (selectedCategory == "History of Presenting Illness") {
            if (isPresentingHistorySubmitted) {
                getPresentingIllnessFormDatas();
            }
        } else if (selectedCategory == "Past Illness") {
            if (isPastIllnessSubmitted) {
                getPastIllnessFormDatas();
            }
        } else if (selectedCategory == "Family history") {
            if (isFamilyHistorySubmitted) {
                getFamilyHistoryFormDatas();
            }
        } else if (selectedCategory == "Personal History") {
            if (isPersonalHistorySubmitted) {
                getPersonalHistoryDatas();
            }
        } else if (selectedCategory == "Premorbid personality") {
            if (isPremorbidSubmitted) {
                getPremorbidPersonalityDatas();
            }
        } else if (selectedCategory == "Mental status examination") {
            if (isMentalStatusSubmitted) {
                getMentalStatusDatas();
            }
        }
        // }
    }, [id, selectedCategory, isBasicFormsSubmittedFields]);

    const handlePrintPdf = () => {
        const pdfUrl = caseDetails?.case_report_file;
        if (pdfUrl) {
            fetch(pdfUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const pdfWindow = window.open(url);
                    if (pdfWindow) {
                        pdfWindow.onload = () => {
                            pdfWindow.print();
                        };
                    } else {
                        console.error("Failed to open PDF window");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching PDF:", error);
                });
        } else {
            console.error("No PDF file to print");
        }
    };

    const manageAlert = () => {
        alert("complete the session to add report");
    };
    console.log(caseDetails?.case_report_file, "caseDetails?.case_report_file");
    return (
        <ContainerMain>
            <SessionsummaryContainerTop>
                <SessionIDLeftDiv>
                    <SessionSummaryText>Case Report</SessionSummaryText>

                    <SessionID>
                        Session ID{" "}
                        <span>
                            :{" "}
                            {sessionDetails?.current_record?.session_record_id}
                        </span>
                    </SessionID>
                </SessionIDLeftDiv>
                {sessionDetails?.current_record?.session_record_status ==
                "Completed" ? (
                    <SessionIDRightDiv className={"active"}>
                        <Print>
                            <img
                                src={print}
                                alt="Icon"
                                onClick={handlePrintPdf}
                            />
                        </Print>
                        <Print>
                            <a
                                href={caseDetails?.case_report_file}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={download} alt="Icon" />
                            </a>
                        </Print>
                        <Print
                            onClick={() => {
                                handleCopyClick(caseDetails?.case_report_file);
                            }}
                        >
                            <img src={share} alt="Icon" />
                            {tooltipVisible && <ToolTip>Link Copied!</ToolTip>}
                        </Print>
                    </SessionIDRightDiv>
                ) : (
                    <SessionIDRightDiv>
                        <Print>
                            <img src={print} alt="Icon" />
                        </Print>
                        <Print>
                            <a>
                                <img src={download} alt="Icon" />
                            </a>
                        </Print>
                        <Print>
                            <img src={share} alt="Icon" />
                        </Print>
                    </SessionIDRightDiv>
                )}
            </SessionsummaryContainerTop>

            {sessionDetails?.current_record?.session_record_status.toLowerCase() ==
            "completed" ? (
                <SessionsummaryContainerBottom>
                    <LeftContainer>
                        <CompletedDIv>
                            <Top>
                                <Left>
                                    <img
                                        src={
                                            sessionDetails?.current_record
                                                ?.session?.patient
                                                ?.profile_photo
                                                ? sessionDetails?.current_record
                                                      ?.session?.patient
                                                      ?.profile_photo
                                                : avatar
                                        }
                                        alt="profile"
                                    />
                                </Left>
                                <Right>
                                    <Data>
                                        {
                                            sessionDetails?.current_record
                                                ?.session?.concern
                                        }
                                    </Data>
                                    <Name>
                                        {" "}
                                        {
                                            sessionDetails?.current_record
                                                ?.session?.patient?.full_name
                                        }
                                    </Name>
                                </Right>
                            </Top>

                            <Bottom>
                                <CaseReportChip
                                    Icon={completed}
                                    Text={`Completed on ${moment(
                                        selectedSession?.case_date
                                    ).format("DD MMM YYYY")}`}
                                />
                                <CallTypeChip
                                    call_type={selectedSession?.meet_mode}
                                />
                            </Bottom>
                        </CompletedDIv>
                        <PatientDetailsFinal patientDetails={patientDetails} />
                        <SessionDetailsFinal
                            caseDetails={caseDetails}
                            selectedSession={selectedSession}
                        />
                    </LeftContainer>
                    <RightContainer>
                        <FormSelectionContainer>
                            Choose report
                            <DateContainer
                                ref={selectorRef}
                                className={
                                    isChiefComplaintsSubmitted == false
                                        ? "disabled"
                                        : ""
                                }
                                onClick={() => {
                                    if (isChiefComplaintsSubmitted) {
                                        setIsBasicReportDropdown(
                                            !isBasicReportDropdown
                                        );
                                    }
                                }}
                            >
                                <Date>{selectedCategory}</Date>
                                <DownIcon src={down_arrow} alt="icon" />
                            </DateContainer>
                            <SelectorDropdownContaner
                                ref={selectorDropDownRef}
                                className={isBasicReportDropdown && "active"}
                            >
                                {basicReportCategories.map((item) => (
                                    <DateContaner
                                        key={item.id}
                                        onClick={() => {
                                            setIsBasicReportDropdown(false);
                                            setSelectedcategory(item.name);
                                        }}
                                    >
                                        <Date>{item.name}</Date>
                                    </DateContaner>
                                ))}
                            </SelectorDropdownContaner>
                        </FormSelectionContainer>
                        {selectedCategory == "Chief complaints" ? (
                            <>
                                {isChiefComplaintsSubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    {/* <DataFieldItemTitle>
                                        Chief complaints :
                                    </DataFieldItemTitle> */}
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                chiefFormFields.chiefComplaints
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>
                                        Add session summary
                                        <SessionCallChip
                                            id={
                                                sessionDetails?.current_record
                                                    ?.id
                                            }
                                            session_status={"Completed"}
                                            type={"current"}
                                        />
                                    </EmptyData>
                                )}
                            </>
                        ) : selectedCategory ==
                          "History of Presenting Illness" ? (
                            <>
                                {isPresentingHistorySubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Precipitating Events :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                presentingIllnessFormFields.precipitatingEvents
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Relevant History :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                presentingIllnessFormFields.relevantHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Onset :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                presentingIllnessFormFields.onset
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory == "Past Illness" ? (
                            <>
                                {isPastIllnessSubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Past Medical :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                pastIllnessFormFields.pastMedical
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Psychiatric History :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                pastIllnessFormFields.psychiatricHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory == "Family history" ? (
                            <>
                                {isFamilyHistorySubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Family Tree :
                                                    </DataFieldItemTitle>

                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                familyHistoryFormFields.familyTree
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Mental Illnesses :
                                                    </DataFieldItemTitle>

                                                    <DataFieldChoiceItemsContainer>
                                                        {familyHistoryFormFields?.mentalIllnesses.map(
                                                            (item, index) => (
                                                                <DataFieldChoiceItem
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </DataFieldChoiceItem>
                                                            )
                                                        )}
                                                    </DataFieldChoiceItemsContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Dementias :
                                                    </DataFieldItemTitle>
                                                    <DataFieldChoiceItemsContainer>
                                                        {familyHistoryFormFields?.dementias.map(
                                                            (item, index) => (
                                                                <DataFieldChoiceItem
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </DataFieldChoiceItem>
                                                            )
                                                        )}
                                                    </DataFieldChoiceItemsContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory == "Personal History" ? (
                            <>
                                {isPersonalHistorySubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Birth and early
                                                        development :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.birthAndEarlyDevolopement
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Milestone development :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.milestoneDevolopment
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Presence of childhood
                                                        disorders :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.childhoodDisorders
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Home atmosphere in
                                                        childhood adolescence :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.homeAtmosphere
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Scholastic and
                                                        Extracurricular
                                                        activities :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.extraCurricular
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Occupational history :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.occupationHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Marital history and
                                                        Family tree :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.maritalHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Sexual history :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.sexualHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Substance History :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.substanceHistory
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Interpersonal relations
                                                        and social activity :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {capitalizeFirstWord(
                                                                personalHistoryFormFields.interPersonalRelations
                                                            )}
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                {gender == "Female" && (
                                                    <DataFieldItem>
                                                        <DataFieldItemTitle>
                                                            Menstrual history :
                                                        </DataFieldItemTitle>
                                                        <DataFieldItemDescriptionContainer>
                                                            <DataFieldDescription>
                                                                {capitalizeFirstWord(
                                                                    personalHistoryFormFields.menstrualHistory
                                                                )}
                                                            </DataFieldDescription>
                                                        </DataFieldItemDescriptionContainer>
                                                    </DataFieldItem>
                                                )}
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory == "Premorbid personality" ? (
                            <>
                                {isPremorbidSubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Self data :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.selfData
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Reltions :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.relations
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Work and Leisure :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.workAndLeisure
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        mood :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.mood
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Attitudes And Standard :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.attitudesAndStandard
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Habits :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                premorbidPersonalityFormFields.habits
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory == "Mental status examination" ? (
                            <>
                                {isMentalStatusSubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Consciousness :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                mentalStatusFormFields.consciousness
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        rapport :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                mentalStatusFormFields.rapport
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        General appearance and
                                                        behavior :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemMultipleDescriptionContainer>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                appearance
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.appearance
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                handedness
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.handedness
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Eye to Eye
                                                                Contact
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.eyeToEyeContact
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Facial
                                                                expression and
                                                                posture
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.facialExpression
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Attitude towards
                                                                examiner
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.attitudesTowardsExaminer.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Motor Behaviour
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.motorBehaviour
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                    </DataFieldItemMultipleDescriptionContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitleContainer>
                                                        <DataFieldItemTitle>
                                                            Cognitive status :
                                                        </DataFieldItemTitle>
                                                        {mentalStatusFormFields.cognitiveStatuses.map(
                                                            (item, index) => (
                                                                <TitleChip
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </TitleChip>
                                                            )
                                                        )}
                                                    </DataFieldItemTitleContainer>
                                                    <DataFieldItemMultipleDescriptionContainer>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Attention and
                                                                concentration
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.attentionAndConcentrations.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.attentionAndConcentrationResult
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Orientation
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.orientations.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Memory
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.memorys.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.memoryResult
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>

                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Abstract ability
                                                                - Proverb test :
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.abstractAbilitys.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Judgement
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.judgments.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.test
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>

                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                General
                                                                information :
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.generalInformations.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>

                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Calculations
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.calculations
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>

                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Intelligence
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.intelligences.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>

                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Thought
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.thoughts.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                    </DataFieldItemMultipleDescriptionContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitleContainer>
                                                        <DataFieldItemTitle>
                                                            Thought :
                                                        </DataFieldItemTitle>
                                                        {mentalStatusFormFields.thoughts.map(
                                                            (item, index) => (
                                                                <TitleChip
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </TitleChip>
                                                            )
                                                        )}
                                                    </DataFieldItemTitleContainer>
                                                    <DataFieldItemMultipleDescriptionContainer>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Stream
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.stream
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Form
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.formIds.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Possession
                                                            </DataFieldSubtitle>
                                                            <DataFieldChoiceItemsContainer>
                                                                {mentalStatusFormFields?.posessions.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <DataFieldChoiceItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </DataFieldChoiceItem>
                                                                    )
                                                                )}
                                                            </DataFieldChoiceItemsContainer>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Content
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.content
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                    </DataFieldItemMultipleDescriptionContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Mood and affect :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemMultipleDescriptionContainer>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Objective affect
                                                                :
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.objectiveAffect
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Appropriateness
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.appropriateness
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Range
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.range
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Communicability
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.communicability
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                    </DataFieldItemMultipleDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Perception :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemMultipleDescriptionContainer>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Sensory
                                                                distortion
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.sensoryDistortion
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                        <DataFieldMultipleItem>
                                                            <DataFieldSubtitle>
                                                                Sensory
                                                                deception
                                                            </DataFieldSubtitle>
                                                            <DataFieldDescription>
                                                                {
                                                                    mentalStatusFormFields.sensoryDeception
                                                                }
                                                            </DataFieldDescription>
                                                        </DataFieldMultipleItem>
                                                    </DataFieldItemMultipleDescriptionContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Other psychotic
                                                        phenomena :
                                                    </DataFieldItemTitle>
                                                    <DataFieldChoiceItemsContainer>
                                                        {mentalStatusFormFields?.psychoticPhenomenas.map(
                                                            (item, index) => (
                                                                <DataFieldChoiceItem
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </DataFieldChoiceItem>
                                                            )
                                                        )}
                                                    </DataFieldChoiceItemsContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Any other phenomena :
                                                    </DataFieldItemTitle>
                                                    <DataFieldChoiceItemsContainer>
                                                        {mentalStatusFormFields?.otherPhenomenas.map(
                                                            (item, index) => (
                                                                <DataFieldChoiceItem
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </DataFieldChoiceItem>
                                                            )
                                                        )}
                                                    </DataFieldChoiceItemsContainer>
                                                </DataFieldItem>

                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Insight :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                mentalStatusFormFields.insight
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : selectedCategory ==
                          "Summary and Diagnostic formulation" ? (
                            <>
                                {isChiefComplaintsSubmitted ? (
                                    !isDetailedLoading && (
                                        <CategoryDataCard>
                                            <CategoryTite>
                                                {selectedCategory}
                                            </CategoryTite>
                                            <Line></Line>
                                            <DataFieldsWrapper>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Diagnosis :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                chiefFormFields.diagnosis
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Recommendations and
                                                        Intervention given :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                chiefFormFields.recommendation
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Treatment plan :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                chiefFormFields.treatmentPlan
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                                <DataFieldItem>
                                                    <DataFieldItemTitle>
                                                        Suggestions given :
                                                    </DataFieldItemTitle>
                                                    <DataFieldItemDescriptionContainer>
                                                        <DataFieldDescription>
                                                            {
                                                                chiefFormFields.suggestions
                                                            }
                                                        </DataFieldDescription>
                                                    </DataFieldItemDescriptionContainer>
                                                </DataFieldItem>
                                            </DataFieldsWrapper>
                                        </CategoryDataCard>
                                    )
                                ) : (
                                    <EmptyData>Datas not available</EmptyData>
                                )}
                            </>
                        ) : null}
                        {recommendedVideos.length > 0 && (
                            <YouTube>
                                <CategoryTite>Recommended Videos</CategoryTite>
                                <Line></Line>
                                <RecommendedVideosDiv>
                                    {recommendedVideos.map((item) => (
                                        <RecommendedVideos>
                                            <Videos
                                                href={item.link}
                                                alt="URL"
                                                target="_blank"
                                            >
                                                <Thumbnail>
                                                    <img
                                                        src={item.image}
                                                        alt="thumnail"
                                                    />
                                                </Thumbnail>
                                                <Icon>
                                                    <img
                                                        src={youTubeIcon}
                                                        alt="Icon"
                                                    />
                                                </Icon>
                                            </Videos>
                                            <Description
                                                href={item.link}
                                                alt="URL"
                                                target="_blank"
                                            >
                                                <DescriptionText>
                                                    {item.title}
                                                </DescriptionText>
                                            </Description>
                                        </RecommendedVideos>
                                    ))}
                                </RecommendedVideosDiv>
                            </YouTube>
                        )}
                    </RightContainer>
                </SessionsummaryContainerBottom>
            ) : (
                <EmptyData>
                    Add session summary
                    {/* <SessionCallChip
                        id={sessionDetails?.current_record?.id}
                        session_status={"Completed"}
                        type={"current"}
                    /> */}
                    <ChipContainerTwo
                        onClick={() => {
                            manageAlert();
                        }}
                    >
                        <IconContainer>
                            <PlusIcon src={report} alt="icon" />
                        </IconContainer>
                        <Summary>Summary</Summary>
                    </ChipContainerTwo>
                </EmptyData>
            )}
        </ContainerMain>
    );
}

const ContainerMain = styled.div``;

const SessionsummaryContainerTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const SessionIDLeftDiv = styled.div``;
const SessionSummaryText = styled.h6`
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 10px;
`;
const SessionIDRightDiv = styled.div`
    display: flex;
    opacity: 0.5;
    &.active {
        opacity: 1;
    }
`;
const Print = styled.div`
    position: relative;
    margin-right: 20px;
    cursor: pointer;
`;
const SessionID = styled.h6`
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
    margin-right: 10px;
    span {
        font-size: 14px;
        margin-left: 0px;

        color: ${THEME_COLORS.text_title};
    }
`;
const SessionsummaryContainerBottom = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    padding: 24px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
`;
const LeftContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const RightContainer = styled.div`
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const CompletedDIv = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    padding: 10px;
    border-radius: 8px;
    box-shadow: ${THEME_COLORS.elevation_expert_e1};
    /* margin-bottom: 20px; */
`;
// const PatientDetails = styled.div``;
// const SessionDetails = styled.div``;
const UpcomingSessionDiv = styled.div``;
const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
const Left = styled.div`
    margin-right: 20px;
`;
const Right = styled.div``;
const Data = styled.h6`
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;
const Name = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.chips_blue_on_container};
`;
const Bottom = styled.div`
    display: flex;
`;
const Heading = styled.h5`
    color: ${THEME_COLORS.text_label};
    font-size: 14px;
    margin-bottom: 20px;
`;
const Datas = styled.ol`
    padding: 0 20px;
    margin-bottom: 20px;
`;
const DataList = styled.li`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    list-style: decimal;
    line-height: 20px;
`;
const Notes = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    line-height: 20px;
    margin-bottom: 20px;
`;
const YouTube = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const RecommendedVideosDiv = styled.ul`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* align-items: center; */
    gap: 30px;
`;
const RecommendedVideos = styled.li`
    width: 33%;
`;
const Videos = styled.a`
    position: relative;
`;
const Thumbnail = styled.div`
    border-radius: 8px;
    margin-bottom: 6px;
    img {
        display: block;
        width: 100%;
        border-radius: 8px;
    }
`;
const Icon = styled.a`
    position: absolute;
    top: 50px;
    right: 90px;
    @media all and (max-width: 1280px) {
        top: 40px;
        right: 80px;
    }
    @media all and (max-width: 980px) {
        top: 35px;
        right: 55px;
    }
`;
const Description = styled.a``;
const DescriptionText = styled.h5`
    width: 80%;
    font-size: 12px;
    color: ${THEME_COLORS.text_title};
    @media all and (max-width: 1080px) {
        width: 85%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const DescriptionFinal = styled.p`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 10px;
`;

const SelectorDropdownContaner = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    padding: 8px;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    right: 0;
    /* top: 80px; */
    z-index: 10;
    display: none;
    &.active {
        display: block;
    }
`;
const DateContaner = styled.div`
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const Date = styled.p`
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
`;
const FormSelectionContainer = styled.div`
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* margin-bottom: 20px; */
    /* padding: 15px; */
    /* border: 1px solid #d3d3d3; */
    border-radius: 20px;
    font-size: 16px;
    position: relative;
    color: ${THEME_COLORS.text_title};
`;
const DateContainer = styled.div`
    margin-top: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 8px;
    background: ${THEME_COLORS.light_100};
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
const DownIcon = styled.img``;
const CategoryTite = styled.div`
    color: ${THEME_COLORS.text_title};
    font-size: 16px;
    /* font-weight: bold; */
    /* font-family: ; */
`;
const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #f5f5f5;
`;
const DataFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const DataFieldItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const DataFieldItemTitle = styled.h5`
    font-size: 15px;
    color: ${THEME_COLORS.text_para};
    text-transform: capitalize;
`;
const DataFieldItemDescriptionContainer = styled.div`
    padding: 10px;
    border-radius: 8px;
    background: ${THEME_COLORS.light_200};
    min-height: 44px;
    box-sizing: border-box;
`;
const DataFieldDescription = styled.p`
    font-size: 15px;
    color: ${THEME_COLORS.text_title};
`;

const CategoryDataCard = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 8px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
`;

const DataFieldItemMultipleDescriptionContainer = styled.div`
    padding: 10px;
    border-radius: 8px;
    background: ${THEME_COLORS.light_200};
    min-height: 44px;
    gap: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const DataFieldMultipleItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;
const DataFieldSubtitle = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    text-transform: capitalize;
`;
const DataFieldChoiceItemsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;
const DataFieldChoiceItem = styled.div`
    display: flex;
    padding: 5px 10px;
    border-radius: 53px;
    border: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    color: ${THEME_COLORS.text_title};
    font-size: 14px;
`;
const DataFieldItemTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const TitleChip = styled.div`
    display: flex;
    padding: 5px 10px;
    border-radius: 53px;
    border: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    color: ${THEME_COLORS.text_title};
    font-size: 14px;
`;
const ToolTip = styled.div`
    position: absolute;
    top: 40px;
    /* bottom: 0px; */
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
    width: max-content;
    &.hidden {
        opacity: 0;
    }
`;
const EmptyData = styled.div`
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* opacity: 0.7; */
    color: #818181;
    font-family: "DM_sans_medium";
    gap: 16px;
    border: 2px dashed #d3d3d3;
    /* height: 100px; */
    border-radius: 8px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
`;
// const CategoryDataCard = styled.div``;
const ChipContainerTwo = styled(Link)`
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    background: ${THEME_COLORS.chips_blue_container};
    justify-content: space-between;
    &.disabled {
        filter: opacity(0.5);
    }
    &.cancelled {
        background: none;
    }
`;

const IconContainer = styled.div`
    width: 16px;
    margin-right: 5px;
`;

const PlusIcon = styled.img`
    display: block;
    width: 100%;
`;
const Summary = styled.p`
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans_medium";
    font-size: 12px;
`;
