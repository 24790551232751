import React from "react";
import styled from "styled-components";

const CustomCheckbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <Check>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </Check>
  </CheckboxContainer>
);

export default CustomCheckbox;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const Check = styled.div`
  display: flex;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "#1DA687" : "transparent")};
  border: 2px solid ${(props) => (props.checked ? "#1DA687" : "gray")};
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 10px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    display: block;
  }
`;
