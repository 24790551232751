import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
import number from "../../../../assets/image/sessionsinglepage/check no.svg";
import radiobutton from "../../../../assets/image/sessionsinglepage/Radio box.svg";
import nofill from "../../../../assets/image/sessionsinglepage/checknum-no fill.svg";
import ChooseDateTimeSlotModal from "./ChooseDateTimeModal";
import DateTimeSlot from "../../../screens/session_summary/modals/DateTimeSlot";
import ModalStructure from "../../../screens/session_summary/modals/ModalStructure";
import RescheduleModalStructure from "./RescheduleModalStructure";
import ConfirmRescheduleModal from "./ConfirmRescheduleModal";

function Reschedulemodal({ isRescheduleModal, setRescheduleModal }) {
  const [isActive, setActive] = useState(false);
  // const [timeDate, setTimeDate] = useState({});
  const [isModal, setModal] = useState(false);
  const [timeSlot, setTimeSlot] = useState({});
  const [data, setData] = useState();
  // const { state, dispatch } = useContext(Context);
  // const { isModal } = state;
  const issues = [
    {
      id: 1,
      issue: "Internet Issues",
    },
    {
      id: 2,
      issue: "Google meet Issues",
    },
    {
      id: 3,
      issue: "Scheduling conflicts",
    },
    {
      id: 4,
      issue: "Illness or emergency",
    },
    {
      id: 5,
      issue: "Unforeseen circumstances",
    },
    {
      id: 6,
      issue: "No Reason",
    },
    {
      id: 7,
      issue: "Other",
    },
  ];

  const handleModal = () => {};
  return (
    <>
      <ConfirmRescheduleModal timeSlot={timeSlot} setTimeSlot={setTimeSlot} />
      <RescheduleModalStructure
        title="Reschedule"
        description="Reschedule the cancelled session."
        handleModal={handleModal}
        setTimeSlot={setTimeSlot}
        timeSlot={timeSlot}
        isModal={isModal}
        setModal={setModal}
        isRescheduleModal={isRescheduleModal}
        setRescheduleModal={setRescheduleModal}
      />
      {isRescheduleModal && (
        <>
          <Cover
            onClick={() => {
              setRescheduleModal(false);
            }}
          >
            {" "}
          </Cover>
          <MainContainer>
            <ContainerWhite>
              <TopContainer>
                <LeftContainer>
                  <Heading>Reschedule</Heading>
                  <Description>Reschedule the cancelled session.</Description>
                </LeftContainer>
                <RightContainer>
                  <ReasonDiv>
                    <Number>
                      <img src={number} alt="Image" />
                    </Number>
                    <ReasonText>Reason</ReasonText>
                  </ReasonDiv>
                  <Line>
                    <img
                      src={
                        require("../../../../assets/image/sessionsinglepage/Line.svg")
                          .default
                      }
                      alt="Image"
                    />
                  </Line>
                  <ReasonDiv>
                    <Number>
                      <img src={nofill} alt="Image" />
                    </Number>
                    <ReasonText>Choose Slot</ReasonText>
                  </ReasonDiv>
                </RightContainer>
              </TopContainer>
              <MiddleContainer>
                <MiddleText>Reason for Rescheduling</MiddleText>
                {issues.map((item) => (
                  <OptionsDiv>
                    <RadioButton
                      onClick={() => {
                        setActive(item.id);
                        setData(item);
                      }}
                    >
                      {item.id === isActive ? (
                        <img
                          src={
                            require("../../../../assets/image/sessionsinglepage/Radio box-active.svg")
                              .default
                          }
                          alt="Image"
                        />
                      ) : (
                        <img src={radiobutton} alt="Image" />
                      )}
                    </RadioButton>
                    <Issues>{item.issue}</Issues>
                  </OptionsDiv>
                ))}

                <input type="text" placeholder="Enter your answer" />
              </MiddleContainer>
              <BottomContainer>
                <Cancel
                  onClick={() => {
                    setRescheduleModal(false);
                  }}
                >
                  Cancel
                </Cancel>
                <RescheduleButton
                  onClick={() => {
                    setModal(true);
                    setRescheduleModal(false);
                  }}
                >
                  Reschedule
                </RescheduleButton>
              </BottomContainer>
            </ContainerWhite>
          </MainContainer>
        </>
      )}
    </>
  );
}

export default Reschedulemodal;
const Cover = styled.div`
  top: 0;
  left: 0;
  z-index: 1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  opacity: 0.4;
`;
const MainContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 400px;
  z-index: 2;
  cursor: default;
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  background: ${THEME_COLORS.chips_green_on_container};
  border-radius: 12px;
  padding-top: 5px;
  @media all and (max-width: 1280px) {
    right: 290px;
    bottom: 8px;
  }
  @media all and (max-width: 1080px) {
    right: 220px;
  }
  @media all and (max-width: 980px) {
    right: 160px;
  }
`;
const ContainerWhite = styled.div`
  padding: 40px;
  background: ${THEME_COLORS.white};
  border-radius: 10px;
  border: 2px solid ${THEME_COLORS.white};
  @media all and (max-width: 1280px) {
    padding: 20px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: Space-Between;
  margin-bottom: 20px;
`;
const LeftContainer = styled.div``;
const Heading = styled.h4`
  font-size: 24px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 10px;
`;
const Description = styled.div`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
`;
const MiddleContainer = styled.div`
  width: 625px;
  padding: 24px;
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  margin-bottom: 40px;
  input {
    background: ${THEME_COLORS.light_200};
    padding: 17px 12px;
    width: 100%;
    border-radius: 8px;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ReasonDiv = styled.div`
  display: flex;
  align-items: center;
`;
const SlotDiv = styled.div``;
const Number = styled.div`
  width: 20px;
  margin-right: 5px;
  img {
    display: block;
    width: 100%;
  }
`;
const ReasonText = styled.h6`
  color: ${THEME_COLORS.text_title};
  font-size: 14px;
`;
const MiddleText = styled.div`
  color: ${THEME_COLORS.chips_green_on_container};
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 20px;
`;
const OptionsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const RadioButton = styled.div`
  margin-right: 10px;
  img {
    display: block;
    width: 100%;
  }
`;
const Issues = styled.h6`
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
`;
const Cancel = styled.button`
  padding: 14px 24px;
  border: 1px solid ${THEME_COLORS.light_400};
  border-radius: 8px;
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
`;
const RescheduleButton = styled.button`
  cursor: pointer;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 14px;
  color: ${THEME_COLORS.white};
  background: ${THEME_COLORS.chips_blue_on_container_2};
`;
const Line = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
