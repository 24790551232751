import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { THEME_COLORS } from '../../ThemeConfig'
import CalendarClickModal from './modals/CalendarClickModal'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import styled from 'styled-components'
import '../../assets/css/CalendarScheduler.css'
import upcomimng from '../../assets/image/dashboard/session-card/upcoming.svg'
import reschedule from '../../assets/image/dashboard/session-card/rescheduled.svg'
import followup from '../../assets/image/dashboard/session-card/followup.svg'
import completed from '../../assets/image/dashboard/session-card/completed.svg'
import cancelled from '../../assets/image/dashboard/session-card/cancelled.svg'
import pending from '../../assets/image/dashboard/session-card//pending.svg'
import { Context } from '../../contexts/Store'
import { expert } from '../../axiosConfig'

export function CalendarScheduler({ filter, search, filtermonth }) {
  const [modal, setModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState()
  const [date, setDate] = useState()
  const [topPosition, setTopPosition] = useState()
  const [leftPosition, setLeftPosition] = useState()
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [events, setEvents] = useState([])
  const [error, SetError] = useState('')
  const [Month, SetMonth] = useState('')
  const [Year, SetYear] = useState('')
  const [clickedDate, setClickedDate] = useState('')

  function handlenewDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const handleMonthChange = (info) => {
    const newDate = info.view.currentStart
    const month = moment(newDate).format('M')
    const year = moment(newDate).format('YYYY')
    SetMonth(month)
    SetYear(year)
  }

  const handleSessions = () => {
    expert
      .get('/sessions/month-slots/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          session_record_status: filter,
          year: Year,
          month: Month,
        },
      })

      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode == 6000) {
          setEvents(data.slots)
        } else if (StatusCode == 6001) {
          SetError(response.data.data)
          // console.log(error, 'error')
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error)
      })
    // }
  }

  useEffect(() => {
    handleSessions()
  }, [filter, filtermonth, Month, Year])

  const handleEventClick = (info) => {
    const dateStr = info.event.start
    const newDate = handlenewDate(dateStr)
    setDate(newDate)

    setModal(true)

    // Get the clicked event's date
    const clickedDate = moment(info.event.start).format('YYYY-MM-DD')

    // Filter events for the clicked date
    const eventsForClickedDate = events.filter(
      (event) => event.date === clickedDate,
    )

    // Calculate positions
    const container = document.querySelector('.fc-daygrid-day-frame')
    const containerRect = container.getBoundingClientRect()
    const cell = document.querySelector(`.fc-day[data-date="${clickedDate}"]`)
    const cellRect = cell.getBoundingClientRect()
    const top = cellRect.top - containerRect.top + 100
    const left = cellRect.left - containerRect.left + 100

    // Set the positions and other necessary data for the modal
    setTopPosition(top)
    setLeftPosition(left)
    setClickedDate(clickedDate)
    setSelectedDate(moment(info.event.start).format('MMMM, DD'))
  }

  const handleDateClick = (info) => {
    setModal(true)
    const clickedDate = moment(info.dateStr).format('YYYY-MM-DD')
    setClickedDate(clickedDate)
    const eventsForClickedDate = events.filter(
      (event) => event.date === clickedDate,
    )

    const container = document.querySelector('.fc-daygrid-day-frame')
    const containerRect = container.getBoundingClientRect()
    const cell = document.querySelector(`.fc-day[data-date="${clickedDate}"]`)
    const cellRect = cell.getBoundingClientRect()

    const top = cellRect.top - containerRect.top + 100
    const left = cellRect.left - containerRect.left + 100

    // Set the positions
    setTopPosition(top)
    setLeftPosition(left)
    const formattedDate = moment(info.dateStr).format('MMMM, DD')

    setSelectedDate(formattedDate)
  }

  useEffect(() => {
    if (events && events.length > 0) {
      const uniqueDates = [...new Set(events.map((event) => event.date))]
      uniqueDates.forEach((date) => {
        const eventsForDate = events.filter((event) => event.date === date)
      })
    }
  }, [events])

  return (
    <>
      <Container>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          datesSet={handleMonthChange} //
        />
        {modal && (
          <CalendarClickModal
            events={events}
            handleEventClick={handleEventClick}
            selectedDate={selectedDate}
            date={clickedDate}
            top={topPosition}
            left={leftPosition}
            setModal={setModal}
          />
        )}
      </Container>
    </>
  )
}

function renderEventContent(eventInfo) {
  const status = eventInfo.event.extendedProps.status?.toLowerCase()
  return (
    <>
      <Cover status={status}>
        <IconContainer>
          <Icon
            src={
              eventInfo.event.extendedProps.status === 'upcoming'
                ? upcomimng
                : eventInfo.event.extendedProps.status === 'completed'
                ? completed
                : eventInfo.event.extendedProps.status === 'cancelled'
                ? cancelled
                : eventInfo.event.extendedProps.status === 'rescheduled'
                ? reschedule
                : eventInfo.event.extendedProps.status === 'followups'
                ? followup
                : pending
            }
            alt="icon"
          />
        </IconContainer>
        <Title status={eventInfo.event.extendedProps.status}>
          {eventInfo.event.extendedProps.slot}
        </Title>
      </Cover>
    </>
  )
}

const Container = styled.section`
  .fc .fc-daygrid-day-frame {
    position: relative;
  }
`
const Cover = styled.div`
  background: ${(props) =>
    props.status === 'upcoming'
      ? THEME_COLORS.chips_green_container
      : props.status === 'rescheduled'
      ? THEME_COLORS.chips_violet_container
      : props.status === 'followups'
      ? THEME_COLORS.chips_orange_container
      : props.status === 'completed'
      ? THEME_COLORS.chips_blue_container
      : THEME_COLORS.chips_red_container};
  padding: 3px 8px;
  border-radius: 4px;
  display: flex;
  width: fit-content;
  margin-bottom: 5px;
  margin-left: 10px;
  position: relative;

  @media all and (max-width: 1200px) {
    margin-left: 0;
  }
  @media all and (max-width: 980px) {
    margin-left: 0 auto;
  }
`
const Title = styled.i`
  color: ${(props) =>
    props.status === 'upcoming'
      ? THEME_COLORS.chips_green_on_container
      : props.status === 'rescheduled'
      ? THEME_COLORS.chips_violet_on_container
      : props.status === 'followups'
      ? THEME_COLORS.chips_orange_on_container
      : props.status === 'completed'
      ? THEME_COLORS.chips_blue_on_container_2
      : THEME_COLORS.chips_red_on_container};
`
const Icon = styled.img`
  margin-right: 10px;
`
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
