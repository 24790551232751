import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import upcoming from '../../../assets/image/dashboard/sorting-chip/upcoming-balck.svg'
import reschedule from '../../../assets/image/dashboard/sorting-chip/rescheduled-balck.svg'
import followup from '../../../assets/image/dashboard/sorting-chip/followup-balck.svg'
import completed from '../../../assets/image/dashboard/sorting-chip/completed-balck.svg'
import cancelled from '../../../assets/image/dashboard/sorting-chip/cancelled-balck.svg'

function FilterModal({ filtermodal, setFilter, setFilterModal }) {
  const dates = [
    {
      id: 1,
      number: 1,
      status: 'Upcoming',
      is_selected: false,
      icon: upcoming,
    },
    {
      id: 2,
      number: 2,
      status: 'Completed',
      is_selected: false,
      icon: completed,
    },
    {
      id: 3,
      number: 3,
      status: 'Cancelled',
      icon: cancelled,
      is_selected: false,
    },
    {
      id: 4,
      number: 1,
      status: 'FollowUp',
      icon: followup,
      is_selected: false,
    },
  ]

  return (
    <>
      <Contaner className={filtermodal && 'active'}>
        {dates.map((item) => (
          <DivContaner
            key={item.id}
            onClick={() => {
              setFilter(item.number)
              setFilterModal(false)
            }}
          >
            <Icon src={item.icon} alt="icon" />
            <Text key={item.id}>{item.status}</Text>
          </DivContaner>
        ))}
      </Contaner>
    </>
  )
}

export default FilterModal

const Contaner = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  padding: 8px 10px;
  border-radius: 12px;
  width: 164px;
  position: absolute;
  right: -10px;
  top: 50px;
  z-index: 10;
  display: none;
  &.active {
    display: block;
  }
`
const DivContaner = styled.div`
  margin-bottom: 8px;
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    background: ${THEME_COLORS.light_100};
  }
`
const Text = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-size: 14px;
`
const Icon = styled.img`
  margin-right: 10px;
`
