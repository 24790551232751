import React from 'react'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'

const DropDown = ({ list, handleMethod, state }) => {
  return (
    <DropdownMenu className="outer-click">
      {list?.map((option, index) => {
        return (
          <Option
            onClick={() => handleMethod(option.name, option.id)}
            className={state === option && 'active'}
            key={index}
          >
            {option.name}
          </Option>
        )
      })}
    </DropdownMenu>
  )
}

export default DropDown

const DropdownMenu = styled.div`
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  background-color: ${THEME_COLORS.white};
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  border-radius: 12px;
  padding: 8px 10px;
  z-index: 100;
  height: max-content;
`
const Option = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.black};
  background-color: ${THEME_COLORS.white};
  padding: 8px 10px;
  border-radius: 8px;
  &.active {
    background-color: ${THEME_COLORS.light_200};
  }
  &:hover {
    background-color: ${THEME_COLORS.light_200};
  }
`
