import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import calender from "../../../assets/image/sessionsinglepage/calender.svg";

function DateChip({ date }) {
    return (
        <>
            {" "}
            <DateContainer>
                <CalenderIcon>
                    <Icon src={calender} alt="icon" />
                </CalenderIcon>
                <DateDiv>{date}</DateDiv>
            </DateContainer>
        </>
    );
}

export default DateChip;
const DateContainer = styled.div`
    margin-right: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4px 8px 4px 3px;
    border-radius: 8px;
    background: ${THEME_COLORS.light_200};

    @media all and (max-width: 1200px) {
        cursor: pointer;
    }
`;
const CalenderIcon = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 10px;
`;
const Icon = styled.img``;
const DateDiv = styled.p`
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;
