import React, { useContext, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

function FormModal({ formModal, setformModal, forms, setdraftModal }) {
    const { dispatch } = useContext(Context);
    return (
        <>
            <Contaner className={formModal && "active"}>
                {forms.map((item) => (
                    <DateContaner
                        key={item.id}
                        onClick={() => {
                            setdraftModal(false);
                            setformModal(false);
                            dispatch({
                                type: "UPDATE_USER",
                                user_details: {
                                    formType: item.name,
                                },
                            });
                        }}
                    >
                        <Date key={item.id}>{item.name}</Date>
                    </DateContaner>
                ))}
            </Contaner>
        </>
    );
}

export default FormModal;

const Contaner = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    padding: 8px;
    border-radius: 10px;
    width: max-content;
    position: absolute;
    right: 25px;
    top: 65px;
    z-index: 10;
    display: none;
    &.active {
        display: block;
    }
`;
const DateContaner = styled.div`
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const Date = styled.p`
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
`;
