import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import down_arrow from '../../../assets/image/dashboard/arrows.svg'
import FormModal from '../../includes/modals/FormModal'
import ChiefComplaints from './forms/ChiefComplaints'
import PresentingIllness from './forms/PresentingIllness'
import PastIllness from './forms/PastIllness'
import FamilyHistory from './forms/FamilyHistory'
import PersonalHistory from './forms/PersonalHistory'
import PremorbidPersonality from './forms/PremorbidPersonality'
import MentalStatusExamination from './forms/MentalStatusExamination'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import { cases } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import DraftModal from '../session_summary/modals/DraftModal'
import { useNavigate } from 'react-router-dom'

const BasicReport = ({
  id,
  activeOption,
  setActiveOption,
  setShowAttentionModal,
  setdraftModal,
  draftModal,
  setFormType,
  formType,
  isBasicFormsSubmittedFields,
  gender,
  getCaseData,
}) => {
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  // const [formType, setFormType] = useState('Chief complaints & Summary')
  const [isDraft, setIsDraft] = useState(false)

  const {
    isChiefComplaintsSubmitted,
    isPresentingHistorySubmitted,
    isPastIllnessSubmitted,
    isFamilyHistorySubmitted,
    isPersonalHistorySubmitted,
    isPremorbidSubmitted,
    isMentalStatusSubmitted,
  } = isBasicFormsSubmittedFields

  // Chief complaints & Summary states
  const [chiefFormFields, setChiefFormFields] = useState({
    chiefComplaints: '',
    summary: '',
    diagnosis: '',
    recommendation: '',
    treatmentPlan: '',
    suggestions: '',
  })
  const [
    initialchiefFormFieldValues,
    setInitialChiefFormFieldValues,
  ] = useState({
    chiefComplaints: '',
    summary: '',
    diagnosis: '',
    recommendation: '',
    treatmentPlan: '',
    suggestions: '',
  })
  // Chief complaints & Summary default values
  const chiefFormFieldsDefaultValues = {
    chiefComplaints: '',
    summary: '',
    diagnosis: '',
    recommendation: '',
    treatmentPlan: '',
    suggestions: '',
  }
  const {
    chiefComplaints,
    summary,
    diagnosis,
    recommendation,
    treatmentPlan,
    suggestions,
  } = chiefFormFields
  // end of chief states

  // history of presenting illness states
  const [
    presentingIllnessFormFields,
    setPresentingIllnessFormFields,
  ] = useState({
    precipitatingEvents: '',
    relevantHistory: '',
    onset: '',
  })
  const [
    initialPresentingIllnessValues,
    setInitialPresentingIllnessValues,
  ] = useState({
    precipitatingEvents: '',
    relevantHistory: '',
    onset: '',
  })
  // history of presenting illness default values
  const presentingIllnessFormDefaultValues = {
    precipitatingEvents: '',
    relevantHistory: '',
    onset: '',
  }
  const {
    precipitatingEvents,
    relevantHistory,
    onset,
  } = presentingIllnessFormFields
  // end of history states

  // past illness states
  const [pastIllnessFormFields, setpastIllnessFormFields] = useState({
    pastMedical: '',
    psychiatricHistory: '',
  })
  const [
    initialPastIllnessFieldValues,
    setInitialpastIllnessFieldValues,
  ] = useState({
    pastMedical: '',
    psychiatricHistory: '',
  })
  const { pastMedical, psychiatricHistory } = pastIllnessFormFields
  // end of past illness states

  // family history states
  const [familyHistoryFormFields, setFamilyHistoryFormFields] = useState({
    familyTree: '',
    mentalIllnesses: [],
    dementias: [],
  })
  const [
    initialfamilyHistoryFormValues,
    setInitialFamilyHistoryFormValues,
  ] = useState({
    familyTree: '',
    mentalIllnesses: [],
    dementias: [],
  })
  const { familyTree, mentalIllnesses, dementias } = familyHistoryFormFields

  // end of family history states

  // personal history states
  const [personalHistoryFormFields, setpersonalHistoryFormFields] = useState({
    birthAndEarlyDevolopement: '',
    milestoneDevolopment: '',
    childhoodDisorders: '',
    homeAtmosphere: '',
    extraCurricular: '',
    occupationHistory: '',
    maritalHistory: '',
    sexualHistory: '',
    substanceHistory: '',
    interPersonalRelations: '',
    menstrualHistory: '',
  })
  const {
    birthAndEarlyDevolopement,
    milestoneDevolopment,
    childhoodDisorders,
    homeAtmosphere,
    extraCurricular,
    occupationHistory,
    maritalHistory,
    sexualHistory,
    substanceHistory,
    interPersonalRelations,
    menstrualHistory,
  } = personalHistoryFormFields
  const [
    initialPersonalHistoryFormValues,
    setInitialPersonalHistoryFormValues,
  ] = useState({
    birthAndEarlyDevolopement: '',
    milestoneDevolopment: '',
    childhoodDisorders: '',
    homeAtmosphere: '',
    extraCurricular: '',
    occupationHistory: '',
    maritalHistory: '',
    sexualHistory: '',
    substanceHistory: '',
    interPersonalRelations: '',
    menstrualHistory: '',
  })
  // end of personal history states

  // Premorbid Personality states
  const [
    premorbidPersonalityFormFields,
    setPremorbidPersonalityFormFields,
  ] = useState({
    selfData: '',
    relations: '',
    workAndLeisure: '',
    mood: '',
    attitudesAndStandard: '',
    habits: '',
  })
  const {
    selfData,
    relations,
    workAndLeisure,
    mood,
    attitudesAndStandard,
    habits,
  } = premorbidPersonalityFormFields
  const [
    initialPremorbidPersonalityFormValues,
    setInitialPremorbidPersonalityFormValues,
  ] = useState({
    selfData: '',
    relations: '',
    workAndLeisure: '',
    mood: '',
    attitudesAndStandard: '',
    habits: '',
  })
  // end of Premorbid Personality states

  // Mental Status examination states
  const [mentalStatusFormFields, setMentalStatusFormFields] = useState({
    consciousness: '',
    rapport: '',
    appearance: '',
    handedness: '',
    eyeToEyeContact: '',
    facialExpression: '',
    attitudesTowardsExaminer: [],
    motorBehaviour: '',
    cognitiveStatuses: [],
    attentionAndConcentrations: [],
    attentionAndConcentrationResult: '',
    orientations: [],
    orientationResult: '',
    memorys: [],
    memoryResult: '',
    abstractAbilitys: [],
    judgments: [],
    test: '',
    social: '',
    personal: '',
    generalInformations: [],
    calculations: '',
    intelligences: [],
    thoughts: [],
    stream: '',
    formIds: [],
    posessions: [],
    content: '',
    objectiveAffect: '',
    appropriateness: '',
    range: '',
    communicability: '',
    sensoryDistortion: '',
    sensoryDeception: '',
    psychoticPhenomenas: [],
    otherPhenomenas: [],
    insight: '',
  })
  const {
    consciousness,
    rapport,
    appearance,
    handedness,
    eyeToEyeContact,
    facialExpression,
    attitudesTowardsExaminer,
    motorBehaviour,
    cognitiveStatuses,
    attentionAndConcentrations,
    attentionAndConcentrationResult,
    orientations,
    orientationResult,
    memorys,
    memoryResult,
    abstractAbilitys,
    judgments,
    test,
    social,
    personal,
    generalInformations,
    calculations,
    intelligences,
    thoughts,
    stream,
    formIds,
    posessions,
    content,
    objectiveAffect,
    appropriateness,
    range,
    communicability,
    sensoryDistortion,
    sensoryDeception,
    psychoticPhenomenas,
    otherPhenomenas,
    insight,
  } = mentalStatusFormFields
  const [
    initialMentalStatusFormValues,
    setInitialMentalStatusFormValues,
  ] = useState({
    consciousness: '',
    rapport: '',
    appearance: '',
    handedness: '',
    eyeToEyeContact: '',
    facialExpression: '',
    attitudesTowardsExaminer: [],
    motorBehaviour: '',
    cognitiveStatuses: [],
    attentionAndConcentrations: [],
    attentionAndConcentrationResult: '',
    orientations: [],
    orientationResult: '',
    memorys: [],
    memoryResult: '',
    abstractAbilitys: [],
    judgments: [],
    test: '',
    social: '',
    personal: '',
    generalInformations: [],
    calculations: '',
    intelligences: [],
    thoughts: [],
    stream: '',
    formIds: [],
    posessions: [],
    content: '',
    objectiveAffect: '',
    appropriateness: '',
    range: '',
    communicability: '',
    sensoryDistortion: '',
    sensoryDeception: '',
    psychoticPhenomenas: [],
    otherPhenomenas: [],
    insight: '',
  })
  // end of Mental Status examination states

  const [formModal, setformModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const navigate = useNavigate()
  const handleCancel = () => {
    if (detectChanges(initialchiefFormFieldValues, chiefFormFields)) {
      setdraftModal(true)
    } else {
      navigate(-1)
    }
  }

  const handlePrevious = () => {
    // setActiveOption(activeOption - 1)
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        activeOption: 1,
      },
    })
  }

  const handleNext = () => {
    if (
      activeOption == 1 &&
      detectChanges(initialchiefFormFieldValues, chiefFormFields)
    ) {
      if (hasEmptyString(chiefFormFields)) {
        setdraftModal(true)
      } else {
        handleChiefFormSubmit()
      }
    } else {
      dispatch({
        type: 'UPDATE_USER',
        user_details: {
          activeOption: activeOption + 1,
        },
      })
    }
  }

  // temporary - onset values in presenting Illness
  const onsetValues = [
    {
      id: 1,
      name: 'Acute',
    },
    {
      id: 2,
      name: 'Insidious',
    },
    {
      id: 3,
      name: 'Unknown',
    },
  ]

  // contents in mental status form
  const contentsOptions = [
    {
      id: 1,
      name: 'Ideas',
    },
    {
      id: 2,
      name: 'Delusions of reference',
    },
    {
      id: 3,
      name: 'Persecution',
    },
    {
      id: 4,
      name: 'Grandeur',
    },
    {
      id: 5,
      name: 'Hypochondriasis',
    },
    {
      id: 6,
      name: 'Worthlessness',
    },
    {
      id: 7,
      name: 'Guilt',
    },
    {
      id: 8,
      name: 'Sin',
    },
    {
      id: 9,
      name: 'Nihilism',
    },
    {
      id: 10,
      name: 'Negation',
    },
    {
      id: 11,
      name: 'Love',
    },
    {
      id: 12,
      name: 'Control',
    },
    {
      id: 13,
      name: 'Infidelity',
    },
    {
      id: 14,
      name: 'Overvalued Ideas',
    },
    {
      id: 15,
      name: 'Mood Congruent',
    },
    {
      id: 16,
      name: 'Incongruent',
    },
    {
      id: 17,
      name: 'Phobia',
    },
    {
      id: 18,
      name: 'Somatization',
    },
    {
      id: 19,
      name: 'Other dominant preoccupations',
    },
  ]
  const forms = [
    {
      id: 1,
      name: 'Chief complaints & Summary',
    },
    {
      id: 2,
      name: 'History of Presenting Illness',
    },
    {
      id: 3,
      name: 'Past Illness',
    },
    {
      id: 5,
      name: 'Family history',
    },
    {
      id: 6,
      name: 'Personal History',
    },

    {
      id: 7,
      name: 'Premorbid personality',
    },
    {
      id: 8,
      name: 'Mental status examination',
    },
  ]

  function detectChanges(initialValues, currentValues) {
    const isObject = (value) => typeof value === 'object' && value !== null

    const deepEqual = (obj1, obj2) => {
      if (!isObject(obj1) || !isObject(obj2)) {
        return obj1 === obj2
      }

      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      return keys1.every((key) => deepEqual(obj1[key], obj2[key]))
    }

    return !deepEqual(initialValues, currentValues)
  }

  // to check if any key is an empty string in an object
  function hasEmptyString(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === '') {
        return true
      }
    }
    return false
  }

  const handleChiefFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('chief_complaints', chiefComplaints)
    formdata.append('summary', summary)
    formdata.append('diagnosis', diagnosis)
    formdata.append('recommendation', recommendation)
    formdata.append('treatment_plan', treatmentPlan)
    formdata.append('suggestions', treatmentPlan)
    formdata.append('treatment_plan', suggestions)
    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .post(`/add-case-report/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          if (isDraft) {
            setIsDraft(false)
          }
          // dispatch({
          //   type: 'UPDATE_USER',
          //   user_details: {
          //     activeOption: 2,
          //   },
          // })
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getChiefFormDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/chief-complaints/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setChiefFormFields({
            chiefComplaints: data.chief_complaints,
            summary: data.summary,
            diagnosis: data.diagnosis,
            recommendation: data.recommendation,
            treatmentPlan: data.treatment_plan,
            suggestions: data.suggestions,
          })

          setInitialChiefFormFieldValues({
            chiefComplaints: data.chief_complaints,
            summary: data.summary,
            diagnosis: data.diagnosis,
            recommendation: data.recommendation,
            treatmentPlan: data.treatment_plan,
            suggestions: data.suggestions,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handlePresentingIllnessFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('precipitating_events', precipitatingEvents)
    formdata.append('negative_history', relevantHistory)
    formdata.append('onset', onset)
    if (isDraft) {
      formdata.append('is_draft', false)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .put(`/add-present-illness/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getPresentingIllnessFormDatas()
          if (isDraft) {
            setIsDraft(false)
          }
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getPresentingIllnessFormDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/present-illness/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setPresentingIllnessFormFields({
            precipitatingEvents: data.precipitating_events,
            relevantHistory: data.negative_history,
            onset: onsetValues.find((item) => item.name == data.onset).id,
          })

          setInitialPresentingIllnessValues({
            precipitatingEvents: data.precipitating_events,
            relevantHistory: data.negative_history,
            onset: onsetValues.find((item) => item.name == data.onset).id,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handlePastIllnessFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('past_medical', pastMedical)
    formdata.append('psychiatric_history', psychiatricHistory)
    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .put(`/add-past-illness/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getPastIllnessFormDatas()
          if (isDraft) {
            setIsDraft(false)
          }
          // console.log(
          //     response.data,
          //     "past Illness form submitted response.data"
          // );
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getPastIllnessFormDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/past-illness/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setpastIllnessFormFields({
            pastMedical: data.past_medical,
            psychiatricHistory: data.psychiatric_history,
          })

          setInitialpastIllnessFieldValues({
            pastMedical: data.past_medical,
            psychiatricHistory: data.psychiatric_history,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handleFamilyHistoryFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('family_tree', familyTree)
    formdata.append(
      'history_of_mental_illness',
      JSON.stringify(mentalIllnesses),
    )
    formdata.append('history_of_dementia', JSON.stringify(dementias))
    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    const data = {
      family_tree: familyTree,
      history_of_mental_illness: mentalIllnesses,
      history_of_dementia: dementias,
    }

    cases
      .put(`/add-family-history/${id}/`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getFamilyHistoryFormDatas()
          if (isDraft) {
            setIsDraft(false)
          }
          // console.log(
          //     response.data,
          //     "past Illness form submitted response.data"
          // );
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getFamilyHistoryFormDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/family-history/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setFamilyHistoryFormFields({
            familyTree: data.family_tree,
            mentalIllnesses: data.history_of_mental_illness.map(
              (item) => item.id,
            ),
            dementias: data.history_of_dementia.map((item) => item.id),
          })

          setInitialFamilyHistoryFormValues({
            familyTree: data.family_tree,
            mentalIllnesses: data.history_of_mental_illness.map(
              (item) => item.id,
            ),
            dementias: data.history_of_dementia.map((item) => item.id),
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handlePersonalHistoryFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('birth_and_early_development', birthAndEarlyDevolopement)
    formdata.append('milestone_development', milestoneDevolopment)
    formdata.append('childhood_disorders', childhoodDisorders)
    formdata.append('home_atmosphere', homeAtmosphere)
    formdata.append(
      'scholastic_and_extracurricular_activities',
      extraCurricular,
    )
    formdata.append('occupation_history', occupationHistory)
    formdata.append('marital_history', maritalHistory)
    formdata.append('sexual_history', sexualHistory)
    formdata.append('substance_history', substanceHistory)
    formdata.append('interpersonal_relations', interPersonalRelations)
    formdata.append('menstrual_history', menstrualHistory)

    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .put(`/add-personal-history/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getPersonalHistoryDatas()
          if (isDraft) {
            setIsDraft(false)
          }
          // console.log(
          //     response.data,
          //     "chief form submitted response.data"
          // );
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getPersonalHistoryDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/personal-history/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setpersonalHistoryFormFields({
            birthAndEarlyDevolopement: data.birth_and_early_development,
            milestoneDevolopment: data.milestone_development,
            childhoodDisorders: data.childhood_disorders,
            homeAtmosphere: data.home_atmosphere,
            extraCurricular: data.scholastic_and_extracurricular_activities,
            occupationHistory: data.occupation_history,
            maritalHistory: data.marital_history,
            sexualHistory: data.sexual_history,
            substanceHistory: data.substance_history,
            interPersonalRelations: data.interpersonal_relations,
            menstrualHistory: data.menstrual_history,
          })

          setInitialPersonalHistoryFormValues({
            birthAndEarlyDevolopement: data.birth_and_early_development,
            milestoneDevolopment: data.milestone_development,
            childhoodDisorders: data.childhood_disorders,
            homeAtmosphere: data.home_atmosphere,
            extraCurricular: data.scholastic_and_extracurricular_activities,
            occupationHistory: data.occupation_history,
            maritalHistory: data.marital_history,
            sexualHistory: data.sexual_history,
            substanceHistory: data.substance_history,
            interPersonalRelations: data.interpersonal_relations,
            menstrualHistory: data.menstrual_history,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handlePremorbidPersonalityFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('self_data', selfData)
    formdata.append('relations', relations)
    formdata.append('work_and_leisure', workAndLeisure)
    formdata.append('mood', mood)
    formdata.append('attitudes_and_standard', attitudesAndStandard)
    formdata.append('habits', habits)

    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .put(`/add-premorbid-personality/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getPremorbidPersonalityDatas()
          if (isDraft) {
            setIsDraft(false)
          }
          // console.log(
          //     response.data,
          //     "chief form submitted response.data"
          // );
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getPremorbidPersonalityDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/premorbid-personality/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setPremorbidPersonalityFormFields({
            selfData: data.self_data,
            relations: data.relations,
            workAndLeisure: data.work_and_leisure,
            mood: data.mood,
            attitudesAndStandard: data.attitudes_and_standard,
            habits: data.habits,
          })

          setInitialPremorbidPersonalityFormValues({
            selfData: data.self_data,
            relations: data.relations,
            workAndLeisure: data.work_and_leisure,
            mood: data.mood,
            attitudesAndStandard: data.attitudes_and_standard,
            habits: data.habits,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handleMentalStatusFormSubmit = () => {
    var formdata = new FormData()

    formdata.append('consciousness', consciousness)
    formdata.append('rapport', rapport)
    formdata.append('appearance', appearance)
    formdata.append('handedness', handedness)
    formdata.append('eye_to_eye_contact', eyeToEyeContact)
    formdata.append('facial_expression', facialExpression)
    if (attitudesTowardsExaminer.length > 0) {
      attitudesTowardsExaminer.forEach((attitudes) => {
        formdata.append('attitude_towards_examiner', attitudes);
      });
    }
    formdata.append('motor_behaviour', motorBehaviour)
    if (cognitiveStatuses.length > 0) {
      cognitiveStatuses.forEach((item) => {
        formdata.append('cognitive_status', item);
      });
    }
    if (attentionAndConcentrations.length > 0) {
      attentionAndConcentrations.forEach((item) => {
        formdata.append('attention_and_concentration', item);
      });
    }
    formdata.append(
      'attention_and_concentration_result',
      attentionAndConcentrationResult,
    )
    if (orientations.length > 0) {
      orientations.forEach((item) => {
        formdata.append('orientation', item);
      });
    }
    formdata.append('orientation_result', orientationResult)
    if (memorys.length > 0) {
      memorys.forEach((item) => {
        formdata.append('memory[]', item);
      });
    }
    formdata.append('memory_result', memoryResult)
    if (abstractAbilitys.length > 0) {
      abstractAbilitys.forEach((item) => {
        formdata.append('abstract_ability', item);
      });
    }
    if (judgments.length > 0) {
      judgments.forEach((item) => {
        formdata.append('judgment[]', item);
      });
    }
    formdata.append('test', test)
    formdata.append('social', social)
    formdata.append('personal', personal)
    if (generalInformations.length > 0) {
      generalInformations.forEach((item) => {
        formdata.append('general_information', item);
      });
    }
    formdata.append('calculations', calculations)
    if (intelligences.length > 0) {
      intelligences.forEach((item) => {
        formdata.append('intelligence', item);
      });
    }
    if (thoughts.length > 0) {
      thoughts.forEach((item) => {
        formdata.append('thought[]', item);
      });
    }
    formdata.append('stream', stream)
    if (formIds.length > 0) {
      formIds.forEach((item) => {
        formdata.append('form', item);
      });
    }
    if (posessions.length > 0) {
      posessions.forEach((item) => {
        formdata.append('possession', item);
      });
    }
    formdata.append('content', content)
    formdata.append('objective_affect', objectiveAffect)
    formdata.append('appropriateness', appropriateness)
    formdata.append('range', range)
    formdata.append('communicability', communicability)
    formdata.append('sensory_distortion', sensoryDistortion)
    formdata.append('sensory_deception', sensoryDeception)
    if (psychoticPhenomenas.length > 0) {
      psychoticPhenomenas.forEach((item) => {
        formdata.append('psychotic_phenomena', item);
      });
    }
    if (otherPhenomenas.length > 0) {
      otherPhenomenas.forEach((phenomenon) => {
        formdata.append('other_phenomena', phenomenon);
      });
    }
    formdata.append('insight', insight)

    if (isDraft) {
      formdata.append('is_draft', true)
    } else {
      formdata.append('is_draft', false)
    }

    cases
      .put(`/add-mental-status-examination/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data

        if (StatusCode === 6000) {
          alert(data.message)
          getCaseData()
          getMentalStatusDatas()
          if (isDraft) {
            setIsDraft(false)
          }
          // console.log(
          //     response.data,
          //     "chief form submitted response.data"
          // );
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error?.response?.data?.data?.message)
        }
      })
  }

  const getMentalStatusDatas = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/mental-status-examination/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setMentalStatusFormFields({
            consciousness: data.consciousness,
            rapport: data.rapport,
            appearance: data.appearance,
            handedness: data.handedness,
            eyeToEyeContact: data.eye_to_eye_contact,
            facialExpression: data.facial_expression,
            attitudesTowardsExaminer: data.attitude_towards_examiner.map(
              (item) => item.id,
            ),
            motorBehaviour: data.motor_behaviour,
            cognitiveStatuses: data.cognitive_status.map((item) => item.id),
            attentionAndConcentrations: data.attention_and_concentration.map(
              (item) => item.id,
            ),
            attentionAndConcentrationResult:
              data.attention_and_concentration_result,
            orientations: data.orientation.map((item) => item.id),
            orientationResult: data.orientation_result,
            memorys: data.memory.map((item) => item.id),
            memoryResult: data.memory_result,
            abstractAbilitys: data.abstract_ability.map((item) => item.id),
            judgments: data.judgment.map((item) => item.id),
            test: data.test,
            social: data.social,
            personal: data.personal,
            generalInformations: data.general_information.map(
              (item) => item.id,
            ),
            calculations: data.calculations,
            intelligences: data.intelligence.map((item) => item.id),
            thoughts: data.thought.map((item) => item.id),
            stream: data.stream,
            formIds: data.form.map((item) => item.id),
            posessions: data.possession.map((item) => item.id),
            content: contentsOptions.find((item) => item.name == data.content)
              .id,
            objectiveAffect: data.objective_affect,
            appropriateness: data.appropriateness,
            range: data.range,
            communicability: data.communicability,
            sensoryDistortion: data.sensory_distortion,
            sensoryDeception: data.sensory_deception,
            psychoticPhenomenas: data.psychotic_phenomena.map(
              (item) => item.id,
            ),
            otherPhenomenas: data.other_phenomena.map((item) => item.id),
            insight: data.insight,
          })
          setInitialMentalStatusFormValues({
            consciousness: data.consciousness,
            rapport: data.rapport,
            appearance: data.appearance,
            handedness: data.handedness,
            eyeToEyeContact: data.eye_to_eye_contact,
            facialExpression: data.facial_expression,
            attitudesTowardsExaminer: data.attitude_towards_examiner.map(
              (item) => item.id,
            ),
            motorBehaviour: data.motor_behaviour,
            cognitiveStatuses: data.cognitive_status.map((item) => item.id),
            attentionAndConcentrations: data.attention_and_concentration.map(
              (item) => item.id,
            ),
            attentionAndConcentrationResult:
              data.attention_and_concentration_result,
            orientations: data.orientation.map((item) => item.id),
            orientationResult: data.orientation_result,
            memorys: data.memory.map((item) => item.id),
            memoryResult: data.memory_result,
            abstractAbilitys: data.abstract_ability.map((item) => item.id),
            judgments: data.judgment.map((item) => item.id),
            test: data.test,
            social: data.social,
            personal: data.personal,
            generalInformations: data.general_information.map(
              (item) => item.id,
            ),
            calculations: data.calculations,
            intelligences: data.intelligence.map((item) => item.id),
            thoughts: data.thought.map((item) => item.id),
            stream: data.stream,
            formIds: data.form.map((item) => item.id),
            posessions: data.possession.map((item) => item.id),
            content: contentsOptions.find((item) => item.name == data.content)
              .id,
            objectiveAffect: data.objective_affect,
            appropriateness: data.appropriateness,
            range: data.range,
            communicability: data.communicability,
            sensoryDistortion: data.sensory_distortion,
            sensoryDeception: data.sensory_deception,
            psychoticPhenomenas: data.psychotic_phenomena.map(
              (item) => item.id,
            ),
            otherPhenomenas: data.other_phenomena.map((item) => item.id),
            insight: data.insight,
          })
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const handleDraftSubmit = () => {
    if (formType == 'Chief complaints & Summary') {
      handleChiefFormSubmit()
    } else if (formType == 'History of Presenting Illness') {
      handlePresentingIllnessFormSubmit()
    } else if (formType == 'Past Illness') {
      handlePastIllnessFormSubmit()
    } else if (formType == 'Family history') {
      handleFamilyHistoryFormSubmit()
    } else if (formType == 'Personal History') {
      handlePersonalHistoryFormSubmit()
    } else if (formType == 'Premorbid personality') {
      handlePremorbidPersonalityFormSubmit()
    } else if (formType == 'Mental status examination') {
      handleMentalStatusFormSubmit()
    }
  }

  const handleClearDraft = () => {
    if (formType == 'Chief complaints & Summary') {
      if (detectChanges(initialchiefFormFieldValues, chiefFormFields)) {
        setChiefFormFields(initialchiefFormFieldValues)
      }
    } else if (formType == 'History of Presenting Illness') {
      if (
        detectChanges(
          initialPresentingIllnessValues,
          presentingIllnessFormFields,
        )
      ) {
        setPresentingIllnessFormFields(initialPresentingIllnessValues)
      }
    } else if (formType == 'Past Illness') {
      if (detectChanges(initialPastIllnessFieldValues, pastIllnessFormFields)) {
        setpastIllnessFormFields(initialPastIllnessFieldValues)
      }
    } else if (formType == 'Family history') {
      if (
        detectChanges(initialfamilyHistoryFormValues, familyHistoryFormFields)
      ) {
        setFamilyHistoryFormFields(initialfamilyHistoryFormValues)
      }
    } else if (formType == 'Personal History') {
      if (
        detectChanges(
          initialPersonalHistoryFormValues,
          personalHistoryFormFields,
        )
      ) {
        setpersonalHistoryFormFields(initialPersonalHistoryFormValues)
      }
    } else if (formType == 'Premorbid personality') {
      if (
        detectChanges(
          initialPremorbidPersonalityFormValues,
          premorbidPersonalityFormFields,
        )
      ) {
        setPremorbidPersonalityFormFields(initialPremorbidPersonalityFormValues)
      }
    } else if (formType == 'Mental status examination') {
      if (
        detectChanges(initialMentalStatusFormValues, mentalStatusFormFields)
      ) {
        setMentalStatusFormFields(initialMentalStatusFormValues)
      }
    }
  }

  //     console.log(draftModal, "draftModal");
  // }, [draftModal]);
  // useEffect(() => {
  //     console.log(isDraft, "isDraft");
  // }, [isDraft]);
  // useEffect(() => {
  //     console.log(chiefFormFields, "chiefFormFields");
  // }, [chiefFormFields]);
  // useEffect(() => {
  //     console.log(presentingIllnessFormFields, "presentingIllnessFormFields");
  // }, [presentingIllnessFormFields]);
  // useEffect(() => {
  //     console.log(pastIllnessFormFields, "pastIllnessFormFields");
  // }, [pastIllnessFormFields]);
  // useEffect(() => {
  //     console.log(familyHistoryFormFields, "familyHistoryFormFields");
  // }, [familyHistoryFormFields]);
  // useEffect(() => {
  //     console.log(personalHistoryFormFields, "personalHistoryFormFields");
  // }, [personalHistoryFormFields]);
  // useEffect(() => {
  //     console.log(
  //         premorbidPersonalityFormFields,
  //         "premorbidPersonalityFormFields"
  //     );
  // }, [premorbidPersonalityFormFields]);
  // useEffect(() => {
  //     console.log(mentalStatusFormFields, "mentalStatusFormFields");
  // }, [mentalStatusFormFields]);

  useEffect(() => {
    // if (activeOption == 1) {
    if (formType == 'Chief complaints & Summary') {
      if (isChiefComplaintsSubmitted) {
        // console.log("sucess");
        getChiefFormDatas()
      }
    } else if (formType == 'History of Presenting Illness') {
      if (isPresentingHistorySubmitted) {
        getPresentingIllnessFormDatas()
      }
    } else if (formType == 'Past Illness') {
      if (isPastIllnessSubmitted) {
        getPastIllnessFormDatas()
      }
    } else if (formType == 'Family history') {
      if (isFamilyHistorySubmitted) {
        getFamilyHistoryFormDatas()
      }
    } else if (formType == 'Personal History') {
      if (isPersonalHistorySubmitted) {
        getPersonalHistoryDatas()
      }
    } else if (formType == 'Premorbid personality') {
      if (isPremorbidSubmitted) {
        getPremorbidPersonalityDatas()
      }
    } else if (formType == 'Mental status examination') {
      if (isMentalStatusSubmitted) {
        getMentalStatusDatas()
      }
    }
    // }
    // console.log(id, "id in basic report");
  }, [id, formType, isBasicFormsSubmittedFields])

  return (
    <>
      <Container className={formModal && 'modal-active'}>
        <Cover>
          <FormSelectionContainer>
            Choose Form
            <DateContainer
              onClick={() => {
                // handleForm();
                if (formType == 'Chief complaints & Summary') {
                  if (
                    detectChanges(initialchiefFormFieldValues, chiefFormFields)
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'History of Presenting Illness') {
                  if (
                    detectChanges(
                      initialPresentingIllnessValues,
                      presentingIllnessFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'Past Illness') {
                  if (
                    detectChanges(
                      initialPastIllnessFieldValues,
                      pastIllnessFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'Family history') {
                  if (
                    detectChanges(
                      initialfamilyHistoryFormValues,
                      familyHistoryFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'Personal History') {
                  if (
                    detectChanges(
                      initialPersonalHistoryFormValues,
                      personalHistoryFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'Premorbid personality') {
                  if (
                    detectChanges(
                      initialPremorbidPersonalityFormValues,
                      premorbidPersonalityFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else if (formType == 'Mental status examination') {
                  if (
                    detectChanges(
                      initialMentalStatusFormValues,
                      mentalStatusFormFields,
                    )
                  ) {
                    setdraftModal(true)
                    setIsDraft(true)
                  } else {
                    setformModal(!formModal)
                  }
                } else {
                  setformModal(!formModal)
                }
              }}
            >
              <Date>{formType}</Date>
              <Icon src={down_arrow} alt="icon" />
            </DateContainer>
            <FormModal
              setdraftModal={setdraftModal}
              setformModal={setformModal}
              formModal={formModal}
              forms={forms}
            />
          </FormSelectionContainer>
          <BottomContainer>
            {formType == 'Chief complaints & Summary' ? (
              <ChiefComplaints
                setdraftModal={setIsDraft}
                draftModal={draftModal}
                setShowAttentionModal={setShowAttentionModal}
                showErrors={showErrors}
                session_id={id}
                chiefFormFields={chiefFormFields}
                initialchiefFormFieldValues={initialchiefFormFieldValues}
                setChiefFormFields={setChiefFormFields}
                detectChanges={detectChanges}
              />
            ) : formType == 'History of Presenting Illness' ? (
              <PresentingIllness
                setdraftModal={setIsDraft}
                setFormType={setFormType}
                formType={formType}
                session_id={id}
                presentingIllnessFormFields={presentingIllnessFormFields}
                initialPresentingIllnessValues={initialPresentingIllnessValues}
                setPresentingIllnessFormFields={setPresentingIllnessFormFields}
                handlePresentingIllnessFormSubmit={
                  handlePresentingIllnessFormSubmit
                }
                detectChanges={detectChanges}
              />
            ) : formType == 'Past Illness' ? (
              <PastIllness
                setdraftModal={setIsDraft}
                formType={formType}
                session_id={id}
                pastIllnessFormFields={pastIllnessFormFields}
                setpastIllnessFormFields={setpastIllnessFormFields}
                initialPastIllnessFieldValues={initialPastIllnessFieldValues}
                handlePastIllnessFormSubmit={handlePastIllnessFormSubmit}
                detectChanges={detectChanges}
              />
            ) : formType == 'Family history' ? (
              <FamilyHistory
                setdraftModal={setIsDraft}
                formType={formType}
                session_id={id}
                familyHistoryFormFields={familyHistoryFormFields}
                setFamilyHistoryFormFields={setFamilyHistoryFormFields}
                initialfamilyHistoryFormValues={initialfamilyHistoryFormValues}
                handleFamilyHistoryFormSubmit={handleFamilyHistoryFormSubmit}
                detectChanges={detectChanges}
              />
            ) : formType == 'Personal History' ? (
              <PersonalHistory
                setdraftModal={setIsDraft}
                formType={formType}
                session_id={id}
                personalHistoryFormFields={personalHistoryFormFields}
                setpersonalHistoryFormFields={setpersonalHistoryFormFields}
                initialPersonalHistoryFormValues={
                  initialPersonalHistoryFormValues
                }
                handlePersonalHistoryFormSubmit={
                  handlePersonalHistoryFormSubmit
                }
                detectChanges={detectChanges}
                gender={gender}
              />
            ) : formType == 'Premorbid personality' ? (
              <PremorbidPersonality
                setdraftModal={setIsDraft}
                formType={formType}
                session_id={id}
                premorbidPersonalityFormFields={premorbidPersonalityFormFields}
                setPremorbidPersonalityFormFields={
                  setPremorbidPersonalityFormFields
                }
                initialPremorbidPersonalityFormValues={
                  initialPremorbidPersonalityFormValues
                }
                handlePremorbidPersonalityFormSubmit={
                  handlePremorbidPersonalityFormSubmit
                }
                detectChanges={detectChanges}
              />
            ) : formType == 'Mental status examination' ? (
              <MentalStatusExamination
                setdraftModal={setIsDraft}
                formType={formType}
                session_id={id}
                mentalStatusFormFields={mentalStatusFormFields}
                setMentalStatusFormFields={setMentalStatusFormFields}
                initialMentalStatusFormValues={initialMentalStatusFormValues}
                handleMentalStatusFormSubmit={handleMentalStatusFormSubmit}
                detectChanges={detectChanges}
                drop={contentsOptions}
              />
            ) : (
              ''
            )}
          </BottomContainer>
        </Cover>
        {formType == 'Chief complaints & Summary' && (
          <RightBottom>
            <ButtonLeft>
              <ButtonTypeOne
                text="Cancel"
                color={THEME_COLORS.chips_blue_on_container_2}
                bkgcolor={THEME_COLORS.white}
                border="none"
                onclick={handleCancel}
              />
            </ButtonLeft>
            <ButtonRight>
              {activeOption !== 1 && (
                <ButtonTypeOne
                  text="Previous"
                  color={THEME_COLORS.dark_500}
                  bkgcolor={THEME_COLORS.white}
                  border={THEME_COLORS.light_400}
                  onclick={handlePrevious}
                />
              )}
              <ButtonTypeOne
                text={activeOption !== 3 ? 'Next' : 'Submit'}
                ml="10px"
                color={THEME_COLORS.white}
                bkgcolor={THEME_COLORS.chips_blue_on_container_2}
                onclick={handleNext}
              />
            </ButtonRight>
          </RightBottom>
        )}
      </Container>
      {draftModal && (
        <DraftModal
          setformModal={setformModal}
          setShowAttentionModal={setdraftModal}
          showAttentionModal={draftModal}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          handleDraftSubmit={handleDraftSubmit}
          handleClearDraft={handleClearDraft}
        />
      )}
    </>
  )
}

export default BasicReport
const Container = styled.div`
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.modal-active {
    max-height: calc(100vh - 69.6px);
    overflow: hidden;
  }
`
const Cover = styled.div`
  /* border-bottom: 1px solid #d3d3d3; */
`
const FormSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  font-size: 16px;
  position: relative;
`
const BottomContainer = styled.div``
const Date = styled.p`
  color: ${THEME_COLORS.dark_500};
  font-family: 'DM_sans_medium';
  font-size: 14px;
  margin-right: 5px;
`
const Icon = styled.img``
const DateContainer = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 8px;
  background: ${THEME_COLORS.white};
  cursor: pointer;
`

const RightBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #d3d3d3;
  @media all and (max-width: 980px) {
    margin-top: 50px;
  }
`
const ButtonLeft = styled.div``
const ButtonRight = styled.div`
  display: flex;
`
