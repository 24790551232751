import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import calender from '../../../assets/image/dashboard/session-card/calender.svg'
function SessionDateChip({ day }) {
  return (
    <>
      <ChipContainer>
        <IconContainer>
          <Icon src={calender} alt="icon" />
        </IconContainer>
        <Date>{day}</Date>
      </ChipContainer>
    </>
  )
}

export default SessionDateChip

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 4px 8px 4px 3px;
  border-radius: 4px;
  background: ${THEME_COLORS.light_200};
  justify-content: space-between;
  /* cursor: pointer; */
`

const Date = styled.p`
  color: ${THEME_COLORS.dark_300};

  font-family: 'DM_sans_medium';
  font-size: 12px;
`
const IconContainer = styled.div`
  width: 16px;
  margin-right: 5px;
`
const Icon = styled.img`
  display: block;
  width: 100%;
`
