import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";
import ChipCircle from "../../includes/chips/ChipCircle";
import ChipRectangle from "../../includes/chips/ChipRectangle";
import report from "../../../assets/image/session_summary/report_add.svg";

const TransactionsListCard = ({ number, client }) => {
    // console.log(client, "client");
    const navigate = useNavigate();
    return (
        <Card>
            <Items>
                <ListItem type="date">{client?.transaction_id}</ListItem>
                <ListItem type="name">{client.transaction_date}</ListItem>
                <ListItem type="date">{client.short_message}</ListItem>
                <ListItem type="name">{client.type}</ListItem>
                <ListItem type="report" className="">
                    {client.amount} INR
                </ListItem>
            </Items>
        </Card>
    );
};

export default TransactionsListCard;

const Card = styled.div`
    min-width: 100%;
    height: max-content;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
    &:last-child {
        border-bottom: none;
    }
`;
const Items = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    height: 100%;
`;
const ListItem = styled.li`
    font-size: 14px;
    font-weight: 500;
    color: ${THEME_COLORS.text_title};
    text-align: left;
    display: flex;
    padding: 10px;
    align-items: center;
    line-height: 20px;
    /* justify-content: center; */
    width: ${(props) =>
        props.type === "no"
            ? "5%"
            : props.type === "count"
            ? "8%"
            : props.type === "date"
            ? "20%"
            : props.type === "name"
            ? "15%"
            : props.type === "report"
            ? "20%"
            : "10%"};
    &.summary {
        justify-content: flex-end;
    }

    @media all and (max-width: 1268px) {
        width: ${(props) =>
            props.type === "no"
                ? "5%"
                : props.type === "count"
                ? "20%"
                : props.type === "date"
                ? "20%"
                : props.type === "name"
                ? "20%"
                : props.type === "report"
                ? "25%"
                : "10%"};
        &.tab-view {
            display: none;
        }
    }
`;
const Report = styled.div`
    width: max-content;
    margin: auto;
`;

const Main = styled(Link)`
    height: 100%;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 8px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: ${(props) => props.mr_left}; */
    cursor: pointer;
    span {
        color: ${THEME_COLORS.chips_blue_on_container_2};
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
    }
    &:hover {
        background: ${THEME_COLORS.gradient_2};
    }
    &.active {
        background: none;
    }

    /* @media all and (max-width:1440px){
    padding: 6px;
  } */
`;
const ImageBox = styled.div`
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
    }
`;
