import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME_COLORS } from '../../../ThemeConfig'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import DropDown from '../../includes/dropdown/DropDown'
import AttentionModal from './modals/AttentionModal'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import FileUploadButton from '../../includes/FileUploadButton'

const BankAccount = ({
  setShowSuccessToast,
  showAttentionModal,
  setShowAttentionModal,
}) => {
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [bankAccount, setBankAccount] = useState('')
  const [showBankAccount, setShowBankAccount] = useState(false)
  const [country, setCountry] = useState('')
  const [showCountry, setShowCountry] = useState(false)
  const [holderName, setHolderName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [statementFile, setStatementFile] = useState('')
  const [statementFileName, setStatementFileName] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [fileError, setFileError] = useState(false)
  const [file, setFile] = useState({})
  const [ifscCode, setIfscCode] = useState('')
  const [panNumber, setPanNumber] = useState('')

  const handleKeyDown = (event) => {
    event.preventDefault()

    if (event.key === 'Backspace') {
      setPanNumber((prevValue) => prevValue.substring(0, prevValue.length - 1))
    }

    if (event.key.length === 1) {
      const currentLength = panNumber.length
      const alphaValid = currentLength < 5 || currentLength === 9
      const numericValid = currentLength >= 5 && currentLength < 9

      if (
        alphaValid &&
        event.key >= 'a' &&
        event.key <= 'z' &&
        !event.ctrlKey
      ) {
        setPanNumber((prevValue) => prevValue + event.key.toUpperCase())
      } else if (
        alphaValid &&
        event.key >= 'A' &&
        event.key <= 'Z' &&
        !event.ctrlKey
      ) {
        setPanNumber((prevValue) => prevValue + event.key)
      } else if (
        numericValid &&
        event.key >= '0' &&
        event.key <= '9' &&
        !event.ctrlKey
      ) {
        setPanNumber((prevValue) => prevValue + event.key)
      }
    }
  }

  const handleIfscCodeChange = (event) => {
    event.preventDefault()

    if (event.key === 'Backspace') {
      setIfscCode((prevValue) => prevValue.substring(0, prevValue.length - 1))
    }

    if (event.key.length === 1) {
      const currentLength = ifscCode.length

      if (currentLength < 4) {
        // Allow only letters for the first four characters
        if (/[a-zA-Z]/.test(event.key)) {
          setIfscCode((prevValue) => prevValue + event.key.toUpperCase())
        }
      } else if (currentLength === 4) {
        // The fifth character must be '0'
        if (event.key === '0' && !event.ctrlKey) {
          setIfscCode((prevValue) => prevValue + event.key)
        }
      } else if (currentLength > 4 && currentLength < 11) {
        // Allow only digits for the last six characters
        if (/[0-9]/.test(event.key) && !event.ctrlKey) {
          setIfscCode((prevValue) => prevValue + event.key)
        }
      }
    }
  }

  const [selectedFiles, setSelectedFiles] = useState({
    IdDocument: null,
    Education: null,
    RCI: null,
  })

  const handleFileChange = (e, contentName) => {
    const file = e.target.files[0]
    setSelectedFiles((prev) => ({
      ...prev,
      [contentName]: file,
    }))
  }

  const handleClear = (contentName) => {
    setSelectedFiles((prev) => ({
      ...prev,
      [contentName]: null,
    }))
    document.getElementById(`${contentName}FileInput`).value = ''
  }

  const bankAccountOptions = [
    {
      id: 1,
      name: 'Federal bank',
    },

    {
      id: 2,
      name: 'SBI',
    },

    {
      id: 3,
      name: 'BOB',
    },
    {
      id: 3,
      name: 'HDFC',
    },
    {
      id: 3,
      name: 'AXIS',
    },
  ]
  const countryOptions = [
    {
      id: 1,
      name: 'India',
    },

    {
      id: 2,
      name: 'US',
    },

    {
      id: 3,
      name: 'Canada',
    },
    {
      id: 3,
      name: 'Australia',
    },
  ]

  const navigate = useNavigate()

  const handleDropdown = (type) => {
    switch (type) {
      case 'bank_account':
        setShowBankAccount(!showBankAccount)
        break
      case 'country':
        setShowCountry(!showCountry)
        break
      default:
        break
    }
  }

  const handleBankAccount = (option) => {
    setBankAccount(option)
    setShowBankAccount(!showBankAccount)
  }

  const handleCountry = (option) => {
    setCountry(option)
    setShowCountry(!showCountry)
  }

  const handleOuterClick = (e) => {
    const element = document.querySelector('.outer-click')
    if (!element.contains(e.target)) {
      setShowBankAccount(false)
      setShowCountry(false)
    }
  }

  const addFile = (e, type) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
    setSelectedFiles((prev) => ({
      ...prev,
      [selectedFile]: file,
    }))
    const fileSize = e.target.files.item(0).size
    const fileMb = fileSize / 1024 ** 2
    if (fileMb <= 5) {
      setFileError(false)
      const file = new FileReader()
      // extract filename from fullpath
      let fullPath = e.target.value
      let startIndex =
        fullPath.indexOf('\\') >= 0
          ? fullPath.lastIndexOf('\\')
          : fullPath.lastIndexOf('/')
      let filename = fullPath.substring(startIndex)
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1)
      }

      file.addEventListener('load', () => {
        if (type === 'statement') {
          setStatementFile(file.result)
          setStatementFileName(filename)
          setFile(file)
        }
      })
      file.readAsDataURL(e.target.files[0])
    } else {
      setFileError(true)
    }
  }

  const cancelFile = (type) => {
    if (type === 'statement') {
      setStatementFile('')
      setStatementFileName('')
    }
  }

  const clearStates = () => {
    setBankAccount('')
    setCountry('')
    setHolderName('')
    setAccountNumber('')
    setIfscCode('')
    setPanNumber('')
    setStatementFile('')
    setStatementFileName('')
    setShowBankAccount(false)
    setShowCountry(false)
    setShowErrors(false)
    setShowAttentionModal(false)
  }

  const handleSubmit = (close) => {
    setFileError('')

    setShowSuccessToast(true)
    clearStates()
    if (close === 'close') {
      setTimeout(() => {
        navigate(-1)
      }, 2000)
    }
  }

  const handleCancel = () => {
    if (
      bankAccount ||
      country ||
      holderName ||
      accountNumber ||
      ifscCode ||
      statementFile
    )
      setShowAttentionModal(true)
    else {
      clearStates()
      navigate(-1)
    }
  }

  const handleInputValidation = (inputValue, setInputState) => {
    const regex = /^[A-Za-z]+$/ // Regular expression to match only letters

    if (regex.test(inputValue) || inputValue === '') {
      setInputState(inputValue)
    }
  }

  const handleInputValidationNumber = (inputValue, setInputState) => {
    const regex = /^[0-9]+$/ // Regular expression to match only numbers

    if (regex.test(inputValue) || inputValue === '') {
      setInputState(inputValue)
    }
  }

  const handlFinalSubmit = () => {
    if (
      bankAccount &&
      country &&
      holderName &&
      ifscCode &&
      statementFile &&
      accountNumber
    ) {
      const data = {
        country: country,
        bank_account: bankAccount,
        account_holder_name: holderName,
        bank_account_number: accountNumber,
        ifsc_code: ifscCode,
        pan_card_number: panNumber,
        bank_statement: statementFile,
      }
      expert
        .post('/expert-bank-account-details/', data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          const { access_token, refresh_token, message } = response.data.data

          setShowErrors('')
          handleSubmit()
        })
        .catch((error) => {
          console.error('Error creating post personal-infooo:', error.response)
          if (error.response.status === 404) {
            setShowErrors('NOT_FOUND')
          }
        })
    } else {
      setShowErrors('*This field is mandatory')
    }
  }

  return (
    <Form
      onClick={(e) => (showBankAccount || showCountry) && handleOuterClick(e)}
    >
      <FormData>
        <Head>Bank Account Details</Head>
        <TitleWrapper>
          <Title>Bank Account Details*</Title>
          <SubTitle>Provide your Personal Info</SubTitle>
        </TitleWrapper>
        <InputContainer>
          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <SelectLabel
                  className={
                    bankAccount ? 'select-label active' : 'select-label'
                  }
                >
                  Select Bank *
                </SelectLabel>
                <SelectValue className="select-value">
                  {bankAccount}
                </SelectValue>
              </Left>
              <Right onClick={() => handleDropdown('bank_account')}>
                <img
                  src={
                    require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                      .default
                  }
                  alt="down arrow"
                />
              </Right>
              {showBankAccount && (
                <DropDown
                  list={bankAccountOptions}
                  handleMethod={handleBankAccount}
                  state={bankAccount}
                />
              )}
            </InputWrapper>
            {showErrors && !bankAccount && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <SelectLabel
                  className={country ? 'select-label active' : 'select-label'}
                >
                  Country/Region *
                </SelectLabel>
                <SelectValue className="select-value">{country}</SelectValue>
              </Left>
              <Right onClick={() => handleDropdown('country')}>
                <img
                  src={
                    require('../../../assets/image/manage_profile/down_arrow_gray.svg')
                      .default
                  }
                  alt="down arrow"
                />
              </Right>
              {showCountry && (
                <DropDown
                  list={countryOptions}
                  handleMethod={handleCountry}
                  state={country}
                />
              )}
            </InputWrapper>
            {showErrors && !country && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <FormLabel
                  htmlFor="holder_name"
                  className={holderName ? 'input-label show' : 'input-label'}
                >
                  Account holder Name *
                </FormLabel>
                <FormInput
                  id="holder_name"
                  name="holderName"
                  value={holderName}
                  type="text"
                  onInput={(e) =>
                    handleInputValidation(e.target.value, setHolderName)
                  }
                  placeholder="Account holder Name *"
                  className={holderName ? 'input-value show' : 'input-value'}
                />
              </Left>
            </InputWrapper>
            {showErrors && !holderName && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <FormLabel
                  htmlFor="account_number"
                  className={accountNumber ? 'input-label show' : 'input-label'}
                >
                  Bank Account Number *
                </FormLabel>
                <FormInput
                  id="account_number"
                  name="accountNumber"
                  value={accountNumber}
                  type="number"
                  onInput={(e) =>
                    handleInputValidationNumber(
                      e.target.value,
                      setAccountNumber,
                    )
                  }
                  placeholder="Bank Account Number *"
                  className={accountNumber ? 'input-value show' : 'input-value'}
                />
              </Left>
            </InputWrapper>
            {showErrors && !accountNumber && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <FormLabel
                  htmlFor="ifsc_code"
                  className={ifscCode ? 'input-label show' : 'input-label'}
                >
                  IFSC Code *
                </FormLabel>
                <FormInput
                  id="ifsc_code"
                  name="ifscCode"
                  value={ifscCode}
                  type="text"
                  onKeyDown={handleIfscCodeChange}
                  // onChange={(e) => setIfscCode(e.target.value)}
                  pattern="[A-Za-z]{4}0[0-9]{6}"
                  placeholder="IFSC Code *"
                  className={ifscCode ? 'input-value show' : 'input-value'}
                />
              </Left>
            </InputWrapper>
            {showErrors && !ifscCode && (
              <Error className="error">This field is required.</Error>
            )}
          </Wrapper>

          <Wrapper>
            <InputWrapper className="input-wrapper">
              <Left>
                <FormLabel
                  htmlFor="pan_number"
                  className={panNumber ? 'input-label show' : 'input-label'}
                >
                  Pan card number
                </FormLabel>
                <FormInput
                  id="pan_number"
                  name="panNumber"
                  value={panNumber}
                  type="text"
                  onKeyDown={handleKeyDown}
                  // onChange={(e) => setPanNumber(e.target.value)}
                  pattern="[A-Za-z]{5}[0-9]{4}[A-Za-z]"
                  placeholder="Pan card number"
                  className={panNumber ? 'input-value show' : 'input-value'}
                />
              </Left>
            </InputWrapper>
          </Wrapper>
        </InputContainer>
        <StatementContainer>
          <Statement>
            <TitleWrapper className="statement">
              <Title>Bank Statement Document*</Title>
              <SubTitle>
                Upload a copy of your bank statement
                <br />
                (current to the last 3 months)
              </SubTitle>
            </TitleWrapper>
            <Points>
              <Point>
                1. Supported file types : png, jpg, jpeg, bmp, pdf, doc, docx{' '}
              </Point>
              <Point>2. Maximum upload file size : 5 MB </Point>
              <Point>3. Maximum file number 1</Point>
            </Points>
          </Statement>
          <FileUpload>
            <InputFileWrapper className={statementFile && 'active'}>
              <Input
                id="statement"
                type="file"
                accept="application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                image/jpg,image/png,image/jpeg,image/bmp"
                onChange={(e) => addFile(e, 'statement')}
                // onChange={(e) => handleFileChange(e, 'statement')}
              />
              <InputLabelWrapper>
                {statementFileName ? (
                  <FilenameWrapper>
                    <InputLabel htmlFor="statement" className="active">
                      {statementFileName}
                    </InputLabel>
                    <CancelButton
                      onClick={() => cancelFile('statement')}
                      src={
                        require('../../../assets/image/manage_profile/cancel.svg')
                          .default
                      }
                      alt="cancel"
                    />
                  </FilenameWrapper>
                ) : (
                  <InputLabel htmlFor="statement">+ Upload</InputLabel>
                )}
              </InputLabelWrapper>
            </InputFileWrapper>
            {showErrors && !statementFile && (
              <Error className="error">Please upload a file.</Error>
            )}
            {fileError && (
              <Error className="error">File size exceeds 5MB</Error>
            )}
          </FileUpload>
          {/* <div className="file">
            {' '}
            <FileUploadButton
              contentName="IdDocument"
              selectedFile={selectedFiles.IdDocument}
              handleFileChange={handleFileChange}
              handleClear={handleClear}
            />
          </div> */}
        </StatementContainer>
      </FormData>
      <FormButtons>
        <ButtonTypeOne
          text="Cancel"
          color={THEME_COLORS.dark_500}
          bkgcolor={THEME_COLORS.white}
          border={THEME_COLORS.light_400}
          onclick={handleCancel}
        />
        <ButtonTypeOne
          text="Save"
          color={THEME_COLORS.white}
          bkgcolor={THEME_COLORS.chips_blue_on_container_2}
          onclick={handlFinalSubmit}
        />
      </FormButtons>
      {showAttentionModal && (
        <AttentionModal
          setShowAttentionModal={setShowAttentionModal}
          showAttentionModal={showAttentionModal}
          handleSubmit={handleSubmit}
        />
      )}
    </Form>
  )
}

export default BankAccount

const Form = styled.form`
  min-height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const FormData = styled.div``

const Head = styled.h4`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 24px;
  color: ${THEME_COLORS.black};
`
const TitleWrapper = styled.div`
  margin-bottom: 32px;
  &.statement {
    margin-bottom: 8px;
  }
`
const Title = styled.h5`
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
`
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.text_para};
`
const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const Wrapper = styled.div`
  margin-bottom: 18px;
  width: 49%;
`
const InputWrapper = styled.div``
const Left = styled.div`
  flex-grow: 1;
`
const Right = styled.div``
const Error = styled.small`
  margin-top: 10px;
`
const SelectLabel = styled.label`
  &.active {
    font-size: 12px;
  }
`
const SelectValue = styled.p``
const FormInput = styled.input`
  &.active {
    font-weight: 500;
    font-size: 16px !important;
  }
`
const FormLabel = styled.label`
  &.show {
    display: block;
  }
`
const StatementContainer = styled.div`
  margin-top: 42px;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 1280px) {
    flex-direction: column;
  }
  &.file {
    height: 200px;
  }
`
const Statement = styled.div`
  width: 52%;
  @media all and (max-width: 1280px) {
    width: 100%;
    margin-bottom: 16px;
  }
`
const Points = styled.ul``
const Point = styled.li`
  font-weight: 400;
  font-size: 12px;
  color: ${THEME_COLORS.text_para};
`
const FileUpload = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media all and (max-width: 1280px) {
    width: 100%;
  }
`
const InputFileWrapper = styled.div`
  border-radius: 8px;
  padding: 6px 16px;
  border: 2px solid ${THEME_COLORS.light_400};
  min-height: 32px;
  width: max-content;
  max-width: 100%;
  &.active {
    background: ${THEME_COLORS.chips_green_container};
    padding: 8px 16px;
    border: none;
  }
`
const Input = styled.input`
  display: none;
`
const InputLabelWrapper = styled.div`
  cursor: pointer;
`
const InputLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
  &.active {
    color: ${THEME_COLORS.chips_green_on_container};
  }
`
const FilenameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const CancelButton = styled.img`
  margin-left: 10px;
`
const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;
`
