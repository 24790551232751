import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { THEME_COLORS } from '../../../ThemeConfig'
import arrow from '../../../assets/image/dashboard/left-arrow-blue.svg'
import DashboardHeader from '../../genaral/DashboardHeader'
import DateCard from '../../includes/cards/DateCard'
import DateChip from '../../includes/chips/DateChip'
import DateDropdown from '../../includes/dropdown/DateDropdown'
import DateModal from '../../includes/modals/DateModal'
import SessionSlotModal from '../session_summary/modals/SessionSlotModal'
import UpdateSlotModal from '../session_summary/modals/UpdateSlotModal'
import SlotCard from './SlotCard'
import { expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import CalenderModal from '../session_summary/modals/CalenderModal'
import Calendar from 'react-calendar'
import slot from '../../../assets/image/dashboard/update-slot-blue.svg'
import editIcon from '../../../assets/image/schedule/edit.svg'
import SetDefaultSlotMdal from '../session_summary/modals/SetDefaultSlotMdal'

function NewSlotDetailPage() {
  const { state, dispatch } = useContext(Context)

  const access_token = state.user_details.access_token

  const [datemodal, setDateModal] = useState(false)
  const [filtermodal, setFilterModal] = useState(false)
  const [month, setMonth] = useState('January')
  const [filter, setFilter] = useState('Upcoming')
  const [datecards, setDateCards] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [date, setDate] = useState(new Date().getDate())
  const [day, setDay] = useState(new Date().getDay())
  const [dot, setDot] = useState(true)
  const [updatemodal, setUpdateModal] = useState(false)
  const [sessionData, setSessionData] = useState([])
  const [error, SetError] = useState('')

  const [selectedDays, setSelectedDays] = useState('')
  const [selectedMonths, setSelectedMonths] = useState('')
  const [selectedYears, setSelectedYears] = useState('')

  const [calendarDay, setCalendarDay] = useState('')
  const [calendarMonth, setCalendarMonth] = useState('')
  const [calendarYear, setCalendarYear] = useState('')
  const [fromatedDate, setfromatedDate] = useState('')

  const currentDate = new Date()

  const [isDefaultTimeSlotModal, setIsDefaultTimeSlotModal] = useState(false)

  // useEffect(() => {
  //   // Disable scrolling on the body element when the modal is open
  //   document.body.classList.add('modal-open')
  //   // Cleanup function to re-enable scrolling when the modal is closed
  //   return () => {
  //     document.body.classList.remove('modal-open')
  //   }
  // }, [])
  const handleModal = () => {
    setIsDefaultTimeSlotModal(false)
  }

  function getFormattedDate() {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const currentDate = new window.Date()
    const month = months[currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }

  // useEffect(() => {
  //     showDates();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleDateClick = (dateObj) => {
    const { date, month, year } = dateObj
    const monthNumberMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    }

    const monthNumber = monthNumberMap[month]
    setSelectedMonths(monthNumber)
    setSelectedDays(date)
    setSelectedYears(year)
  }
  useEffect(() => {
    if (selectedDate) {
      handleDateClick(selectedDate)
    } else {
      const day = currentDate.getDate()

      const currentMonthNumber = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()

      setSelectedMonths(currentMonthNumber)
      setSelectedDays(day)
      setSelectedYears(year)
    }
  }, [selectedDate])

  const getSessions = () => {
    expert
      .get('/sessions/committed/', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          date: calendarDay,
          month: calendarMonth,
          year: calendarYear,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data
        if (StatusCode == 6000) {
          setSessionData(response.data.data)
        } else if (StatusCode == 6001) {
          SetError(response)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        SetError(error.message)
      })
  }
  useEffect(() => {
    getSessions()
  }, [calendarDay, calendarMonth, calendarYear])

  const handleCaledarClick = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const selectedMonthNumber = date.getMonth() + 1
      const selectedMonth = date.toLocaleString('default', {
        month: 'short',
      })
      const selectedDay = date.getDate()
      const selectedYear = date.getFullYear()

      const formattedDate = `${selectedMonth} ${selectedDay}, ${selectedYear}`

      setCalendarDay(selectedDay)
      setCalendarMonth(selectedMonthNumber)
      setCalendarYear(selectedYear)
      setSelectedDate(formattedDate)
    }
  }

  const setDefaultDate = () => {
    const currentDate = new Date()
    const defaultMonthNumber = currentDate.getMonth() + 1
    const defaultMonth = currentDate.toLocaleString('default', {
      month: 'short',
    })
    const defaultDay = currentDate.getDate()
    const defaultYear = currentDate.getFullYear()

    const formattedDate = `${defaultMonth} ${defaultDay}, ${defaultYear}`

    setCalendarDay(defaultDay)
    setCalendarMonth(defaultMonthNumber)
    setCalendarYear(defaultYear)
    setSelectedDate(formattedDate)
  }

  useState(() => {
    setDefaultDate()
  }, [])

  return (
    <>
      <MainContainer
        className={(updatemodal || isDefaultTimeSlotModal) && 'modal-active'}
      >
        <Container>
          <Cover>
            <TopContainer>
              {/* <ButtonContainer to="/">
                                <Icon src={arrow} />
                                <Content>Back to Dashboard</Content>
                            </ButtonContainer> */}
              <DateChip />
              <ButtonDiv>
                <DefaultButton
                  onClick={() => {
                    setIsDefaultTimeSlotModal(true)
                  }}
                >
                  <EditIcon src={editIcon} alt="icon" />
                  Default Time
                </DefaultButton>
                <UpdateButton>
                  <Deatil
                    onClick={() => {
                      setUpdateModal(true)
                    }}
                  >
                    <Icon src={slot} />
                    <Content>Update Slots</Content>
                  </Deatil>
                </UpdateButton>
              </ButtonDiv>
            </TopContainer>
            <Bottom>
              <RightContainer>
                <Calendar
                  onClickDay={(date) => {
                    handleCaledarClick(date)
                  }}
                />
                <DetailsCard>
                  Select a date in the calendar to view all the committed
                  sessions for that day.
                </DetailsCard>
              </RightContainer>
              <LeftContainer>
                {selectedDate ? (
                  <Heading>All Schedule - {selectedDate}</Heading>
                ) : (
                  <Heading>
                    All Schedule - {getFormattedDate(currentDate)}
                  </Heading>
                )}
                <BottomContainer>
                  <SlotCard
                    sessionData={sessionData}
                    day={selectedDays}
                    month={selectedMonths}
                    year={selectedYears}
                  />
                </BottomContainer>
              </LeftContainer>
            </Bottom>
          </Cover>
          {updatemodal && (
            <UpdateSlotModal
              updatemodal={updatemodal}
              setUpdateModal={setUpdateModal}
            />
          )}

          {isDefaultTimeSlotModal && (
            <SetDefaultSlotMdal
              setModal={setIsDefaultTimeSlotModal}
              handleModal={handleModal}
              isModal={isDefaultTimeSlotModal}
            />
          )}
        </Container>
      </MainContainer>
    </>
  )
}

export default NewSlotDetailPage
const MainContainer = styled.section`
  /* height: 100vh; */
  &.modal-active {
    height: 90vh;
    overflow: hidden;
  }
`
const Container = styled.section`
  padding: 20px 30px;
  position: relative;
`
const Cover = styled.section`
  padding: 16px;
  border: 1px solid ${THEME_COLORS.light_200};
  border-radius: 10px;
`
const LeftContainer = styled.div`
  width: 70%;
  padding: 40px 20px 20px 20px;
  background: ${THEME_COLORS.chips_green_container};
  border-radius: 10px;
  overflow: scroll;
  height: calc(100vh - 220px);
  ::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1280px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
const RightContainer = styled.div`
  width: 30%;
  margin-right: 30px;
  @media all and (max-width: 1280px) {
    width: 100%;
    margin-right: 0;
  }
  .react-calendar {
    padding: 8px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: auto;
    border-radius: 10px;
    box-shadow: ${THEME_COLORS.elevation_expert_e3};
    @media all and (max-width: 1400px) {
      width: 100%;
    }
  }

  .react-calendar button {
    display: flex !important;
    font-size: 14px;
    padding: 12px;
    border-radius: 48px;
    width: 35px !important;
    height: 55px;
    justify-content: center;
    align-items: center;
    /* flex: 0 0 13.2857% !important; */
    @media all and (max-width: 1400px) {
      font-size: 14px;
      height: 60px !important;
    }

    &:hover {
      background: ${THEME_COLORS.chips_green_container};
    }

    @media all and (max-width: 1400px) {
    }
  }

  .react-calendar__tile--now {
    background: ${THEME_COLORS.chips_green_container};
  }

  .react-calendar__tile--active {
    background: ${THEME_COLORS.chips_green_on_container} !important;
    color: white;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    &:hover {
      background: none;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    font-family: 'DM_sans_medium';
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    span {
      font-family: 'DM_sans_medium' !important;
    }
  }
  .react-calendar__navigation__prev2-button {
    background: ${THEME_COLORS.chips_blue_container};
    /* display: none; */
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__prev2-button {
    display: none !important;
  }
  .react-calendar__tile .react-calendar__year-view__months__month {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__label {
    background: none;
    &:hover {
      background: none;
    }
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar button {
    display: flex;
  }
`
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 8px;
`
const DateDiv = styled.div`
  position: relative;
`
const Filter = styled.div`
  position: relative;
`
const Middle = styled.div``
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 1280px) {
    flex-wrap: wrap-reverse;
    /* flex-direction: column; */
  }
`

const BottomContainer = styled.div``
const RightDiv = styled.div`
  display: flex;
  width: 15%;
  justify-content: flex-end;
`
const ButtonContainer = styled(Link)`
  display: flex;
  justify-content: space-evenly;
  width: 175px;
  cursor: pointer;
`
const Content = styled.p`
  color: ${THEME_COLORS.chips_blue_on_container_2};
`
const Icon = styled.img`
  margin-right: 5px;
`
const Dates = styled.div`
  margin-bottom: 20px;
`
const Heading = styled.h2`
  margin-bottom: 20px;
  color: ${THEME_COLORS.text_title};
  font-size: 18px;
`
const Deatil = styled(Link)`
  padding: 8px 15px;
  background: ${THEME_COLORS.chips_blue_container};
  /* width: 80%; */
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @media all and (max-width: 1080px) {
    width: 350px;
  }
`

const UpdateButton = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 1280px) {
    flex-wrap: wrap-reverse;
  }
`
const DetailsCard = styled.div`
  padding: 20px;
  background: #e0f8f3;
  margin-top: 20px;
  border-radius: 8px;
  color: #818181;
  font-size: 14px;
  border: 1px dashed #818181a3;
`
const DefaultButton = styled.div`
  /* width: 130px; */
  width: fit-content;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  border-radius: 8px;
  background: ${THEME_COLORS.chips_blue_container};
  cursor: pointer;
  color: ${THEME_COLORS.chips_blue_on_container_2};
  font-family: 'DM_sans_medium';
  font-size: 14px;
`
const EditIcon = styled.img`
  /* width: 100%;
    display: block; */
`
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
