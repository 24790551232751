import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import MainRouter from './components/routing/routers/MainRouter'
import Store from './contexts/Store'

function App() {
  return (
    <Router>
      <Store>
        <MainRouter />
      </Store>
    </Router>
  )
}

export default App
